import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_EMP_PROFILE_FOR_ROSTER = gql`
  query getEmployeeProfileForRoster(
    $hostelId: ID
    $rosterMonth: Int
    $rosterYear: Int
  ) {
    getEmployeeProfileForRoster(
      hostelId: $hostelId
      rosterMonth: $rosterMonth
      rosterYear: $rosterYear
    ) {
      _id
      hostel {
        _id
        name
      }
      prefix
      fullName
      spouseFullName
      fathersFullName
      empCode
      dob
      gender
      bloodGroup
      maritalStatus
      personalEmail
      presentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      permanentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      mobileNo
      skilledType
      doj
      empType
      empStatus
      department
      subDepartment
      designation
      businessUnit
      location
      uan
      esic
      aadhaar
      pan
      bankDetails {
        bankName
        branchName
        accountNumber
        ifscCode
      }
      emergencyName
      emergencyNo
      images {
        image
        key
        type
      }
      dateOfExit
      resignedStatus
      exitReason
      transferDate
      confirmationDate
      currentCTC
      newCTC
      appraisedDate
      appraisalDate
      nextAppraisalDate
      performanceGrade
      remarks
      appraisedPDF
      appraisalLogs {
        currentCTC
        newCTC
        designation
        newDesignation
        appraisedDate
        appraisalDate
        nextAppraisalDate
        performanceGrade
        remarks
        appraisedPDF
      }
      logs
      dateOfResignation
      attachments
      transferLogs {
        fromHostel
        toHostel
        date
      }
    }
  }
`;

export const useGetEmployeeProfileForRoster = (
  hostelId,
  rosterMonth,
  rosterYear
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMP_PROFILE_FOR_ROSTER,
    {
      variables: { hostelId, rosterMonth, rosterYear },
      skip: !(hostelId && rosterMonth && rosterYear),
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_EMPLOYEE_ROSTER_BY_HOSTEL_ID = gql`
  query ($hostelId: ID, $month: Int, $year: Int) {
    getEmployeeRosterByHostelId(
      hostelId: $hostelId
      month: $month
      year: $year
    ) {
      _id
      hostelId {
        _id
        name
      }
      empId {
        _id
        fullName
        empCode
        dob
        designation
        subDepartment
        empStatus
        empType
        agencyName
        doj
        dateOfExit
      }
      empCode
      rosterDate
      rosterSlot
      rosterSelected
      logs
    }
  }
`;

export const useGetEmployeeRosterByHostelId = (hostelId, month, year) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_ROSTER_BY_HOSTEL_ID,
    {
      variables: { hostelId, month, year },
      skip: !(month && year),
    }
  );
  return { loading, error, data, refetch };
};
