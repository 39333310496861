import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const SYNC_DAILY_OCCUPANCY_TRACKER = gql`
    mutation syncDailyOccupancyTracker($date: String!) {
        syncDailyOccupancyTracker(date: $date) {
            status
            message
        }
    }
`;

export const useSyncDailyOccupancyTracker = () => {
    const [syncDailyOccupancyTracker, { loading, error, data, refetch }] =
        useMutation(SYNC_DAILY_OCCUPANCY_TRACKER);

    useLoadingHandler(loading);

    const syncDailyOccupancyTrackerHandler = (date) => {
        syncDailyOccupancyTracker({
            variables: { date },
        });
    };

    return [syncDailyOccupancyTrackerHandler, { loading, error, data, refetch }];
};