import React from "react";
import { FileText } from "react-feather";
import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";

const headers = [
  "Action",
  "Hostel Code",
  "Hostel Name",
  "Month",
  "Year",
  "Area Manager",
  "Op. Model",
  "Occ%",
  "ADR",
  "Bed Revenue",
  "F&B Revenue",
  "Ancillary Revenue",
  "Total OPEX",
  "Overall Op. Margin (in INR)",
  "Hostel margin (in INR)",
  "Hostel margin (in %)",
  "F&B margin (in INR)",
  "F&B margin (in %)",
  "Ancillary margin (in INR)",
  "Ancillary margin (in %)",
  "Analysis (Operational Margin %)",
];

const PLStatementTable = ({ data, openOverviewModal }) => {
  // Function to format percentage values
  const formatPercentage = (value) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      return `${(numValue * 100).toFixed(2)}%`;
    }
    console.log(value, "value is not a valid number");
    return "N/A";
  };

  const extractFieldValue = (fields, fieldName) => {
    if (!fields || !Array.isArray(fields)) {
      console.warn("Invalid fields array:", fields);
      return "N/A";
    }

    const field = fields.find((item) => item.fieldName === fieldName);
    if (!field) {
      console.info(`Field not found: ${fieldName}`);
      return "N/A";
    }

    const value = field.fieldValue;

    const percentageFields = [
      "Occ%",
      "Hostel margin (in %)",
      "F&B margin (in %)",
      "Ancillary margin (in %)",
      "Analysis (Operational Margin %",
    ];

    if (percentageFields.includes(fieldName)) {
      return formatPercentage(value);
    }

    return value !== undefined && value !== null ? value : "N/A";
  };

  if (!data || data.length === 0) {
    console.warn("No data available to display in the table.");
    return <p>No data available</p>;
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <Button.Ripple
            color="light"
            onClick={(e) => {
              e.preventDefault();
              openOverviewModal();
            }}
          >
            Create P&L Statement
          </Button.Ripple>
        </CardHeader>
      </Card>

      <Table bordered responsive>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => {
            return (
              <tr key={item._id}>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => openOverviewModal(item._id)}
                  >
                    <FileText size={14} />
                  </Button>
                </td>
                <td>{item.hostelCode}</td>
                <td>{item.hostelName || "N/A"}</td>
                <td>{item.month}</td>
                <td>{item.year}</td>
                <td>{item.operationalModel}</td>
                <td>{item.areaManager}</td>
                <td>{extractFieldValue(item.fields, "Occ%")}</td>
                <td>{extractFieldValue(item.fields, "ADR")}</td>
                <td>{extractFieldValue(item.fields, "Bed Revenue")}</td>
                <td>{extractFieldValue(item.fields, "F&B Revenue")}</td>
                <td>{extractFieldValue(item.fields, "Ancillary Revenue")}</td>
                <td>{extractFieldValue(item.fields, "Total OPEX")}</td>
                <td>
                  {extractFieldValue(
                    item.fields,
                    "Overall Op. Margin (in INR)"
                  )}
                </td>
                <td>
                  {extractFieldValue(item.fields, "Hostel margin (in INR)")}
                </td>
                <td>
                  {extractFieldValue(item.fields, "Hostel margin (in %)")}
                </td>
                <td>{extractFieldValue(item.fields, "F&B margin (in INR)")}</td>
                <td>{extractFieldValue(item.fields, "F&B margin (in %)")}</td>
                <td>
                  {extractFieldValue(item.fields, "Ancillary margin (in INR)")}
                </td>
                <td>
                  {extractFieldValue(item.fields, "Ancillary margin (in %)")}
                </td>
                <td>
                  {extractFieldValue(
                    item.fields,
                    "Analysis (Operational Margin %"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PLStatementTable;

//Expand and collapse
// import React, { useState } from "react";
// import { FileText } from "react-feather";
// import { Card, CardHeader, Button, Table, Collapse } from "reactstrap";

// const headers = [
//   "Action",
//   "Hostel Code",
//   "Hostel Details", // Main collapsible section for Hostel Details
//   "Revenue", // Main collapsible section for Revenue
//   "Expense", // Main collapsible section for Expense
//   "Margin", // Main collapsible section for Margin
// ];

// const hostelDetailsHeaders = [
//   "Hostel Name",
//   "Month",
//   "Year",
//   "Area Manager",
//   "Op. Model",
// ];

// const revenueHeaders = [
//   "Occ%",
//   "ADR",
//   "Bed Revenue",
//   "F&B Revenue",
//   "Ancillary Revenue",
//   "Total OPEX",
// ];

// const expenseHeaders = ["Overall Op. Margin (in INR)"];

// const marginHeaders = [
//   "Hostel margin (in INR)",
//   "Hostel margin (in %)",
//   "F&B margin (in INR)",
//   "F&B margin (in %)",
//   "Ancillary margin (in INR)",
//   "Ancillary margin (in %)",
//   "Analysis (Operational Margin %)",
// ];

// const PLStatementTable = ({ data, openOverviewModal }) => {
//   // Collapsible state for each section
//   const [collapseHostelDetails, setCollapseHostelDetails] = useState(false);
//   const [collapseRevenue, setCollapseRevenue] = useState(false);
//   const [collapseExpense, setCollapseExpense] = useState(false);
//   const [collapseMargin, setCollapseMargin] = useState(false);

//   // Function to format percentage values
//   const formatPercentage = (value) => {
//     const numValue = parseFloat(value);
//     if (!isNaN(numValue)) {
//       return `${(numValue * 100).toFixed(2)}%`;
//     }
//     return "N/A";
//   };

//   const extractFieldValue = (fields, fieldName) => {
//     if (!fields || !Array.isArray(fields)) {
//       return "N/A";
//     }

//     const field = fields.find((item) => item.fieldName === fieldName);
//     if (!field) {
//       return "N/A";
//     }

//     const value = field.fieldValue;

//     const percentageFields = [
//       "Occ%",
//       "Hostel margin (in %)",
//       "F&B margin (in %)",
//       "Ancillary margin (in %)",
//       "Analysis (Operational Margin %",
//     ];

//     if (percentageFields.includes(fieldName)) {
//       return formatPercentage(value);
//     }

//     return value !== undefined && value !== null ? value : "N/A";
//   };

//   if (!data || data.length === 0) {
//     return <p>No data available</p>;
//   }

//   return (
//     <div>
//       <Card>
//         <CardHeader>
//           <Button.Ripple
//             color="light"
//             onClick={(e) => {
//               e.preventDefault();
//               openOverviewModal();
//             }}
//           >
//             Create P&L Statement
//           </Button.Ripple>
//         </CardHeader>
//       </Card>

//       <Table bordered responsive>
//         <thead>
//           {/* Main Header Row */}
//           <tr>
//             <th rowSpan={2}>Action</th>
//             <th rowSpan={2}>Hostel Code</th>
//             <th colSpan={hostelDetailsHeaders.length}>
//               <Button
//                 color="link"
//                 onClick={() => setCollapseHostelDetails(!collapseHostelDetails)}
//               >
//                 {collapseHostelDetails ? "Collapse" : "Expand"} Hostel Details
//               </Button>
//             </th>
//             <th colSpan={revenueHeaders.length}>
//               <Button
//                 color="link"
//                 onClick={() => setCollapseRevenue(!collapseRevenue)}
//               >
//                 {collapseRevenue ? "Collapse" : "Expand"} Revenue
//               </Button>
//             </th>
//             <th rowSpan={2}>Expense</th>
//             <th colSpan={marginHeaders.length}>
//               <Button
//                 color="link"
//                 onClick={() => setCollapseMargin(!collapseMargin)}
//               >
//                 {collapseMargin ? "Collapse" : "Expand"} Margin
//               </Button>
//             </th>
//           </tr>

//           {/* Sub-headers for Hostel Details */}
//           <tr>
//             {collapseHostelDetails
//               ? hostelDetailsHeaders.map((subHeader, index) => (
//                   <th key={index}>{subHeader}</th>
//                 ))
//               : null}
//             {collapseRevenue
//               ? revenueHeaders.map((subHeader, index) => (
//                   <th key={index}>{subHeader}</th>
//                 ))
//               : null}
//             {collapseExpense ? <th>{expenseHeaders[0]}</th> : null}
//             {collapseMargin
//               ? marginHeaders.map((subHeader, index) => (
//                   <th key={index}>{subHeader}</th>
//                 ))
//               : null}
//           </tr>
//         </thead>

//         <tbody>
//           {data.map((item, rowIndex) => (
//             <tr key={item._id}>
//               <td>
//                 <Button
//                   color="primary"
//                   size="sm"
//                   onClick={() => openOverviewModal(item._id)}
//                 >
//                   <FileText size={14} />
//                 </Button>
//               </td>
//               <td>{item.hostelCode}</td>

//               {/* Hostel Details Row (show 1st value even if collapsed) */}
//               {collapseHostelDetails ? (
//                 <>
//                   <td>{item.hostelName || "N/A"}</td>
//                   <td>{item.month}</td>
//                   <td>{item.year}</td>
//                   <td>{item.areaManager}</td>
//                   <td>{item.operationalModel}</td>
//                 </>
//               ) : (
//                 // Default value shown when collapsed
//                 <td>{item.hostelName || "N/A"}</td>
//               )}

//               {/* Revenue Row (show 1st value even if collapsed) */}
//               {collapseRevenue ? (
//                 <>
//                   <td>{extractFieldValue(item.fields, "Occ%")}</td>
//                   <td>{extractFieldValue(item.fields, "ADR")}</td>
//                   <td>{extractFieldValue(item.fields, "Bed Revenue")}</td>
//                   <td>{extractFieldValue(item.fields, "F&B Revenue")}</td>
//                   <td>{extractFieldValue(item.fields, "Ancillary Revenue")}</td>
//                   <td>{extractFieldValue(item.fields, "Total OPEX")}</td>
//                 </>
//               ) : (
//                 // Default value shown when collapsed
//                 <td>{extractFieldValue(item.fields, "Occ%")}</td>
//               )}

//               {/* Expense Row */}
//               {collapseExpense && (
//                 <td>
//                   {extractFieldValue(
//                     item.fields,
//                     "Overall Op. Margin (in INR)"
//                   )}
//                 </td>
//               )}

//               {/* Margin Row (show 1st value even if collapsed) */}
//               {collapseMargin ? (
//                 <>
//                   <td>
//                     {extractFieldValue(item.fields, "Hostel margin (in INR)")}
//                   </td>
//                   <td>
//                     {extractFieldValue(item.fields, "Hostel margin (in %)")}
//                   </td>
//                   <td>
//                     {extractFieldValue(item.fields, "F&B margin (in INR)")}
//                   </td>
//                   <td>{extractFieldValue(item.fields, "F&B margin (in %)")}</td>
//                   <td>
//                     {extractFieldValue(
//                       item.fields,
//                       "Ancillary margin (in INR)"
//                     )}
//                   </td>
//                   <td>
//                     {extractFieldValue(item.fields, "Ancillary margin (in %)")}
//                   </td>
//                   <td>
//                     {extractFieldValue(
//                       item.fields,
//                       "Analysis (Operational Margin %"
//                     )}
//                   </td>
//                 </>
//               ) : (
//                 // Default value shown when collapsed
//                 <td>
//                   {extractFieldValue(item.fields, "Hostel margin (in INR)")}
//                 </td>
//               )}
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default PLStatementTable;

// import React from "react";
// import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";
// import { FileText } from "react-feather";
// import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

// const headers = [
//   "Action",
//   "Hostel Code",
//   "Hostel Name",
//   "Age (in months)",
//   "Op. Model",
//   "Area Manager",
//   "No. of rooms",
//   "No. of beds",
//   "Total Inventory",
//   "Blocked Inventory",
//   "Total Revenue",
// ];

// const PLStatementTable = ({ data, openOverviewModal }) => {
//   return (
//     <div>
//       <Card>
//         <CardHeader>
//           <Button.Ripple
//             color="light"
//             onClick={(e) => {
//               e.preventDefault();
//               openOverviewModal();
//             }}
//           >
//             Create P&L Statement
//           </Button.Ripple>
//         </CardHeader>
//       </Card>

//       <div>
//         <Table hover>
//           <thead style={{}}>
//             <tr>
//               {headers.map((headerItem) => (
//                 <th key={uuidv4()}>{headerItem}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={item._id || index}>
//                 <td>
//                   <Button
//                     color="primary"
//                     size="sm"
//                     onClick={() => openOverviewModal(item._id)}
//                   >
//                     <FileText size={14} className={reactFeatherIcon} />
//                   </Button>
//                 </td>
//                 <td>{item.hostelCode}</td>
//                 <td>{item?.hostelName}</td>
//                 <td>{item?.hostelAgeInMonths}</td>
//                 <td>{item?.operationModel}</td>
//                 <td>{item?.areaManager}</td>
//                 <td>{item?.numberOfRooms}</td>
//                 <td>{item?.numberOfBeds}</td>
//                 <td>{item?.totalInventory}</td>
//                 <td>{item?.blockedInventory}</td>
//                 <td>{item?.totalRevenue}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </div>
//     </div>
//   );
// };

// export default PLStatementTable;
