import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_PL_STATEMENT = gql`
  mutation createPLStatement($plInput: PLStatementInput!) {
    createPLStatement(plInput: $plInput) {
      month
      year
      hostelId
      hostelName
      hostelCode
      hostelAgeInMonths
      operationModel
      areaManager
      totalRevenue
      numberOfRooms
      numberOfBeds
      totalInventory
      blockedInventory
      totalAvailableBedNight
      totalSoldBedNight
      occupancyPercentage
      adr
      bedRevenue
      fbAOV
      fbOrders
      fbOrderPerBedNightSold
      fbRevenue
      ancillaryRevenue
      totalOpex
      rent
      rentalCostPerRoom
      u1ElectricityFuelGenset
      u2WaterTanker
      u3Subscriptions
      u4Toiletry
      u5Laundry
      repairMaintenence
      miscOpex
      ota
      totalSalary
      hostelMandays
      numberOfHostelStaff
      hostelSalaryOwn
      hostelSalaryAgency
      hostelSalaryPerManday
      hostelWelfare
      cafeMandays
      numberOfCafeStaff
      fbSalaryAndWelfare
      cafeSalaryPerManday
      fbMaterialCost
      ancillaryCost
      otherPayouts
      ipPayout
      franchisePayout
      overallOpMargin
      hostelMargin
      hostelMarginPercentage
      fbMarginIn
      fbMarginPercentage
      ancillaryMargin
      ancillaryMarginPercentage
      analysisOperationalMargin
      revRentalMultiple
      otaPercentage
      utilitiesToTotalRev
      rmToTotalRev
      miscToTotalRev
      hostelStaffSalaryPerBedSales
      fbStaffSalaryPerFBSales
      fbFoodCostPerFBSales
      capexIn
      nps
      orm
      numberOfPositiveReviews
      numberOfNeutralReviews
      numberOfNegativeReviews
      totalNumberOfReviews
      overallRemarks
      remarksOnOps
      remarksOnCX
      remarksOnPOSales
      actionsFollowUps
    }
  }
`;

export const useCreatePLStatement = () => {
  const [createPLStatement, { loading, error, data, refetch }] =
    useMutation(CREATE_PL_STATEMENT);

  useLoadingHandler(loading);

  const createPLStatementHandler = ({ plInput }) => {
    createPLStatement({
      variables: { plInput },
    });
  };

  return [createPLStatementHandler, { loading, error, data, refetch }];
};

// const UPDATE_PL_STATEMENT = gql`
//   mutation updatePLStatement($id: ID!, $plInput: PLStatementInput!) {
//     updatePLStatement(id: $id, plInput: $plInput) {
//       month
//       year
//       hostelId
//       hostelName
//       hostelCode
//       hostelAgeInMonths
//       operationModel
//       areaManager
//       totalRevenue
//       numberOfRooms
//       numberOfBeds
//       totalInventory
//       blockedInventory
//       totalAvailableBedNight
//       totalSoldBedNight
//       occupancyPercentage
//       adr
//       bedRevenue
//       fbAOV
//       fbOrders
//       fbOrderPerBedNightSold
//       fbRevenue
//       ancillaryRevenue
//       totalOpex
//       rent
//       rentalCostPerRoom
//       u1ElectricityFuelGenset
//       u2WaterTanker
//       u3Subscriptions
//       u4Toiletry
//       u5Laundry
//       repairMaintenence
//       miscOpex
//       ota
//       totalSalary
//       hostelMandays
//       numberOfHostelStaff
//       hostelSalaryOwn
//       hostelSalaryAgency
//       hostelSalaryPerManday
//       hostelWelfare
//       cafeMandays
//       numberOfCafeStaff
//       fbSalaryAndWelfare
//       cafeSalaryPerManday
//       fbMaterialCost
//       ancillaryCost
//       otherPayouts
//       ipPayout
//       franchisePayout
//       overallOpMargin
//       hostelMargin
//       hostelMarginPercentage
//       fbMarginIn
//       fbMarginPercentage
//       ancillaryMargin
//       ancillaryMarginPercentage
//       analysisOperationalMargin
//       revRentalMultiple
//       otaPercentage
//       utilitiesToTotalRev
//       rmToTotalRev
//       miscToTotalRev
//       hostelStaffSalaryPerBedSales
//       fbStaffSalaryPerFBSales
//       fbFoodCostPerFBSales
//       capexIn
//       nps
//       orm
//       numberOfPositiveReviews
//       numberOfNeutralReviews
//       numberOfNegativeReviews
//       totalNumberOfReviews
//       overallRemarks
//       remarksOnOps
//       remarksOnCX
//       remarksOnPOSales
//       actionsFollowUps
//       updatedAt
//     }
//   }
// `;

// export const useUpdatePLStatement = () => {
//   const [updatePLStatement, { loading, error, data, refetch }] =
//     useMutation(UPDATE_PL_STATEMENT);

//   useLoadingHandler(loading);

//   // Update handler that sends id and plInput correctly
//   const updatePLStatementHandler = ({ id, plInput }) => {
//     updatePLStatement({
//       variables: {
//         id,
//         plInput,
//       },
//     });
//   };

//   return [updatePLStatementHandler, { loading, error, data, refetch }];
// };

const UPDATE_PL_STATEMENT = gql`
  mutation updatePLStatement($id: ID!, $plInput: PLStatementInput!) {
    updatePLStatement(id: $id, plInput: $plInput) {
      _id
      month
      year
      hostelId
      hostelName
      hostelCode
      operationalModel
      areaManager
      fields {
        fieldName
        fieldValue
        isCritical
        isProvisional
        remark
        breakup
      }
      createdAt
      updatedAt
    }
  }
`;

export const useUpdatePLStatement = () => {
  const [updatePLStatement, { data, error, loading }] =
    useMutation(UPDATE_PL_STATEMENT);

  return [
    async ({ id, plInput }) => {
      const response = await updatePLStatement({
        variables: { id, plInput },
      });

      if (response.data && response.data.updatePLStatement) {
        return response;
      } else {
        throw new Error("Update failed: Response is missing data.");
      }
    },
    { data, error, loading },
  ];
};

export const UPLOAD_PL_STATEMENTS = gql`
  mutation uploadPLStatements($file: Upload!, $month: String!, $year: String!) {
    uploadPLStatements(file: $file, month: $month, year: $year) {
      success
      message
    }
  }
`;

export const useUploadPLStatements = () => {
  const [uploadPLStatements, { loading, error, data, refetch }] =
    useMutation(UPLOAD_PL_STATEMENTS);

  useLoadingHandler(loading);

  const uploadPLStatementsHandler = ({ file, month, year }) => {
    uploadPLStatements({
      variables: {
        file,
        month,
        year,
      },
    });
  };

  return [uploadPLStatementsHandler, { data, refetch, loading, error }];
};

//Investor data
export const UPLOAD_INVESTOR_DATA = gql`
  mutation uploadInvestorData($file: Upload!) {
    uploadInvestorData(file: $file) {
      success
      message
    }
  }
`;

export const useUploadInvestorData = () => {
  const [uploadInvestorData, { loading, error, data, refetch }] =
    useMutation(UPLOAD_INVESTOR_DATA);

  useLoadingHandler(loading);

  const uploadInvestorDataHandler = ({ file }) => {
    uploadInvestorData({
      variables: {
        file,
      },
    });
  };

  return [uploadInvestorDataHandler, { data, refetch, loading, error }];
};
