import { UserPlus, Circle } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Owner")
  ? [
      {
        id: "owner",
        title: "Owner",
        icon: <UserPlus size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "occupancyTracker",
            title: "Daily Occupancy Tracker",
            icon: <Circle size={12} />,
            navLink: "/report/occupancyTracker",
          },
          {
            id: "bookingReport",
            title: "Hostelwise booking report",
            icon: <Circle size={12} />,
            navLink: "/report/bookingReport",
          },
          {
            id: "dailyBookings",
            title: "Daily Bookings",
            icon: <Circle size={12} />,
            navLink: "/report/dailyBookings",
          },
          {
            id: "sourceReport",
            title: "Business Source Report",
            icon: <Circle size={12} />,
            navLink: "/report/sourceReport",
          },
          {
            id: "carReport",
            title: "Contribution Analysis Report",
            icon: <Circle size={12} />,
            navLink: "/report/carReport",
          },
          {
            id: "carOTAwiseReport",
            title: "CAR OTAwise Report",
            icon: <Circle size={12} />,
            navLink: "/report/carOTAwiseReport",
          },
          {
            id: "monthlyRevenueSalesReport",
            title: "Roomwise ADR Report",
            icon: <Circle size={12} />,
            navLink: "/report/monthlyRevenueSalesReport",
          },
          {
            id: "hourlySales",
            title: "Hourly Booking Report",
            icon: <Circle size={12} />,
            navLink: "/report/hourlySales",
          },
          {
            id: "occupancyADRReport",
            title: "Occupancy ADR Report",
            icon: <Circle size={12} />,
            navLink: "/report/occupancyADRReport",
          },
        ],
      },
    ]
  : [];
