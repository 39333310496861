import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Flatpickr from "react-flatpickr";
import SlideModal from "./slide-modal/index";
import {
  useAddBlogsBlog,
  useUpdateBlogsBlog,
  useDestinationList,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [blogTitle, setBlogTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [tags, setTags] = useState("");
  const [timeToRead, setTimeToRead] = useState("");
  const [publishDate, setPublishDate] = useState(new Date());

  const [url, setUrl] = useState("");
  const [seo, setSeo] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [status, setStatus] = useState(false);
  const [destination, setDestination] = useState([]);
  const [DestinationsList, setDestinationsList] = useState([]);
  const { data: allDestinationsData } = useDestinationList();

  const [addBlogsBlogHandler, { data }] = useAddBlogsBlog();
  const [updateBlogsBlogHandler, { data: updatedBlogsBlogData }] =
    useUpdateBlogsBlog();

  useEffect(() => {
    if (allDestinationsData?.getDestinationList?.length) {
      allDestinationsData?.getDestinationList?.map((item) => {
        setDestinationsList((prev) => [
          ...prev,
          { ...item, label: item?.destination, value: item?._id },
        ]);
      });
    }
  }, [allDestinationsData?.getDestinationList]);

  useEffect(() => {
    if (data?.createBlog?.blogTitle || updatedBlogsBlogData) {
      refetch();
      handleToggle();

      toaster("success", "Blog added successfully");
    }
  }, [data, updatedBlogsBlogData]);

  const handleDestinationChange = function (DestinationsList) {
    return setDestination(DestinationsList);
  };

  const submitDataHandler = () => {
    const destinationsArray = [];
    // eslint-disable-next-line array-callback-return
    destination?.map((item) => {
      destinationsArray?.push(item?.value);
    });
    let data = {
      blogTitle,
      authorName,
      shortDescription,
      displayOrder: parseInt(displayOrder),
      url,
      seo: { metaTitle, metaDesc, keywords },
      status,
      tags,
      timeToRead,
      publishDate,
      destinations: JSON.parse(JSON.stringify(destinationsArray)),
    };

    if (data.blogTitle) {
      if (editData?.length) {
        const { _id } = editData[0];
        updateBlogsBlogHandler(_id, data);
      } else {
        addBlogsBlogHandler(data);
      }
      setBlogTitle("");
      setAuthorName("");
      setShortDescription("");
      setDisplayOrder("");
      setUrl("");
      setSeo("");
      setTags("");
      setTimeToRead("");
      setPublishDate(new Date());
      setStatus(false);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      setBlogTitle(editData[0]?.blogTitle);
      setAuthorName(editData[0]?.authorName);
      setShortDescription(editData[0]?.shortDescription);
      setDisplayOrder(editData[0]?.displayOrder);
      setMetaTitle(editData[0]?.seo?.metaTitle);
      setMetaDesc(editData[0]?.seo?.metaDesc);
      setKeywords(editData[0]?.seo?.keywords);
      setUrl(editData[0]?.url);

      setStatus(editData[0]?.status);
      setTags(editData[0]?.tags);
      setTimeToRead(editData[0]?.timeToRead);
      setPublishDate(editData[0]?.publishDate);
      let destinationSelectedData = [];
      editData[0]?.destinations?.map((destination) => {
        allDestinationsData?.getDestinationList?.map((destinationlist) => {
          if (destination?._id === destinationlist?._id) {
            destinationSelectedData?.push({
              label: destination?.destination,
              value: destination?._id,
            });
          }
        });
      });

      setDestination(destinationSelectedData);
    } else {
      setBlogTitle("");
      setAuthorName("");
      setShortDescription("");
      setDisplayOrder("");
      setUrl("");
      setSeo("");
      setStatus(false);
      setTags("");
      setTimeToRead("");
      setPublishDate("");
      setDestination([]);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Blog Title */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="blogTitle">
                Blog Title
              </Label>
              <Input
                type="text"
                value={blogTitle}
                id="blogTitle"
                name="blogTitle"
                placeholder="Add Blog Title"
                onChange={(e) => setBlogTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Author Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="authorName">
                Author Name
              </Label>
              <Input
                type="text"
                value={authorName}
                id="authorName"
                name="authorName"
                placeholder="Add Author Name"
                onChange={(e) => setAuthorName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Short Description */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="shortDescription">
                Short Description
              </Label>
              <Input
                type="textarea"
                value={shortDescription}
                id="shortDescription"
                name="shortDescription"
                placeholder="Add Short Description"
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Tags */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tags">
                Tags
              </Label>
              <Input
                type="text"
                value={tags}
                id="tags"
                name="tags"
                placeholder="Add Tags"
                onChange={(e) => setTags(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Time To Read */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="timeToRead">
                Time To Read
              </Label>
              <Input
                type="text"
                value={timeToRead}
                id="timeToRead"
                name="timeToRead"
                placeholder="Add Time To Read"
                onChange={(e) => setTimeToRead(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Publish Date */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="publish date">
                Publish Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={publishDate}
                onChange={(date) => setPublishDate(date)}
                id="publishDate"
                placeholder="Add Publish Date"
                required
              />
            </FormGroup>
          </Col>

          {/* // ! Display Order */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order
              </Label>
              <Input
                type="text"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Add Display Order"
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! SEO */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="seo">
                SEO
              </Label>

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Title
                </Label>
                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add Meta Title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Description
                </Label>
                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add Meta Desc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </Col>
              <br />
              <Col sm="12">
                <Label className="form-label" for="seo">
                  Keywords
                </Label>
                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add Keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </Col>
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL (Format: /blogs/blog-name)
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add URL"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Destinations */}
          <Col>
            <FormGroup className="w-100">
              <Label className="form-label" for="destination">
                Destinations
              </Label>
              <Select
                onChange={handleDestinationChange}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                id="destination"
                name="destination"
                value={destination}
                options={DestinationsList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Status */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>

          {/* // ! Logs */}
          {editData?.length && editData[0]?.logs && (
            <Col sm="12">
              {/* <hr/> */}
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>

                <br />
                {editData[0]?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
