import React, { useEffect, useState } from 'react'
import EventCategoriesTable from './EventCategoriesTable'
import { useGetAllActiveEventCategories, useGetAllEventCategories } from '../../../api/EventCategories/Queries';
import EventsAddEditModal from './EventsAddEditModal';

const EventCategories = () => {
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [editData, setEditData] = useState(null);

    const { data, loading, refetch } = useGetAllEventCategories()
    const { data: activeEvents } = useGetAllActiveEventCategories()

    useEffect(() => {
        if (data?.getAllEventCategories) {
            setTableData(data?.getAllEventCategories);
        } else {
            setTableData([]);
        }
    }, [data]);

    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    };

    useEffect(() => {
        if (data?.getAllEventCategories) {
            let editData = data?.getAllEventCategories.find((item) => item._id === id)
            setEditData(editData);
        } else {
            setId(null);
            setEditData(null);
        }
    }, [id])
    return (
        localStorage.getItem("modules")?.includes("Glu Configuration") && (
            <>
                <EventCategoriesTable
                    setId={setId}
                    openModal={() => setOpenModal(!openModal)}
                    tableData={tableData}
                    refetch={refetch}
                />

                <EventsAddEditModal
                    open={openModal}
                    editData={editData}
                    handleToggle={toggleModal}
                    refetch={refetch}
                    eventCategoryList={activeEvents?.getAllActiveEventCategories}
                />
            </>)
    )
}

export default EventCategories