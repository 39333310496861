import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_ALL_MERCHANDISE_PRODUCTS = gql`
    query {
        getAllMerchandiseProducts{
            _id
            name
            price
            tax
            description
            mainImage
            displayOrder
            logs
        }
}
`
export const useGetAllMerchandiseProducts = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_MERCHANDISE_PRODUCTS);
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};

export const GET_PRODUCT_DETAILS_BY_ID = gql`
query getProductById($productId:ID){
    getMerchandiseProductDetailsById(productId:$productId){
        name
        description
        price
        tax
        displayOrder
        mainImage
        productImages{
            displayOrder
            image
        }
        items{
            displayOrder
            name
            description
            quantity
            image
        }
    }
}
`

export const useGetProductDetailsById = (productId) => {
    const { loading, error, data, refetch } = useQuery(GET_PRODUCT_DETAILS_BY_ID, {
        variables: {
            productId,
        }
    });
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};