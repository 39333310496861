import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toaster from "@components/common/Toaster";
import { useUploadInvestorData } from "../../api/P&LStatement/Mutation";
import { useGetInvestorData } from "../../api/P&LStatement/Queries";
import InvestorDataTable from "./InvestorDataTable";

import { useEffect } from "react";

const InvestorDataComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadInvestorDataHandler, { loading, error, data: uploadData }] =
    useUploadInvestorData();

  const { data, refetch } = useGetInvestorData();
  const investorData = data?.getInvestorData || [];

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) setFile(null);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toaster("error", "Please upload a file before submitting.");
      return;
    }

    try {
      await uploadInvestorDataHandler({ file });
    } catch (err) {
      toaster("error", `Upload failed: ${err.message}`);
    }
  };

  useEffect(() => {
    if (uploadData?.uploadInvestorData?.success) {
      toaster("success", `${uploadData.uploadInvestorData.message}`);

      refetch();
      toggleModal();
    } else if (uploadData?.uploadInvestorData?.success === false) {
      toaster("error", "Upload failed: No success in response.");
    }
  }, [uploadData, refetch]);

  return (
    <div>
      <Row>
        <Col className="mb-2">
          <Button
            color="info"
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            onClick={toggleModal}
          >
            Upload Investor Statement
          </Button>
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Upload P&L Statement</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="file">Upload File</Label>
              <Input type="file" id="file" onChange={handleFileChange} />
            </FormGroup>
            <FormGroup>
              <Button color="primary" onClick={handleUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload"}
              </Button>
            </FormGroup>
            {error && <div className="text-danger mt-2">{error.message}</div>}
          </Form>
        </ModalBody>
      </Modal>

      <InvestorDataTable investorData={investorData} />
    </div>
  );
};

export default InvestorDataComponent;
