import React, { useEffect, useState } from "react";
import OccupancyADRTable from "./OccupancyADRTable";
import { useGetSalesOccupancyReport } from "../../../api/OccupancyADRReport/Queries";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { CSVLink } from "react-csv";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import { useHostelList } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const tableHeaderData = [
  { label: "Hostel Name", key: "name" },
  { label: "Available bed nights", key: "totalActiveRooms" },
  { label: "Sold bed nights", key: "totalOccupiedRooms" },
  { label: "Occupancy", key: "occupancy" },
  { label: "Adr", key: "adr" },
  { label: "Revenue", key: "revenue" },
];

const OccupancyADRReport = () => {
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  // const [searchFromDate, setSearchFromDate] = useState(
  //   dateFormatter(new Date())
  // );
  // const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const [tableData, setTableData] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [searchHostel, setSearchHostel] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { data: allHostelsData } = useHostelList();
  const { data, loading, refetch } = useGetSalesOccupancyReport(
    filterData?.fromDate,
    filterData?.toDate,
    filterData?.hostelIds
    // searchFromDate,
    // searchToDate,
    // searchHostel.length > 0 ? searchHostel.map((hostel) => hostel.value) : []
  );

  // useEffect(() => {
  //   refetch();
  // }, [hostel]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (data?.salesOccupancyReport) {
      setTableData(data?.salesOccupancyReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleSearch = () => {
    if (dateValidator(fromDate, toDate)) {
      const data = {
        fromDate,
        toDate,
        hostelIds:
          hostel.length > 0 ? hostel.map((hostel) => hostel.value) : [],
      };
      // setSearchFromDate(fromDate);
      // setSearchToDate(toDate);
      // setSearchHostel(hostel);
      setFilterData(data);
    } else {
      toaster("error", "Invalid Dates");
    }
  };

  const handleResetData = () => {
    setTableData([]);
    // setSearchFromDate(dateFormatter(new Date()));
    // setSearchToDate(dateFormatter(new Date()));
    setFromDate(dateFormatter(new Date()));
    setToDate(dateFormatter(new Date()));
    // setSearchHostel([]);
    setHostel([]);
  };

  return (
    (localStorage.getItem("modules")?.includes("Reports") ||
      localStorage.getItem("modules")?.includes("Owner")) && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                To Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel*
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Button.Ripple
              onClick={handleSearch}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>
            <CSVLink
              headers={tableHeaderData}
              data={tableData}
              filename={"extra_charges_report.csv"}
            >
              <Button.Ripple
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="success"
                appearance="success"
                type="button"
              >
                Export
              </Button.Ripple>
            </CSVLink>
          </Col>
        </Row>

        {loading ? (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            This report will take some time as real-time data is processing from
            eZee
          </h2>
        ) : tableData.length > 0 ? (
          <OccupancyADRTable data={tableData} refetch={refetch} />
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Data Found
          </h2>
        )}
      </div>
    )
  );
};

export default OccupancyADRReport;
