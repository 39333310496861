import React, { useState, useEffect } from "react";
import SlideModal from "./slide-modal/index";
import { Col, Row, Input, FormGroup, Label, CustomInput } from "reactstrap";
import {
  useCreateOpsFinance,
  useHostelList,
  useUpdateOpsFinance,
} from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import Flatpickr from "react-flatpickr";

const CashInAddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [totalAmount, setTotalAmount] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [entryBy, setEntryBy] = useState("");
  const [arrHeads, setArrHeads] = useState(null);
  const [ezeeCheckBox, setEzeeCheckBox] = useState(false);
  const [transactionDate, setTransactionDate] = useState();
  const [logs, setLogs] = useState([]);

  const { data: allHostelsData } = useHostelList();

  const [createOpsFinanceHandler, { data: createdOpsFinanceData }] =
    useCreateOpsFinance();

  const [updateOpsFinanceHandler, { data: updateDaybookData }] =
    useUpdateOpsFinance();

  const PaymentModeList = [{ label: "Cash", value: "Cash" }];

  const HeadList = [
    { label: "Bed", value: "Bed" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
    { label: "HO", value: "HO" },
  ];

  useEffect(() => {
    if (createdOpsFinanceData?.createOpsFinance?.entryType) {
      refetch();
      handleToggle();
    }
  }, [createdOpsFinanceData]);

  useEffect(() => {
    if (updateDaybookData?.updateOpsFinance) {
      refetch();
      handleToggle();
    }
  }, [updateDaybookData]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);

      //have added this case when only one hostel is assigned to the account, in such case assigning the first hostel
      if (HostelsList.length === 1) {
        setHostel(HostelsList[0]);
      }
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (editData?._id) {
      const hostelInfo = allHostelsData?.getHostelList?.find(
        (item) => item?._id === editData?.hostelId?._id
      );

      setHostel({ label: hostelInfo?.name, value: hostelInfo?._id });

      setReservationId(
        editData?.reservationId !== null ? editData?.reservationId : ""
      );
      setTotalAmount(
        editData?.totalAmount !== null ? editData?.totalAmount : ""
      );
      setRemarks(editData?.remarks !== null ? editData?.remarks : "");
      setEntryBy(editData?.entryBy !== null ? editData?.entryBy : "");
      setPaymentMode(
        editData?.paymentMode !== null
          ? { label: editData?.paymentMode, value: editData?.paymentMode }
          : null
      );
      const selectedHeads = editData?.head?.map((i) => ({
        label: i,
        value: i,
      }));
      setTransactionDate(
        editData?.entryDate ? new Date(editData?.entryDate) : null
      );
      setArrHeads(selectedHeads === undefined ? [] : selectedHeads);
      setLogs(editData?.logs ? editData?.logs : []);
    } else {
      setHostel(null);
      setTotalAmount("");
      setReservationId("");
      setRemarks("");
      setEntryBy("");
      setPaymentMode(null);
      setArrHeads(null);
      setTransactionDate(null);
      setLogs([]);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const currentDate = new Date();

    // const entryDate = transactionDate
    //   ? transactionDate.toLocaleDateString("en-CA")
    //   : currentDate.toLocaleDateString("en-CA");

    const entryDate =
      transactionDate &&
      transactionDate instanceof Date &&
      !isNaN(transactionDate)
        ? transactionDate.toLocaleDateString("en-CA")
        : currentDate.toLocaleDateString("en-CA");

    // console.log("Final Entry Date for Submission:", entryDate);
    // console.log("Final Entry Date for Submission:", entryDate);

    const entryTime = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const isNumeric = /^\d+$/.test(String(totalAmount).trim());
    const department = localStorage.getItem("department");

    if (
      totalAmount.toString().trim() === "" ||
      !isNumeric ||
      (department !== "Finance" && Number(totalAmount) === 0)
    ) {
      toaster(
        "error",
        "Please enter a valid numeric value for Total Amount that is not zero."
      );
      return;
    }

    const fixedTotalAmount = Math.floor(Math.max(Number(totalAmount), 0));

    const newLog = `${localStorage.getItem("name")} ${
      editData ? "modified" : "created"
    } this cash-in entry on ${currentDate.toLocaleString("en-IN", {
      hour12: false,
    })}`;
    setLogs([...logs, newLog]);

    if (
      arrHeads.some((head) => head.value === "Bed") &&
      !reservationId.trim()
    ) {
      toaster("error", "Reservation ID is mandatory when the head is Bed.");
      return;
    }

    const data = {
      hostelId: hostel?.value,
      head: arrHeads?.map((head) => head.value),
      totalAmount: fixedTotalAmount,
      entryType: "Cash In",

      // entryDate: transactionDate
      //   ? new Date(new Date(transactionDate).setHours(0, 0, 0, 0))
      //   : new Date(new Date().setHours(0, 0, 0, 0)),
      entryDate,

      entryTime: entryTime,
      reservationId,
      remarks,
      entryBy,
      paymentMode: paymentMode?.value,
      logs: [...logs, newLog],
    };

    if (
      hostel &&
      arrHeads &&
      totalAmount &&
      entryBy &&
      paymentMode &&
      // reservationId &&
      remarks
    ) {
      console.log("Data after submit:", data);

      if (editData?._id) {
        updateOpsFinanceHandler(editData._id, data);
        console.log(data, "data after submit in console");
        toaster("success", "Cash-In Updated Successfully!");
      } else {
        createOpsFinanceHandler(data);
        console.log(data, "data after submit");
        toaster("success", "Cash-In Added Successfully!");
        if (HostelsList.length === 1) {
          setHostel(HostelsList[0]);
        } else {
          setHostel(null);
        }
        setTotalAmount("");
        setReservationId("");
        setRemarks("");
        setEntryBy("");
        setPaymentMode(null);
        setArrHeads(null);
        setTransactionDate(null);
        setLogs([]);
      }
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
                // isDisabled={editData?._id}
                // isDisabled={HostelsList.length === 1 || editData?._id}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffRole">
                Cash In Head*
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                options={HeadList}
                value={arrHeads}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(module) => {
                  setArrHeads(module);
                }}
                // isDisabled={editData?._id}
              />
            </FormGroup>
          </Col> */}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffRole">
                Cash In Head*
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti={false}
                options={HeadList}
                value={arrHeads?.[0] || null}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOption) => {
                  setArrHeads(selectedOption ? [selectedOption] : []);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalAmount">
                Cash In Amount*
              </Label>
              <Input
                type="text"
                value={totalAmount}
                id="totalAmount"
                name="totalAmount"
                placeholder="Add Cash In Amount"
                onChange={(e) => setTotalAmount(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationID">
                Reservation Id*
              </Label>
              <Input
                type="text"
                value={reservationId}
                id="reservationID"
                name="reservationID"
                placeholder="Add Reservation Id"
                onChange={(e) => setReservationId(e.target.value)}
                // disabled={editData?._id}
                maxLength={15}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentMode">
                Receipt Mode*
              </Label>
              <Select
                isClearable={false}
                id="paymentMode"
                name="paymentMode"
                value={paymentMode}
                options={PaymentModeList}
                required
                onChange={(value) => setPaymentMode(value)}
                classNamePrefix="select"
                // isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="remarks">
                Remarks*
              </Label>
              <Input
                type="textarea"
                value={remarks}
                id="remarks"
                name="remarks"
                placeholder="Add Remarks"
                onChange={(e) => setRemarks(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="entryBy">
                Cash In By*
              </Label>
              <Input
                type="text"
                value={entryBy}
                id="entryBy"
                name="entryBy"
                placeholder="Add Cash In By"
                onChange={(e) => setEntryBy(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="transactionDate">
                Transaction Date (when the transaction occurred)*
              </Label>

              {/* <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                name="transactionDate"
                placeholder="Transaction Date"
                value={transactionDate || new Date()}
                // onChange={(date) => setTransactionDate(date[0])}
                onChange={(date) => {
                  setTransactionDate(date[0]);
                  console.log("Selected Date:", date[0]);
                }}
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                  minDate: (() => {
                    const today = new Date();
                    const role = localStorage.getItem("role");

                    if (role === "Ground Staff") {
                      today.setDate(today.getDate() - 2);
                    } else if (role === "Area Manager") {
                      today.setDate(today.getDate() - 3);
                    }

                    return today;
                  })(),
                }}
              /> */}

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                name="transactionDate"
                placeholder="Transaction Date"
                value={transactionDate || new Date()}
                onChange={(date) => {
                  // console.log("Selected Date (Raw):", date[0]);
                  const localDate = new Date(
                    date[0].getFullYear(),
                    date[0].getMonth(),
                    date[0].getDate()
                  );
                  setTransactionDate(localDate);
                }}
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                  minDate: (() => {
                    const today = new Date();
                    const role = localStorage.getItem("role");

                    if (role === "Ground Staff") {
                      today.setDate(today.getDate() - 2);
                    } else if (role === "Area Manager") {
                      today.setDate(today.getDate() - 3);
                    }

                    return today;
                  })(),
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="ezeeCheckBox"
                name="user account"
                value={ezeeCheckBox}
                label="Have you created an entry(s) in EZEE of this transaction?"
                className="custom-control-Primary"
                onChange={(e) => setEzeeCheckBox(e.target.checked)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {logs.map((log, index) => (
                <Label key={index} className="form-label">
                  {log}
                </Label>
              ))}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default CashInAddEditModal;
