import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_ALL_CONFIRM_ORDERS = gql`
query 
    getAllSuccessfullOrders($filter:filterObject){
        getAllSuccessfullOrders(filter:$filter){
            data{
                _id
                orderId
                user{
                    name
                    email   
                    mobile
                    city
                    landmark
                    state
                    pincode
                    addressLine1
                }
                reservationId
                razorpayOrderId
                razorpayPaymentId
                totalPriceWithoutTax
                totalPriceWithTax
                totalTax
                createdAt
                products{
                    name
                    totalAmount
                    totalTax
                    quantity
                }
                status
                shippingDetails{
                    shippingVendorName
                    trackingDetails
                    remark
                }
                logs
            }

            page
            count
        }
    }
`

export const useGetAllConfirmedOrders = (filter) => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_CONFIRM_ORDERS, {
        variables:
            { filter, }

    });
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};