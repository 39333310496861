// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateBlogsBlog,
  useDestinationList,
} from "../../../api/dataSource";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import Flatpickr from "react-flatpickr";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const Overview = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [blogTitle, setBlogTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [tags, setTags] = useState("");
  const [timeToRead, setTimeToRead] = useState("");
  const [publishDate, setPublishDate] = useState(new Date());
  const [url, setUrl] = useState("");
  const [seo, setSeo] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [status, setStatus] = useState(false);
  const [subHeading, setSubheading] = useState("");
  const [destination, setDestination] = useState([]);
  const [DestinationsList, setDestinationsList] = useState([]);
  const { data: allDestinationsData } = useDestinationList();

  const dispatch = useDispatch();
  const [updateBlogsBlogHandler, { data: updatedBlogsBlogData }] =
    useUpdateBlogsBlog();

  useEffect(() => {
    if (allDestinationsData?.getDestinationList?.length) {
      allDestinationsData?.getDestinationList?.map((item) => {
        setDestinationsList((prev) => [
          ...prev,
          { ...item, label: item?.destination, value: item?._id },
        ]);
      });
    }
  }, [allDestinationsData?.getDestinationList]);

  const handleDestinationChange = function (DestinationsList) {
    return setDestination(DestinationsList);
  };

  useEffect(() => {
    if (state.tableData) {
      setBlogTitle(state.tableData?.blogTitle);
      setAuthorName(state.tableData?.authorName);
      setShortDescription(state.tableData?.shortDescription);
      setDisplayOrder(state.tableData?.displayOrder);
      setMetaTitle(state.tableData?.seo?.metaTitle);
      setMetaDesc(state.tableData?.seo?.metaDesc);
      setKeywords(state.tableData?.seo?.keywords);
      setUrl(state.tableData?.url);

      setStatus(state.tableData?.status);
      setTags(state.tableData?.tags);
      setTimeToRead(state.tableData?.timeToRead);
      setPublishDate(state.tableData?.publishDate);
      setSubheading(state?.tableData?.subHeading);

      let destinationSelectedData = [];

      state?.tableData?.destinations?.map((destination) => {
        allDestinationsData?.getDestinationList?.map((destinationlist) => {
          if (destination?._id === destinationlist?._id) {
            destinationSelectedData?.push({
              label: destination?.destination,
              value: destination?._id,
            });
          }
        });
      });

      setDestination(destinationSelectedData);

      console.log(destinationSelectedData, "destinationSelectedData");
    } else {
      setBlogTitle("");
      setAuthorName("");
      setShortDescription("");
      setDisplayOrder("");
      setUrl("");
      setSeo("");
      setStatus(false);
      setTags("");
      setTimeToRead("");
      setPublishDate("");
      setSubheading("");
      setDestination([]);
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();

    const destinationsArray = [];
    // eslint-disable-next-line array-callback-return
    destination?.map((item) => {
      destinationsArray?.push(item?.value);
    });

    const data = {
      blogTitle,
      authorName,
      shortDescription,
      displayOrder: parseInt(displayOrder),
      url,
      seo: { metaTitle, metaDesc, keywords },
      status,
      tags,
      timeToRead,
      publishDate,
      subHeading,
      destinations: JSON.parse(JSON.stringify(destinationsArray)),
    };

    if (id) {
      updateBlogsBlogHandler(id, data);
      toaster("success", "Overview updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        {/* // ! Blog Title */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="blogTitle">
              Blog Title
            </Label>
            <Input
              type="text"
              value={blogTitle}
              id="blogTitle"
              name="blogTitle"
              placeholder="Add Blog Title"
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="blogSubheading ">
              Sub heading
            </Label>
            <Input
              type="text"
              value={subHeading}
              id="blogSubheading"
              name="blogSubheading"
              placeholder="Add Subheading"
              onChange={(e) => setSubheading(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Author Name */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="authorName">
              Author Name
            </Label>
            <Input
              type="text"
              value={authorName}
              id="authorName"
              name="authorName"
              placeholder="Add Author Name"
              onChange={(e) => setAuthorName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Short Description */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="shortDescription">
              Short Description
            </Label>
            <Input
              type="textarea"
              value={shortDescription}
              id="shortDescription"
              name="shortDescription"
              placeholder="Add Short Description"
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Tags */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="tags">
              Tags
            </Label>
            <Input
              type="text"
              value={tags}
              id="tags"
              name="tags"
              placeholder="Add Tags"
              onChange={(e) => setTags(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Time To Read */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="timeToRead">
              Time To Read
            </Label>
            <Input
              type="text"
              value={timeToRead}
              id="timeToRead"
              name="timeToRead"
              placeholder="Add Time To Read"
              onChange={(e) => setTimeToRead(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Publish Date */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="publish date">
              Publish Date
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={publishDate}
              onChange={(date) => setPublishDate(date)}
              id="publishDate"
              placeholder="Add Publish Date"
              required
            />
          </FormGroup>
        </Col>

        {/* // ! Display Order */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="displayOrder">
              Display Order
            </Label>
            <Input
              type="text"
              value={displayOrder}
              id="displayOrder"
              name="displayOrder"
              placeholder="Add Display Order"
              onChange={(e) => setDisplayOrder(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! URL */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              URL (Format: /blogs/blog-name)
            </Label>
            <Input
              type="text"
              value={url}
              id="url"
              name="url"
              placeholder="Add URL"
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! SEO */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="seo">
              SEO
            </Label>

            <Col sm="11">
              <Label className="form-label" for="seo">
                Meta Title
              </Label>
              <Input
                type="text"
                value={metaTitle}
                id="metaTitle"
                name="metaTitle"
                placeholder="Add Meta Title"
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </Col>
            <br />

            <Col sm="11">
              <Label className="form-label" for="seo">
                Meta Description
              </Label>
              <Input
                type="text"
                value={metaDesc}
                id="metaDesc"
                name="metaDesc"
                placeholder="Add Meta Desc"
                onChange={(e) => setMetaDesc(e.target.value)}
              />
              <br />
            </Col>

            <Col sm="11">
              <Label className="form-label" for="seo">
                Keywords
              </Label>
              <Input
                type="text"
                value={keywords}
                id="keywords"
                name="keywords"
                placeholder="Add Keywords"
                onChange={(e) => setKeywords(e.target.value)}
              />
            </Col>
          </FormGroup>
        </Col>

        {/* // ! Status */}
        <Col sm="6">
          <SwitchIcon
            checked={status}
            id="status"
            name="status"
            label="Publish on Website ?"
            handleChecked={(value) => setStatus(value)}
          />
        </Col>

        {/* // ! Destinations */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="destination">
              Destinations
            </Label>
            <Select
              onChange={handleDestinationChange}
              isClearable={false}
              theme={selectThemeColors}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              id="destination"
              name="destination"
              value={destination}
              options={DestinationsList}
              className="react-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Overview;
