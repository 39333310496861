import React, { useEffect, useState } from "react";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { selectThemeColors, dateFormatter } from "@utils";
import {
  useGetAllPaginatedWebCheckin,
  useGetAllWebCheckin,
  useHostelList,
  useGetHostelsNameandId,
  useGenerateCheckinDataForExport,
} from "../../api/dataSource";
import WebCheckinTable from "./WebCheckinTable";
import AddEditModall from "./AddEditModall";
import Flatpickr from "react-flatpickr";

// const tableHeaderDataObject = [
//   { label: "Hostel Name", key: "hostelName" },
//   { label: "Reservation ID", key: "reservationID" },
//   { label: "Time of Arrival", key: "timeOfArrival" },
//   { label: "First Name", key: "firstName" },
//   { label: "Last Name", key: "lastName" },
//   { label: "Gender", key: "gender" },
//   { label: "DOB", key: `dateOfBirth` },
//   { label: "Country Code", key: "countryCode" },
//   { label: "Phone Number", key: "phoneNumber" },
//   { label: "Email", key: "email" },
//   { label: "How did you book The Hosteller", key: "howDidYouBookTheHosteller" },
//   { label: "Group Size", key: "groupSize" },
//   { label: "C Form Application Number", key: "cFormAppNo" },
//   { label: "Residing Country", key: "residingCountry" },
//   { label: "Residing State", key: "residingState" },
//   { label: "Residing City", key: "residingCity" },
//   { label: "Coming from(City)", key: "comingFromCity" },
//   { label: "Going to(City)", key: "goingToCity" },
//   { label: "Mode of transport", key: "modeOfTransport" },
//   {
//     label: "Have you stayed with The Hosteller before",
//     key: "haveYouStayedWithTheHostellerBefore",
//   },
//   { label: "What would best describe you?", key: "whatWouldBestDescribeYou" },
//   { label: "Gov. ID Proof", key: "govtIdProof" },
//   { label: "Gov. ID Number", key: "govtIdNumber" },
//   { label: "Upload Gov. ID", key: "uploadGovId" },
//   { label: "Upload aadhar (Front Image)", key: "uploadAadharCardFrontImage" },
//   { label: "Upload aadhar (Back Image)", key: "uploadAadhaarCardBackImage" },
//   { label: "Upload Visa", key: "uploadVisa" },
//   { label: "Submission Time", key: "createdAt" },
// ];

const WebCheckin = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  // const [exportTableData, setExportTableData] = useState([]);
  const userRole = localStorage.getItem("role") || ""
  const [id, setId] = useState("");
  // const [filteredData, setFilteredData] = useState({
  //   hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  // });
  const currentDate = new Date();
  const dayDifference = new Date(currentDate);
  dayDifference.setDate(dayDifference.getDate() - 1);
  const [checkinDateFrom, setCheckinDateFrom] = useState(dayDifference);
  const [checkinDateTo, setCheckinDateTo] = useState(currentDate);

  const [filteredData, setFilteredData] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    checkinDateFrom,
    checkinDateTo,
  });
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [guestName, setGuestName] = useState("");
  const [reservationID, setReservationID] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  // const [checkinDateFrom, setCheckinDateFrom] = useState(null);
  // const [checkinDateTo, setCheckinDateTo] = useState(null);
  const [nationality, setNationality] = useState("");
  const [cFormNo, setCFormNo] = useState("");
  // const { data: HostelsData } = useHostelList();
  const { data: HostelsData } = useGetHostelsNameandId();
  const [dataCount, setDataCount] = useState(0);
  const [getCheckinExportDataHandler, { loading, data: checkinDataForExport }] =
    useGenerateCheckinDataForExport();
  const [submissionTime, setSubmissionTime] = useState(null);
  const [membershipType, setMembershipType] = useState("");
  const [severity, setSeverity] = useState(null);
  const [isBlackListed, setIsBlackListed] = useState(null);
  const [otpVerified, setOtpVerified] = useState(null)

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
    if (HostelsData?.getHostelsNameandId?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelID) => {
            return HostelsData?.getHostelsNameandId?.find(
              (item) => item._id === hostelID
            );
          })
          : HostelsData?.getHostelsNameandId;

      let hostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(hostelList);
    }
  }, [HostelsData]);

  const inputFilter = {
    limit,
    page,
    filteredData,
    // checkinDateFrom,
    // checkinDateTo,
  };
  const exportInputFilter = {
    filteredData,
  };
  const { data, refetch } = useGetAllPaginatedWebCheckin(inputFilter); // ! Coded by Ashwin
  // const { data: exportData } = useGetAllWebCheckin(exportInputFilter);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setDataCount(data?.getPaginatedWebCheckinList?.count);
  }, [data?.getPaginatedWebCheckinList?.count]);

  useEffect(() => {
    if (data?.getPaginatedWebCheckinList?.data) {
      let editData = data?.getPaginatedWebCheckinList?.data?.find(
        (item) => item._id === id
      );
      setEditData(editData);
      setTableData(data?.getPaginatedWebCheckinList?.data);
    } else {
      setId(null);
      setEditData(null);
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedWebCheckinList?.count / limit));
  }, [
    data?.getPaginatedWebCheckinList?.count,
    data?.getPaginatedWebCheckinList?.data,
    limit,
    id,
  ]);

  // useEffect(() => {
  //   if (exportData?.getAllWebCheckinList?.data) {
  //     setExportTableData(exportData?.getAllWebCheckinList?.data);
  //   } else {
  //     setId(null);
  //     setExportTableData([]);
  //   }
  // }, [exportData, id]);

  const toggleModal = () => {
    setOpenEditModal(!openEditModal);
    setId(null);
    setEditData(null);
  };

  const guestNationality = [
    { label: "Indian", value: "Indian" },
    { label: "Foreigner", value: "Foreigner" },
  ];

  const cFormOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const membershipTypeOptions = [
    { label: "Member", value: "member" },
    { label: "Non Member", value: "nonMember" },
  ];

  const submissionTimeOptions = [
    { label: "Pre arrival", value: "prearrival" },
    { label: "On arrival", value: "onarrival" },
  ];

  const isBlackListedOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const OTPVerifiedOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]
  const severityList = [
    { label: "Low", value: "Low" },
    // { label: "Moderate", value: "Moderate" },
    { label: "High", value: "High" },
  ];

  const handleSetFilter = (params) => {
    if (params) {
      setFilteredData(params);
    }
  };

  const submitHandler = () => {
    const data = {
      hostelIdArray: !hostel?.value ? null : [hostel?.value],
      guestName,
      reservationID,
      phoneNumber,
      email,
      cFormNo: cFormNo?.value,
      checkinDateFrom,
      checkinDateTo,
      nationality: nationality?.value,
      submissionTime: submissionTime?.value,
      membershipType: membershipType?.value,
      severity: severity?.value,
      isBlackListed: isBlackListed?.value,
      otpVerified: otpVerified?.value
    };
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === "") {
        delete data[key];
      }
    });
    handleSetFilter(data);
    setPage(1);
  };

  const refreshHandler = () => {
    try {
      handleSetFilter({
        hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
      });
      setHostel([]);
      setReservationID("");
      setGuestName("");
      setPhoneNumber("");
      setEmail("");
      setCheckinDateFrom(null);
      setCheckinDateTo(null);
      setNationality("");
      setCFormNo("");
      setSubmissionTime(null);
      setMembershipType([]);
      setSeverity(null);
      setIsBlackListed(null);
      setOtpVerified(null)
      setPage(1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log(checkinDataForExport?.generateXlsxWebCheckinList?.data)
    if (checkinDataForExport?.generateXlsxWebCheckinList?.data) {
      if (checkinDataForExport?.generateXlsxWebCheckinList?.data.length > 0) {
        const byteArray = new Uint8Array(
          checkinDataForExport?.generateXlsxWebCheckinList?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `webCheckinData_pg.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [checkinDataForExport]);

  return (
    localStorage.getItem("modules")?.includes("Glu Reports") && (
      <div>
        <Row>
          <div style={{ margin: "0 20px" }}>
            <Row>
              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="product">
                    <strong>Hostel Name</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    theme={selectThemeColors}
                    id="hostel"
                    name="hostel"
                    options={HostelsList}
                    onChange={(value) => {
                      setHostel(value);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="reservationID">
                    <strong>Reservation ID</strong>
                  </Label>
                  <Input
                    type="text"
                    id="reservationID"
                    name="reservationID"
                    value={reservationID}
                    placeholder="Reservation ID"
                    onChange={(e) => {
                      setReservationID(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="guestName">
                    <strong>Guest Name</strong>
                  </Label>
                  <Input
                    type="text"
                    id="guestName"
                    name="guestName"
                    placeholder="Guest Name"
                    value={guestName}
                    onChange={(e) => {
                      setGuestName(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="phoneNumber">
                    <strong>Phone Number</strong>
                  </Label>
                  <Input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="email">
                    <strong> Email</strong>
                  </Label>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="url">
                    <strong>Check In Date Range</strong>
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    id="checkinDate"
                    placeholder="Check In Date Range"
                    options={{
                      mode: "range",
                    }}
                    value={[checkinDateFrom, checkinDateTo]}
                    onChange={(date) => {
                      setCheckinDateFrom(dateFormatter(date[0]));
                      setCheckinDateTo(dateFormatter(date[1]));
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="Category">
                    <strong>Nationality</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="nationality"
                    name="nationality"
                    value={nationality}
                    options={guestNationality}
                    onChange={(value) => {
                      setNationality(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="cformno">
                    <strong>C-Form Submitted</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="cformno"
                    name="cformno"
                    value={cFormNo}
                    options={cFormOptions}
                    onChange={(value) => {
                      setCFormNo(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="cformno">
                    <strong>Membership Type</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="membershipType"
                    name="membershipType"
                    value={membershipType}
                    options={membershipTypeOptions}
                    onChange={(value) => {
                      setMembershipType(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              {/* <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="severity">
                    <strong>Severity</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    // theme={selectThemeColors}
                    // isMulti
                    id="severity"
                    name="severity"
                    options={severityList}
                    value={severity}
                    onChange={(value) => setSeverity(value)}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col> */}

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="isBlackListed">
                    <strong>Blacklisted</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="isBlackListed"
                    name="isBlackListed"
                    value={isBlackListed}
                    options={isBlackListedOptions}
                    onChange={(value) => {
                      setIsBlackListed(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="submissionTime">
                    <strong>Submission Time</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="submissionTime"
                    name="submissionTime"
                    value={submissionTime}
                    options={submissionTimeOptions}
                    onChange={(value) => {
                      setSubmissionTime(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup className="w-100">
                  <Label className="form-label" for="otpVerified">
                    <strong>OTP Verified</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="otpVerified"
                    name="otpVerified"
                    value={otpVerified}
                    options={OTPVerifiedOptions}
                    onChange={(value) => {
                      setOtpVerified(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col style={{ margin: "10px" }}>
                <Button
                  style={{
                    backgroundColor: "#ffe700",
                    height: "40px",
                    marginTop: "22px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="white"
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  Search
                </Button>
                <Button
                  style={{
                    backgroundColor: "#ff9999",
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="danger"
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    refreshHandler();
                  }}
                >
                  Reset
                </Button>

                {userRole !== "Ground Staff" && <Button
                  style={{
                    backgroundColor: "#ff9999",
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                  onClick={() => getCheckinExportDataHandler(exportInputFilter)}
                >
                  Export
                </Button>}
              </Col>
            </Row>
          </div>
        </Row>
        <WebCheckinTable
          setId={setId}
          openEditModal={() => setOpenEditModal(!openEditModal)}
          data={tableData}
          // exportData={exportTableData}
          // page={page}
          refetch={refetch}
          handleSetFilter={handleSetFilter}
          // tableHeaderDataObject={tableHeaderDataObject}
          // exportTableData={exportTableData}
          dataCount={dataCount}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModall
          open={openEditModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default WebCheckin;
