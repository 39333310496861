import React, { useEffect, useState } from "react";
import SlideModal from "@components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import { useHostelList, useDestinationList } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function AddEditModal({
  addEditModal,
  handleToggle,
  createStripConfigHandler,
  editData,
  updateStripConfigHandler,
  refetch,
  setId,
}) {
  const [stripType, setStripType] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [category, setCategory] = useState("");
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();
  const [destination, setDestination] = useState([]);
  const [DestinationsList, setDestinationsList] = useState([]);
  const { data: allDestinationsData } = useDestinationList();

  const type = [
    {
      label: "Homepage",
      value: "Homepage",
    },
    {
      label: "Hostels",
      value: "Hostels",
    },
    {
      label: "Hostel Details",
      value: "Hostel Details",
    },
    {
      label: "Destinations",
      value: "Destinations",
    },
    {
      label: "Destination Details",
      value: "Destination Details",
    },
    {
      label: "Workation",
      value: "Workation",
    },
    {
      label: "Contact Us",
      value: "Contact Us",
    },
    {
      label: "Invest and Partner",
      value: "Invest and Partner",
    },
    {
      label: "Membership",
      value: "Membership",
    },
  ];

  const stripCategory = [
    {
      label: "Offer",
      value: "Offer",
    },
    {
      label: "Announcement",
      value: "Announcement",
    },
  ];

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        setHostelsList((prev) => [
          ...prev,
          { ...item, label: item?.name, value: item?._id },
        ]);
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (allDestinationsData?.getDestinationList?.length) {
      allDestinationsData?.getDestinationList?.map((item) => {
        setDestinationsList((prev) => [
          ...prev,
          { ...item, label: item?.destination, value: item?._id },
        ]);
      });
    }
  }, [allDestinationsData?.getDestinationList]);

  const handleChange = function (HostelsList) {
    return setHostel(HostelsList);
  };

  const handleDestinationChange = function (DestinationsList) {
    return setDestination(DestinationsList);
  };

  const handleSubmit = () => {
    const hostelsArray = [];
    // eslint-disable-next-line array-callback-return
    hostel?.map((item) => {
      hostelsArray?.push(item?.value);
    });

    const destinationsArray = [];
    // eslint-disable-next-line array-callback-return
    destination?.map((item) => {
      destinationsArray?.push(item?.value);
    });

    const InputStripConfig = {
      stripType: stripType,
      title: title,
      url: url,
      buttonTitle: buttonTitle,
      stripCategory: category,
      hostels: JSON.parse(JSON.stringify(hostelsArray)),
      destinations: JSON.parse(JSON.stringify(destinationsArray)),
    };
    if (title && stripType) {
      if (editData) {
        updateStripConfigHandler(editData?._id, InputStripConfig);
        toaster("success", "Strip updated");
        handleToggle();
        setId("");
      } else {
        createStripConfigHandler(InputStripConfig);
        toaster("success", "Strip added");
      }
      setStripType("");
      refetch();
      setTitle("");
      setUrl("");
      setButtonTitle("");
      setCategory("");
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  useEffect(() => {
    if (editData) {
      setStripType(editData?.stripType || "");
      setTitle(editData?.title || "");
      setUrl(editData?.url || "");
      setButtonTitle(editData?.buttonTitle || "");
      setCategory(editData?.stripCategory || "");

      let hotelSelectedData = [];
      editData?.hostels?.map((hostel) => {
        allHostelsData?.getHostelList?.map((hostellist) => {
          if (hostel?._id === hostellist?._id) {
            hotelSelectedData?.push({
              label: hostel?.name,
              value: hostel?._id,
            });
          }
        });
      });

      setHostel(hotelSelectedData);

      let destinationSelectedData = [];
      editData?.destinations?.map((destination) => {
        allDestinationsData?.getDestinationList?.map((destinationlist) => {
          if (destination?._id === destinationlist?._id) {
            destinationSelectedData?.push({
              label: destination?.destination,
              value: destination?._id,
            });
          }
        });
      });

      setDestination(destinationSelectedData);
      refetch();
    } else {
      setStripType("");
      setTitle("");
      setUrl("");
      setButtonTitle("");
      setCategory("");
      setHostel([]);
      setDestination([]);
    }
  }, [editData, allHostelsData, allDestinationsData, refetch]);

  return (
    <SlideModal
      submitDataHandler={handleSubmit}
      handleToggle={handleToggle}
      open={addEditModal}
    >
      <form action="">
        <Row>
          <Col>
            <FormGroup>
              <Label for="stripType">Strip Type</Label>
              <Select
                id="stripType"
                name="stripType"
                value={type.find((option) => option.value === stripType)}
                options={type}
                required
                onChange={(e) => setStripType(e.value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>
        {stripType === "Hostel Details" && (
          <Row>
            <Col>
              <FormGroup className="w-100">
                <Label className="form-label" for="hostel">
                  Hostels
                </Label>
                <Select
                  onChange={handleChange}
                  isClearable={false}
                  theme={selectThemeColors}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  id="hostel"
                  name="hostel"
                  value={hostel}
                  options={HostelsList}
                  className="react-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {stripType === "Destination Details" && (
          <Row>
            <Col>
              <FormGroup className="w-100">
                <Label className="form-label" for="destination">
                  Destinations
                </Label>
                <Select
                  onChange={handleDestinationChange}
                  isClearable={false}
                  theme={selectThemeColors}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  id="destination"
                  name="destination"
                  value={destination}
                  options={DestinationsList}
                  className="react-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <FormGroup>
              <Label for="stripCategory">Strip category</Label>
              <Select
                id="stripCategory"
                name="stripCategory"
                value={stripCategory.find(
                  (option) => option.value === category
                )}
                options={stripCategory}
                required
                onChange={(e) => setCategory(e.value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                name="title"
                id="title"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="url">URL</Label>
              <Input
                placeholder="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="text"
                name="url"
                id="url"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="buttonTitle">Button Title</Label>
              <Input
                placeholder="Button title"
                value={buttonTitle}
                onChange={(e) => setButtonTitle(e.target.value)}
                type="text"
                name="buttonTitle"
                id="buttonTitle"
              />
            </FormGroup>
          </Col>
        </Row>

        {/* // ! Logs */}
        <Row>
          {editData?._id && editData?.logs && (
            <Col sm="12">
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>

                <br />
                {editData?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
}
export default AddEditModal;
