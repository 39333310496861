import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const WebcheckinAnalysisTable = ({ tableData, months }) => {
  const [tableHeaderData, setTableHeaderData] = useState(["Data Point"]);
  const fractionToPercentage = (numerator, denominator) => {
    if (denominator == 0) {
      return "--";
    }
    var result = (numerator / denominator) * 100;
    return result.toFixed(2) + "%";
  };

  const topContribution = (stateList, totalDocuments) => {
    let total = 0;
    for (let i = 0; i < 10; i++) {
      if (stateList[i]) {
        total += stateList[i]?.count;
      }
    }
    const topContribution = fractionToPercentage(total, totalDocuments);
    return topContribution;
  };

  const restOfIndia = (residingState, totalDocuments) => {
    let topContributionNumber = topContribution(residingState, totalDocuments);
    let result = (100 - topContributionNumber.slice(0, -1)).toFixed(2);
    return result + "%";
  };

  useEffect(() => {
    setTableHeaderData(months);
  }, [months]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>Web Checkin Data Analysis</CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4>
                    Total Guests
                  </h4>
                </td>
                {tableData?.map((item, index) => (
                  <td key={index}>{item?.totalDocuments}</td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Residing country
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr >
                <td style={{ paddingLeft: "80px" }}>Indian</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.residingCountries?.Indian,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Foreigner</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.residingCountries?.Foreigner,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Gender
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Male</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.genders?.Male,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Female</td>
                {/* <td>{fractionToPercentage(genders?.Female, tableData?.totalDocuments)}</td> */}
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.genders?.Female,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }} >Others</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.genders?.Other,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Returning customers
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Returning Customer</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayedWithHosteller?.stayedWithHosteller,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    What would best describe
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Business Professional</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.BusinessProfessional,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Working Professional</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.WorkingProfessional,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Student</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.Student,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Freelancer</td>

                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.FreeLancer,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Not Working/Not Studying</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.Other,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Group size
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Solo</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.Solo,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>Couple</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["2"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>3-4 px group</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["3-4"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>5-6 px group</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["5-6"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>6+ group</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["6+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Age group
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>18-21</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.ageGroup?.["18-21"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>22-25</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.ageGroup?.["22-25"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>26-28</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.ageGroup?.["26-28"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>29-32</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.ageGroup?.["29-32"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>32+</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.ageGroup?.["32+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Primary purpose of travel
                  </h4>
                </td>
                <td></td>
              </tr>
              {[...Array(tableData[0].purposeOfTravel?.length).keys()].map(
                (i) => (
                  <tr key={i}>
                    <td style={{ paddingLeft: "80px" }}>{tableData[0].purposeOfTravel[i]?._id}</td>
                    {tableData?.map((tableItem, index) => (
                      <td key={index}>
                        {tableItem.purposeOfTravel.map((residingItem) => {
                          if (
                            residingItem._id ===
                            tableData[0].purposeOfTravel[i]?._id
                          ) {
                            return fractionToPercentage(
                              residingItem.count,
                              tableItem?.totalDocuments
                            );
                          }
                        })}
                      </td>
                    ))}
                  </tr>
                )
              )}
              <tr>
                <td>
                  <h4>
                    Booking source
                  </h4>
                </td>
                <td></td>
              </tr>
              {[...Array(tableData[0].bookingSource?.length).keys()].map(
                (i) => (
                  <tr key={i}>
                    <td style={{ paddingLeft: "80px" }}>{tableData[0].bookingSource[i]?._id}</td>
                    {tableData?.map((tableItem, index) => (
                      <td key={index}>
                        {tableItem.bookingSource.map((residingItem) => {
                          if (
                            residingItem._id ===
                            tableData[0].bookingSource[i]?._id
                          ) {
                            return fractionToPercentage(
                              residingItem.count,
                              tableItem?.totalDocuments
                            );
                          }
                        })}
                      </td>
                    ))}
                  </tr>
                )
              )}
              <tr>
                <td>
                  <h4>
                    Stay duration
                  </h4>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>1</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["1"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>2</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["2"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>3</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["3"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>4</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["4"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>5</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["5"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>6</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["6"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>7</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["7"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>7+</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["7+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>14+</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["14+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>21+</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["21+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ paddingLeft: "80px" }}>28+</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayDuration?.["28+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h4>
                    Residing states
                  </h4>
                </td>
                <td></td>
              </tr>
              {[...Array(tableData[0].residingState?.length).keys()].map(
                (i) => (
                  <tr key={i}>
                    <td style={{ paddingLeft: "80px" }}>{tableData[0].residingState[i]?._id}</td>
                    {tableData?.map((tableItem, index) => (
                      <td key={index}>
                        {tableItem.residingState.map((residingItem) => {
                          if (
                            residingItem._id ===
                            tableData[0].residingState[i]?._id
                          ) {
                            return fractionToPercentage(
                              residingItem.count,
                              tableItem?.totalDocuments
                            );
                          }
                        })}
                      </td>
                    ))}
                  </tr>
                )
              )}

              {tableData && tableData[0]?.residingState?.length > 10 && (
                <tr>
                  <td style={{ paddingLeft: "80px" }}>Top 10 states contribution</td>
                  {tableData.map((item, index) => (
                    <td key={index}>
                      {topContribution(
                        item?.residingState,
                        item?.totalDocuments
                      )}
                    </td>
                  ))}
                </tr>
              )}

              {tableData && tableData[0]?.residingState?.length > 10 && (
                <tr>
                  <td style={{ paddingLeft: "80px" }}>Rest of India</td>
                  {tableData?.map((item, index) => (
                    <td key={index}>
                      {restOfIndia(item?.residingState, item?.totalDocuments)}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row >
  );
};

export default WebcheckinAnalysisTable;
