import React from 'react';

const ConnectTagUserName = ({ comment, userList, setComment, setVisibility, setSearchName, setTaggedUsers }) => {

    const handleClick = (name) => {
        setVisibility(false);
        const lastIndex = comment.lastIndexOf('@');
        setComment((prevComment) => prevComment.substring(0, lastIndex + 1) + name);
        setTaggedUsers((taggedUsers) => [...taggedUsers, name]);
        setSearchName(name);
    };

    return (
        <div>
            {userList?.length > 0 ? (
                <div
                    style={{
                        position: 'absolute',
                        maxHeight: '250px',
                        top: '70px',
                        left: 0,
                        overflowY: 'auto',
                        backdropFilter: 'blur(10px)',
                        zIndex: 10,
                        backgroundColor: '#ffffff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: '8px',
                        width: '400px',
                    }}
                >
                    {userList?.map((user, index) => (
                        <div
                            key={index}
                            onClick={() => handleClick(user?.name)}
                            style={{
                                cursor: 'pointer',
                                padding: '8px 10px',
                                marginBottom: '4px',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '4px',
                                transition: 'background-color 0.3s',
                            }}
                            onMouseEnter={(e) => (e.target.style.backgroundColor = '#e6e6e6')}
                            onMouseLeave={(e) => (e.target.style.backgroundColor = '#f9f9f9')}
                        >
                            {user?.name}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default ConnectTagUserName;
