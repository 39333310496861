import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Input, Row, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useUpdateMerchandiseOrders } from "../../../api/MerchandiseOrder/Mutation";
import toaster from "@components/common/Toaster";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  console.log(editData);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);
  const [shippingVendorName, setShippingVendorName] = useState("");
  const [razorpayPamentId, setRazorpayPaymentId] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalTax, setTotalTax] = useState("");
  const [trackingDetails, setTrakingDetails] = useState("");
  const [remark, setRemark] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [addressLine1, setAddressLine1] = useState(null);
  const [city, setCity] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [pincode, setPincode] = useState(null)
  const [stateName, setStateName] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [products, setProducts] = useState([])
  const [updateMerchandiseOrderHandler, { loading, error, data }] =
    useUpdateMerchandiseOrders();
  const statusValueList = [
    { label: "Pending", value: "Pending" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
  ];

  const onSubmitHandler = () => {
    const data = {
      status: status?.value,
      shippingVendorName,
      trackingDetails,
      remark,
      deliveryDate,
    };

    updateMerchandiseOrderHandler(editData?._id, data);
    refetch();
    handleToggle();
  };

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);
  useEffect(() => {
    if (editData?._id) {
      const statusValue = { label: editData?.status, value: editData?.status };
      const productList = editData?.products?.map((item) => { return `${item?.name} x ${item?.quantity}` })
      setName(editData?.user?.name);
      setMobile(editData?.user?.mobile);
      setEmail(editData?.user?.email);
      setStatus(statusValue);
      setRazorpayPaymentId(editData?.razorpayPaymentId);
      setTotalAmount(editData.totalPriceWithTax);
      setTotalTax(editData?.totalTax);
      setAddressLine1(editData?.user?.addressLine1);
      setCity(editData?.user?.city);
      setLandmark(editData?.user?.landmark);
      setPincode(editData?.user?.pincode);
      setStateName(editData?.user?.state)
      setShippingVendorName(editData?.shippingDetails?.shippingVendorName);
      setTrakingDetails(editData?.shippingDetails?.trackingDetails);
      setRemark(editData?.shippingDetails?.remark)
      setOrderId(editData?.orderId)
      setProducts(productList)
    } else {
      setName("");
      setMobile("");
      setEmail("");
      setStatus(null);
      setTotalAmount("");
      setTotalTax("");
      setAddressLine1(null)
      setCity(null)
      setLandmark(null)
      setPincode(null)
      setStateName(null)
      setShippingVendorName(null);
      setTrakingDetails(null)
      setRemark(null);
      setOrderId(null);
      setProducts([])
    }
  }, [editData]);

  //   useEffect(() => {
  //     refetch();
  //   }, [updatedData, createdData]);
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form>
        <Row>
          <Col>
            <Row>
              <Col>
                <label>Order Id</label>
                <Input
                  autoFocus
                  type="text"
                  value={orderId}
                  id="orderId"
                  name="orderId"
                  placeholder="Order Id"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Products:</label>
                <div style={{ padding: "10px" }}>

                  {products.map((item) => { return <p>- {" "}{item}</p> })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Name</label>
                <Input
                  autoFocus
                  type="text"
                  value={name}
                  id="name"
                  name="name"
                  placeholder="Name"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Mobile</label>
                <Input
                  autoFocus
                  type="text"
                  value={mobile}
                  id="name"
                  name="name"
                  placeholder="Mobile number"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Email</label>
                <Input
                  autoFocus
                  type="text"
                  value={email}
                  id="email"
                  name="email"
                  placeholder="Email"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Address</label>
                <Input
                  autoFocus
                  type="text"
                  value={addressLine1}
                  id="address"
                  name="address"
                  placeholder="Address"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>City</label>
                <Input
                  autoFocus
                  type="text"
                  value={city}
                  id="city"
                  name="city"
                  placeholder="City"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Landmark</label>
                <Input
                  autoFocus
                  type="text"
                  value={landmark}
                  id="landmark"
                  name="landmark"
                  placeholder="Landmark"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>State</label>
                <Input
                  autoFocus
                  type="text"
                  value={stateName}
                  id="state"
                  name="state"
                  placeholder="State"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Pincode</label>
                <Input
                  autoFocus
                  type="text"
                  value={pincode}
                  id="pincode"
                  name="pincode"
                  placeholder="Pincode"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Razorpay Id</label>
                <Input
                  autoFocus
                  type="text"
                  value={razorpayPamentId}
                  id="razorpayPamentId"
                  name="razorpayPamentId"
                  placeholder="Razorpay Id"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Total amount</label>
                <Input
                  autoFocus
                  type="Number"
                  value={totalAmount}
                  id="totalAmount"
                  name="totalAmount"
                  placeholder="Total amount"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Status</label>
                <Select
                  onChange={(newStatus) => {
                    if (status.value === "Pending") {
                      if (newStatus.value === "Shipped") {
                        setStatus(newStatus);
                      } else {
                        toaster("error", "invalid status update");
                      }
                    } else if (status.value === "Shipped") {
                      if (newStatus.value === "Delivered") {
                        setStatus(newStatus);
                      } else {
                        toaster("error", "invalid status update");
                      }
                    } else {
                      toaster("error", "status can't be updated");
                    }
                  }}
                  closeMenuOnSelect={true}
                  id="status"
                  name="status"
                  value={status}
                  options={statusValueList}
                  className="react-select"
                  classNamePrefix="select"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Shipping vendor name</label>
                <Input
                  // autoFocus
                  type="text"
                  value={shippingVendorName}
                  id="ShippingVendorName"
                  name="ShippingVendorName"
                  placeholder="Shipping vendor name"
                  onChange={(e) => setShippingVendorName(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Tracking details</label>
                <Input
                  // autoFocus
                  type="text"
                  value={trackingDetails}
                  id="trackingDetails"
                  name="trackingDetails"
                  placeholder="Tracking details"
                  onChange={(e) => setTrakingDetails(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Remark</label>
                <Input
                  // autoFocus
                  type="text"
                  value={remark}
                  id="remark"
                  name="remark"
                  placeholder="Remark"
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Col>
            </Row>
            <Row>

              <Col>
                <label>Delivery date</label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={deliveryDate}
                  onChange={(date) => setDeliveryDate(date)}
                  id="deliveryDate"
                  placeholder="Add delivery date"
                  required
                />
              </Col>
            </Row>
          </Col>

          {/* // ! Logs */}
          {editData?.logs && (
            <Col sm="12">
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>
  
                <br />
                {editData?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal >
  );
};

export default AddEditModal;
