import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import { useCreateAmenities, useUpdateAmenity,  useUploadImage} from "../../api/dataSource";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const AddEditModal = ({
  open,
  handleToggle,
  editData,
  refetch: refetchAmenities,
}) => {

  const { id } = useParams();
  const [previewArr, setPreviewArr] = useState([]);
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [createAmenitiesHandler] = useCreateAmenities();
  const [updateAmenityHandler] = useUpdateAmenity();

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  useEffect(() => {
    if (editData?.name) {
      setName(editData.name);
      setKey(editData.key);
      setPreviewArr([editData.icon]);
    } else {
      setName("");
      setKey("");
      setPreviewArr([]);
    }
  }, [editData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/Amenities");
    toaster("success", "Amenity image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading amenity image: ${error.message}`);
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const onSubmitHandler = () => {
    if ((imageData?.uploadImage?.url || editData?.icon) && name) {
      if (editData?._id) {
        updateAmenityHandler(editData._id, {
          name: name,
          icon: imageData?.uploadImage?.url || editData.icon,
          key: key,
        });

        toaster("success", "Amenity updated successfully");
      } else {
        createAmenitiesHandler({
          _id: id,
          name: name,
          icon: imageData?.uploadImage?.url,
          key: key,
        });

        toaster("success", "Amenity added successfully");
      }

      refetchAmenities();
      handleToggle();
      imageReset();
      setName("");
      setKey("");
      setPreviewArr([]);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col>
            <Row className="mb-3">
              <Col sm={6}>
                <label>Name*</label>
                <Input
                  autoFocus
                  type="text"
                  value={name}
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col sm={6}>
                <label>Key</label>
                <Input
                  autoFocus
                  type="text"
                  value={key}
                  id="key"
                  name="key"
                  placeholder="Key"
                  onChange={(e) => setKey(e.target.value)}
                />
              </Col>
            </Row>
            <p>Upload icon*</p>
            <DragDrop note="Image size should be less than 2MB" uppy={uppy} />
            {renderPreview()}
          </Col>

          {/* // ! Logs */}
          {editData?._id && (
            <Col sm="12">
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>
  
                <br />
                {editData?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;

// <Col sm='12'>
//   <FormGroup>
//     <Label for='amenities'>Amenities</Label>
//     <Select
//       onChange={(value) => setAmenities(value)}
//       isClearable={false}
//       theme={selectThemeColors}
//       closeMenuOnSelect={false}
//       components={animatedComponents}
//       value={amenities}
//       isMulti
//       options={colourOptions}
//       className='react-select'
//       classNamePrefix='select'
//     />
//   </FormGroup>
// </Col>;
