//test uplaod

import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import { useUploadPLStatements } from "../../api/P&LStatement/Mutation";
import { useGetPaginatedPLStatements } from "../../api/P&LStatement/Queries";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import TablePagination from "../../@core/components/common/TablePagination";
import PLStatementTable from "./PLStatamentTable";
import PLStatementOverviewModal from "./PLStatementOverviewModal";

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const years = Array.from({ length: 10 }, (_, i) => {
  const year = new Date().getFullYear() - i;
  return { value: year.toString(), label: year.toString() };
});

const PLStatementComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadPLStatementsHandler, { loading, error, data: uploadData }] =
    useUploadPLStatements();

  const [page, setPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState(1);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [monthFilter, setMonthFilter] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [filterFields, setFilterFields] = useState({});
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const handleOverviewOpen = (id) => {
    const dataItem = tableData.find((item) => item._id === id);
    setEditData(dataItem || null);
    setOverviewModalOpen(true);
  };

  const { data, refetch } = useGetPaginatedPLStatements(
    limit,
    page,
    filterFields
  );

  const handleSearch = () => {
    setPage(1);
    setFilterFields({
      ...filterFields,
      hostelIds: hostelIdArray?.map((hostel) => hostel.value),
      month: monthFilter?.value,
      year: yearFilter?.value,
    });
  };

  const handleReset = () => {
    setFilterFields({});
    setHostelIdArray(null);
    setMonthFilter(null);
    setYearFilter(null);
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  const paginatedData = useMemo(() => data || [], [data]);
  const totalPages = useMemo(
    () => Math.ceil((data?.getPaginatedPLStatement?.count || 0) / limit),
    [data, limit]
  );

  useEffect(() => {
    setTableData((prev) =>
      JSON.stringify(prev) !== JSON.stringify(paginatedData)
        ? paginatedData
        : prev
    );
    setTotalPage((prev) => (prev !== totalPages ? totalPages : prev));
  }, [paginatedData, totalPages]);

  useEffect(() => {
    if (id && data?.getPaginatedPLStatement?.data?.length > 0) {
      let findData = data?.getPaginatedPLStatement?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedPLStatement?.data, id]);

  const clearForm = () => {
    setSelectedMonth(null);
    setSelectedYear(null);
    setFile(null);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) clearForm();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (uploadData?.uploadPLStatements?.success) {
      toaster("success", `${uploadData.uploadPLStatements.message}`);
      refetch();
      clearForm();
      toggleModal();
    } else if (uploadData?.uploadPLStatements?.success === false) {
      toaster("error", "Upload failed: No success in response.");
    }
  }, [uploadData, refetch]);

  const handleUpload = async () => {
    if (!selectedMonth || !selectedYear || !file) {
      toaster("error", "All fields are required. Please fill them.");
      return;
    }

    const plInput = {
      month: selectedMonth.value,
      year: selectedYear.value,
      file,
    };

    try {
      await uploadPLStatementsHandler(plInput);
    } catch (err) {
      toaster("error", `Upload failed: ${err.message}`);
    }
  };

  return (
    <div>
      {/* Upload Button */}
      <Row>
        <Col sm="2">
          <FormGroup>
            <Label for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              isClearable
              isSearchable
              theme={selectThemeColors}
              isMulti
              id="hostel"
              value={hostelIdArray}
              options={HostelsList}
              onChange={setHostelIdArray}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup>
            <Label for="monthFilter">
              <strong>Month</strong>
            </Label>
            <Select
              id="monthFilter"
              theme={selectThemeColors}
              options={months}
              value={monthFilter}
              onChange={setMonthFilter}
              placeholder="Select Month"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup>
            <Label for="yearFilter">
              <strong>Year</strong>
            </Label>
            <Select
              id="yearFilter"
              theme={selectThemeColors}
              options={years}
              value={yearFilter}
              onChange={setYearFilter}
              placeholder="Select Year"
            />
          </FormGroup>
        </Col>

        <Button
          style={{
            backgroundColor: "#ffe700",
            height: "40px",
            marginTop: "22px",
            borderRadius: "5px",
            padding: "10px 40px",
            marginLeft: "30px",
          }}
          color="white"
          onClick={() => {
            handleSearch();
          }}
        >
          Search
        </Button>

        <Button
          style={{
            backgroundColor: "#ff9999",
            height: "40px",
            marginTop: "22px",
            marginLeft: "30px",
            borderRadius: "5px",
            padding: "10px 40px",
          }}
          color="danger"
          onClick={() => {
            handleReset();
          }}
        >
          Reset
        </Button>
      </Row>

      <Row>
        <Col className="mb-2">
          <Button
            color="info"
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              // marginLeft: "30px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            onClick={toggleModal}
          >
            Upload P&L Statement
          </Button>
        </Col>
      </Row>

      {/* Card with Header */}
      <Card>
        <CardHeader>
          <h4>P&L Statement - Finance</h4>
        </CardHeader>
      </Card>

      {/* Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Upload P&L Statement</ModalHeader>
        <ModalBody>
          <Form>
            {/* Month Dropdown */}
            <FormGroup>
              <Label for="month">Select Month</Label>
              <Select
                id="month"
                theme={selectThemeColors}
                options={months}
                value={selectedMonth}
                onChange={setSelectedMonth}
                placeholder="Choose a month"
              />
            </FormGroup>

            {/* Year Dropdown */}
            <FormGroup>
              <Label for="year">Select Year</Label>
              <Select
                id="year"
                theme={selectThemeColors}
                options={years}
                value={selectedYear}
                onChange={setSelectedYear}
                placeholder="Choose a year"
              />
            </FormGroup>

            {/* File Upload */}
            <FormGroup>
              <Label for="file">Upload File</Label>
              <Input type="file" id="file" onChange={handleFileChange} />
            </FormGroup>

            {/* Submit Button */}
            <FormGroup>
              <Button color="primary" onClick={handleUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload"}
              </Button>
            </FormGroup>

            {/* Error Handling */}
            {error && <div className="text-danger mt-2">{error.message}</div>}
          </Form>
        </ModalBody>
      </Modal>

      <PLStatementTable
        openTableModal={() => setOpenTableModal(!openTableModal)}
        setId={setId}
        data={tableData}
        openOverviewModal={handleOverviewOpen}
        editData={editData}
        refetch={refetch}
      />

      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <PLStatementOverviewModal
        isOpen={overviewModalOpen}
        toggle={() => setOverviewModalOpen(!overviewModalOpen)}
        editData={editData}
        refetch={refetch}
      />
    </div>
  );
};

export default PLStatementComponent;

// import React, { useState, useEffect } from "react";
// import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
// import Select from "react-select";
// import { useGetPaginatedPLStatements } from "../../api/P&LStatement/Queries";
// import { useHostelList } from "../../api/dataSource";
// import { selectThemeColors } from "@utils";

// const PLStatementComponent = () => {
//   const limit = 10;
//   const [openModal, setOpenModal] = useState(false);
//   const [page, setPage] = useState(1);
//   const [id, setId] = useState("");
//   const [editData, setEditData] = useState(null);
//   const [tableData, setTableData] = useState([]);
//   const [hostelIdArray, setHostelIdArray] = useState([]);
//   const [HostelsList, setHostelsList] = useState("");
//   const [totalPage, setTotalPage] = useState(1);
//   const [hostel, setHostel] = useState([]);

//   const [dataCount, setDataCount] = useState(0);
//   //   const [filter, setFilter] = useState({
//   //     hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
//   //   });
//   //   const { data, refetch } = useGetPaginatedPLStatements({
//   //     limit,
//   //     page,
//   //     filter,
//   //   });

//   //   const { data: allHostelsData } = useHostelList();

//   //   const handleSearch = () => {
//   //     const filter = {
//   //       hostelIdArray:
//   //         hostel.length > 0
//   //           ? hostel?.map((hostel) => hostel.value)
//   //           : JSON.parse(localStorage.getItem("hostels")),
//   //     };
//   //     setPage(1);
//   //     setFilter(filter);
//   //   };

//   //   const handleReset = () => {
//   //     setFilter({
//   //       hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
//   //     });
//   //     setHostelIdArray(null);
//   //     setHostel([]);
//   //   };

//   //   useEffect(() => {
//   //     if (data) {
//   //       console.log("API Response:", data);
//   //     }
//   //   }, [data]);

//   //   useEffect(() => {
//   //     const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
//   //     if (allHostelsData?.getHostelList?.length) {
//   //       const filteredHostels =
//   //         allowedHostels !== null && allowedHostels?.length !== 0
//   //           ? allowedHostels?.map((hostelID) => {
//   //               return allHostelsData?.getHostelList?.find(
//   //                 (item) => item?._id === hostelID
//   //               );
//   //             })
//   //           : allHostelsData?.getHostelList;
//   //       let HostelsList = filteredHostels?.map((item) => {
//   //         return { label: item?.name, value: item?._id };
//   //       });
//   //       setHostelsList(HostelsList);
//   //     }
//   //   }, [allHostelsData?.getHostelList, limit, page]);

//   return (
//     <div>
//       <Row>
//         {/* <Col sm="3">
//           <FormGroup>
//             <Label for="hostel">
//               <strong>Hostel Name</strong>
//             </Label>
//             <Select
//               isClearable
//               isSearchable
//               theme={selectThemeColors}
//               isMulti
//               id="hostel"
//               value={hostelIdArray}
//               options={HostelsList}
//               onChange={setHostelIdArray}
//               classNamePrefix="select"
//             />
//           </FormGroup>
//         </Col>

//         <Col className="mb-2">
//           <Button
//             style={{
//               backgroundColor: "#ffe700",
//               height: "40px",
//               marginTop: "22px",
//               borderRadius: "5px",
//               padding: "10px 40px",
//             }}
//             color="white"
//             onClick={() => {
//               handleSearch();
//             }}
//           >
//             Search
//           </Button>

//           <Button
//             style={{
//               backgroundColor: "#ff9999",
//               height: "40px",
//               marginTop: "22px",
//               marginLeft: "40px",
//               borderRadius: "5px",
//               padding: "10px 40px",
//             }}
//             color="danger"
//             onClick={() => {
//               handleReset();
//             }}
//           >
//             Reset
//           </Button>
//         </Col> */}
//       </Row>
//     </div>
//   );
// };

// export default PLStatementComponent;
