import gql from "graphql-tag";

// ! getMediaPageDetails added by Ashwin
export const GET_MEDIA_DETAILS = gql`
  query getMediaPageDetails($slug: String) {
    getMediaPageDetails(slug: $slug) {
      _id
      images {
        image
        key
        type
      }
      logs
    }
  }
`;
