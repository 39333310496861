import React, { useState, useEffect } from 'react'
import Posts from './Posts'
import { Input, Button } from "reactstrap";
import TablePagination from "@components/common/TablePagination";
import { useConnectPostsPaggination } from "../../api/dataSource";

const ConnectPosts = ({postList, refetch}) => {
  const [post, setPost] = useState("");
  const postData = postList?.getAllPosts || [];
  const [searchPost, setSearchPost] = useState(postData);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  const { data, refetch: pagginationRefetch } = useConnectPostsPaggination(
      limit,
      page,
      filteredData
    );

    useEffect(() => {
      setSearchPost(postList?.getAllPosts || []);
    }, [postList]);

    useEffect(() => {
      if (data?.getPagginatedPostsList) {
        setSearchPost(data?.getPagginatedPostsList?.data || []);
        setTotalPage(Math.ceil(data?.getPagginatedPostsList?.count / limit));
      }
    }, [data]);
  
    const handleSearch = () => {
      const searchQuery = post.trim().toLowerCase();
      setFilteredData({ post: searchQuery });
      setPage(1); 
    };
  
    useEffect(() => {
      pagginationRefetch();
    }, [filteredData, page, limit]);
  
  
  return (
    <div>
      <div style={{marginBottom: "20px", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
        <h3 style={{fontWeight:"800"}}>Recent Posts</h3>
        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", width: "35%"}}>
          <Input
            type="text"
            value={post}
            id="post"
            name="post"
            placeholder="Post Name"
            onChange={(e) => setPost(e.target.value)}
          />

          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
      {searchPost && searchPost.length > 0 ?  (
        searchPost.map((data, index) => <Posts key={index} post={data} />)
      ) : (
        <h5 style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "20px"}}>No matching posts found.</h5>
      )}

      <TablePagination
        totalPage={totalPage}
        setPage={setPage}
        page={page}
      />
    </div>
  )
}

export default ConnectPosts


