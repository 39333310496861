import React, { useEffect, useState } from "react";
import CarOTAwiseReportTable from "./CarOTAwiseReportTable";
import { useGetCarOTAwiseReport } from "../../../api/CarOTAwiseReport/Queries";
// import "uppy/dist/uppy.css";
// import "@uppy/status-bar/dist/style.css";
// import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
// import { CSVLink } from "react-csv";
import toaster from "@components/common/Toaster";
import Select from "react-select";

const CarOTAwiseReport = () => {
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(
    dateFormatter(new Date())
  );
  const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const [tableData, setTableData] = useState([]);
  const [isHostel, setIsHostel] = useState({
    label: "Property Wise",
    value: true,
  });

  const list = [
    { label: "All Properties", value: false },
    { label: "Property Wise", value: true },
  ];

  const { data, refetch } = useGetCarOTAwiseReport(
    searchFromDate,
    searchToDate,
    isHostel?.value
  );

  useEffect(() => {
    if (data?.getCarReport?.length > 0) {
      setTableData(data?.getCarReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleSearch = () => {
    if (dateValidator(fromDate, toDate)) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
    } else {
      toaster("error", "Invalid Dates");
    }
  };

  const handleResetData = () => {
    setSearchFromDate(dateFormatter(new Date()));
    setSearchToDate(dateFormatter(new Date()));
    setToDate(dateFormatter(new Date()));
    setFromDate(dateFormatter(new Date()));
    setIsHostel({
      label: "Property Wise",
      value: true,
    });
    setTableData([]);
  };

  return (
    (localStorage.getItem("modules")?.includes("Reports") ||
      localStorage.getItem("modules")?.includes("Owner")) && (
      <div>
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                To Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="isHostel">
                  Data
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="membershipType"
                name="membershipType"
                value={isHostel}
                options={list}
                onChange={(value) => setIsHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col style={{ marginTop: "22px", display: "flex" }}>
            <Button.Ripple
              onClick={handleSearch}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>

            {/* <FormGroup>
              <CSVLink
                // headers={tableHeaderData}
                data={tableData}
                filename={"Source_wise_sale_report.csv"}
              >
                <Button.Ripple
                 style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginLeft:"10px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="light"
                appearance="danger"
                type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </FormGroup> */}
          </Col>
        </Row>
        {tableData.length > 0 ? (
          <CarOTAwiseReportTable
            data={tableData}
            refetch={refetch}
            isHostel={isHostel}
          />
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )}
      </div>
    )
  );
};

export default CarOTAwiseReport;
