import React from 'react'
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit, FileText } from "react-feather";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";
import Avatar from "@components/common/avatar";

const tableHeaderData = ["Action", "Icon", "Name", "status", "Display Order"];

const EventCategoriesTable = ({ openModal,
    setId,
    tableData,
    handleCreateUpdateMerchandiseProducts, }) => {
    return (
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <CardTitle>
                            <div>
                                <h2>Event categories</h2>
                            </div>
                        </CardTitle>
                        <Button.Ripple
                            color="light"
                            onClick={(e) => {
                                e.preventDefault();
                                openModal();
                            }}
                        >
                            Add category
                        </Button.Ripple>
                    </CardHeader>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableHeaderData?.map((item) => {
                                    return <th key={uuidv4()}>{item}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((item) => (
                                <tr key={uuidv4()}>
                                    <td style={{ width: "10%" }}>
                                        <Button
                                            className="rounded-circle btn-icon"
                                            color="none"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setId(item._id);
                                                openModal();
                                            }}
                                        >
                                            <Edit
                                                color={reactFeatherIcon.iconColor}
                                                size={reactFeatherIcon.iconSize}
                                            />
                                        </Button>
                                        {/* <Button
                                            className="rounded-circle btn-icon"
                                            color="none"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDelete(e, item);
                                            }}
                                        >
                                            <Trash2
                                                className="mr-50"
                                                color={reactFeatherIcon.iconColor}
                                                size={reactFeatherIcon.iconSize}
                                            />
                                        </Button> */}
                                    </td>
                                    <td>
                                        <Avatar img={item?.categoryIcon} />
                                    </td>
                                    <td>{item.name}</td>
                                    <td>
                                        <Badge
                                            pill
                                            color={item?.status ? "light-success" : "light-danger"}
                                            className="mr-1"
                                        >
                                            {item?.status ? "Active" : "Inactive"}
                                        </Badge>
                                    </td>
                                    <td>{item.displayOrder}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Row>
    )
}

export default EventCategoriesTable