import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_SALES_OCCUPANCY_REPORT = gql`
  query salesOccupancyReport(
    $fromDate: String
    $toDate: String
    $hostelIds: [String]
  ) {
    salesOccupancyReport(
      fromDate: $fromDate
      toDate: $toDate
      hostelIds: $hostelIds
    ) {
      totalActiveRooms
      totalOccupiedRooms
      occupancy
      adr
      revenue
      name
    }
  }
`;

export const useGetSalesOccupancyReport = (fromDate, toDate, hostelIds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_SALES_OCCUPANCY_REPORT,
    {
      variables: {
        fromDate,
        toDate,
        hostelIds,
      },
      skip: !fromDate || !toDate || !hostelIds,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
