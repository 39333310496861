// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";

import {
  useCreatePartnerNew,
  useUploadImage,
  useUpdatePartnerNew,
} from "../../api/dataSource";

import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  // ! Partner New
  const [hostelLocation, setHostelLocation] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [status, setStatus] = useState("");
  const [caseStudyLink, setCaseStudyLink] = useState("");
  const [formLink, setFormLink] = useState("");
  const [label1, setLabel1] = useState("");
  const [value1, setValue1] = useState("");
  const [label2, setLabel2] = useState("");
  const [value2, setValue2] = useState("");
  const [label3, setLabel3] = useState("");
  const [value3, setValue3] = useState("");
  const [label4, setLabel4] = useState("");
  const [value4, setValue4] = useState("");
  const [createPartnerNewHandler, { data }] = useCreatePartnerNew();
  const [updatePartnerNewHandler, { data: updatedPartnerNewData }] =
    useUpdatePartnerNew();
  const dispatch = useDispatch();

  const [previewArr, setPreviewArr] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const { id } = useParams();
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();

  const PartnerNewStatusList = [
    { label: "Current", value: "Current" },
    { label: "Past", value: "Past" },
  ];

  useEffect(() => {
    if (updatedPartnerNewData) {
      refetch();
      handleToggle();
    }
  }, [data, updatedPartnerNewData]);

  useEffect(() => {
    if (editData?._id) {
      setHostelLocation(editData?.hostelLocation);
      setHostelName(editData?.hostelName);
      setImagePreview(editData?.image);
      setStatus(
        editData?.status === null || editData?.status === ""
          ? ""
          : { label: editData?.status, value: editData?.status }
      );
      setCaseStudyLink(editData?.caseStudyLink);
      setFormLink(editData?.formLink);
      setLabel1(editData?.label1?.label);
      setValue1(editData?.label1?.value);
      setLabel2(editData?.label2?.label);
      setValue2(editData?.label2?.value);
      setLabel3(editData?.label3?.label);
      setValue3(editData?.label3?.value);
      setLabel4(editData?.label4?.label);
      setValue4(editData?.label4?.value);
    } else {
      setHostelLocation("");
      setHostelName("");
      setImagePreview("");
      setStatus("");
      setCaseStudyLink("");
      setFormLink("");
      setLabel1("");
      setValue1("");
      setLabel2("");
      setValue2("");
      setLabel3("");
      setValue3("");
      setLabel4("");
      setValue4("");
    }
  }, [editData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/PartnerNew");
    toaster("success", "Partner new image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading partner new image: ${error.message}`);
  });

  const renderPreview = () =>
    imagePreview ? (
      <img className="rounded mr-1 mb-2 w-50" src={imagePreview} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImagePreview(imageData?.uploadImage?.url);
  }, [imageData]);

  const onSubmitHandler = () => {
    const data = {
      hostelLocation: hostelLocation,
      hostelName: hostelName,
      image: imagePreview,
      status: status.value,
      caseStudyLink: caseStudyLink,
      formLink: formLink,
      label1: {
        label: label1,
        value: value1,
      },
      label2: {
        label: label2,
        value: value2,
      },
      label3: {
        label: label3,
        value: value3,
      },
      label4: {
        label: label4,
        value: value4,
      },
    };

    if (hostelLocation && hostelName) {
      if (editData?._id) {
        const _id = editData._id;
        updatePartnerNewHandler(_id, data);
      } else {
        createPartnerNewHandler(data);
      }

      toaster("success", "Partner added successfully");

      refetch();
      setHostelLocation("");
      setHostelName("");
      setImagePreview("");
      setStatus("");
      setCaseStudyLink("");
      setFormLink("");
      setLabel1("");
      setValue1("");
      setLabel2("");
      setValue2("");
      setLabel3("");
      setValue3("");
      setLabel4("");
      setValue4("");
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/* // ! Hostel's Location */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostelsLocation">
                Hostel's Location*
              </Label>
              <Input
                type="text"
                value={hostelLocation}
                id="hostelLocation"
                name="hostelLocation"
                placeholder="Hostel Location"
                onChange={(e) => setHostelLocation(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Hostel's Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostelName">
                Hostel's Name*
              </Label>
              <Input
                type="email"
                value={hostelName}
                id="hostelName"
                name="hostelName"
                placeholder="Hostel Name"
                onChange={(e) => setHostelName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Status */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffRole">
                Status
              </Label>
              <Select
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={PartnerNewStatusList}
                onChange={(value) => setStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Case Study Link */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="caseStudyLink">
                Case Study Link
              </Label>
              <Input
                type="text"
                value={caseStudyLink}
                id="caseStudyLink"
                name="caseStudyLink"
                placeholder="Case Study Link"
                onChange={(e) => setCaseStudyLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Case Study Link */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="formLink">
                Form Link
              </Label>
              <Input
                type="text"
                value={formLink}
                id="formLink"
                name="formLink"
                placeholder="Form Link"
                onChange={(e) => setFormLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Label 1 Title */}
          <Col sm="12" styles="display:flex">
            <FormGroup className="w-100">
              <Label className="form-label" for="label1">
                Label 1
              </Label>
              {/* // ! Label 1 */}
              <Row>
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={label1}
                      id="label1"
                      name="label1"
                      placeholder="Label"
                      maxlength="10"
                      onChange={(e) => setLabel1(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                {/* // ! Value 1 */}
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={value1}
                      id="value1"
                      name="value1"
                      placeholder="Value"
                      maxlength="10"
                      onChange={(e) => setValue1(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </Col>

          {/* // ! Label 2 Title */}
          <Col sm="12" styles="display:flex">
            <FormGroup className="w-100">
              <Label className="form-label" for="label2">
                Label 2
              </Label>
              {/* // ! Label 2 */}
              <Row>
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={label2}
                      id="label2"
                      name="label2"
                      placeholder="Label"
                      maxlength="10"
                      onChange={(e) => setLabel2(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                {/* // ! Value 2 */}
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={value2}
                      id="value2"
                      name="value2"
                      placeholder="Value"
                      maxlength="10"
                      onChange={(e) => setValue2(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </Col>

          {/* // ! Label 3 Title */}
          <Col sm="12" styles="display:flex">
            <FormGroup className="w-100">
              <Label className="form-label" for="label1">
                Label 3
              </Label>
              {/* // ! Label 3 */}
              <Row>
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={label3}
                      id="label3"
                      name="label3"
                      placeholder="Label"
                      maxlength="10"
                      onChange={(e) => setLabel3(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                {/* // ! Value 3 */}
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={value3}
                      id="value3"
                      name="value3"
                      placeholder="Value"
                      maxlength="10"
                      onChange={(e) => setValue3(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </Col>

          {/* // ! Label 4 Title */}
          <Col sm="12" styles="display:flex">
            <FormGroup className="w-100">
              <Label className="form-label" for="label4">
                Label 4
              </Label>
              {/* // ! Label 4 */}
              <Row>
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={label4}
                      id="label4"
                      name="label4"
                      placeholder="Label"
                      maxlength="10"
                      onChange={(e) => setLabel4(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                {/* // ! Value 4 */}
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={value4}
                      id="value4"
                      name="value4"
                      placeholder="Value"
                      maxlength="10"
                      onChange={(e) => setValue4(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col sm="12">
            <p>Upload Image</p>
            <DragDrop
              note="Image size should be less than 500KB"
              width="100%"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop image here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <Col sm="6">
              <Label className="form-label w-100">Image preview here</Label>
              {imageLoad ? "Uploading image..." : renderPreview()}
            </Col>
          </Col>

           {/* // ! Logs */}
            {editData?._id && editData?.logs && (
              <Col sm="12">
                <FormGroup className="w-100 mt-2">
                  <Label className="form-label" for="logs">
                    <strong>Logs:</strong>
                  </Label>
  
                  <br />
                  {editData?.logs
                    ?.map((log, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Label className="form-label" for="name">
                            <li>{log}.</li>
                          </Label>
                          <br />
                        </React.Fragment>
                      );
                    })
                    .reverse()}
                </FormGroup>
              </Col>
            )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
