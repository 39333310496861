// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_PAGINATED_PRESS_MENTION = gql`
query getPaginatedPressMentionList($filter: filter) {
    getPaginatedPressMentionList(filter: $filter) {
      count
      page
      limit
      data {
            _id
            image
            newsImage
            title
            publishedDate
            url  
            displayOrder
            status  
            logs  
      }
    }
}
`