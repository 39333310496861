import React, { useEffect, useState } from "react";
// import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import AddEditModal from "./AddEditModal";
import { Edit, Trash2 } from "react-feather";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

function StripConfigTable(props) {
  const {
    tableBody,
    createStripConfigHandler,
    handleToggle,
    addEditModal,
    setId,
    editData,
    updateStripConfigHandler,
    refetch,
    deleteStripHandler,
  } = props;
  const tableHeaderData = [
    "ACTION",
    "HOSTELS",
    "DESTINATION",
    "STRIP TYPE",
    "STRIP CATEGORY",
    "TITLE",
    "URL",
    "BUTTON TEXT",
  ];

  return (
    <Row>
      <Col>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader>
              <CardTitle>
                <h2>Strip Config</h2>
              </CardTitle>
            </CardHeader>
            <Button
              onClick={() => handleToggle()}
              className="mx-2"
              color="light"
              type="button"
            >
              Add
            </Button>
            {addEditModal && (
              <AddEditModal
                setId={setId}
                refetch={refetch}
                updateStripConfigHandler={updateStripConfigHandler}
                editData={editData}
                createStripConfigHandler={createStripConfigHandler}
                handleToggle={handleToggle}
                addEditModal={addEditModal}
              />
            )}
          </div>
          <Table responsive sm="small" hover>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableBody?.map((data) => {
                return (
                  <tr key={uuidv4()}>
                    <tr>
                      <td style={{ width: "10px" }}>
                        <Button
                          onClick={() => {
                            handleToggle();
                            setId(data?._id);
                          }}
                          color="none"
                        >
                          <Edit
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>

                      <td style={{ width: "10px" }}>
                        <Button
                          onClick={() => deleteStripHandler(data?._id)}
                          color="none"
                        >
                          <Trash2
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                    </tr>

                    <td>
                      {data?.hostels?.map((item) => {
                        return <p> {item?.name}</p>;
                      })}
                    </td>
                    <td>
                      {data?.destinations?.map((item) => {
                        return <p> {item?.destination}</p>;
                      })}
                    </td>

                    <td>{data?.stripType}</td>
                    <td>{data?.stripCategory}</td>
                    <td>{data?.title}</td>
                    <td>{data?.url}</td>
                    <td>{data?.buttonTitle}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
}

export default StripConfigTable;
