import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function LogsModal(props) {
  const { showModal, empData, empRosterData, onClose } = props;
  const [employee, setEmployee] = useState(null);

  const handleClose = () => {
    try {
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (empData) {
      setEmployee(
        empRosterData?.getEmployeeRosterByHostelId?.find(
          (emp) => emp?.empId?._id === empData?._id
        )
      );
    }
  }, [empData, empRosterData]);

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      {employee?.logs?.length > 0 ? (
        <>
          <ModalHeader className="bg-transparent" toggle={handleClose}>
            Employee Name : {employee?.empId?.fullName} ({employee?.empCode})
          </ModalHeader>
          <ModalBody className="px-sm-5 mx-50 pb-5">
            <h3>Roster Logs</h3>
            <ul>
              {/* {employee?.map((emp) => {
            return emp?.logs?.map((log, index) => {
              return <li key={index}>{log}</li>;
            });
          })} */}
              {employee?.logs
                ?.map((log, index) => {
                  return <li key={index}>{log}</li>;
                })
                ?.reverse()}
            </ul>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader className="bg-transparent" toggle={handleClose}>
            {/* Employee Name : "NA" */}
          </ModalHeader>
          <ModalBody className="px-sm-5 mx-50 pb-5">
            <h3>Logs not available</h3>
          </ModalBody>
        </>
      )}
    </Modal>
  );
}
