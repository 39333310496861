import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  Button,
  Collapse,
} from "reactstrap";
import Select from "react-select";
import toaster from "../../@core/components/common/Toaster";
import { useHostelList } from "../../api/dataSource";
import RefundRequestSlideModal from "./RefundRequestSlideModal";
import RefudRequestAttachmentComponent from "./RefudRequestAttachmentComponent";
import {
  useGetRefundGuestDataByReservationId,
  useGetEmployeeProfileByHostelId,
  useGetFrontOfficeProfilesByHostelId,
} from "../../api/RefundRequestPostStay/Queries";

import {
  useCreatePostStayRefundRequest,
  useUpdatePostStayRefundRequest,
} from "../../api/RefundRequestPostStay/Mutation";
import AddVideoUrlBtn from "../WebApp/BlacklistedGuests/add-video-url-btn";
import { v4 as uuidv4 } from "uuid";
import { Trash } from "react-feather";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const userDepartment = localStorage.getItem("department");
  const userRole = localStorage.getItem("role");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [hostelName, setHostelName] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [comment, setComment] = useState("");
  const [reservationID, setReservationID] = useState("");
  const [guestData, setGuestData] = useState({
    bookingAmount: null,
    guestName: "",
    phoneNumber: "",
    email: "",
    bookingSource: "",
  });

  const [initiatedBy, setInitiatedBy] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [logs, setLogs] = useState([]);
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const toggleLogs = () => {
    setIsLogsOpen(!isLogsOpen);
  };

  const { loading, error, data, fetchData } =
    useGetRefundGuestDataByReservationId(reservationID);

  const {
    loading: employeeLoading,
    data: employeeData,
    refetch: refetchEmployeeData,
  } = useGetFrontOfficeProfilesByHostelId(hostel?.value);

  const [
    createPostStayRefundRequestHandler,
    { data: createRefundData, refetch: createRefetch },
  ] = useCreatePostStayRefundRequest();

  const [updatePostStayRefundRequestHandler, { data: updatedRefundDataData }] =
    useUpdatePostStayRefundRequest();

  const [errors, setErrors] = useState({});

  const [reason, setReason] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [attachmentList, setAttachmentList] = useState([]);
  const [dummyStateCaseMedia] = useState(null);
  const uploadAttachmentMedia = [{ label: "imageURL", value: "photo" }];
  const [videoUrl, setVideoUrl] = useState([{ id: uuidv4(), url: "" }]);
  const [approverRemark, setApproverRemark] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [status, setStatus] = useState(editData?.status || "");

  const statusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  const rejectionReasonOptions = [
    { value: "Service was rendered", label: "Service was rendered" },
    { value: "Duplicate refund request", label: "Duplicate refund request" },
    { value: "Inaccurate claim", label: "Inaccurate claim" },
  ];

  useEffect(() => {
    if (createRefundData?.createPostStayRefundRequest) {
      refetch();
    }
  }, [createRefundData, refetch]);

  const handleFetchGuestData = async () => {
    const trimmedReservationID = reservationID?.trim();

    if (!trimmedReservationID) {
      toaster("error", "Please enter a valid Reservation ID.");
      return;
    }

    if (trimmedReservationID.length > 13) {
      toaster("error", "Reservation ID cannot exceed 13 characters.");
      return;
    }

    try {
      const response = await fetchData({
        variables: {
          reservationID: trimmedReservationID,
        },
      });

      const fetchedData = response.data?.getRefundGuestDataByReservationId;

      // Check if all fields have valid non-null, non-undefined, and non-empty values
      const allFieldsExist =
        fetchedData &&
        Object.values(fetchedData).every(
          (value) => value !== null && value !== undefined && value !== ""
        );

      if (allFieldsExist) {
        setGuestData({
          reservationID: fetchedData.reservationID,
          bookingAmount: fetchedData.bookingAmount,
          guestName: fetchedData.guestName,
          phoneNumber: fetchedData.phoneNumber,
          email: fetchedData.email,
          bookingSource: fetchedData.bookingSource,
        });
        toaster("success", "Guest data fetched successfully.");
      } else {
        toaster(
          "error",
          "No valid data found. This Reservation ID may be invalid, or associated details do not exist."
        );
        setGuestData({
          reservationID: "",
          bookingAmount: "",
          guestName: "",
          phoneNumber: "",
          email: "",
          bookingSource: "",
        });
      }
    } catch (error) {
      console.error(error);
      toaster(
        "error",
        "Failed to fetch guest data. Please check the Reservation ID."
      );
      setGuestData({
        reservationID: "",
        bookingAmount: "",
        guestName: "",
        phoneNumber: "",
        email: "",
        bookingSource: "",
      });
    }
  };

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;

    if (!/^\d+$/.test(value)) {
      toaster("error", "Only whole numbers are allowed for the refund amount.");
      return;
    }

    if (value > guestData.bookingAmount) {
      toaster("error", "Refund amount cannot exceed the booking amount.");
    } else {
      setRefundAmount(value);
    }
  };

  const requestReasonOptions = [
    { label: "R&M", value: "R&M" },
    { label: "Cleanliness", value: "Cleanliness" },
    { label: "Safety", value: "Safety" },
    { label: "Staff misconduct", value: "Staff misconduct" },
    { label: "Co-traveler misconduct", value: "Co-traveler misconduct" },
    { label: "Non-serviceability", value: "Non-serviceability" },
    { label: "Blacklist", value: "Blacklist" },
    { label: "Others", value: "Others" },
  ];

  const checkoutOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  useEffect(() => {
    if (data?.getRefundGuestDataByReservationId) {
      // Data exists, populate the fields
      const fetchedData = data.getRefundGuestDataByReservationId;
      setGuestData({
        reservationID: fetchedData.reservationID,
        bookingAmount: fetchedData.bookingAmount,
        guestName: fetchedData.guestName,
        phoneNumber: fetchedData.phoneNumber,
        email: fetchedData.email,
        bookingSource: fetchedData.bookingSource,
      });
    } else {
      // Clear the fields if no data is found
      setGuestData({
        reservationID: "",
        bookingAmount: "",
        guestName: "",
        phoneNumber: "",
        email: "",
        bookingSource: "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (hostel?.value) {
      refetchEmployeeData();
    }
  }, [hostel, refetchEmployeeData]);

  useEffect(() => {
    if (employeeData?.getFrontOfficeProfilesByHostelId) {
      setEmployeeList(employeeData.getFrontOfficeProfilesByHostelId);
    }
  }, [employeeData]);

  useEffect(() => {
    if (editData) {
      setHostel(
        editData?.hostel
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );

      setHostelName(editData?.hostel ? editData?.hostel?.name : "");
      setReservationID(editData?.reservationID ? editData?.reservationID : "");
      setGuestData({
        bookingAmount: editData?.bookingAmount || null,
        guestName: editData?.guestName || "",
        phoneNumber: editData?.mobileNumber || "",
        email: editData?.email || "",
        bookingSource: editData?.bookingSource || "",
      });

      setReason(
        editData?.refundReason
          ? { label: editData?.refundReason, value: editData?.refundReason }
          : null
      );
      setComment(editData?.refundRemark || "");
      setCheckout(
        editData?.checkOut
          ? { label: editData?.checkOut, value: editData?.checkOut }
          : null
      );
      setRefundAmount(editData?.refundAmount || null);
      setInitiatedBy(
        editData?.initiatedBy
          ? { label: editData?.initiatedBy, value: editData?.initiatedBy }
          : null
      );

      setAttachmentList(editData?.photo ? editData?.photo : []);

      setVideoUrl(
        editData?.videoUrl?.map((item) => {
          return {
            id: uuidv4(),
            url: item,
          };
        })
      );

      setStatus(editData?.status || "");
      setApproverRemark(editData?.approverRemark || "");
      setRejectionReason(editData?.rejectionReason || "");
      setLogs(editData?.logs ? editData?.logs : []);
    }
  }, [editData]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const handleRemoveUrls = (id) => {
    const newVideoUrlData = videoUrl.filter((item) => item.id !== id);
    setVideoUrl(newVideoUrlData);
  };

  const handleTextChangeUrl = (val, id, key) => {
    setVideoUrl(
      videoUrl.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );
  };

  const resetHandler = () => {
    setHostel(null);
    setHostelName("");
    setReservationID("");
    setGuestData({
      bookingAmount: null,
      guestName: "",
      phoneNumber: "",
      email: "",
      bookingSource: "",
    });
    setReason(null);
    setComment("");
    setCheckout(null);
    setRefundAmount(null);
    setInitiatedBy(null);
    setAttachmentList([]);
    setVideoUrl([]);
    setStatus("");
    setApproverRemark("");
    setRejectionReason("");
    setErrors({});
  };

  const handleSubmit = async () => {
    const newErrors = {};

    if (!hostel) newErrors.hostel = "Hostel is required.";

    if (!initiatedBy?.label) {
      newErrors.initiatedBy = "Initiated by is required.";
    }

    if (!reservationID?.trim())
      newErrors.reservationID = "Reservation ID is required.";

    if (!guestData.guestName?.trim()) {
      newErrors.guestName = "Guest name is required.";
    }
    if (!guestData.email?.trim()) {
      newErrors.email = "Email is required.";
    }
    if (!guestData.phoneNumber?.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
    }

    if (!refundAmount || isNaN(refundAmount) || refundAmount <= 0) {
      newErrors.refundAmount =
        "Refund amount must be a valid number greater than 0.";
    }

    if (!refundAmount || isNaN(refundAmount) || refundAmount <= 0) {
      newErrors.refundAmount =
        "Refund amount must be a valid number greater than 0.";
    } else if (!/^\d+(\.\d{1,3})?$/.test(refundAmount)) {
      newErrors.refundAmount =
        "Refund amount can have up to 3 decimal places only.";
    }

    if (refundAmount > guestData.bookingAmount) {
      newErrors.refundAmount =
        "Refund amount cannot exceed the booking amount.";
    }

    if (!reason) newErrors.reason = "Reason is required.";
    if (!comment?.trim()) newErrors.comment = "Comment is required.";
    if (!checkout) newErrors.checkout = "Check-out status is required.";

    if (userRole === "Area Manager" && userDepartment === "Operations") {
      if (status === "Pending") newErrors.status = "Status is required.";
      if (status === "Rejected" && !rejectionReason) {
        newErrors.rejectionReason = "Rejection reason is required.";
      }
      if (status && !approverRemark) {
        newErrors.approverRemark = `Please provide ${
          status === "Approved" ? "an approval" : "a rejection"
        } remark.`;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      toaster("error", newErrors[firstErrorKey]);
      setErrors(newErrors);
      return;
    }

    let videoUrlClone = JSON.parse(JSON.stringify(videoUrl));
    videoUrlClone.forEach((item) => delete item.id);
    const videoUrlData = videoUrlClone.map((item) => item.url);

    // Base refund request input
    const refundRequestInput = {
      hostel: hostel.value,
      reservationID,
      refundAmount: parseFloat(refundAmount),
      refundReason: reason.value,
      refundRemark: comment,
      checkOut: checkout.value,
      photo: attachmentList,
      guestName: guestData.guestName,
      email: guestData.email,
      mobileNumber: guestData.phoneNumber,
      bookingAmount: guestData.bookingAmount,
      bookingSource: guestData.bookingSource,
      initiatedBy: initiatedBy ? initiatedBy.label : "",
      videoUrl: videoUrlData,
      hostelName: hostel.label,
    };

    if (userRole === "Area Manager" && userDepartment === "Operations") {
      refundRequestInput.status = status;
      refundRequestInput.approverRemark = approverRemark;

      if (status === "Rejected") {
        refundRequestInput.rejectionReason = rejectionReason;
      }
    }

    try {
      if (editData?._id) {
        await updatePostStayRefundRequestHandler(
          editData._id,
          refundRequestInput
        );
        toaster("success", "Refund request updated successfully!");
        refetch();
      } else {
        await createPostStayRefundRequestHandler(refundRequestInput);
        toaster("success", "Refund request submitted successfully!");
        refetch();
      }
      resetHandler();
      handleToggle();
    } catch (error) {
      toaster("error", "An error occurred while submitting the request.");
      console.error(error);
    }
  };

  const handleToggleWithReset = () => {
    resetHandler();
    handleToggle();
    refetch();
  };

  return (
    <RefundRequestSlideModal
      open={open}
      handleToggle={handleToggleWithReset}
      submitDataHandler={() => handleSubmit()}
      editData={editData}
      userRole={userRole}
      userDepartment={userDepartment}
    >
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              Hostel name*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostel"
              name="hostel"
              options={hostelsList}
              value={hostel}
              onChange={(value) => {
                setHostel(value);
                setInitiatedBy(null);
              }}
              isDisabled={!!editData?._id || userRole !== "Ground Staff"}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reservationID">
              Reservation ID*
            </Label>
            <div className="d-flex align-items-center">
              <Input
                type="text"
                value={reservationID}
                id="reservationID"
                name="reservationID"
                placeholder="Reservation ID"
                onChange={(e) => setReservationID(e.target.value)}
                disabled={!!editData?._id || userRole !== "Ground Staff"}
                className="mr-2"
              />
              <Button
                onClick={handleFetchGuestData}
                color={editData?._id ? "secondary" : "primary"}
                size="md"
                className=""
                disabled={
                  loading || !!editData?._id || userRole !== "Ground Staff"
                }
              >
                {loading ? "Fetching..." : "Fetch"}
              </Button>
            </div>
            {error && toaster("error", "Error fetching guest data.")}
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              Guest Name*
            </Label>
            <Input type="text" value={guestData.guestName || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="phoneNumber">
              Phone Number*
            </Label>
            <Input type="text" value={guestData.phoneNumber || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="email">
              Email*
            </Label>
            <Input type="email" value={guestData.email || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="bookingSource">
              Booking Source*
            </Label>
            <Input type="text" value={guestData.bookingSource || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="bookingAmount">
              Booking Amount*
            </Label>
            <Input
              type="number"
              value={guestData.bookingAmount || 0}
              readOnly
            />
          </FormGroup>
        </Col>

        {/* Employee List */}
        {hostel && employeeLoading ? (
          <Col sm="12">
            <p>Loading employees...</p>
          </Col>
        ) : (
          <Col sm="12">
            <FormGroup>
              <Label>Initiated By*</Label>
              <Select
                options={employeeList.map((emp) => ({
                  label: emp.fullName,
                  value: emp._id,
                }))}
                isClearable
                value={
                  initiatedBy?.value
                    ? { label: initiatedBy.label, value: initiatedBy.value }
                    : null
                }
                onChange={(selectedOption) => {
                  setInitiatedBy(selectedOption);
                }}
                isDisabled={!!editData?._id}
              />
            </FormGroup>
          </Col>
        )}

        <Col sm="12">
          <hr />
          <FormGroup className="w-100">
            <Label className="form-label" for="refundAmount">
              Refund Amount*
            </Label>
            <Input
              type="number"
              value={refundAmount || ""}
              id="refundAmount"
              name="refundAmount"
              placeholder="Enter refund amount"
              onChange={handleRefundAmountChange}
              onWheel={(e) => e.target.blur()}
              step="1"
              disabled={!!editData?._id || userRole !== "Ground Staff"}
            />
            {refundAmount > guestData.bookingAmount && (
              <div style={{ color: "red" }}>
                Refund amount cannot be more than the booking amount!
              </div>
            )}
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reason">
              Reason*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="reason"
              name="reason"
              options={requestReasonOptions}
              value={reason}
              onChange={(value) => {
                setReason(value);
              }}
              classNamePrefix="select"
              isDisabled={userRole !== "Ground Staff"}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="comment">
              Comment*
            </Label>
            <Input
              type="textarea"
              value={comment}
              id="comment"
              name="comment"
              rows="1"
              placeholder="Add comment"
              onChange={(e) => setComment(e.target.value)}
              disabled={userRole !== "Ground Staff"}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="checkout">
              Check-out (Y/N)*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="checkout"
              name="checkout"
              options={checkoutOptions}
              value={checkout}
              onChange={(value) => {
                setCheckout(value);
              }}
              classNamePrefix="select"
              isDisabled={userRole !== "Ground Staff"}
            />
          </FormGroup>
        </Col>

        {videoUrl.map(({ id, url }, index) => (
          <React.Fragment key={id}>
            <Col sm="12">
              <Row>
                <Col sm="10">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="index">
                      Video URL
                    </Label>
                  </FormGroup>
                </Col>

                <Col sm="2">
                  {videoUrl.length > 1 && (
                    <Button.Ripple
                      className="btn-icon rounded-circle mb-1 mr-1"
                      color="danger"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveUrls(id)}
                      disabled={!!editData?._id || userRole !== "Ground Staff"}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  )}
                </Col>

                <Col sm="12">
                  <FormGroup className="w-100">
                    <Input
                      type="text"
                      value={url}
                      id="url"
                      name="url"
                      placeholder={`Video URL`}
                      onChange={(e) =>
                        handleTextChangeUrl(e.target.value, id, "url")
                      }
                      disabled={!!editData?._id || userRole !== "Ground Staff"}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </React.Fragment>
        ))}

        <Col sm="12">
          <Label className="form-label" for="attachmentList">
            Upload Image
          </Label>
          <RefudRequestAttachmentComponent
            imageList={attachmentList}
            setImageList={setAttachmentList}
            editData={dummyStateCaseMedia}
            uploadType={uploadAttachmentMedia}
            userRole={userRole}
            userDepartment={userDepartment}
          />
        </Col>

        {(userRole === "Area Manager" ||
          userRole === "Regional Manager" ||
          (userRole === "Ground Staff" &&
            (editData?.status === "Approved" ||
              editData?.status === "Rejected"))) &&
          userDepartment === "Operations" && (
            <>
              {/* Status Dropdown */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="status">
                    Status
                  </Label>
                  <Select
                    id="status"
                    name="status"
                    options={statusOptions}
                    value={statusOptions.find(
                      (option) => option.value === status
                    )}
                    onChange={(selectedOption) =>
                      setStatus(selectedOption?.value)
                    }
                    isClearable
                    classNamePrefix="select"
                    menuPlacement="top"
                    isDisabled={
                      userRole === "Ground Staff" ||
                      editData?.status !== "Pending"
                    }
                  />
                </FormGroup>
              </Col>

              {/* Approval Remark (Conditional) */}
              {status === "Approved" && (
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="approverRemark">
                      Approval Remark
                    </Label>
                    <Input
                      type="text"
                      id="approverRemark"
                      value={approverRemark}
                      onChange={(e) => setApproverRemark(e.target.value)}
                      placeholder="Enter approval remark"
                      disabled={
                        userRole === "Ground Staff" ||
                        editData?.status !== "Pending"
                      }
                    />
                  </FormGroup>
                </Col>
              )}

              {/* Rejection Section (Conditional) */}
              {status === "Rejected" && (
                <>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="rejectionReason">
                        Rejection Reason
                      </Label>
                      <Select
                        id="rejectionReason"
                        name="rejectionReason"
                        options={rejectionReasonOptions}
                        value={rejectionReasonOptions.find(
                          (option) => option.value === rejectionReason
                        )}
                        onChange={(selectedOption) =>
                          setRejectionReason(selectedOption?.value)
                        }
                        isClearable
                        classNamePrefix="select"
                        isDisabled={editData?.status === "Rejected"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="approverRemark">
                        Rejection Remark
                      </Label>
                      <Input
                        type="text"
                        id="approverRemark"
                        value={approverRemark}
                        onChange={(e) => setApproverRemark(e.target.value)}
                        placeholder="Enter rejection remark"
                        disabled={editData?.status === "Rejected"}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
            </>
          )}

        {editData?.payoutID && (
          <div className="refund-details-section mt-1 p-1 border rounded">
            <h5 className="mb-2">Refund Details</h5>
            <Row>
              <Col sm="6">
                <Label className="form-label">Payout ID</Label>
                <p className="form-control-static">{editData.payoutID}</p>
              </Col>
              <Col sm="6">
                <Label className="form-label">Payout Link Creation Time</Label>
                <p className="form-control-static">
                  {editData.payoutLinkCreationTime
                    ? new Date(editData.payoutLinkCreationTime).toLocaleString()
                    : "N/A"}
                </p>
              </Col>
              <Col sm="6">
                <Label className="form-label">Payout Status</Label>
                <p className="form-control-static">
                  {editData.payoutStatus || "N/A"}
                </p>
              </Col>
              <Col sm="6">
                <Label className="form-label">Payout Link</Label>
                <p className="form-control-static">
                  {editData.payoutLink ? (
                    <a
                      href={editData.payoutLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Payout Link
                    </a>
                  ) : (
                    "N/A"
                  )}
                </p>
              </Col>
              <Col sm="6">
                <Label className="form-label">Refund Amount</Label>
                <p className="form-control-static">
                  ₹{editData.refundAmount?.toFixed(2) || "N/A"}
                </p>
              </Col>
            </Row>
          </div>
        )}

        <>
          {editData?.logs.length > 0 && (
            <Col sm="12">
              <hr />
              <FormGroup className="w-100">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Label className="form-label" for="logs">
                    <strong>Logs:</strong>
                  </Label>
                  <Button color="primary" size="sm mb-1" onClick={toggleLogs}>
                    {isLogsOpen ? "Hide Logs" : "Show Logs"}
                  </Button>
                </div>
                <Collapse isOpen={isLogsOpen}>
                  <ul
                    style={{
                      listStylePosition: "inside",
                      paddingLeft: "1.2rem",
                    }}
                  >
                    {logs
                      .slice()
                      .reverse()
                      .map((log, index) => (
                        <li key={index}>
                          <span>{log}</span>{" "}
                        </li>
                      ))}
                  </ul>
                </Collapse>
              </FormGroup>
            </Col>
          )}
        </>
      </Row>
    </RefundRequestSlideModal>
  );
};
export default AddEditModal;
