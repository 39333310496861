// !Added by Ashwin

import React, { useState } from "react";
import { Edit, FileText } from "react-feather";
import { Badge, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";
import AddBlog from "../../../../../../views/Blogs/Blog/AddBlog";

const BlogsBlogTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleDelete,
  handleCreateUpdateBlogs,
}) => {
  return (
    <>
      {tableRowData.map(({ blogTitle, publishDate, status, id }) => (
        <tr key={id}>
          <td style={{ width: "10px" }}>
            <Button
              className="rounded-circle btn-icon"
              color="none"
              onClick={(e) => {
                e.preventDefault();
                openModal();
                handleEdit(id);
              }}
            >
              <Edit
                color={reactFeatherIcon.iconColor}
                size={reactFeatherIcon.iconSize}
              />
            </Button>
          </td>
          <td style={{ width: "10px" }}>
            <Button
              className="rounded-circle btn-icon"
              color="none"
              onClick={(e) => {
                e.preventDefault();
                handleCreateUpdateBlogs(id);
                // console.log("clicked");
              }}
            >
              <FileText
                color={reactFeatherIcon.iconColor}
                size={reactFeatherIcon.iconSize}
              />
            </Button>
          </td>
          <td>
            <span className="align-middle font-weight-bold">{blogTitle}</span>
          </td>
          <td>
            <span className="align-middle font-weight-bold">
              {dateFormatHandler(publishDate)}
            </span>
          </td>
          <td>
            <Badge
              pill
              color={status ? "light-success" : "light-danger"}
              className="mr-1"
            >
              {status ? "Active" : "Inactive"}
            </Badge>
          </td>
        </tr>
      ))}
    </>
  );
};

export default BlogsBlogTableBody;
