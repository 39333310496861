import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";


export const UPDATE_CO_LIVING_ROOM_RATES = gql`
    mutation updateColivingHostel($updateArrayParams:[ColivingInputType]!){
        updateColivingHostel(updateArrayParams:$updateArrayParams){
             count
             success
             error
        }
    }
`



export const useGetUpdateColivingRoomRates = () => {
    const [updateColiving, { loading, data, error, refetch }] = useMutation(UPDATE_CO_LIVING_ROOM_RATES)

    useLoadingHandler(loading)

    const updateColivingHandler = (updateArrayParams) => {

        updateColiving({
            variables: {
                updateArrayParams
            }

        })
    }

    return [updateColivingHandler, { data, error, refetch }]
}