import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import FeaturedImage from "./FeaturedImage";
import BlogCategory from "./BlogCategory";
import SimilarBlogs from "./SimilarBlogs";
import BlogContent from "./BlogContent";
import Overview from "./Overview";
import WhatsNext from "./WhatsNext";
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useGetBlogById } from "../../../api/dataSource";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import Preview from "./Preview";

const tabOptions = [
  {
    id: 0,
    title: "Overview",
    formComponent: <Overview />,
  },
  {
    id: 1,
    title: "Featured Image",
    formComponent: <FeaturedImage />,
  },
  {
    id: 2,
    title: "Blog Content",
    formComponent: <BlogContent />,
  },
  {
    id: 3,
    title: "Category",
    formComponent: <BlogCategory />,
  },
  {
    id: 4,
    title: "Similar Blogs",
    formComponent: <SimilarBlogs />,
  },

  {
    id: 5,
    title: "What's Next",
    formComponent: <WhatsNext />,
  },
  {
    id: 6,
    title: "Preview",
    formComponent: <Preview />,
  },
];

const AddBlog = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const { data } = useGetBlogById(id);

  // console.log(data?.getBlogById, "data?.getBlogById");

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getBlogById) {
      dispatch(tableDataHandler(data?.getBlogById));
    }
  }, [data, dispatch]);

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  {active !== 0 && "Blog - "}
                  {tabOptions.find((item) => item.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddBlog;
