//Added By Gaurav
import { useMutation, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";
import gql from "graphql-tag";

export const GET_HOSTEL_ROOMS_BY_ID = gql`
  query getHostelRoomsByHostelId($hostelId: ID) {
    getHostelRoomsByHostelId(hostelId: $hostelId) {
      hostelId
      roomName
      roomNo
      smartLink
      roomOtp
      masterOtp
      _id
      status
      smartLockId
      notWorkingCount
    notWorkingLogs
    }
  }
`;

export const useHostelRoomsByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_ROOMS_BY_ID, {
    variables: {
      hostelId: hostelId,
    },
    skip: !hostelId,
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const CREATE_HOSTEL_ROOMS = gql`
  mutation createHostelRooms($hostelRooms: [HostelRoomsInput]) {
    createHostelRooms(hostelRooms: $hostelRooms) {
      data
    }
  }
`;

export const useCreateHostelRooms = () => {
  const [createHostelRooms, { loading, error, data, refetch }] =
    useMutation(CREATE_HOSTEL_ROOMS);

  const createHostelRoomsHandler = ({ hostelRooms }) => {
    createHostelRooms({
      variables: { hostelRooms },
    });
  };

  return [createHostelRoomsHandler, { loading, error, data, refetch }];
};

export const SYNC_HOSTEL_ROOMS = gql`
  mutation syncHostelRooms($hostelId: ID) {
    syncHostelRooms(hostelId: $hostelId) {
      status
      message
    }
  }
`;

export const useSyncHostelRooms = () => {
  const [syncHostelRooms, { loading, error, data, refetch }] =
    useMutation(SYNC_HOSTEL_ROOMS);

  const syncHostelRoomsHandler = (hostelId) => {
    syncHostelRooms({
      variables: { hostelId },
    });
  };

  return [syncHostelRoomsHandler, { loading, error, data, refetch }];
};


export const GET_SMARTLOCK_OTP = gql`
mutation getSmartLockID ($hostelId: ID, $roomNo: String){
  getSmartLockOtp(hostelId: $hostelId, roomNo: $roomNo)
  
} 
`

export const useGetSmartLockOtp = () => {
  const [getSmartLockOtp, { loading, error, data, refetch }] = useMutation(GET_SMARTLOCK_OTP);

  const getsmartLockOtpHandler = (hostelId, roomNo) => {
    getSmartLockOtp({
      variables: { hostelId, roomNo },
    });
  };
  return [getsmartLockOtpHandler, { loading, error, data, refetch }];
};
