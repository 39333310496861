import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import PhotosForm from "./PhotosForm";
import DetailsForm from "./DetailsForm";
import OverviewForm from "./OverviewForm";
import ReviewsForm from "./ReviewsForm";
import Policy from "./Policy";
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useGetHostelById } from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import HowToReach from "./HowToReach";
import FaqForm from "./FaqForm";
import HostelPolicy from "./HostelPolicy";
// ! Coded by Ashwin
import NewDetails from "./NewDetails";
import TimingInformation from "./TimingInformation";
import ReviewsCategory from "./ReviewsCategory";
import SEOForm from "./SEOForm";
import SimilarHostels from "./SimilarHostels";
import Blog from "./Blog";
import GSTForm from "./GSTForm";
import Automation from "./Automation";
import Glu from "./Glu";
import PotraitImageSection from "./PotraitImageSection";
import LogsSection from "./LogsSection";

const tabOptions = [
  {
    id: 0,
    title: "Hostel Details",
    formComponent: <DetailsForm />,
  },
  {
    id: 14,
    title: "Important Details",
    formComponent: <NewDetails />,
  },
  {
    id: 1,
    title: "Overview",
    formComponent: <OverviewForm />,
  },
  {
    id: 2,
    title: "Photos",
    // formComponent: null,
    formComponent: <PhotosForm />,
  },
  // {
  //   id: 3,
  //   title: "Things To Know",
  //   formComponent: <ThingsToknow />,
  // },
  {
    id: 4,
    title: "How to Reach",
    formComponent: <HowToReach />,
  },
  // {
  //   id: 5,
  //   title: "Location",
  //   formComponent: <LocationForm />,
  // },
  {
    id: 7,
    title: "Reviews",
    formComponent: <ReviewsForm />,
  },
  {
    id: 16,
    title: "Reviews Category",
    formComponent: <ReviewsCategory />,
  },
  {
    id: 9,
    title: "FAQ",
    formComponent: <FaqForm />,
  },
  // {
  //   id: 10,
  //   title: "Create Card",
  //   formComponent: <CardForm />,
  // },
  // {
  //   id: 11,
  //   title: "Recommendations",
  //   formComponent: <Recommendations />,
  // },
  {
    id: 12,
    title: "Landing Policy",
    formComponent: <HostelPolicy />,
  },
  {
    id: 13,
    title: "Guest Info Policy",
    formComponent: <Policy />,
  },
  // ! Coded by Ashwin

  {
    id: 15,
    title: "Timing Information",
    formComponent: <TimingInformation />,
  },

  {
    id: 17,
    title: "SEO",
    formComponent: <SEOForm />,
  },
  {
    id: 18,
    title: "Similar Hostels",
    formComponent: <SimilarHostels />,
  },
  {
    id: 19,
    title: "Blogs",
    formComponent: <Blog />,
  },
  {
    id: 20,
    title: "GST",
    formComponent: <GSTForm />,
  },
  {
    id: 21,
    title: "Automation",
    formComponent: <Automation />,
  },
  {
    id: 22,
    title: "Glu",
    formComponent: <Glu />,
  },
  {
    id: 23,
    title: "Portrait Image Section",
    formComponent: <PotraitImageSection />,
  },
  {
    id: 24,
    title: "Logs",
    formComponent: <LogsSection />,
  },
];

const AddHostel = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const { data } = useGetHostelById(id);
  console.log(data);
  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getHostelById) {
      dispatch(tableDataHandler(data?.getHostelById));
    }
  }, [data, dispatch]);

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  {active !== 0 && "Hostel Details - "}
                  {tabOptions.find((item) => item?.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item?.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddHostel;

// <StatisticsCard />;
