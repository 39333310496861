import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Operations")
  ? [
      {
        id: "operations",
        title: "Operations",
        icon: <Grid size={20} />,
        badge: "light-warning",
        children: [
          // localStorage.getItem("modules")?.includes("Day Book") && {
          {
            id: "finance",
            title: "Day Book",
            icon: <Circle size={12} />,
            navLink: "/operations/finance",
          },
          // localStorage.getItem("modules")?.includes("Day Book") && {
          {
            id: "closingBalanceReport",
            title: "Live cash balance",
            icon: <Circle size={12} />,
            navLink: "/operations/closingBalanceReport",
          },

          // localStorage.getItem("role") === "Super Admin" ||
          localStorage.getItem("modules")?.includes("Employee Profile") && {
            id: "employeeProfile",
            title: "Employee Profile",
            icon: <Circle size={12} />,
            navLink: "/hr/employeeProfile",
          },

          // localStorage.getItem("role") === "Super Admin" ||
          localStorage.getItem("modules")?.includes("Employee Profile") && {
            id: "employeeLeaveBalance",
            title: "Employee Leave Balance",
            icon: <Circle size={12} />,
            navLink: "/hr/employeeLeaveBalance",
          },
          // localStorage.getItem("role") === "Super Admin" ||
          localStorage.getItem("modules")?.includes("Employee Attendance") && {
            id: "employeeAttendance",
            title: "Employee Attendance",
            icon: <Circle size={12} />,
            navLink: "/hr/employeeAttendance",
          },
          localStorage.getItem("modules")?.includes("Employee Attendance") && {
            id: "employeeAttendance",
            title: "Hostelwise Food charges",
            icon: <Circle size={12} />,
            navLink: "/hr/hostelWiseFoodCharges",
          },
          localStorage.getItem("modules")?.includes("Purchase Request") && {
            id: "vendor",
            title: "Vendor Details",
            icon: <Circle size={12} />,
            navLink: "/operations/vendor",
          },
          localStorage.getItem("modules")?.includes("Payment Request") && {
            id: "GO Entry",
            title: "OPEX Request",
            icon: <Circle size={12} />,
            navLink: "/operations/goentry",
          },
          localStorage.getItem("modules")?.includes("Purchase Request") && {
            id: "GO Purchase",
            title: "CAPEX Request",
            icon: <Circle size={12} />,
            navLink: "/operations/goPurchase",
          },
          localStorage.getItem("modules")?.includes("Glu Reports") && {
            id: "BlacklistedGuests",
            title: "Blacklisted Guests CMS",
            icon: <Circle size={12} />,
            navLink: "/webApp/blacklistedGuests",
          },
          // {
          //   id: "employeeRoster",
          //   title: "Employee Roster",
          //   icon: <Circle size={12} />,
          //   navLink: "/hr/employeeRoster",
          // },
        ],
      },
    ]
  : [];
