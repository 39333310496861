import gql from "graphql-tag";

export const START_CONVERSATION = gql`
mutation createPost($conversationInput: conversationInput){
    createPost(conversationInput:$conversationInput){
        title
        department
    }
}
`;

export const ADD_COMMENT_ON_POST = gql `
mutation addCommentToPost($conversationReplyInput:conversationReplyInput){
    addCommentToPost( conversationReplyInput:$conversationReplyInput){
        comment
        addedBy
    }
}
`

export const ADD_REPLY_ON_COMMENT = gql `
mutation addReplyToComment($id: ID, $replyToCommentInput:replyToCommentInput){
    addReplyToComment(id:$id,replyToCommentInput:$replyToCommentInput){
        comment
    }
}
`

export const DELETE_CONNECT_POST = gql`
mutation deleteConnectPost($userId:ID, $postId:ID){
    deleteConnectPost(userId:$userId, postId:$postId){
        message
    }
}
`

export const DELETE_CONNECT_COMMENT = gql`
mutation deleteConnect($userId:ID, $commentId: ID){
    deleteConnectComment(userId: $userId, commentId:$commentId){
        message
    }
}
`

export const DELETE_CONNECT_REPLY = gql`
mutation deleteConnectReply($userId:ID, $replyId: ID){
    deleteConnectReply(userId: $userId, replyId:$replyId){
        message
    }
}
`