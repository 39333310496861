// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import Flatpickr from "react-flatpickr";
import { customList } from "country-codes-list";
// import { useHostelList } from "../../api/dataSource";
import { useGetHostelsNameandId } from "../../api/dataSource";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import {
  useCreateWebCheckin,
  useUpdateWebCheckin,
  useUploadImage,
} from "../../api/dataSource";
import { Eye, EyeOff } from "react-feather";
import { useUpdateUnmaskNumber } from "../../api/WebCheckin/Mutation";

const animatedComponents = makeAnimated();

const AddEditModall = ({ open, handleToggle, refetch, editData }) => {
  const [reservationID, setReservationID] = useState("");
  const [hostelList, setHostelList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [hostelId, setHostelId] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [age, setAge] = useState(null);
  const [timeOfArrival, setTimeOfArrival] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [howDidYouBookTheHosteller, setHowDidYouBookTheHosteller] =
    useState("");
  const [groupSize, setGroupSize] = useState("");
  const [residingCountry, setResidingCountry] = useState("");
  const [residingState, setResidingState] = useState("");
  const [residingCity, setResidingCity] = useState("");
  const [comingFromCity, setComingFromCity] = useState("");
  const [goingToCity, setGoingToCity] = useState("");
  const [modeOfTransport, setModeOfTransport] = useState("");
  const [govtIdProof, setGovtIdProof] = useState("");
  const [govtIdNumber, setGovtIdNumber] = useState("");
  const [cFormAppNo, setCFormAppNo] = useState("");
  const [unmaskNumber, setUnmaskNumber] = useState(false);
  const [primaryPurpose, setPrimaryPurpose] = useState("");
  const [stayedWithUsIn12Months, setStayedWithUsIn12Months] = useState("");

  const [
    haveYouStayedWithTheHostellerBefore,
    setHaveYouStayedWithTheHostellerBefore,
  ] = useState("");
  const [whatWouldBestDescribeYou, setWhatWouldBestDescribeYou] = useState("");

  const [governmentIdPreview, setGovernmentIdPreview] = useState(null);
  const [aadhaarCardFrontImagePreview, setAadhaarCardFrontImagePreview] =
    useState(null);
  const [aadhaarCardBackImagePreview, setAadhaarCardBackImagePreview] =
    useState(null);
  const [visaPreview, setVisaPreview] = useState(null);
  const [passportBackImagePreview, setPassportBackImagePreview] =
    useState(null);

  const [govIdBackImagePreview, setGovIdBackImagePreview] = useState(null);

  const dateFormat = "d/m/Y";
  const options = {
    dateFormat: dateFormat,
  };

  const myCountryCodesObject = customList("countryCode", "{countryNameEn}");

  const [createWebCheckinHandler, { data }] = useCreateWebCheckin();
  const [updateWebCheckinHandler, { data: updatedWebCheckinData }] =
    useUpdateWebCheckin();

  const [updateUnmaskNumberHandler, { data: outputObj }] =
    useUpdateUnmaskNumber();

  useEffect(() => {
    if (updatedWebCheckinData) {
      refetch();
      handleToggle();
    }
  }, [updatedWebCheckinData]);

  useEffect(() => {
    setUnmaskNumber(outputObj?.unmaskNumberAndUpdateLogs?.message);
    refetch();
  }, [outputObj?.unmaskNumberAndUpdateLogs?.message, refetch]);
  const [
    uploadGovernmentIdHandler,
    {
      data: governmentIdData,
      reset: governmentIdReset,
      loading: governmentIdLoad,
    },
  ] = useUploadImage();

  const [
    uploadAadhaarCardFrontImageHandler,
    {
      data: aadhaarCardFrontImageData,
      reset: aadhaarCardFrontImageReset,
      loading: aadhaarCardFrontImageLoad,
    },
  ] = useUploadImage();

  const [
    uploadAadhaarCardBackImageHandler,
    {
      data: aadhaarCardBackImageData,
      reset: aadhaarCardBackImageReset,
      loading: aadhaarCardBackImageLoad,
    },
  ] = useUploadImage();
  const [
    uploadVisaHandler,
    { data: visaData, reset: visaReset, loading: visaLoad },
  ] = useUploadImage();

  const [
    uploadPassportBackImageHandler,
    {
      data: passportBackImageData,
      reset: passportBackImageReset,
      loading: passportBackImageLoad,
    },
  ] = useUploadImage();

  const [
    uploadGovIdBackImageHandler,
    { data: govIdBackImageData, loading: govIdBackImageLoad },
  ] = useUploadImage();

  // const { data: hostelData } = useHostelList();
  const { data: hostelData } = useGetHostelsNameandId();

  const GenderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const HowDidYouBookTheHostellerList = [
    { label: "Booking.com", value: "Booking.com" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "Hostelworld", value: "Hostelworld" },
    { label: "Website", value: "Website" },
    { label: "Direct call/mail", value: "Direct call/mail" },
    { label: "Walk-in", value: "Walk-in" },
    { label: "Google", value: "Google" },
    { label: "TH Staff Complimentary", value: "TH Staff Complimentary" },
    { label: "Other", value: "Other" },
  ];

  const GroupSizeList = [
    { label: "Solo", value: "Solo" },
    { label: "2", value: "2" },
    { label: "3-4", value: "3-4" },
    { label: "5-6", value: "5-6" },
    { label: "6+", value: "6+" },
  ];

  const ResidingCountryList = Object.values(myCountryCodesObject).map(
    (item) => {
      return { label: item, value: item };
    }
  );
  const ModeOfTransportList = [
    { label: "Public Transport", value: "Public Transport" },
    { label: "Private Transport", value: "Private Transport" },
    { label: "Rental Transport", value: "Rental Transport" },
  ];

  const GovtIdProofList = [
    { label: "Aadhaar Card", value: "Aadhaar Card" },
    { label: "Passport", value: "Passport" },
    { label: "Driving Licence", value: "Driving Licence" },
    { label: "Voter ID", value: "Voter ID" },
  ];

  const HaveYouStayedWithTheHostellerBeforeList = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const WhatWouldBestDescribeYouList = [
    { label: "Student", value: "Student" },
    { label: "Business Professional", value: "Business Professional" },
    { label: "Working Professional", value: "Working Professional" },
    { label: "Freelancer", value: "Freelancer" },
    { label: "Other", value: "Other" },
  ];

  // useEffect(() => {
  //   let hostelList = [];
  //   if (hostelData?.getHostelList?.length) {
  //     hostelData?.getHostelList?.map((item) =>
  //       hostelList.push({ value: item?._id, label: item?.name })
  //     );
  //     setHostelList(hostelList);
  //   }
  // }, [hostelData?.getHostelList]);

  useEffect(() => {
    let hostelList = [];
    if (hostelData?.getHostelsNameandId?.length) {
      hostelData?.getHostelsNameandId?.map((item) =>
        hostelList.push({ value: item?._id, label: item?.name })
      );
      setHostelList(hostelList);
    }
  }, [hostelData?.getHostelsNameandId]);

  const uppyGovernmentId = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  const uppyGovernmentIdBack = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  const uppyAadhaarCardFrontImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  const uppyAadhaarCardBackImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  const uppyVisa = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  const uppyPassportBackImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  uppyGovernmentId.use(thumbnailGenerator);
  uppyAadhaarCardFrontImage.use(thumbnailGenerator);
  uppyAadhaarCardBackImage.use(thumbnailGenerator);
  uppyVisa.use(thumbnailGenerator);
  uppyPassportBackImage.use(thumbnailGenerator);
  uppyGovernmentIdBack.use(thumbnailGenerator);

  uppyGovernmentId.on("thumbnail:generated", (file, preview) => {
    setGovernmentIdPreview([preview]);
    uploadGovernmentIdHandler(file.data, "image/WebCheckin");
  });

  uppyGovernmentIdBack.on("thumbnail:generated", (file, preview) => {
    setGovIdBackImagePreview([preview]);
    uploadGovIdBackImageHandler(file.data, "image/WebCheckin");
  });

  uppyAadhaarCardFrontImage.on("thumbnail:generated", (file, preview) => {
    setAadhaarCardFrontImagePreview([preview]);
    uploadAadhaarCardFrontImageHandler(file.data, "image/WebCheckin");
  });

  uppyAadhaarCardBackImage.on("thumbnail:generated", (file, preview) => {
    setAadhaarCardBackImagePreview([preview]);
    uploadAadhaarCardBackImageHandler(file.data, "image/WebCheckin");
  });

  uppyVisa.on("thumbnail:generated", (file, preview) => {
    setVisaPreview([preview]);
    uploadVisaHandler(file.data, "image/WebCheckin");
  });

  uppyPassportBackImage.on("thumbnail:generated", (file, preview) => {
    setPassportBackImagePreview([preview]);
    uploadPassportBackImageHandler(file.data, "image/WebCheckin");
  });

  const renderGovernmentIdPreview = () =>
    governmentIdPreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={governmentIdPreview}
        alt="avatar"
        onClick={() => {
          window.open(governmentIdPreview, "_blank");
        }}
        height={80}
      />
    ) : null;

  const renderAadhaarCardFrontImagePreview = () =>
    aadhaarCardFrontImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={aadhaarCardFrontImagePreview}
        alt="avatar"
        onClick={() => {
          window.open(aadhaarCardFrontImagePreview, "_blank");
        }}
        height={80}
      />
    ) : null;

  const renderAadhaarCardBackImagePreview = () =>
    aadhaarCardBackImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={aadhaarCardBackImagePreview}
        alt="avatar"
        onClick={() => {
          window.open(aadhaarCardBackImagePreview, "_blank");
        }}
        height={80}
      />
    ) : null;

  const renderVisaPreview = () =>
    visaPreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={visaPreview}
        alt="avatar"
        onClick={() => {
          window.open(visaPreview, "_blank");
        }}
        height={80}
      />
    ) : null;

  const renderPassportBackImagePreview = () =>
    passportBackImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={passportBackImagePreview}
        alt="avatar"
        onClick={() => {
          window.open(passportBackImagePreview, "_blank");
        }}
        height={80}
      />
    ) : null;

  const renderGovIdBackImagePreview = () =>
    govIdBackImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={govIdBackImagePreview}
        alt="avatar"
        onClick={() => {
          window.open(govIdBackImagePreview, "_blank");
        }}
        height={80}
      />
    ) : null;

  useEffect(() => {
    setGovernmentIdPreview(governmentIdData?.uploadImage?.url);
  }, [governmentIdData]);

  useEffect(() => {
    setAadhaarCardFrontImagePreview(
      aadhaarCardFrontImageData?.uploadImage?.url
    );
  }, [aadhaarCardFrontImageData]);

  useEffect(() => {
    setAadhaarCardBackImagePreview(aadhaarCardBackImageData?.uploadImage?.url);
  }, [aadhaarCardBackImageData]);

  useEffect(() => {
    setVisaPreview(visaData?.uploadImage?.url);
  }, [visaData]);

  useEffect(() => {
    setPassportBackImagePreview(passportBackImageData?.uploadImage?.url);
  }, [passportBackImageData]);

  useEffect(() => {
    setGovIdBackImagePreview(govIdBackImageData?.uploadImage?.url);
  }, [govIdBackImageData]);

  useEffect(() => {
    if (editData?._id) {
      setReservationID(editData?.reservationID);
      setHostel(
        editData?.hostelName
          ? { label: editData?.hostelName, value: editData?.hostelName }
          : ""
      );
      setFirstName(editData?.firstName);
      setLastName(editData?.lastName);
      setGender(
        editData?.gender
          ? { label: editData?.gender, value: editData?.gender }
          : ""
      );
      setTimeOfArrival(editData?.timeOfArrival);
      setDateOfBirth(editData?.dateOfBirth);
      setAge(editData?.age);
      setCountryCode(editData?.countryCode);
      setPhoneNumber(editData?.phoneNumber);
      setEmail(editData?.email);
      setHowDidYouBookTheHosteller(
        editData?.howDidYouBookTheHosteller
          ? {
              label: editData?.howDidYouBookTheHosteller,
              value: editData?.howDidYouBookTheHosteller,
            }
          : ""
      );
      setGroupSize(
        editData?.groupSize
          ? { label: editData?.groupSize, value: editData?.groupSize }
          : ""
      );
      setResidingCountry(
        editData?.residingCountry
          ? {
              label: editData?.residingCountry,
              value: editData?.residingCountry,
            }
          : ""
      );
      setResidingState(editData?.residingState);
      setResidingCity(editData?.residingCity);
      setComingFromCity(editData?.comingFromCity);
      setGoingToCity(editData?.goingToCity);
      setModeOfTransport(
        editData?.modeOfTransport
          ? {
              label: editData?.modeOfTransport,
              value: editData?.modeOfTransport,
            }
          : ""
      );
      setGovtIdProof(
        editData?.govtIdProof
          ? {
              label: editData?.govtIdProof,
              value: editData?.govtIdProof,
            }
          : ""
      );
      setGovtIdNumber(editData?.govtIdNumber || "");
      setCFormAppNo(editData?.cFormAppNo || "");
      setHaveYouStayedWithTheHostellerBefore(
        editData?.haveYouStayedWithTheHostellerBefore
          ? {
              label: editData?.haveYouStayedWithTheHostellerBefore,
              value: editData?.haveYouStayedWithTheHostellerBefore,
            }
          : ""
      );
      setWhatWouldBestDescribeYou(
        editData?.whatWouldBestDescribeYou
          ? {
              label: editData?.whatWouldBestDescribeYou,
              value: editData?.whatWouldBestDescribeYou,
            }
          : ""
      );
      setGovernmentIdPreview(editData?.uploadGovId);
      setGovIdBackImagePreview(editData?.uploadGovIdBackImage);
      setAadhaarCardFrontImagePreview(editData?.uploadAadharCardFrontImage);
      setAadhaarCardBackImagePreview(editData?.uploadAadhaarCardBackImage);
      setVisaPreview(editData?.uploadVisa);
      setPassportBackImagePreview(editData?.uploadPassportBackImage);
      setUnmaskNumber(editData?.unmaskNumber || false);
      setPrimaryPurpose(editData?.primaryPurpose || "");
      setStayedWithUsIn12Months(
        editData?.stayedWithUsIn12Months !== null
          ? { label: editData?.stayedWithUsIn12Months, value: editData }
          : ""
      );
    } else {
      setReservationID("");
      setHostel(null);
      setFirstName("");
      setLastName("");
      setGender("");
      setDateOfBirth("");
      setAge(null);
      setTimeOfArrival("");
      setComingFromCity("");
      setCountryCode("");
      setPhoneNumber("");
      setEmail("");
      setHowDidYouBookTheHosteller("");
      setGroupSize("");
      setResidingCountry("");
      setResidingState("");
      setResidingCity("");
      setComingFromCity("");
      setGoingToCity("");
      setModeOfTransport("");
      setGovtIdProof("");
      setGovtIdNumber("");
      setCFormAppNo("");
      setHaveYouStayedWithTheHostellerBefore("");
      setWhatWouldBestDescribeYou("");
      setGovernmentIdPreview("");
      setGovIdBackImagePreview("");
      setAadhaarCardFrontImagePreview("");
      setAadhaarCardBackImagePreview("");
      setVisaPreview("");
      setPassportBackImagePreview("");
      setUnmaskNumber(false);
      setPrimaryPurpose("");
      setStayedWithUsIn12Months("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      govtIdNumber: govtIdNumber,
      cFormAppNo: cFormAppNo,
      uploadGovId: governmentIdPreview,
      uploadGovIdBackImage: govIdBackImagePreview,
      uploadPassportBackImage: passportBackImagePreview,
      uploadVisa: visaPreview,
      uploadAadhaarCardBackImage: aadhaarCardBackImagePreview,
      uploadAadharCardFrontImage: aadhaarCardFrontImagePreview,
    };

    if (data?.govtIdNumber) {
      if (editData?._id) {
        const _id = editData?._id;
        updateWebCheckinHandler(_id, data);
      } else {
        createWebCheckinHandler(data);
      }
      toaster("success", "Web Checkin added successfully");
      refetch();
    } else {
      toaster("error", "Please fill the required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/* // ! Reservation ID*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationID">
                Reservation ID
              </Label>
              <Input
                type="text"
                value={reservationID}
                id="reservationID"
                name="reservationID"
                placeholder="Reservation ID"
                required
                disabled
                onChange={(e) => setReservationID(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="hostel">Hostel name</Label>
              <Select
                isClearable={false}
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelList}
                isDisabled={true}
                isOptionDisabled={editData?.hostelName}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* // ! First Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="firstName">
                First name
              </Label>
              <Input
                type="text"
                value={firstName}
                id="firstName"
                name="firstName"
                placeholder="First Name"
                required
                disabled
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Last Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="lastName">
                Last name
              </Label>
              <Input
                type="text"
                value={lastName}
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                required
                disabled
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Gender Type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="gender">
                Gender
              </Label>
              <Select
                isClearable={false}
                id="gender"
                name="gender"
                value={gender}
                options={GenderList}
                isDisabled={true}
                onChange={(value) => setGender(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Date of Birth */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="dateOfBirth">
                Date of birth
              </Label>
              <Flatpickr
                className="form-control border-dark text-dark"
                value={new Date(dateOfBirth).toLocaleDateString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })}
                onChange={(date) => setDateOfBirth(date)}
                id="dateOfBirth"
                placeholder="Add date of birth"
                options={options}
                disabled
                required
              />
            </FormGroup>
          </Col>

          {/* // ! Age */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="age">
                Age
              </Label>
              <Input
                type="text"
                value={age}
                id="age"
                name="age"
                placeholder="Age"
                required
                disabled
                onChange={(e) => setAge(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Time Of Arrival */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="timeOfArrival">
                Time of arrival
              </Label>
              <Input
                type="text"
                value={timeOfArrival}
                id="timeOfArrival"
                name="timeOfArrival"
                placeholder="Time of arrival"
                required
                disabled
                onChange={(e) => setTimeOfArrival(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Country Code */}
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="countryCode">
                Country code
              </Label>
              <Input
                type={"text"}
                value={countryCode?.toString()}
                id="countryCode"
                name="countryCode"
                placeholder="CC"
                required
                disabled
                onChange={(e) => {
                  setCountryCode(e.target.value).toString();
                }}
              />
            </FormGroup>
          </Col>

          {/* // ! Phone Number */}
          <Col sm="7" className="" style={{ position: "relative" }}>
            <FormGroup className="w-100">
              <Label className="form-label" for="phoneNumber">
                Phone number
              </Label>
              <Input
                type={unmaskNumber ? "text" : "password"}
                value={phoneNumber}
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                required
                disabled
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
            <div
              className="p-1"
              color="light"
              size="sm"
              onClick={() => {
                updateUnmaskNumberHandler(editData?._id, !unmaskNumber);
              }}
              style={{ position: "absolute", bottom: "6px", right: "10px" }}
            >
              {unmaskNumber ? <Eye /> : <EyeOff />}
            </div>
          </Col>
          <Col className="mt-1"></Col>

          {/* OTP verified */}
          {editData?.otpVerified && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="otpVerified">
                  OTP verified
                </Label>
                <Input
                  type="text"
                  id="otpVerified"
                  name="otpVerfied"
                  value={editData?.otpVerified ? "Yes" : "No"}
                  disabled
                />
              </FormGroup>
            </Col>
          )}

          {/* // ! Email */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                Email
              </Label>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                placeholder="Email"
                required
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! How did you book The Hosteller */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="howDidYouBookTheHosteller">
                How did you book The Hosteller?
              </Label>
              <Select
                isClearable={false}
                id="howDidYouBookTheHosteller"
                name="howDidYouBookTheHosteller"
                value={howDidYouBookTheHosteller}
                options={HowDidYouBookTheHostellerList}
                isDisabled={true}
                onChange={(value) => setHowDidYouBookTheHosteller(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Group Size */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="groupSize">
                Group size
              </Label>
              <Select
                isClearable={false}
                id="groupSize"
                name="groupSize"
                value={groupSize}
                options={GroupSizeList}
                isDisabled={true}
                onChange={(value) => setGroupSize(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Residing Country */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="residingCountry">
                Residing country
              </Label>
              <Select
                isClearable={false}
                id="residingCountry"
                name="residingCountry"
                value={residingCountry}
                options={ResidingCountryList}
                isDisabled={true}
                onChange={(value) => setResidingCountry(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! cFormAppNo*/}
          {editData?.residingCountry !== "India" && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="govtIdNumber">
                  C Form Application Number
                </Label>
                <Input
                  type="text"
                  value={cFormAppNo}
                  id="cFormAppNo"
                  name="cFormAppNo"
                  placeholder="C Form Application Number"
                  required
                  onChange={(e) => {
                    setCFormAppNo(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          )}

          {/* // ! Residing State */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="residingState">
                Residing state
              </Label>
              <Input
                type="text"
                value={residingState}
                id="residingState"
                name="residingState"
                placeholder="Residing State"
                required
                disabled
                onChange={(e) => setResidingState(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Residing City */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="residingCity">
                Residing city
              </Label>
              <Input
                type="text"
                value={residingCity}
                id="residingCity"
                name="residingCity"
                placeholder="Residing City"
                required
                disabled
                onChange={(e) => setResidingCity(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Coming from City */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="comingFromCity">
                Coming from city
              </Label>
              <Input
                type="text"
                value={comingFromCity}
                id="comingFromCity"
                name="comingFromCity"
                placeholder="Coming from City"
                required
                disabled
                onChange={(e) => setComingFromCity(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Going to City */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="goingToCity">
                Going to city
              </Label>
              <Input
                type="text"
                value={goingToCity}
                id="goingToCity"
                name="goingToCity"
                placeholder="Going to City"
                required
                disabled
                onChange={(e) => setGoingToCity(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Mode of Transport */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="modeOfTransport">
                Mode of transport
              </Label>
              <Select
                isClearable={false}
                id="modeOfTransport"
                name="modeOfTransport"
                value={modeOfTransport}
                options={ModeOfTransportList}
                isDisabled={true}
                onChange={(value) => setModeOfTransport(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Government ID Proof*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="govtIdProof">
                Government ID proof
              </Label>
              <Select
                isClearable={false}
                id="govtIdProof"
                name="govtIdProof"
                value={govtIdProof}
                options={GovtIdProofList}
                isDisabled={true}
                onChange={(value) => setGovtIdProof(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Government ID Number*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="govtIdNumber">
                Government ID number
              </Label>
              <Input
                type="text"
                value={govtIdNumber}
                id="govtIdNumber"
                name="govtIdNumber"
                placeholder="Government ID Number"
                required
                onChange={(e) => {
                  setGovtIdNumber(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          {/* // ! Have you Stayed with the Hosteller before?*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label
                className="form-label"
                for="haveYouStayedWithTheHostellerBefore"
              >
                Have you stayed with The Hosteller before?
              </Label>
              <Select
                isClearable={false}
                id="haveYouStayedWithTheHostellerBefore"
                name="haveYouStayedWithTheHostellerBefore"
                value={haveYouStayedWithTheHostellerBefore}
                options={HaveYouStayedWithTheHostellerBeforeList}
                isDisabled={true}
                onChange={(value) =>
                  setHaveYouStayedWithTheHostellerBefore(value)
                }
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! What would best describe you?*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="whatWouldBestDescribeYou">
                What would best describe you?
              </Label>
              <Select
                isClearable={false}
                id="whatWouldBestDescribeYou"
                name="whatWouldBestDescribeYou"
                value={whatWouldBestDescribeYou}
                options={WhatWouldBestDescribeYouList}
                isDisabled={true}
                onChange={(value) => setWhatWouldBestDescribeYou(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* added by ganesh for webcheckin changes */}

          {editData?.stayedWithUsIn12Months && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="stayedWithUsIn12Months">
                  Have you stayed with The Hosteller in the last 12 months?
                </Label>
                <Select
                  isClearable={false}
                  id="stayedWithUsIn12Months"
                  name="stayedWithUsIn12Months"
                  value={stayedWithUsIn12Months}
                  options={HaveYouStayedWithTheHostellerBeforeList}
                  isDisabled={true}
                  // onChange={(value) =>
                  //   setStayedWithUsIn12Months(value)
                  // }
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          )}

          {editData?.primaryPurpose && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="primaryPurpose">
                  Primary Purpose of Travel
                </Label>
                <Input
                  isClearable={false}
                  id="primaryPurpose"
                  name="primaryPurpose"
                  value={editData?.primaryPurpose}
                  disabled={true}
                  //onChange={(e) => primaryPurpose(e.target.value)}
                  //classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          )}

          <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            {/* // ! Upload Government ID */}
            {editData?.uploadGovId && (
              <Row>
                <Col sm="12" style={{ marginLeft: "20px" }}>
                  <p>Upload government ID front image</p>
                  <DragDrop
                    note="Image size should be less than 500KB"
                    width="100%"
                    uppy={uppyGovernmentId}
                    locale={{
                      strings: {
                        dropHereOr: "Drop image here or %{browse}",
                        browse: "browse",
                      },
                    }}
                  />
                  <Col sm="12">
                    <Label className="form-label w-100">
                      {/* Passport front image preview */}
                      Gov ID front image preview
                    </Label>
                    <Col sm="12">
                      {governmentIdLoad
                        ? "Uploading image..."
                        : renderGovernmentIdPreview()}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}

            {/* // ! Gov Id Back Image */}
            {editData?.uploadGovIdBackImage && (
              <Row>
                <Col sm="12" style={{ marginLeft: "20px" }}>
                  <p> Upload Gov ID back image</p>
                  <DragDrop
                    note="Image size should be less than 500KB"
                    width="100%"
                    uppy={uppyGovernmentIdBack}
                    locale={{
                      strings: {
                        dropHereOr: "Drop image here or %{browse}",
                        browse: "browse",
                      },
                    }}
                  />

                  <Col sm="12">
                    <Label> Gov ID back image preview</Label>
                    <Col sm="12">
                      {govIdBackImageLoad
                        ? "Uploading image..."
                        : renderGovIdBackImagePreview()}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}

            {/* // ! Aadhaar Card Front Image */}
            {editData?.uploadAadharCardFrontImage && (
              <Row>
                <Col sm="12" style={{ marginLeft: "20px" }}>
                  <p>Aadhaar card front image</p>
                  <DragDrop
                    note="Image size should be less than 500KB"
                    width="100%"
                    uppy={uppyAadhaarCardFrontImage}
                    locale={{
                      strings: {
                        dropHereOr: "Drop image here or %{browse}",
                        browse: "browse",
                      },
                    }}
                  />
                  <Col sm="12">
                    <Label className="form-label w-100">
                      Aadhaar card front image preview
                    </Label>
                    <Col sm="12">
                      {aadhaarCardFrontImageLoad
                        ? "Uploading image..."
                        : renderAadhaarCardFrontImagePreview()}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}

            {/* // ! Aadhaar Card Back Image */}
            {editData?.uploadAadhaarCardBackImage && (
              <Row>
                <Col sm="12" style={{ marginLeft: "20px" }}>
                  <p>Aadhaar card back image</p>
                  <DragDrop
                    note="Image size should be less than 500KB"
                    width="100%"
                    uppy={uppyAadhaarCardBackImage}
                    locale={{
                      strings: {
                        dropHereOr: "Drop image here or %{browse}",
                        browse: "browse",
                      },
                    }}
                  />
                  <Col sm="12">
                    <Label className="form-label w-100">
                      Aadhaar card back image preview
                    </Label>
                    <Col sm="12">
                      {aadhaarCardBackImageLoad
                        ? "Uploading image..."
                        : renderAadhaarCardBackImagePreview()}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}

            {/* // ! Visa */}
            {editData?.uploadVisa && (
              <Row>
                <Col sm="12" style={{ marginLeft: "20px" }}>
                  <p>Upload Visa image</p>
                  <DragDrop
                    note="Image size should be less than 500KB"
                    width="100%"
                    uppy={uppyVisa}
                    locale={{
                      strings: {
                        dropHereOr: "Drop image here or %{browse}",
                        browse: "browse",
                      },
                    }}
                  />
                  <Col sm="12">
                    <Label className="form-label w-100">
                      Visa image preview
                    </Label>
                    <Col sm="12">
                      {visaLoad ? "Uploading image..." : renderVisaPreview()}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}

            {/* // ! Passport Back Image */}
            {editData?.uploadPassportBackImage && (
              <Row>
                <Col sm="12" style={{ marginLeft: "20px" }}>
                  <p>Uplaod Passport back image</p>
                  <DragDrop
                    note="Image size should be less than 500KB"
                    width="100%"
                    uppy={uppyPassportBackImage}
                    locale={{
                      strings: {
                        dropHereOr: "Drop image here or %{browse}",
                        browse: "browse",
                      },
                    }}
                  />
                  <Col sm="12">
                    <Label className="form-label w-100">
                      Passport back image preview
                    </Label>
                    <Col sm="12">
                      {passportBackImageLoad
                        ? "Uploading image..."
                        : renderPassportBackImagePreview()}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}
          </div>
          {editData?.unmaskEditLogs?.length > 0 && (
            <Col sm="12">
              <div className="font-weight-bold text-left py-1">Logs:</div>
              {[...editData.unmaskEditLogs]?.reverse()?.map((el, i) => {
                return (
                  <Label key={i}>
                    <li>{el}</li>
                  </Label>
                );
              })}
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModall;
