import { DollarSign } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Post-stay Refunds")
  ? [
      {
        id: "refundRequestPostStay",
        title: "Post-Arrival Refunds",
        icon: <DollarSign />,
        navLink: "/refundRequestPostStay",
      },
    ]
  : [];
