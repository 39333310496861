import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";


export const GET_HOSTEL_LIST_CO_LIVING = gql`
    query getHostelListColiving {
        getHostelListColiving {
            _id
            name
        }
    }
`


export const useGetAllHostelListColiving = () => {
    const { loading, data, error, refetch } = useQuery(GET_HOSTEL_LIST_CO_LIVING)

    useLoadingHandler(loading)

    return { data, error, refetch }
}

export const GET_COLIVING_LIST = gql`
    query getColivingDetails($hostelId:ID!){
        getColivingDetails(hostelId:$hostelId){
             _id
            hostelId
            roomType
            RatePlanID
            RoomTypeID
            RateTypeID
            prices{
             amount
             tax
             period
        }
        }
        }
`

export const useGetAllColivingList = () => {
    const [fetchColiving, { loading, data, error, refetch }] = useLazyQuery(GET_COLIVING_LIST)

    useLoadingHandler(loading)

    const fetchColivingHandler = (hostelId) => {
        fetchColiving({
            variables: {
                hostelId
            },
            fetchPolicy: "network-only"
        })
    }

    return [fetchColivingHandler, { data, error, refetch }]
}