import React, { useState } from 'react'
import { Button, Card, CardHeader, CardTitle, Col, Row, Table } from 'reactstrap';
import connecttopBackground from "../../assets/images/connect/connecttopBackground.png"
import AddEditConnectModelnew from './AddEditConnectModelnew';
const ConnectTop = ({ openModal, refetch }) => {
  const [openModalNew, setOpenModalNew] = useState(false);
  return (
    <div>
      <Row>
        <Col>
          <Card style={{ backgroundImage: `url(${connecttopBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <CardHeader>
              <CardTitle style={{ color: "black", fontSize: "30px", fontWeight: "600", marginLeft: "90px" }}>CONNECT</CardTitle>
              <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
              style={{fontWeight:"800", height:"45px", width:"200px",fontSize:"16px"}}
              >
              Add Post
            </Button.Ripple>

              {/* <Button.Ripple
                color="light"
                onClick={() => setOpenModalNew(!openModalNew)}
                style={{ fontWeight: "800", height: "45px", width: "200px", fontSize: "16px" }}
              >
                Add Conversation
              </Button.Ripple> */}
            </CardHeader>
          </Card>
        </Col>
      </Row>
      {/* <AddEditConnectModelnew openModal={openModalNew} setOpenModalNew={setOpenModalNew} refetch={refetch}/> */}

    </div>
  )
}

export default ConnectTop