import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_ALL_EVENT_CATEGORIES = gql`
query getAllEventCategories{
    getAllEventCategories{
        _id
        categoryIcon
        name
        displayOrder
        status
    }
}
`
export const GET_ALL_ACTIVE_EVENT_CATEGORIES = gql`
query getAllActiveEventCategories{
    getAllActiveEventCategories{
        _id
        name
    }
}
`

export const useGetAllEventCategories = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_EVENT_CATEGORIES);
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};

export const useGetAllActiveEventCategories = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_ACTIVE_EVENT_CATEGORIES);
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};