import React, { useEffect, useState } from "react";
import SlideModal from "./slide-modal";
import {
  useHostelList,
  useGetWebCheckinDataByReservationId,
} from "../../../api/dataSource";
import { useCreateBlacklistedGuests } from "../../../api/WebApp/BlacklistedGuests/Mutation";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import AddVideoUrlBtn from "./add-video-url-btn";
import { Trash } from "react-feather";
// import confirmDelete from "@components/common/confirmDelete";
import AttachmentComponent from "./AttachmentComponent";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [reservationID, setReservationID] = useState("".toString());

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [type, setType] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [documentID, setDocumentID] = useState(null);
  const [reason, setReason] = useState(null);
  const [status, setStatus] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [videoUrl, setVideoUrl] = useState([{ id: uuidv4(), url: "" }]);
  const [logs, setLogs] = useState(null);
  const [dummyState] = useState(null);

  const uploadImageType = [{ label: "mediaURL", value: "supportingEvidence" }];

  //for fetching hostel List
  const { data: allHostelsData } = useHostelList();

  const { data: webCheckinData, refetch: refetchWebCheckinData } =
    useGetWebCheckinDataByReservationId(reservationID);

  // console.log(reservationID, "reservationID");

  // console.log(webCheckinData, "webCheckinData");

  //create Handler
  const [
    createBlacklistedGuestsHandler,
    { data: createBlacklistedGuestsData },
  ] = useCreateBlacklistedGuests();

  const userName = localStorage.getItem("name");

  const userRole = localStorage.getItem("role");

  const userDepartment = localStorage.getItem("department");

  const userHostel = localStorage.getItem("hostels");

  const GS = userRole === "Ground Staff";

  const severityList = [
    { label: "Low", value: "Low" },
    // { label: "Moderate", value: "Moderate" },
    { label: "High", value: "High" },
  ];

  const satusList = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Reversed", value: "Reversed" },
  ];

  const typeList = [
    { label: "Property damage", value: "Property damage" },
    { label: "Misconduct", value: "Misconduct" },
    { label: "Illegal activities", value: "Illegal activities" },
    { label: "Non-payment", value: "Non-payment" },
    { label: "Fraudulent activity", value: "Fraudulent activity" },
    { label: "Policy violation", value: "Policy violation" },
    { label: "Abusive behavior", value: "Abusive behavior" },
    { label: "Disturbance", value: "Disturbance" },
    { label: "Safety", value: "Safety" },
    { label: "Repeated complaints", value: "Repeated complaints" },
    { label: "Defamation", value: "Defamation" },
  ];

  //refetching and toggle logic
  useEffect(() => {
    if (createBlacklistedGuestsData?.createBlacklistedGuests) {
      refetch();
      handleToggle();
    }
  }, [createBlacklistedGuestsData, refetch]);

  useEffect(() => {
    const allowedHostels = JSON.parse(userHostel);
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    let guestWebcheckinData = webCheckinData?.getWebcheckinDataByReservationId;

    // console.log(guestWebcheckinData, "guestWebcheckinData");

    if (
      guestWebcheckinData !== null &&
      guestWebcheckinData !== undefined &&
      !editData?._id
    ) {
      if (guestWebcheckinData?.reservationID) {
        setName(
          guestWebcheckinData?.firstName?.concat(
            " ",
            guestWebcheckinData?.lastName
          )
        );
        setEmail(guestWebcheckinData?.email);
        setMobile(guestWebcheckinData?.phoneNumber);
        setDocumentID(guestWebcheckinData?.govtIdNumber);
        // setDocumentID(
        //   guestWebcheckinData?.govtIdNumber?.length > 0
        //     ? guestWebcheckinData?.govtIdNumber
        //     : null
        // );
      }
    } else {
      setName("");
      setEmail("");
      setMobile("");
      setDocumentID("");
    }
  }, [webCheckinData]);

  const handleAddVideoUrls = () => {
    setVideoUrl([...videoUrl, { id: uuidv4(), url: "" }]);
  };

  const handleRemoveUrls = (id) => {
    // eslint-disable-next-line array-callback-return
    let newVideoUrlData = videoUrl.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    // confirmDelete(() => setVideoUrl(newVideoUrlData));
    setVideoUrl(newVideoUrlData);
  };

  const handleTextChangeUrl = (val, id, key) => {
    setVideoUrl(
      videoUrl.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );
  };

  //setting edit data value
  useEffect(() => {
    // let guestWebcheckinData = webCheckinData?.getWebcheckinDataByReservationId;
    if (editData?._id) {
      setHostel(
        editData?.hostel?.name
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );

      setReservationID(editData?.reservationID);
      setName(editData?.name);
      setEmail(editData?.email);
      setMobile(editData?.mobile);
      setType(
        editData?.type !== null
          ? { label: editData?.type, value: editData?.type }
          : null
      );
      setSeverity(
        editData?.severity !== null
          ? { label: editData?.severity, value: editData?.severity }
          : null
      );
      setDocumentID(editData?.documentID);
      setReason(editData?.reason);
      setStatus(
        editData?.status !== null
          ? { label: editData?.status, value: editData?.status }
          : null
      );
      setAttachment(
        editData?.supportingEvidence !== null
          ? editData?.supportingEvidence
          : []
      );
      // setVideoUrl(
      //   editData?.videoUrl?.length > 0 && editData.videoUrl.map((item) => item)
      // );

      setVideoUrl(
        editData?.videoUrl?.map((item) => {
          return {
            id: uuidv4(),
            url: item,
          };
        })
      );
    } else {
      // setHostelsList([]);
      // setHostel(null);
      setReservationID("".toString());
      // setReservationID(null);
      setName(null);
      setEmail(null);
      setMobile(null);
      setType(null);
      setSeverity(null);
      setDocumentID(null);
      setReason(null);
      setStatus(null);
      setAttachment([]);
      setVideoUrl([{ id: uuidv4(), url: "" }]);
      setLogs(null);
    }
  }, [editData]);

  //submit handler for form
  const onSubmitHandler = () => {
    let videoUrlClone = JSON.parse(JSON.stringify(videoUrl));
    videoUrlClone.forEach((item) => {
      delete item.id;
    });
    const videoUrlData = videoUrlClone?.map((item) => item?.url);
    //! below filter to remove "" from []
    // ?.filter((item) => item !== "");

    const data = {
      hostel: hostel?.value,
      reservationID,
      name,
      email,
      mobile,
      type: type?.value,
      severity: severity?.value,
      documentID,
      reason,
      supportingEvidence: attachment,
      videoUrl: videoUrlData,
      status: status?.value,
    };

    // console.log(data, "DATA");

    if (
      !data?.hostel ||
      !data?.reservationID ||
      !data?.name ||
      !data?.email ||
      !data?.mobile ||
      !data?.type ||
      !data?.severity ||
      !data?.reason ||
      // !data?.supportingEvidence ||
      !data?.videoUrl
      // || !data?.supportingEvidence?.length
      // || !data?.videoUrl?.length
      // || !data?.status
    ) {
      toaster("error", "Please fill all the required details marked with '*'");
      return;
    }

    createBlacklistedGuestsHandler({
      ...data,
    });
    toaster("success", "Blacklisted guest added successfully");

    setHostelsList([]);
    setHostel(null);
    setReservationID("".toString());
    // setReservationID(null);
    setName(null);
    setEmail(null);
    setMobile(null);
    setType(null);
    setSeverity(null);
    setDocumentID(null);
    setReason(null);
    setStatus(null);
    setAttachment([]);
    setVideoUrl([{ id: uuidv4(), url: "" }]);
    setLogs(null);
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
      userRole={userRole}
      userDepartment={userDepartment}
    >
      <form onSubmit={() => console.log()}>
        {
          /* userRole === "Area Manager" ||  */
          /* userRole === "Ground Staff" && */
          <>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Hostel*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="hostel"
                    name="hostel"
                    options={hostelsList}
                    value={hostel}
                    isDisabled={editData?._id}
                    onChange={(value) => setHostel(value)}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="reservationID">
                    Reservation ID*
                  </Label>

                  <Input
                    type="text"
                    value={reservationID}
                    id="reservationID"
                    name="reservationID"
                    placeholder="Reservation ID"
                    onChange={(e) => setReservationID(e.target.value)}
                    disabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="name">
                    Guest Name*
                  </Label>

                  <Input
                    type="text"
                    value={name}
                    id="name"
                    name="name"
                    placeholder="Guest Name"
                    onChange={(e) => setName(e.target.value)}
                    disabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="email">
                    Email ID*
                  </Label>

                  <Input
                    type="text"
                    value={email}
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="mobile">
                    Mobile number*
                  </Label>

                  <Input
                    type="text"
                    value={mobile}
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile"
                    onChange={(e) => setMobile(e.target.value)}
                    disabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="type">
                    Reason*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="type"
                    name="type"
                    options={typeList}
                    value={type}
                    onChange={(value) => setType(value)}
                    classNamePrefix="select"
                    isDisabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="severity">
                    Severity*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="severity"
                    name="severity"
                    options={severityList}
                    value={severity}
                    onChange={(value) => setSeverity(value)}
                    classNamePrefix="select"
                    isDisabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="documentID">
                    Document ID
                  </Label>

                  <Input
                    type="text"
                    value={documentID}
                    id="documentID"
                    name="documentID"
                    placeholder="Document ID"
                    onChange={(e) => setDocumentID(e.target.value)}
                    disabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="reason">
                    Comment*
                  </Label>

                  <Input
                    type="textarea"
                    value={reason}
                    id="reason"
                    name="reason"
                    placeholder="Comment"
                    rows="1"
                    onChange={(e) => setReason(e.target.value)}
                    disabled={editData?._id}
                  />
                </FormGroup>
              </Col>

              {/* <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="status">
                  Status*
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="status"
                  name="status"
                  options={satusList}
                  value={status}
                  onChange={(value) => setStatus(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col> */}

              <Col sm="12">
                <Label className="form-label" for="attachment">
                  Supporting evidence {/* <br /> */}
                  (Max file size: 10MB)
                </Label>
                <AttachmentComponent
                  imageList={attachment}
                  setImageList={setAttachment}
                  editData={dummyState}
                  uploadType={uploadImageType}
                  userDepartment={userDepartment}
                  user={GS}
                />
              </Col>

              {videoUrl.map(({ id, url }, index) => (
                <React.Fragment key={id}>
                  <Col sm="12">
                    <Row>
                      <Col sm="10">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="index">
                            Video URL {index + 1}
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col sm="2">
                        {videoUrl.length > 1 ? (
                          <>
                            <Button.Ripple
                              className="btn-icon rounded-circle mb-1 mr-1"
                              color="danger"
                              type="button"
                              size="sm"
                              onClick={() => handleRemoveUrls(id)}
                              disabled={editData?._id}
                            >
                              <Trash size={14} />
                            </Button.Ripple>
                          </>
                        ) : null}
                      </Col>

                      <Col sm="12">
                        <FormGroup className="w-100">
                          {/* <Label className="form-label" for="url">
                              Name*
                            </Label> */}

                          <Input
                            type="text"
                            value={url}
                            id="url"
                            name="url"
                            placeholder={`Video Url`}
                            onChange={(e) =>
                              handleTextChangeUrl(e.target.value, id, "url")
                            }
                            disabled={editData?._id}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            <AddVideoUrlBtn addAction={handleAddVideoUrls} user={GS} />
            <hr
              style={{
                borderTop: "2px solid #ffe700",
                width: "100%",
              }}
            />
          </>
        }
      </form>
    </SlideModal>
  );
};
export default AddEditModal;
