import gql from "graphql-tag";

export const GET_CATEGORY_BLOGS = gql`
  query getPaginatedBlogCategoryList($filter: filter) {
    getPaginatedBlogCategoryList(filter: $filter) {
      data {
        _id
        categoryName
        image
        url
        seo {
          metaTitle
          metaDesc
          keywords
        }
        status
        logs
      }
      count
      page
      limit
    }
  }
`;

export const GET_BLOGS = gql`
  query getPaginatedBlogList($filter: filter) {
    getPaginatedBlogList(filter: $filter) {
      data {
        _id
        blogTitle
        blogCategory {
          _id
          categoryName
        }
        featuredImage
        authorName
        shortDescription
        blogContent
        similarBlogs {
          _id
          blogTitle
        }
        url
        seo {
          metaTitle
          metaDesc
          keywords
        }
        tags
        status
        displayOrder
        publishDate
        timeToRead
        whatsNext {
          title
          urlText
          link
        }
        destinations {
          _id
          destination
        }
        logs
      }
      count
      page
      limit
    }
  }
`;

export const GET_BLOG_BY_ID = gql`
  query getPaginatedBlogList($blogId: ID) {
    getBlogById(blogId: $blogId) {
      _id
      blogTitle
      blogCategory {
        _id
        categoryName
      }
      featuredImage
      authorName
      shortDescription
      blogContent
      similarBlogs {
        _id
        blogTitle
      }
      url
      seo {
        metaTitle
        metaDesc
        keywords
      }
      tags
      status
      displayOrder
      publishDate
      timeToRead
      whatsNext {
        title
        urlText
        link
      }
      subHeading
      destinations {
        _id
        destination
      }
    }
  }
`;

export const GET_BLOG_CATEGORY_LIST = gql`
  query getBlogCategoryList {
    getBlogCategoryList {
      _id
      categoryName
    }
  }
`;

export const GET_SIMILAR_BLOGS_LIST = gql`
  query getBlogsList {
    getBlogsList {
      _id
      blogTitle
    }
  }
`;
