import React from 'react'
import { Col, Row, FormGroup, Label } from "reactstrap";
import { useSelector } from "react-redux";

const Logs = () => {
    const state = useSelector((state) => state?.cms);
    const logs = state?.tableData?.logs

  return (
    <form> 
        <Row>
            {logs?.length > 0 && (
                <Col sm="12">
                <FormGroup className="w-100 mt-2">
                    <Label className="form-label" for="logs">
                    <strong>Logs:</strong>
                    </Label>

                    <br />
                    {logs
                    ?.map((log, i) => {
                        return (
                        <React.Fragment key={i}>
                            <Label className="form-label" for="name">
                            <li>{log}.</li>
                            </Label>
                            <br />
                        </React.Fragment>
                        );
                    })
                    .reverse()}
                </FormGroup>
                </Col>
            )}
        </Row>
    </form>
  )
}
                

export default Logs
