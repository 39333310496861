import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  useAddReplyToPost,
  useDeleteConnectPost,
  useGetAllUserList,
  useGetPostDetails,
  useGetReplyToPost,
} from "../../api/dataSource";
import { Badge, Button, Col, Input, Row } from "reactstrap";
import Reply from "./Reply";
import toaster from "@components/common/Toaster";
import { useHistory } from "react-router";
import { htmlParserChecker } from "../../utility/Utils";
import ConnectTagUserName from "./ConnectTagUserName";
import ConfirmDeleteForConnect from "./ConfirmDeleteForConnect";
import ConnectPopupImage from "./ConnectPopupImage";
import { Trash } from "react-feather";

const ConnectPostDetail = () => {
  const url = useParams();
  const history = useHistory();

  const { data: replyonpost, refetch: replyrefetch } = useGetReplyToPost(
    url.postId
  );
  const { data: postDetails, refetch } = useGetPostDetails(url.postId);
  const [addReplyToPostHandler, { data: addedReplyData }] = useAddReplyToPost();
  const [featuredImage, setFeaturedImage] = useState("");
  const [comment, setComment] = useState("");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("name")
  const replyList = replyonpost?.getAllReplyToPosts;
  const post = postDetails?.getDetailsOfPost;
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const [deleteConnectPostHandler, { loading, data }] = useDeleteConnectPost()
  const [currentWord, setCurrentWord] = useState("");
  const [isExecuting, setIsExecuting] = useState(false)
  const [searchName, setSearchName] = useState("");
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [showImagePopup, setShowImagePopup] = useState(false)
  const [popupImageUrl, setPopupImageUrl] = useState("")
  const { data: allUserList } = useGetAllUserList(searchName)

  const list = Array.isArray(allUserList?.getUserListToTag) ? allUserList.getUserListToTag : [];

  const DepartmentList = [
    { name: "Sales", value: "Sales" },
    { name: "Marketing", value: "Marketing" },
    { name: "Technology", value: "Technology" },
    { name: "Management", value: "Management" },
    { name: "Customer Experience", value: "Customer Experience" },
    { name: "Operations", value: "Operations" },
    { name: "Finance", value: "Finance" },
    { name: "HR", value: "HR" },
  ];

  const combinedList = [...list, ...DepartmentList];

  const filteredList = combinedList.filter(item =>
    item.name.toLowerCase().includes(searchName.toLowerCase())
  );

  const setImage = async () => {
    if (post && post?.image) {
      await setFeaturedImage(post?.image);
    }
  };

  useEffect(() => {
    setImage();
  }, [post]);

  const commentRegex = /^\s/

  const submitHandler = async () => {
    try {
      // this will be used when unique userId is given to every one in connect
      // const mentionedUsers = comment.match(/@(\w+)/g)
      // if(mentionedUsers){
      //   mentionedUsers.forEach(mention => {
      //     const mentionUserName = mention.substring(1);
      //     console.log(mentionUserName)
      //   })
      // }
      const submitData = {
        connectID: url.postId,
        comment,
        addedBy: userName,
        addedDateTime: new Date(),
        addedById: userId,
        taggedInComment: taggedUsers
      };
      if (comment.trim().length) {
        await addReplyToPostHandler(submitData);
        toaster("success", "Commented");
        setComment("");
        setSearchName("")
        setTaggedUsers([])
        setIsExecuting(false)
      } else {
        toaster("error", "please add comment first")
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addedReplyData) {
      replyrefetch();
    }
  }, [addedReplyData]);

  const handleNavigate = () => {
    history.push(`/connect/connectHomepage`);
  };

  const handleDeletePost = async () => {
    const postId = post?._id;
    const deleteOperation = await deleteConnectPostHandler(userId, postId)
  }
  
  const deletePost = async () => {
    !showImagePopup &&
      ConfirmDeleteForConnect({
        callback: () => handleDeletePost(),
        text: "Deleting a post will remove all its comments and replies.",
      });
  };

  useEffect(() => {
    if (data) {
      if (data?.deleteConnectPost?.message === "deletedSuccessfully") {
        toaster("success", "Post deleted");
        handleNavigate()
      }
    }
  }, [data])

  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    const lastChar = e.nativeEvent.data;
    const lastKeyCode = e.nativeEvent.inputType;

    setComment(inputValue);

    if (lastKeyCode === "deleteContentBackward") {
      const cursorPosition = e.target.selectionStart;
      const match = inputValue.slice(0, cursorPosition).match(/@\S+$/);

      if (match) {
          const tagStart = match.index;
          setComment((prevComment) => prevComment.slice(0, tagStart));
          setTaggedUsers((taggedUsers) =>
            taggedUsers.filter((tag) => !match[0].trim().startsWith(tag))
          );
          setSearchName("");
          setIsExecuting(false);
          return;
      }
    }

    if (lastChar === '@' && !isExecuting) {
        setIsExecuting(true);
        setSearchName("");
    } else if (lastChar === ' ' && isExecuting) {
        setTaggedUsers(taggedUsers => [...taggedUsers, searchName]);
        setIsExecuting(false);
    } else if (isExecuting && lastChar !== '@') {
        setSearchName(searchName + lastChar);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (comment.length > 0) {
        submitHandler(); 
      }
    }
  };

  const toggleImage = (imageUrl) => {
    if (showImagePopup) {
      setShowImagePopup(false)
    } else {
      setShowImagePopup(true)
      setPopupImageUrl(imageUrl)
    }
  }
  return (
    <div style={{ paddingBottom: "100px" }}>
      <div
        className="card"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "22px",
          gap: "20px"
        }}
      >
        <div style={{ display: "flex" }}>
          <svg width="22" height="23" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7118_33940)">
              <circle cx="12.9999" cy="13.8268" r="12.35" fill="#595959" />
              <path d="M8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908Z" fill="#595959" />
              <path d="M20.4425 21.4158V22.943C18.4131 24.601 15.8215 25.5947 12.9998 25.5947C10.1781 25.5947 7.58646 24.601 5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158Z" fill="#F8F8F8" />
              <path d="M5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158V22.943" fill="#F8F8F8" />
              <path d="M17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908Z" fill="#F8F8F8" />
              <path d="M12.9999 26.1766C6.19008 26.1766 0.649902 20.6364 0.649902 13.8266C0.649902 7.01674 6.19008 1.47656 12.9999 1.47656C19.8097 1.47656 25.3499 7.01674 25.3499 13.8266C25.3499 20.6364 19.8097 26.1766 12.9999 26.1766ZM12.9999 2.64035C6.83173 2.64035 1.81369 7.65839 1.81369 13.8266C1.81369 19.9947 6.83173 25.0128 12.9999 25.0128C19.1681 25.0128 24.1861 19.9947 24.1861 13.8266C24.1861 7.65839 19.1681 2.64035 12.9999 2.64035Z" fill="#595959" />
            </g>
            <defs>
              <clipPath id="clip0_7118_33940">
                <rect width="26" height="26" fill="white" transform="translate(0 0.82666)" />
              </clipPath>
            </defs>
          </svg>
          <div style={{ display: 'flex', flexDirection: "column", marginLeft: "10px" }}>
            <span style={{ fontWeight: "500", fontSize: "16px" }}>{post?.addedBy}</span>
            <span>{post?.createdAt ? new Date(post.createdAt).toLocaleDateString('en-GB',{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            }, options) : ""}</span>
          </div>
          {
            post?.addedById == userId && <span style={{ height: "40px", width: "40px", borderRadius: "20px", backgroundColor: "yellow", right: "20px", position: "absolute", color: "black", textAlign: "center", cursor: "pointer", paddingTop:"8px"}} onClick={() => deletePost()}>                    <Trash size={20} />
            </span>
          }
        </div>
        <hr style={{ marginTop: "-10px" }} />
        <div style={{ width: "100%", marginTop: "-22px" }}>
          <h2 style={{ textTransform: "uppercase", fontWeight: "700" }}>{post?.title}</h2>
          {featuredImage && (
            <img
              src={featuredImage}
              alt="Post"
              style={{ cursor: "pointer", height: "auto", width: "auto", maxWidth: "100%", marginTop: "5px", borderRadius: "5px" }}
              onClick={()=>toggleImage(featuredImage)}
            />
          )}
        </div>

        <p style={{ fontSize: "16px", color: "#7e7e7e", textAlign: "justify" }}>{htmlParserChecker(post?.description)}</p>

        <div style={{ marginTop: "-20px", fontSize: "16px" }}>
          {
            post?.department.length > 0 &&

            <div>
              <span style={{ fontWeight: "500", color: "#595959" }}>Department:&nbsp;</span>
              <span style={{ color: "#7e7e7e", }}>
                {post?.department?.join(", ")}
              </span>
            </div>
          }
          {
            post?.hostels?.length > 0 &&
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#595959", fontWeight: "500" }}>Hostels:&nbsp;</span>
              {post?.hostels?.map((hostelId, index) => (
                <span style={{ color: "#7e7e7e" }}>{`${hostelId.name}, `}</span>
              ))}
            </div>
          }
        </div>

        {
          (post?.attachment?.length > 0) && <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {post?.attachment?.map((imageItem, index) => (
                <span key={index}>
                  {imageItem.endsWith('.pdf') ? (
                    <img
                      className="imageSection__image"
                      src={
                        "https://static-dev.thehosteller.com/GO_PURCHASE_REQUEST/pdfImage-1720680601586.png"
                      }
                      alt="Attachment"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(imageItem, "_blank")}
                    />
                    ) : (
                    <img
                      src={imageItem}
                      alt={`Attachment ${index + 1}`}
                      style={{ width: "auto", height: "150px",cursor:"pointer" }}
                      onClick={(e) => { toggleImage(imageItem) }}
                    />
                  )}
                </span>
              ))}
            </div>
          </div>
        }
        {
          showImagePopup && <ConnectPopupImage popupImageUrl={popupImageUrl} toggleImage={toggleImage}/>
        }
      </div>

      <div>
        <h3 style={{ fontWeight: "800" }}>
          Add your comments
        </h3>

        <div style={{ display: 'flex', gap: "10px", position: "relative", justifyContent: "center" }}>
          <Input
            type="textarea"
            value={comment}
            id="Comment"
            name="Comment"
            placeholder={`Add a comment...`}
            onChange={(e) => handleCommentChange(e)}
            onKeyDown={handleKeyDown}
            rows={2}
            style={{position: "relative"}}
          />
          {isExecuting &&
            <ConnectTagUserName comment={comment} userList={filteredList} setComment={setComment} setVisibility={setIsExecuting}
              setSearchName={setSearchName} setTaggedUsers={setTaggedUsers} taggedUsers={taggedUsers}
            />}
          <div style={{ display: "flex", justifyContent: "end", height: "40px" }}>
            <Button.Ripple
              color="light"
              onClick={submitHandler}
              style={{ alignItems: "right", width: "150px", fontWeight: "600" }}
            >
              Comment
            </Button.Ripple>
          </div>
        </div>
      </div>
      {replyList?.length !== 0 && <h3 style={{ fontWeight: "800", marginTop: "20px" }}>
        {replyList?.length} {replyList?.length === 1 ? "Comment" : "Comments"}
      </h3> }
      <div style={{ marginLeft: "-20px" }}>
        {replyList?.length > 0 ? (
          replyList?.map((comment, index) => (
            <Reply key={index} commentData={comment} refetch={replyrefetch} />
          ))
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h3>No comments yet</h3>
          </div>
        )}
      </div>
    </div>
  );
};
export default ConnectPostDetail;
