import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  Button,
  CustomInput,
} from "reactstrap";
import Select from "react-select";
import toaster from "../../@core/components/common/Toaster";
import { useHostelList } from "../../api/dataSource";
import RefundRequestPreStaySlideModal from "./RefundRequestPreStaySlideModal";
import { useGetPreStayRefundGuestDataByReservationId } from "../../api/RefundRequestPreStay/Queries";
import {
  useCreatePreStayRefundRequest,
  useUpdatePreStayRefundRequest,
} from "../../api/RefundRequestPreStay/Mutation";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const userDepartment = localStorage.getItem("department");
  const userRole = localStorage.getItem("role");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const { data: allHostelsData } = useHostelList();
  const [refundRemark, setRefundRemark] = useState("");
  const [reservationID, setReservationID] = useState("");
  const [guestData, setGuestData] = useState({
    hostel: null,
    bookingAmount: null,
    guestName: "",
    phoneNumber: "",
    email: "",
    bookingSource: "",
  });
  const [refundReason, setRefundReason] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [priority, setPriority] = useState(null);
  const [refundMode, setRefundMode] = useState(null);
  const [refundType, setRefundType] = useState(null);
  const [status, setStatus] = useState(null);
  const [approverRemark, setApproverRemark] = useState(null);
  // const [markedOnEzee, setMarkedOnEzee] = useState(false);
  // const [cancelOnEzee, setCancelOnEzee] = useState(true);
  const [markedOnEzee, setMarkedOnEzee] = useState({
    label: "No",
    value: false,
  });
  const [cancelOnEzee, setCancelOnEzee] = useState({
    label: "Yes",
    value: true,
  });

  const { loading, error, data, fetchData } =
    useGetPreStayRefundGuestDataByReservationId(reservationID);
  const [
    createPreStayRefundRequestHandler,
    { data: createdPreStayRefundData, error: createError },
  ] = useCreatePreStayRefundRequest();

  const [
    updatePreStayRefundRequestHandler,
    { data: updatedPreStayRefundData, error: updateError },
  ] = useUpdatePreStayRefundRequest();

  useEffect(() => {
    if (
      createdPreStayRefundData?.createPreStayRefundRequest?.hostel?._id
        ?.length > 0 &&
      editData?._id === undefined
    ) {
      toaster("success", "Prestay refund request created successfully");
      refetch();
      handleToggle();
    }

    if (
      updatedPreStayRefundData?.updatePreStayRefundRequest?.hostel?._id
        ?.length > 0 &&
      editData?._id !== undefined
    ) {
      toaster("success", "Prestay refund request updated successfully");
      refetch();
      handleToggle();
    }

    if (
      (createError || updateError) &&
      ((createError?.errors && createError?.errors[0]?.message?.length > 0) ||
        (updateError?.errors && updateError?.errors[0]?.message?.length > 0))
    ) {
      const errorMessage =
        createError?.errors[0]?.message || updateError?.errors[0]?.message;
      toaster("error", errorMessage);
      refetch();
      handleToggle();
    }
  }, [
    createdPreStayRefundData,
    updatedPreStayRefundData,
    createError,
    updateError,
  ]);

  useEffect(() => {
    if (data?.getPreStayRefundGuestDataByReservationId) {
      const fetchedData = data?.getPreStayRefundGuestDataByReservationId;
      setGuestData({
        hostel: {
          label: fetchedData?.hostelName,
          value: fetchedData?.hostelId,
        },
        reservationID: fetchedData?.reservationID,
        bookingAmount: fetchedData?.bookingAmount,
        guestName: fetchedData?.guestName,
        phoneNumber: fetchedData?.phoneNumber,
        email: fetchedData?.email,
        bookingSource: fetchedData?.bookingSource,
      });
      fetchedData?.bookingSource === "Internet Booking Engine" &&
        setRefundAmount(fetchedData?.bookingAmount);
      fetchedData?.bookingSource === "Internet Booking Engine" &&
        setRefundType({ label: "Full", value: "Full" });
    }

    if (data?.getPreStayRefundGuestDataByReservationId?.hostelId) {
      toaster("success", "Guest data fetched successfully");
    }

    if (data?.getPreStayRefundGuestDataByReservationId?.hostelId === null) {
      toaster("error", "Incorrect reservation ID");
    }
  }, [data]);

  const handleFetchGuestData = () => {
    if (reservationID) {
      fetchData({
        variables: {
          reservationID,
        },
      });

      // toaster("success", "Guest data fetched successfully");
    }
    if (!reservationID) {
      toaster("info", "Please enter a Reservation ID.");
      return;
    }
    if (error) {
      console.log("No data available; check if reservationID is valid.");
      toaster(
        "error",
        "Failed to fetch guest data. Please check the reservation ID."
      );
    }
  };

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;
    if (
      guestData.bookingAmount !== 0 &&
      guestData.bookingAmount !== null &&
      value > guestData.bookingAmount
    ) {
      toaster("error", "Refund amount cannot exceed the booking amount.");
    } else {
      setRefundAmount(value);
    }
  };

  useEffect(() => {
    if (!editData?._id) {
      refundType?.value === "Full"
        ? setRefundAmount(Number(guestData.bookingAmount))
        : setRefundAmount(null);
    }
  }, [refundType?.value]);

  const refundModeOptions = [
    { label: "Razorpay", value: "Razorpay" },
    // { label: "Wallet", value: "Wallet" },
  ];

  const refundReasonOptions = [
    { label: "Over booking", value: "Over booking" },
    {
      label: "Natural calamities & unforeseen events",
      value: "Natural calamities & unforeseen events",
    },
    {
      label: "Normal refunds within policies",
      value: "Normal refunds within policies",
    },
    { label: "Non-serviceability", value: "Non-serviceability" },
    { label: "Others", value: "Others" },
  ];

  const priorityOptions = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  const refundOptions = [
    { label: "Full", value: "Full" },
    { label: "Partial", value: "Partial" },
  ];

  const statusOptions = status !== "Pending" && [
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ];

  const settledOnEzeeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const cancelOnEzeeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  // useEffect(() => {
  //   const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
  //   if (allHostelsData?.getHostelList?.length) {
  //     const filteredHostels =
  //       allowedHostels !== null && allowedHostels?.length !== 0
  //         ? allowedHostels?.map((hostelID) => {
  //             return allHostelsData?.getHostelList?.find(
  //               (item) => item?._id === hostelID
  //             );
  //           })
  //         : allHostelsData?.getHostelList;
  //     let HostelsList = filteredHostels?.map((item) => {
  //       return { label: item?.name, value: item?._id };
  //     });
  //     setHostelsList(HostelsList);
  //   }
  // }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    setHostel(null);
    setReservationID("");
    setGuestData({
      bookingAmount: null,
      guestName: "",
      phoneNumber: "",
      email: "",
      bookingSource: "",
    });
    setRefundAmount(null);
    setPriority(null);
    setRefundMode(null);
    setRefundType(null);
    setRefundReason(null);
    setRefundRemark("");
    setStatus(null);
    setApproverRemark(null);
    // setMarkedOnEzee(false);
    // setCancelOnEzee(true);

    setMarkedOnEzee({ label: "No", value: false });
    setCancelOnEzee({ label: "Yes", value: true });
  }, [handleToggle]);

  useEffect(() => {
    if (editData?._id) {
      // setHostel({
      //   label: editData?.hostel?.name,
      //   value: editData?.hostel?._id,
      // });
      setReservationID(editData?.reservationID);
      setGuestData({
        hostel: editData?.hostel?._id
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null,
        bookingAmount: editData?.bookingAmount,
        guestName: editData?.guestName,
        phoneNumber: editData?.mobileNumber,
        email: editData?.email,
        bookingSource: editData?.bookingSource,
      });
      setRefundAmount(editData?.refundAmount);
      setPriority({ label: editData?.priority, value: editData?.priority });
      setRefundMode({
        label: editData?.refundMode,
        value: editData?.refundMode,
      });
      setRefundType({
        label: editData?.refundType,
        value: editData?.refundType,
      });
      setRefundReason({
        label: editData?.refundReason,
        value: editData?.refundReason,
      });
      setRefundRemark(editData?.refundRemark);
      editData?.status !== "Pending" &&
        setStatus({ label: editData?.status, value: editData?.status });
      editData?.status !== "Pending" &&
        setApproverRemark(editData?.approverRemark);
      // editData?.status !== "Pending" &&
      //   setMarkedOnEzee(Boolean(editData?.markedOnEzee));
      // editData?.status !== "Pending" &&
      //   setCancelOnEzee(Boolean(editData?.cancelOnEzee));

      // setMarkedOnEzee(Boolean(editData?.markedOnEzee));
      // setCancelOnEzee(Boolean(editData?.cancelOnEzee));
      setMarkedOnEzee({
        label: editData?.markedOnEzee ? "Yes" : "No",
        value: editData?.markedOnEzee,
      });
      setCancelOnEzee({
        label: editData?.cancelOnEzee ? "Yes" : "No",
        value: editData?.cancelOnEzee,
      });
    } else {
      setHostel(null);
      setReservationID("");
      setGuestData({
        bookingAmount: null,
        guestName: "",
        phoneNumber: "",
        email: "",
        bookingSource: "",
      });
      setRefundAmount(null);
      setPriority(null);
      setRefundMode(null);
      setRefundType(null);
      setRefundReason(null);
      setRefundRemark("");
      setStatus(null);
      setApproverRemark(null);
      // setMarkedOnEzee(false);
      // setCancelOnEzee(true);

      setMarkedOnEzee({ label: "No", value: false });
      setCancelOnEzee({ label: "Yes", value: true });
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const newErrors = {};

    // Validation checks
    // if (!hostel || !hostel.value) newErrors.hostel = "Hostel is required.";
    if (!reservationID?.trim())
      newErrors.reservationID = "Reservation ID is required.";

    if (!guestData?.guestName) {
      newErrors.guestName = "Guest name is required, fetch the data";
    }
    if (!guestData?.email) {
      newErrors.email = "Email is required, fetch the data";
    }
    if (!guestData?.bookingSource) {
      newErrors.bookingSource = "Booking source is required, fetch the data";
    }

    if (
      guestData.bookingAmount !== 0 &&
      guestData.bookingAmount !== null &&
      refundAmount > guestData?.bookingAmount
    ) {
      newErrors.refundAmount =
        "Refund amount cannot exceed the booking amount.";
    }

    if (!refundAmount || isNaN(refundAmount) || refundAmount < 1) {
      newErrors.refundAmount =
        "Refund amount must be a valid number greater than or equal 1.";
    }

    if (!priority || !priority.value)
      newErrors.priority = "Priority is required";
    if (!refundMode || !refundMode.value)
      newErrors.refundMode = "Refund mode is required";
    if (!refundType || !refundType.value)
      newErrors.refundType = "Refund type is required";
    if (!refundReason || !refundReason.value)
      newErrors.refundReason = "Refund reason is required";
    if (!refundRemark) newErrors.refundRemark = "Refund remark is required";
    // if (!markedOnEzee) newErrors.markedOnEzee = "Settled on ezee is required";

    if (editData?._id && userRole !== "Reservation Executive") {
      if (!status) newErrors.status = "Status is required";

      if (!approverRemark)
        newErrors.approverRemark = "Approver remark is required";

      // if (!cancelOnEzee) newErrors.cancelOnEzee = "Cancel on ezee is required";
    }

    // Check for errors in each field
    for (const [key, errorMessage] of Object.entries(newErrors)) {
      if (errorMessage) {
        toaster("error", errorMessage); // Display specific error message
        return; // Stop execution if any validation error exists
      }
    }

    const data = {
      // hostel: hostel?.value,
      reservationID,
      hostel:
        editData?._id !== undefined
          ? editData?.hostel?._id
          : guestData?.hostel?.value,
      guestName: guestData.guestName,
      mobileNumber: guestData.phoneNumber,
      email: guestData.email,
      bookingSource: guestData.bookingSource,
      bookingAmount: Number(guestData.bookingAmount),
      refundAmount:
        (guestData.bookingSource === "Internet Booking Engine" &&
          guestData?.bookingAmount > 0) ||
        (refundType?.value === "Full" && guestData?.bookingAmount > 0)
          ? Number(guestData.bookingAmount)
          : Number(refundAmount),
      priority: priority?.value,
      refundMode: refundMode?.value,
      refundType: refundType?.value,
      refundReason: refundReason?.value,
      refundRemark,

      // status: editData?.status === "Pending" ? status?.value : null,
      // approverRemark: editData?.status === "Pending" ? approverRemark : null,
      status: status?.value,
      approverRemark: approverRemark,
      // markedOnEzee: editData?.status === "Pending" ? markedOnEzee : null,
      // cancelOnEzee: editData?.status === "Pending" ? cancelOnEzee : null,
      markedOnEzee: markedOnEzee?.value,
      cancelOnEzee: cancelOnEzee?.value,
    };

    if (
      guestData.bookingSource === "Internet Booking Engine" ||
      (guestData.bookingSource !== "Internet Booking Engine" &&
        Number(guestData.bookingAmount) === Number(refundAmount))
    ) {
      if (!editData?._id) {
        createPreStayRefundRequestHandler(data);
        // toaster("success", "Prestay refund request created successfully");
      } else {
        const id = editData?._id;
        updatePreStayRefundRequestHandler(id, data);
        // toaster("success", "Prestay refund request updated successfully");
      }
    } else if (
      guestData.bookingSource !== "Internet Booking Engine" &&
      Number(guestData.bookingAmount) !== Number(refundAmount)
    ) {
      if (!editData?._id) {
        createPreStayRefundRequestHandler(data);
        // toaster("success", "Prestay refund request created successfully");
      } else {
        const id = editData?._id;
        updatePreStayRefundRequestHandler(id, data);
        // toaster("success", "Prestay refund request updated successfully");
      }
    }

    // if (
    //   guestData.bookingSource === "Internet Booking Engine" ||
    //   Number(guestData.bookingAmount) === Number(refundAmount)
    // ) {
    //   // Conditions are satisfied for creating or updating the refund request
    //   const id = editData?._id;
    //   if (!id) {
    //     createPreStayRefundRequestHandler(data);
    //     toaster("success", "Prestay refund request created successfully");
    //   } else {
    //     updatePreStayRefundRequestHandler(id, data);
    //     toaster("success", "Prestay refund request updated successfully");
    //   }
    // }

    setHostel(null);
    setReservationID("");
    setGuestData({
      hostel: null,
      bookingAmount: null,
      guestName: "",
      phoneNumber: "",
      email: "",
      bookingSource: "",
    });
    setRefundAmount(null);
    setPriority(null);
    setRefundMode(null);
    setRefundType(null);
    setRefundReason(null);
    setRefundRemark("");
    setStatus(null);
    setApproverRemark(null);
    // setMarkedOnEzee(false);
    // setCancelOnEzee(true);
    setMarkedOnEzee({ label: "No", value: false });
    setCancelOnEzee({ label: "Yes", value: true });
  };

  return (
    <RefundRequestPreStaySlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
      userRole={userRole}
      // userDepartment={userDepartment}
    >
      <Row>
        {/*//! Reservation ID */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reservationID">
              Reservation ID*
            </Label>
            <Input
              type="text"
              value={reservationID}
              id="reservationID"
              name="reservationID"
              placeholder="Reservation ID"
              onChange={(e) => setReservationID(e.target.value)}
              disabled={editData?._id}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleFetchGuestData}
                color="light"
                size="sm"
                className="mt-2"
                // style={{ marginLeft: "390px" }}
                disabled={
                  loading ||
                  (guestData?.bookingAmount !== null &&
                    data?.getPreStayRefundGuestDataByReservationId
                      ?.bookingSource) ||
                  editData?.reservationID
                }
              >
                {loading ? "Fetching..." : "Fetch"}
              </Button>
            </div>
            {error && toaster("error", "Error fetching guest data.")}
          </FormGroup>
        </Col>
        {/*//! Hostel */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              Hostel*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostel"
              name="hostel"
              // options={hostelsList}
              value={guestData.hostel || null}
              // onChange={(value) => setHostel(value)}
              classNamePrefix="select"
              isDisabled={true}
            />
          </FormGroup>
        </Col>
        {/*//! Guest Name */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              Guest name*
            </Label>
            <Input type="text" value={guestData.guestName || ""} readOnly />
          </FormGroup>
        </Col>
        {/*//! Phone Number */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="phoneNumber">
              Phone number
            </Label>
            <Input type="text" value={guestData.phoneNumber || ""} readOnly />
          </FormGroup>
        </Col>
        {/*//! Email */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="email">
              Email*
            </Label>
            <Input type="email" value={guestData.email || ""} readOnly />
          </FormGroup>
        </Col>
        {/*//! Booking Source */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="bookingSource">
              Booking source*
            </Label>
            <Input type="text" value={guestData.bookingSource || ""} readOnly />
          </FormGroup>
        </Col>
        {/*//! Booking Amount */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="bookingAmount">
              Booking amount*
            </Label>
            <Input
              type="number"
              value={guestData.bookingAmount || 0}
              readOnly
            />
          </FormGroup>
          <hr />
        </Col>
        {/*//! Priority */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="priority">
              Priority*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="priority"
              name="priority"
              options={priorityOptions}
              value={priority}
              onChange={(value) => {
                setPriority(value);
              }}
              classNamePrefix="select"
              isDisabled={editData?._id}
            />
          </FormGroup>
        </Col>
        {/*//! Refund Type */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="refundType">
              Refund type*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="refundType"
              name="refundType"
              options={refundOptions}
              value={refundType}
              onChange={(value) => {
                setRefundType(value);
              }}
              classNamePrefix="select"
              isDisabled={
                editData?._id ||
                guestData?.bookingSource === "Internet Booking Engine"
              }
            />
          </FormGroup>
        </Col>
        {/*//! Refund Mode */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="refundMode">
              Refund mode*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="refundMode"
              name="refundMode"
              options={refundModeOptions}
              value={refundMode}
              onChange={(value) => {
                setRefundMode(value);
              }}
              classNamePrefix="select"
              isDisabled={editData?._id}
            />
          </FormGroup>
        </Col>
        {/*//! Refund Amount */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="refundAmount">
              Refund amount*
            </Label>
            <Input
              type="number"
              value={refundAmount || ""}
              id="refundAmount"
              name="refundAmount"
              placeholder="Add refund amount"
              onChange={handleRefundAmountChange}
              onWheel={(e) => e.target.blur()}
              disabled={
                // refundType?.value === "Full" ||
                editData?._id
              }
            />
            {/* {refundAmount !== 0 && refundAmount > guestData.bookingAmount && (
              <div style={{ color: "red" }}>
                Refund amount cannot be more than the booking amount!
              </div>
            )} */}
          </FormGroup>
        </Col>
        {/*//! Refund Reason */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="refundReason">
              Refund reason*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="refundReason"
              name="refundReason"
              options={refundReasonOptions}
              value={refundReason}
              onChange={(value) => {
                setRefundReason(value);
              }}
              classNamePrefix="select"
              isDisabled={editData?._id}
            />
          </FormGroup>
        </Col>
        {/*//! Refund Remark */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="refundRemark">
              Refund remark*
            </Label>
            <Input
              type="textarea"
              value={refundRemark}
              id="refundRemark"
              name="refundRemark"
              rows="1"
              placeholder="Add refund remark"
              onChange={(e) => setRefundRemark(e.target.value)}
              disabled={editData?._id}
            />
          </FormGroup>
        </Col>
        {/*//! Settled On Ezee */}
        <Col sm="12">
          <FormGroup>
            {/* <CustomInput
              type="checkbox"
              id="markedOnEzee"
              name="markedOnEzee"
              checked={markedOnEzee}
              label="Settled on Ezee"
              className="custom-control-Primary"
              onChange={(e) => setMarkedOnEzee(e.target.checked)}
              disabled={
                // editData?._id
                editData?.status === "Rejected"
              }
            /> */}

            <Label className="form-label" for="markedOnEzee">
              Is it settled on Ezee?
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="markedOnEzee"
              name="markedOnEzee"
              options={settledOnEzeeOptions}
              value={markedOnEzee}
              onChange={(value) => {
                setMarkedOnEzee(value);
              }}
              classNamePrefix="select"
              isDisabled={
                // editData?._id
                editData?.status === "Rejected"
              }
            />
          </FormGroup>
        </Col>
        {/*//! Cancel on Ezee */}
        <Col sm="12">
          <FormGroup>
            {/* <CustomInput
              type="checkbox"
              id="cancelOnEzee"
              name="cancelOnEzee"
              checked={cancelOnEzee}
              label="Cancel on Ezee"
              className="custom-control-Primary"
              onChange={(e) => setCancelOnEzee(e.target.checked)}
              disabled={
                editData?.status === "Approved" ||
                editData?.status === "Rejected"
              }
            /> */}

            <Label className="form-label" for="cancelOnEzee">
              Do you want to cancel on Ezee?
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="cancelOnEzee"
              name="cancelOnEzee"
              options={cancelOnEzeeOptions}
              value={cancelOnEzee}
              onChange={(value) => {
                setCancelOnEzee(value);
              }}
              classNamePrefix="select"
              isDisabled={
                editData?.status === "Approved" ||
                editData?.status === "Rejected"
              }
            />
          </FormGroup>
        </Col>
        {/*//! Status */}
        {editData?._id && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                Status*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="status"
                name="status"
                options={statusOptions}
                value={status}
                onChange={(value) => {
                  setStatus(value);
                }}
                classNamePrefix="select"
                isDisabled={
                  editData?.status !== "Pending" ||
                  userRole === "Reservation Executive"
                }
              />
            </FormGroup>
          </Col>
        )}
        {/*//! Approver Remark */}
        {editData?._id && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="approverRemark">
                Approver remark*
              </Label>
              <Input
                type="textarea"
                value={approverRemark}
                id="approverRemark"
                name="approverRemark"
                rows="1"
                placeholder="Add approver remark"
                onChange={(e) => setApproverRemark(e.target.value)}
                disabled={
                  editData?.status !== "Pending" ||
                  userRole === "Reservation Executive"
                }
              />
            </FormGroup>
          </Col>
        )}
        {/*//! Cancel on Ezee */}
        {/* {editData?._id && (
          <Col sm="12">
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="cancelOnEzee"
                name="cancel on Ezee"
                checked={cancelOnEzee}
                label="Cancel on Ezee*"
                className="custom-control-Primary"
                onChange={(e) => setCancelOnEzee(e.target.checked)}
                disabled={editData?.status !== "Pending"}
              />
            </FormGroup>
          </Col>
        )} */}
        {/*//! Razorpay Refund ID */}
        {editData?.razprpayRefundID && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="razprpayRefundID">
                Razorpay refund ID
              </Label>
              <Input
                type="text"
                value={editData?.razprpayRefundID || ""}
                readOnly
              />
            </FormGroup>
          </Col>
        )}
        {/*//! Payment ID */}
        {editData?.paymentID && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentID">
                Payment ID
              </Label>
              <Input type="text" value={editData?.paymentID || ""} readOnly />
            </FormGroup>
          </Col>
        )}
        {/*//! Refund Status */}
        {editData?.refundStatus && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="refundStatus">
                Refund status
              </Label>
              <Input
                type="text"
                value={editData?.refundStatus || ""}
                readOnly
              />
            </FormGroup>
          </Col>
        )}
        {/*//! Refund Start Time */}
        {editData?.refundStartTime && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="refundStartTime">
                Refund start time
              </Label>
              <Input
                type="text"
                value={new Date(editData?.refundStartTime).toLocaleString(
                  "en-IN",
                  {}
                )}
                readOnly
              />
            </FormGroup>
          </Col>
        )}
        {editData?._id && (
          <Col sm="12">
            {/* <hr/> */}
            <FormGroup className="w-100 mt-2">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>

              <br />
              {editData?.logs
                ?.map((log, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Label className="form-label" for="name">
                        <li>{log}.</li>
                      </Label>
                      <br />
                    </React.Fragment>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        )}
      </Row>
    </RefundRequestPreStaySlideModal>
  );
};
export default AddEditModal;
