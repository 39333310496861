import React, { useState, useEffect } from "react";
import SlideModal from "./slide-modal/index";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import {
  useCreateOpsFinance,
  useHostelList,
  useUpdateOpsFinance,
  useUpdateInvoicePendingClarification,
} from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import FinanceImagesSection from "./FinanceImagesSection/index";
import CapexSubHeadsSubFields from "./CapexSubHeadsSubFields";
import AncillaryPayoutSubFields from "./AncillaryPayoutSubFields";
import AncillaryMaterialSubFields from "./AncillaryMaterialSubFields";
import HostelRentalSubFields from "./HostelRentalSubFields";
import HostelPayoutSubFields from "./HostelPayoutSubFields";
import HostelSalarySubFields from "./HostelSalarySubFields";
import HostelAdvanceSalarySubFields from "./HostelAdvanceSalarySubFields";
import HostelHKSubFields from "./HostelHKSubFields";
import HostelGeneralSubFields from "./HostelGeneralSubFields";
import HostelCommissionSubFields from "./HostelCommissionSubFields";
import HOSTEL_R_AND_M_SUBFIELDS from "./HostelR&MSubFields";
import HostelUtilitiesSubFields from "./HostelUtilitiesSubFields";
import F_AND_B_MATERIAL_SUBFIELDS from "./F&BMaterialSubFields";
import Flatpickr from "react-flatpickr";

const CashOutAddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [entryFrom, setEntryFrom] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [totalAmount, setTotalAmount] = useState("");
  const [amountWithoutGST, setAmountWithoutGST] = useState("");
  const [GST, setGST] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [remarkImageList, setRemarkImageList] = useState([]);
  const [entryBy, setEntryBy] = useState("");
  const [paymentReferenceId, setPaymentReferenceId] = useState("");
  const [expenseType, setExpenseType] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [arrHeads, setArrHeads] = useState(null);
  const [subHead, setSubHead] = useState(null);
  const [dummyState, setDummyState] = useState(null);
  const [vendorName, setVendorName] = useState("");
  const [warranty, setWarranty] = useState("");
  const [labourAmount, setLabourAmount] = useState("");
  const [materialAmount, setMaterialAmount] = useState("");
  const [subHeadType, setSubHeadType] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [dataToSubmit, setDataToSubmit] = useState({});
  const [transactionDate, setTransactionDate] = useState(new Date());

  const { data: allHostelsData } = useHostelList();
  const [createOpsFinanceHandler, { data: createdOpsFinanceData, error }] =
    useCreateOpsFinance();

  const [
    updateOpsFinanceHandler,
    { data: updateDaybookData, error: updateDaybookError },
  ] = useUpdateOpsFinance();

  const [invoicePendingStatusCheck, setInvoicePendingStatusCheck] =
    useState(false);
  const [pendingInvoiceCorrectionRemark, setPendingInvoiceCorrectionRemark] =
    useState("");
  const [showInvoiceCorrectionForm, setShowInvoiceCorrectionForm] =
    useState(false);

  const [updateInvoicePendingClarificationHandler] =
    useUpdateInvoicePendingClarification();

  const isFinanceDepartmentUser =
    localStorage.getItem("department") === "Finance";

  const handleInvoiceCorrectionSubmit = () => {
    setShowInvoiceCorrectionForm(true);
  };

  const handleCancelInvoiceCorrection = () => {
    setShowInvoiceCorrectionForm(false);
    setPendingInvoiceCorrectionRemark("");
  };

  const handleSuggestedChangesSubmit = async () => {
    try {
      await updateInvoicePendingClarificationHandler(editData._id, {
        invoicePendingStatusCheck: true,
        pendingInvoiceCorrectionRemark,
      });
      toaster("success", "Suggested changes submitted successfully!");
      setShowInvoiceCorrectionForm(false);
      refetch();
      handleToggle();
    } catch (error) {
      toaster("error", "An error occurred. Please try again later.");
    }
  };

  const handleMarkAsCompleted = async () => {
    try {
      await updateInvoicePendingClarificationHandler(editData._id, {
        invoicePendingStatusCheck: false,
      });
      toaster("success", "Invoice correction marked as completed!");
      refetch();
      handleToggle();
      resetInvoiceCheckFields();
    } catch (error) {
      toaster("error", "An error occurred. Please try again later.");
    }
  };

  const handleUpdateInvoiceImages = async () => {
    const updatedImageList = imageList;
    await updateInvoicePendingClarificationHandler(editData._id, {
      uploadInvoice: updatedImageList,
    });
    refetch();
    handleToggle();
  };

  useEffect(() => {
    if (updateDaybookData?.updateOpsFinance) {
      refetch();
      handleToggle();
      resetInvoiceCheckFields();
    }
  }, [updateDaybookData]);

  const FormList = [
    { label: "GO", value: "GO" },
    { label: "HO", value: "HO" },
  ];

  const PaymentModeList = [
    { label: "Cash", value: "Cash" },
    { label: "Bank", value: "Bank" },
  ];

  const HeadList = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
    { label: "CAPEX", value: "CAPEX" },
  ];
  const ExpenseList = [
    { label: "Provisional", value: "Provisional" },
    { label: "Actual", value: "Actual" },
  ];

  let SubHeadList;

  if (arrHeads?.value === "Hostel") {
    if (entryFrom?.value === "HO") {
      SubHeadList = [
        { label: "Rental", value: "Rental" },
        { label: "Payout", value: "Payout" },
        { label: "Salary", value: "Salary" },
        { label: "Advance Salary", value: "Advance Salary" },
        { label: "Utilities", value: "Utilities" },
        { label: "R&M", value: "R&M" },
        { label: "HK", value: "HK" },
        { label: "Commission", value: "Commission" },
        { label: "General", value: "General" },
      ];
    } else {
      SubHeadList = [
        { label: "Utilities", value: "Utilities" },
        { label: "R&M", value: "R&M" },
        { label: "HK", value: "HK" },
        { label: "General", value: "General" },
      ];
    }
  } else if (arrHeads?.value === "F&B") {
    SubHeadList = [{ label: "Material", value: "Material" }];
  } else if (arrHeads?.value === "Ancillary") {
    SubHeadList = [
      { label: "Material", value: "Material" },
      { label: "Vendor Payout", value: "Vendor Payout" },
    ];
  } else if (arrHeads?.value === "CAPEX") {
    SubHeadList = [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ];
  }

  const UploadType = [{ label: "images", value: "uploadInvoice" }];
  const RemarkUpload = [{ label: "images", value: "remarkImages" }];
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (createdOpsFinanceData?.createOpsFinance?.entryType) {
      toaster("success", "Cash-Out Added Successfully!");
      resetFields();
      refetch();
      handleToggle();
    } else if (error) {
      console.error("API Error:", error);
      toaster("error", "An error occurred. Please try again later.");
    }
  }, [createdOpsFinanceData, error]);

  useEffect(() => {
    if (updateDaybookData?.updateOpsFinance) {
      toaster("success", "Cash-Out Updated Successfully!");
      refetch();
      handleToggle();
    } else if (updateDaybookError) {
      console.error("API Error:", updateDaybookError);
      toaster(
        "error",
        "An error occurred while updating the Cash-Out. Please try again later."
      );
    }
  }, [updateDaybookData, updateDaybookError]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);

      //have added this case when only one hostel is assigned to the account, in such case assigning the first hostel
      if (HostelsList.length === 1) {
        setHostel(HostelsList[0]);
      }
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (amountWithoutGST !== "" && GST !== "") {
      const total = parseFloat(amountWithoutGST) + parseFloat(GST);
      setTotalAmount(total.toFixed(0));
    }
  }, [amountWithoutGST, GST]);

  useEffect(() => {
    if (editData?._id) {
      const hostelInfo = allHostelsData?.getHostelList?.find(
        (item) => item?._id === editData?.hostelId?._id
      );

      setHostel({ label: hostelInfo?.name, value: hostelInfo?._id });

      setAmountWithoutGST(
        editData?.amountWithoutGST !== null ? editData?.amountWithoutGST : ""
      );
      setGST(editData?.GST !== null ? editData?.GST : "");

      setGstNumber(editData?.gstNumber !== null ? editData?.gstNumber : "");
      setVendorName(
        editData?.vendorNameDayBook !== null ? editData?.vendorNameDayBook : ""
      );
      setTotalAmount(
        editData?.totalAmount !== null ? editData?.totalAmount : ""
      );
      setRemarks(editData?.remarks !== null ? editData?.remarks : "");
      setRemarkImageList(editData?.remarkImages ? editData?.remarkImages : []);
      setEntryBy(editData?.entryBy !== null ? editData?.entryBy : "");
      setPaymentMode(
        editData?.paymentMode !== null
          ? { label: editData?.paymentMode, value: editData?.paymentMode }
          : null
      );
      setEntryFrom(
        editData?.entryFrom !== null
          ? { label: editData?.entryFrom, value: editData?.entryFrom }
          : null
      );

      setPaymentReferenceId(
        editData?.paymentReferenceId !== null
          ? editData?.paymentReferenceId
          : ""
      );
      setExpenseType(
        editData?.expenseType !== null
          ? { label: editData?.expenseType, value: editData?.expenseType }
          : null
      );
      setImageList(editData?.uploadInvoice ? editData?.uploadInvoice : []);
      const selectedHeads = editData?.head?.map((i) => ({
        label: i,
        value: i,
      }));
      setArrHeads(selectedHeads === undefined ? [] : selectedHeads);
      setSubHead(
        editData?.subHead
          ? { label: editData?.subHead, value: editData?.subHead }
          : null
      );
      setTransactionDate(editData?.entryDate ? editData?.entryDate : null);
      setLogs(editData?.logs ? editData?.logs : []);

      setInvoicePendingStatusCheck(editData?.invoicePendingStatusCheck);
      setPendingInvoiceCorrectionRemark(
        editData.pendingInvoiceCorrectionRemark || ""
      );
    } else {
      resetFields();
    }
  }, [editData]);

  const onSubmitHandler = async () => {
    const currentDate = new Date();

    // const entryDate = transactionDate
    //   ? transactionDate.toLocaleDateString("en-CA")
    //   : currentDate.toLocaleDateString("en-CA");

    const entryDate =
      transactionDate &&
      transactionDate instanceof Date &&
      !isNaN(transactionDate)
        ? transactionDate.toLocaleDateString("en-CA")
        : currentDate.toLocaleDateString("en-CA");

    const entryTime = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const isNumeric = /^\d+$/.test(String(totalAmount).trim());
    const department = localStorage.getItem("department");

    if (
      totalAmount.toString().trim() === "" ||
      !isNumeric ||
      (department !== "Finance" && Number(totalAmount) === 0)
    ) {
      toaster(
        "error",
        "Please enter a valid numeric value for Total Amount that is not zero."
      );
      return;
    }

    const roundedTotalAmount = Math.round(totalAmount);

    const newLog = `${localStorage.getItem("name")} ${
      editData ? "modified" : "created"
    } this cash-out entry on ${currentDate.toLocaleString("en-IN", {
      hour12: false,
    })}`;
    setLogs([...logs, newLog]);

    if (GST > 0 && !gstNumber) {
      toaster("error", "Please enter a valid GST Number.");
      return;
    }

    if (GST > 0 && !vendorName) {
      toaster("error", "Please enter a valid Vendor Name.");
      return;
    }

    if (!imageList || imageList.length === 0) {
      toaster("error", "Please upload a correct invoice image.");
      return;
    }

    const data = {
      hostelId: hostel?.value,
      head: arrHeads?.value,
      totalAmount: Math.abs(roundedTotalAmount) * -1,
      entryType: "Cash Out",

      // entryDate: transactionDate
      //   ? new Date(new Date(transactionDate).setHours(0, 0, 0, 0))
      //   : new Date(new Date().setHours(0, 0, 0, 0)),

      entryDate,

      entryTime: entryTime,
      amountWithoutGST: Number(amountWithoutGST),
      GST: Number(GST),
      gstNumber: GST > 0 ? gstNumber : null,
      vendorNameDayBook: vendorName,
      remarks,
      remarkImages: remarkImageList,
      entryBy,
      paymentMode: paymentMode?.value,
      paymentReferenceId,
      expenseType: expenseType?.value,
      entryFrom: entryFrom?.value,
      uploadInvoice: imageList,
      subHead: subHead?.value,
      subFields: dataToSubmit,
      logs: [...logs, newLog],
    };

    try {
      if (
        hostel &&
        arrHeads &&
        totalAmount &&
        amountWithoutGST &&
        (GST !== null || GST === 0) &&
        paymentMode &&
        entryFrom &&
        subHead &&
        remarks &&
        remarkImageList &&
        imageList &&
        entryBy
      ) {
        if (editData?._id) {
          await updateOpsFinanceHandler(editData._id, data);
        } else {
          await createOpsFinanceHandler(data);
        }
      } else {
        toaster("error", "Please fill all the required details marked with *");
      }
    } catch (error) {
      console.error("API Error:", error);
      toaster("error", "An error occurred. Please try again later.");
    }
  };

  const resetFields = () => {
    if (HostelsList.length === 1) {
      setHostel(HostelsList[0]);
    } else {
      setHostel(null);
    }
    // setHostel(null);
    setTotalAmount("");
    setAmountWithoutGST("");
    setGST("");
    setRemarks("");
    setRemarkImageList([]);
    setEntryBy("");
    setPaymentMode(null);
    setPaymentReferenceId("");
    setExpenseType(null);
    setEntryFrom(null);
    setImageList([]);
    setArrHeads(null);
    setSubHead(null);
    setSubHeadType(null);
    setVendorName("");
    setWarranty("");
    setLabourAmount("");
    setMaterialAmount("");
    setQuantity("");
    setTransactionDate(null);
    setLogs([]);
    setShowInvoiceCorrectionForm(false);
    setPendingInvoiceCorrectionRemark("");
  };

  const resetInvoiceCheckFields = () => {
    setShowInvoiceCorrectionForm(false);
    setPendingInvoiceCorrectionRemark("");
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
                // isDisabled={editData?._id}
                // isDisabled={HostelsList.length === 1 || editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="entryFrom">
                Cash Out From*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="entryFrom"
                name="entryFrom"
                value={entryFrom}
                options={FormList}
                required
                onChange={(value) => setEntryFrom(value)}
                classNamePrefix="select"
                // isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="transactionDate">
                Transaction Date (when the transaction occurred)*
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                name="transactionDate"
                placeholder="Transaction Date"
                value={transactionDate || new Date()}
                // onChange={(date) => setTransactionDate(date[0])}
                onChange={(date) => {
                  // console.log("Selected Date (Raw):", date[0]);
                  const localDate = new Date(
                    date[0].getFullYear(),
                    date[0].getMonth(),
                    date[0].getDate()
                  );
                  setTransactionDate(localDate);
                }}
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                  minDate: (() => {
                    const today = new Date();
                    const role = localStorage.getItem("role");

                    if (role === "Ground Staff") {
                      today.setDate(today.getDate() - 2);
                    } else if (role === "Area Manager") {
                      today.setDate(today.getDate() - 3);
                    }

                    return today;
                  })(),
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="arrHeads">
                Cash Out Head*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                options={HeadList}
                value={arrHeads}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(module) => {
                  setSubHead(null);
                  setPaymentMode(null);
                  setArrHeads(module);
                }}
                // isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>
          {arrHeads && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="subHead">
                  Sub Head*
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="subHead"
                  name="subHead"
                  value={subHead}
                  options={SubHeadList}
                  required
                  onChange={(value) => setSubHead(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {/* //! HOSTEL -> RENTAL ->  RENTAL TYPE */}
          {((arrHeads?.value === "Hostel" && subHead?.value === "Rental") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "Rental")) && (
            <HostelRentalSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {/* //! HOSTEL -> PAYOUT ->  PAYOUT TYPE */}
          {((arrHeads?.value === "Hostel" && subHead?.value === "Payout") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "Payout")) && (
            <HostelPayoutSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {/* //! HOSTEL -> SALARY ->  SALARY TYPE */}
          {((arrHeads?.value === "Hostel" && subHead?.value === "Salary") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "Salary")) && (
            <HostelSalarySubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((hostel?.value &&
            arrHeads?.value === "Hostel" &&
            subHead?.value === "Advance Salary") ||
            (editData?.hostelId?._id &&
              editData?.head[0] === "Hostel" &&
              editData?.subHead === "Advance Salary")) && (
            <HostelAdvanceSalarySubFields
              setDataToSubmit={setDataToSubmit}
              hostel={hostel?.value}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "Hostel" && subHead?.value === "Utilities") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "Utilities")) && (
            <HostelUtilitiesSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "Hostel" && subHead?.value === "R&M") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "R&M")) && (
            <HOSTEL_R_AND_M_SUBFIELDS
              setDataToSubmit={setDataToSubmit}
              editData={editData}
              setTotalAmountWithoutGST={setAmountWithoutGST}
            />
          )}

          {((arrHeads?.value === "Hostel" && subHead?.value === "HK") ||
            (editData?.head[0] === "Hostel" && editData?.subHead === "HK")) && (
            <HostelHKSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "Hostel" && subHead?.value === "Commission") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "Commission")) && (
            <HostelCommissionSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "Hostel" && subHead?.value === "General") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "General")) && (
            <HostelGeneralSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "F&B" && subHead?.value === "Material") ||
            (editData?.head[0] === "F&B" &&
              editData?.subHead === "Material")) && (
            <F_AND_B_MATERIAL_SUBFIELDS
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "Ancillary" &&
            subHead?.value === "Vendor Payout") ||
            (editData?.head[0] === "Ancillary" &&
              editData?.subHead === "Vendor Payout")) && (
            <AncillaryPayoutSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "Ancillary" &&
            subHead?.value === "Material") ||
            (editData?.head[0] === "Ancillary" &&
              editData?.subHead === "Material")) && (
            <AncillaryMaterialSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((arrHeads?.value === "CAPEX" &&
            (subHead?.value === "Hostel" ||
              subHead?.value === "F&B" ||
              subHead?.value === "Ancillary")) ||
            (editData?.head[0] === "CAPEX" &&
              (editData?.subHead === "Hostel" ||
                editData?.subHead === "F&B" ||
                editData?.subHead === "Ancillary"))) && (
            <CapexSubHeadsSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
              setTotalAmountWithoutGST={setAmountWithoutGST}
            />
          )}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentMode">
                Payment Mode*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="paymentMode"
                name="paymentMode"
                value={paymentMode}
                options={PaymentModeList}
                required
                onChange={(value) => setPaymentMode(value)}
                classNamePrefix="select"
                // isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="amountWithoutGST">
                Amount Without GST*
              </Label>
              <Input
                type="text"
                value={amountWithoutGST}
                id="amountWithoutGST"
                name="amountWithoutGST"
                placeholder="Add Amount Without GST"
                onChange={(e) => setAmountWithoutGST(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="GST">
                GST (amount)*
              </Label>
              <Input
                type="text"
                value={GST}
                id="GST"
                name="GST"
                placeholder="Add GST"
                onChange={(e) => setGST(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          {GST > 0 && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="gstNumber">
                  GST Number*
                </Label>
                <Input
                  type="text"
                  value={gstNumber}
                  id="gstNumber"
                  name="gstNumber"
                  placeholder="Add GST Number"
                  onChange={(e) => setGstNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}

          {GST > 0 && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorName">
                  Vendor Name*
                </Label>
                <Input
                  type="text"
                  value={vendorName}
                  id="vendorName"
                  name="vendorName"
                  placeholder="Add Vendor Name"
                  onChange={(e) => setVendorName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalAmount">
                Total Amount*
              </Label>
              <Input
                type="text"
                value={totalAmount < 0 ? Math.abs(totalAmount) : totalAmount}
                id="totalAmount"
                name="totalAmount"
                placeholder="Total Amount will be calculated automatically"
                disabled
                style={{ opacity: 1, cursor: "not-allowed" }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <div className="d-flex align-items-center">
              <Label className="form-label" for="imageList">
                Upload Invoice Images*
              </Label>
              {editData?.invoicePendingStatusCheck && (
                <Button
                  color="dark"
                  size="sm"
                  className="mx-2"
                  onClick={handleUpdateInvoiceImages}
                >
                  Update Invoice
                </Button>
              )}
            </div>
            <FinanceImagesSection
              imageList={imageList}
              setImageList={setImageList}
              editData={dummyState}
              uploadType={UploadType}
            />
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentReferenceId">
                Payment Reference Id (only in case of bank payments)*
              </Label>
              <Input
                type="text"
                value={paymentReferenceId}
                id="paymentReferenceId"
                name="paymentReferenceId"
                placeholder="Add Payment Reference Id"
                onChange={(e) => setPaymentReferenceId(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="entryBy">
                Cash Out By*
              </Label>
              <Input
                type="text"
                value={entryBy}
                id="entryBy"
                name="entryBy"
                placeholder="Add Cash Out By"
                onChange={(e) => setEntryBy(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="remarks">
                Remarks*
              </Label>
              <Input
                type="textarea"
                value={remarks}
                id="remarks"
                name="remarks"
                placeholder="Add Remarks"
                onChange={(e) => setRemarks(e.target.value)}
                // disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <Label className="form-label" for="remarkImageList">
              Upload Supporting Images*
            </Label>
            <FinanceImagesSection
              imageList={remarkImageList}
              setImageList={setRemarkImageList}
              editData={dummyState}
              uploadType={RemarkUpload}
            />
          </Col>

          {(entryFrom?.value === "HO" || editData?.entryFrom === "HO") && (
            <Col sm="12" style={{ zIndex: "105" }}>
              <FormGroup className="w-100">
                <Label className="form-label" for="expenseType">
                  Expense Base*
                </Label>
                <Select
                  menuPlacement="top"
                  isClearable={false}
                  isSearchable={true}
                  id="expenseType"
                  name="expenseType"
                  value={expenseType}
                  options={ExpenseList}
                  required
                  onChange={(value) => setExpenseType(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          )}

          {/* New Section for Invoice Correction */}
          <>
            {editData?._id && (
              <Col sm="12">
                {editData?.invoicePendingStatusCheck && (
                  <Label className="form-label">
                    Invoice Correction Pending:{" "}
                    {editData.pendingInvoiceCorrectionRemark}
                  </Label>
                )}

                {isFinanceDepartmentUser && (
                  <>
                    {!editData?.invoicePendingStatusCheck &&
                      !showInvoiceCorrectionForm && (
                        <Button
                          color="info"
                          size="sm"
                          className="mb-2"
                          onClick={handleInvoiceCorrectionSubmit}
                        >
                          Mark Invoice Correction as Pending
                        </Button>
                      )}

                    {showInvoiceCorrectionForm && (
                      <>
                        <FormGroup className="w-100">
                          <Label
                            className="form-label"
                            for="pendingInvoiceCorrectionRemark"
                          >
                            Suggested Changes
                          </Label>
                          <Input
                            type="textarea"
                            value={pendingInvoiceCorrectionRemark}
                            id="pendingInvoiceCorrectionRemark"
                            name="pendingInvoiceCorrectionRemark"
                            placeholder="Enter suggested changes"
                            onChange={(e) =>
                              setPendingInvoiceCorrectionRemark(e.target.value)
                            }
                          />
                        </FormGroup>
                        <div className="d-flex">
                          <Button
                            color="primary"
                            size="sm"
                            className="me-2"
                            onClick={handleSuggestedChangesSubmit}
                          >
                            Submit Suggested Changes
                          </Button>
                          <div className="mx-1"></div>
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={handleCancelInvoiceCorrection}
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    )}

                    {editData?.invoicePendingStatusCheck && (
                      <div className="text-end mt-2">
                        <Button
                          color="success"
                          size="sm"
                          onClick={handleMarkAsCompleted}
                        >
                          Mark as Completed
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </Col>
            )}
          </>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {logs.map((log, index) => (
                <Label key={index} className="form-label">
                  {log}
                </Label>
              ))}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default CashOutAddEditModal;
