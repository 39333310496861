import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";

export const GET_WEBSITE_BOOKING_EXPORT = gql`
  mutation getWebsiteBookingReportForExport($filter: filter) {
    getWebsiteBookingReportForExport(filter: $filter)
  }
`;


export const UPDATE_WEBSITE_BOOKING = gql`
  mutation updateBooking($id:ID,$updateParams:updateParams){
    updateBooking(id:$id,updateParams:$updateParams){
       providerRefId
    }
  }
`

export const useUpdateBooking = () => {
  const [updateBooking, { loading, data, error, refetch }] = useMutation(UPDATE_WEBSITE_BOOKING)


  useLoadingHandler(loading)

  const updateBookingHandler = (id, updateParams) => {
    console.log(id, updateParams)
    updateBooking({
      variables: {
        id,
        updateParams
      }
    })
  }


  return [updateBookingHandler, { data, error, refetch }]
}
