import React from "react";
import { useParams } from "react-router";
import { useGetHostelLogsById } from "../../../../api/Packages/Queries";

const LogsSection = () => {
  const { id } = useParams();

  const { data } = useGetHostelLogsById(id);

  const logs = data?.getHostelLogsById?.logs;

  return (
    <>
      {logs?.length > 0 && (
        <>
          <p>
            {" "}
            <strong> Logs</strong>
          </p>

          <ul>
            {logs.map((log, index) => <li key={index}>{log}</li>).reverse()}
          </ul>
        </>
      )}
    </>
  );
};

export default LogsSection;
