import React from "react";
import { FileText } from "react-feather";
import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";

const headers = [
  "Action",
  "Hostel Code",
  "Hostel Name",
  "Month",
  "Year",
  "Area Manager",
  "Op. Model",
  "Occ%",
  "ADR",
  "Bed Revenue",
  "F&B Revenue",
  "Ancillary Revenue",
  "Total OPEX",
  "Overall Op. Margin (in INR)",
  "Hostel margin (in INR)",
  "Hostel margin (in %)",
  "F&B margin (in INR)",
  "F&B margin (in %)",
  "Ancillary margin (in INR)",
  "Ancillary margin (in %)",
  "Analysis (Operational Margin %)",
];

const PLStatementTable = ({ data, openOverviewModal }) => {
  // Function to format percentage values
  const formatPercentage = (value) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      return `${(numValue * 100).toFixed(2)}%`;
    }
    console.log(value, "value is not a valid number");
    return "N/A";
  };

  const extractFieldValue = (fields, fieldName) => {
    if (!fields || !Array.isArray(fields)) {
      console.warn("Invalid fields array:", fields);
      return "N/A";
    }

    const field = fields.find((item) => item.fieldName === fieldName);
    if (!field) {
      console.info(`Field not found: ${fieldName}`);
      return "N/A";
    }

    const value = field.fieldValue;

    const percentageFields = [
      "Occ%",
      "Hostel margin (in %)",
      "F&B margin (in %)",
      "Ancillary margin (in %)",
      "Analysis (Operational Margin %",
    ];

    if (percentageFields.includes(fieldName)) {
      return formatPercentage(value);
    }

    return value !== undefined && value !== null ? value : "N/A";
  };

  if (!data || data.length === 0) {
    console.warn("No data available to display in the table.");
    return <p>No data available</p>;
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <Button.Ripple
            color="light"
            onClick={(e) => {
              e.preventDefault();
              openOverviewModal();
            }}
          >
            Create P&L Statement
          </Button.Ripple>
        </CardHeader>
      </Card>

      <Table bordered responsive>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => {
            return (
              <tr key={item._id}>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => openOverviewModal(item._id)}
                  >
                    <FileText size={14} />
                  </Button>
                </td>
                <td>{item.hostelCode}</td>
                <td>{item.hostelName || "N/A"}</td>
                <td>{item.month}</td>
                <td>{item.year}</td>
                <td>{item.operationalModel}</td>
                <td>{item.areaManager}</td>
                <td>{extractFieldValue(item.fields, "Occ%")}</td>
                <td>{extractFieldValue(item.fields, "ADR")}</td>
                <td>{extractFieldValue(item.fields, "Bed Revenue")}</td>
                <td>{extractFieldValue(item.fields, "F&B Revenue")}</td>
                <td>{extractFieldValue(item.fields, "Ancillary Revenue")}</td>
                <td>{extractFieldValue(item.fields, "Total OPEX")}</td>
                <td>
                  {extractFieldValue(
                    item.fields,
                    "Overall Op. Margin (in INR)"
                  )}
                </td>
                <td>
                  {extractFieldValue(item.fields, "Hostel margin (in INR)")}
                </td>
                <td>
                  {extractFieldValue(item.fields, "Hostel margin (in %)")}
                </td>
                <td>{extractFieldValue(item.fields, "F&B margin (in INR)")}</td>
                <td>{extractFieldValue(item.fields, "F&B margin (in %)")}</td>
                <td>
                  {extractFieldValue(item.fields, "Ancillary margin (in INR)")}
                </td>
                <td>
                  {extractFieldValue(item.fields, "Ancillary margin (in %)")}
                </td>
                <td>
                  {extractFieldValue(
                    item.fields,
                    "Analysis (Operational Margin %"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PLStatementTable;

//collapsed expand table

// import React, { useState } from "react";
// import { FileText, PlusCircle, MinusCircle } from "react-feather";
// import { Card, CardHeader, Button, Table } from "reactstrap";

// const headers = [
//   {
//     section: "Hostel Details",
//     subHeaders: ["Hostel Name", "Area Manager", "Op. Model"],
//   },
//   {
//     section: "Revenue",
//     subHeaders: [
//       "Occ%",
//       "ADR",
//       "Bed Revenue",
//       "F&B Revenue",
//       "Ancillary Revenue",
//     ],
//   },
//   { section: "Expenses", subHeaders: ["Total OPEX"] },
//   {
//     section: "Margin Analysis",
//     subHeaders: [
//       "Overall Op. Margin (in INR)",
//       "Hostel margin (in INR)",
//       "Hostel margin (in %)",
//       "F&B margin (in INR)",
//       "F&B margin (in %)",
//       "Ancillary margin (in INR)",
//       "Ancillary margin (in %)",
//       "Analysis (Operational Margin %)",
//     ],
//   },
// ];

// const PLStatementTable = ({ data, openOverviewModal }) => {
//   const [expandedSections, setExpandedSections] = useState({});

//   const toggleSection = (section) => {
//     setExpandedSections((prev) => ({
//       ...prev,
//       [section]: !prev[section],
//     }));
//   };

//   // Function to format percentage values
//   const formatPercentage = (value) => {
//     const numValue = parseFloat(value);
//     if (!isNaN(numValue)) {
//       return `${(numValue * 100).toFixed(2)}%`;
//     }
//     return "N/A";
//   };

//   const extractFieldValue = (fields, fieldName) => {
//     if (!fields || !Array.isArray(fields)) {
//       console.warn("Invalid fields array:", fields);
//       return "N/A";
//     }

//     const field = fields.find((item) => item.fieldName === fieldName);
//     if (!field) {
//       console.info(`Field not found: ${fieldName}`);
//       return "N/A";
//     }

//     const value = field.fieldValue;

//     const percentageFields = [
//       "Occ%",
//       "Hostel margin (in %)",
//       "F&B margin (in %)",
//       "Ancillary margin (in %)",
//       "Analysis (Operational Margin %",
//     ];

//     if (percentageFields.includes(fieldName)) {
//       return formatPercentage(value);
//     }

//     return value !== undefined && value !== null ? value : "N/A";
//   };

//   if (!data || data.length === 0) {
//     return <p>No data available</p>;
//   }

//   const renderSubHeaders = (section) => {
//     const header = headers.find((h) => h.section === section);
//     if (!header) return null;

//     return expandedSections[section] ? (
//       header.subHeaders.map((subHeader, index) => (
//         <th key={index}>{subHeader}</th>
//       ))
//     ) : (
//       <th>{header.subHeaders[0]}</th> // Show only the first column in collapsed view
//     );
//   };

//   const renderRowData = (item, section) => {
//     const header = headers.find((h) => h.section === section);
//     if (!header) return null;

//     if (expandedSections[section]) {
//       return header.subHeaders.map((fieldName, index) => {
//         if (fieldName === "Hostel Name") {
//           return <td key={index}>{item.hostelName || "N/A"}</td>;
//         }
//         if (fieldName === "Area Manager") {
//           return <td key={index}>{item.areaManager || "N/A"}</td>;
//         }
//         if (fieldName === "Op. Model") {
//           return <td key={index}>{item.operationalModel || "N/A"}</td>;
//         }
//         return <td key={index}>{extractFieldValue(item.fields, fieldName)}</td>;
//       });
//     }

//     // Show only the first field in collapsed view
//     if (header.subHeaders[0] === "Hostel Name") {
//       return <td>{item.hostelName || "N/A"}</td>;
//     }
//     if (header.subHeaders[0] === "Area Manager") {
//       return <td>{item.areaManager || "N/A"}</td>;
//     }
//     if (header.subHeaders[0] === "Op. Model") {
//       return <td>{item.operationalModel || "N/A"}</td>;
//     }

//     return <td>{extractFieldValue(item.fields, header.subHeaders[0])}</td>;
//   };

//   return (
//     <div>
//       <Card>
//         <CardHeader>
//           <Button.Ripple
//             color="light"
//             onClick={(e) => {
//               e.preventDefault();
//               openOverviewModal();
//             }}
//           >
//             Create P&L Statement
//           </Button.Ripple>
//         </CardHeader>
//       </Card>

//       <Table bordered responsive>
//         <thead>
//           <tr>
//             <th style={{ textAlign: "center", fontWeight: "bold" }}>Action</th>{" "}
//             {/* Centered and bold Action */}
//             <th style={{ textAlign: "center", fontWeight: "bold" }}>
//               Month - Year
//             </th>{" "}
//             {/* Centered and bold Month - Year */}
//             {headers.map((header, index) => (
//               <th
//                 key={index}
//                 colSpan={
//                   expandedSections[header.section]
//                     ? header.subHeaders.length
//                     : 1
//                 }
//                 style={{ textAlign: "center", fontWeight: "bold" }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     padding: "0 5px",
//                   }}
//                 >
//                   <span>{header.section}</span>
//                   <Button
//                     color="link"
//                     size="sm"
//                     onClick={() => toggleSection(header.section)}
//                     style={{
//                       padding: 0,
//                       fontSize: "18px",
//                       cursor: "pointer",
//                     }}
//                   >
//                     {expandedSections[header.section] ? (
//                       <MinusCircle size={20} color="green" />
//                     ) : (
//                       <PlusCircle size={20} color="blue" />
//                     )}
//                   </Button>
//                 </div>
//               </th>
//             ))}
//           </tr>
//           <tr>
//             <th></th>
//             <th></th>
//             {headers.map((header, index) => (
//               <React.Fragment key={index}>
//                 {renderSubHeaders(header.section)}
//               </React.Fragment>
//             ))}
//           </tr>
//         </thead>

//         <tbody>
//           {data.map((item, rowIndex) => (
//             <tr key={item._id}>
//               <td>
//                 <Button
//                   color="primary"
//                   size="sm"
//                   onClick={() => openOverviewModal(item._id)}
//                 >
//                   <FileText size={14} />
//                 </Button>
//               </td>
//               <td>{`${item.month} - ${item.year}`}</td>
//               {headers.map((header, index) => (
//                 <React.Fragment key={index}>
//                   {renderRowData(item, header.section)}
//                 </React.Fragment>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default PLStatementTable;

//centered header
// <thead>
//   <tr>
//     <th style={{ textAlign: "center", fontWeight: "bold" }}>Action</th>{" "}
//     <th style={{ textAlign: "center", fontWeight: "bold" }}>Month - Year</th>{" "}
//     {headers.map((header, index) => (
//       <th
//         key={index}
//         colSpan={
//           expandedSections[header.section] ? header.subHeaders.length : 1
//         }
//         style={{
//           textAlign: "center",
//           fontWeight: "bold",
//           position: "relative", // to position the button at the end
//         }}
//       >
//         <span style={{ display: "block" }}>{header.section}</span>
//         {/* Button at the right end */}
//         <Button
//           color="link"
//           size="sm"
//           onClick={() => toggleSection(header.section)}
//           style={{
//             position: "absolute", // position button at the right end
//             right: "5px", // adjust as necessary
//             top: "50%",
//             transform: "translateY(-50%)", // vertically center the button
//             padding: 0,
//             fontSize: "18px",
//             cursor: "pointer",
//           }}
//         >
//           {expandedSections[header.section] ? (
//             <MinusCircle size={20} color="green" />
//           ) : (
//             <PlusCircle size={20} color="blue" />
//           )}
//         </Button>
//       </th>
//     ))}
//   </tr>
//   <tr>
//     <th></th>
//     <th></th>
//     {headers.map((header, index) => (
//       <React.Fragment key={index}>
//         {renderSubHeaders(header.section)}
//       </React.Fragment>
//     ))}
//   </tr>
// </thead>

//older structure
// import React from "react";
// import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";
// import { FileText } from "react-feather";
// import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

// const headers = [
//   "Action",
//   "Hostel Code",
//   "Hostel Name",
//   "Age (in months)",
//   "Op. Model",
//   "Area Manager",
//   "No. of rooms",
//   "No. of beds",
//   "Total Inventory",
//   "Blocked Inventory",
//   "Total Revenue",
// ];

// const PLStatementTable = ({ data, openOverviewModal }) => {
//   return (
//     <div>
//       <Card>
//         <CardHeader>
//           <Button.Ripple
//             color="light"
//             onClick={(e) => {
//               e.preventDefault();
//               openOverviewModal();
//             }}
//           >
//             Create P&L Statement
//           </Button.Ripple>
//         </CardHeader>
//       </Card>

//       <div>
//         <Table hover>
//           <thead style={{}}>
//             <tr>
//               {headers.map((headerItem) => (
//                 <th key={uuidv4()}>{headerItem}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={item._id || index}>
//                 <td>
//                   <Button
//                     color="primary"
//                     size="sm"
//                     onClick={() => openOverviewModal(item._id)}
//                   >
//                     <FileText size={14} className={reactFeatherIcon} />
//                   </Button>
//                 </td>
//                 <td>{item.hostelCode}</td>
//                 <td>{item?.hostelName}</td>
//                 <td>{item?.hostelAgeInMonths}</td>
//                 <td>{item?.operationModel}</td>
//                 <td>{item?.areaManager}</td>
//                 <td>{item?.numberOfRooms}</td>
//                 <td>{item?.numberOfBeds}</td>
//                 <td>{item?.totalInventory}</td>
//                 <td>{item?.blockedInventory}</td>
//                 <td>{item?.totalRevenue}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </div>
//     </div>
//   );
// };

// export default PLStatementTable;
