import { Package, Circle } from "react-feather";

const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

const filterModules = (role, modules) => {
  try {
    if (modules?.includes("Finance")) {
      return [
        {
          id: "investorData",
          title: "Investor Data",
          icon: <Circle size={12} />,
          navLink: "/investorData",
        },
      ];
    }
  } catch (error) {
    console.error(error);
  }
};

export default modules?.includes("Finance")
  ? [
      {
        id: "finance",
        title: "Finance",
        icon: <Package size={20} />,
        badge: "light-warning",
        children:
          filterModules(role, modules)?.length > 0
            ? filterModules(role, modules)
            : null,
      },
    ]
  : [];
