import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Select from "react-select";
import SlideModal from "@components/common/slide-modal";
import SwitchIcon from "@components/common/switch-icon";
import {
  useCreateTravelPackage,
  useHostelList,
  useGetAllRooms,
  useUpdateTravelPackage,
} from "@api";
import toaster from "@components/common/Toaster";
import Flatpickr from "react-flatpickr";
import { dateFormatter, addDays } from "@utils";

const AddEditModal = ({
  open,
  handleToggle,
  refetch,
  editData,
  selectedHostel,
  setRetchTravelPackages,
}) => {
  const [createTravelPackageHandler, { data: createTravelPackageData }] =
    useCreateTravelPackage();
  const [updateTravelPackageHandler, { data: updateTravelPackageData }] =
    useUpdateTravelPackage();

  const { data: hostelData } = useHostelList();

  const [status, setStatus] = useState(false);
  const [roomType, setRoomType] = useState(null);
  const [hostel, setHostel] = useState(null);
  const [hostelList, setHostelList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const membershipList = [
    { label: "EXPLORER", value: "EXPLORER" },
    { label: "VOYAGER", value: "VOYAGER" },
    { label: "WANDERER", value: "WANDERER" },
  ];
  const [memberShipType, setMemberShipType] = useState(null);
  const [checkInDate, setCheckInDate] = useState(dateFormatter(new Date()));
  const [checkOutDate, setCheckOutDate] = useState(
    dateFormatter(addDays(dateFormatter(new Date()), 1))
  );
  const [meals, setMeals] = useState("");

  useEffect(() => {
    selectedHostel && setHostel(selectedHostel);
  }, [selectedHostel]);

  const { data: allRoomsData } = useGetAllRooms(
    hostel?.value,
    Boolean(!hostel?.value)
  );

  const toggle = () => {
    open && handleToggle();

    setRoomType(null);
    setMemberShipType(null);
    setMeals("");
    setCheckInDate(dateFormatter(new Date()));
    setCheckOutDate(dateFormatter(addDays(dateFormatter(new Date()), 1)));
    setHostel(null);
    setStatus(false);
    setRoomList([]);
  };

  useEffect(() => {
    if (!open) toggle();
  }, [open]);

  useEffect(() => {
    if (hostel?.value) {
      if (allRoomsData?.getAllRooms?.length) {
        allRoomsData?.getAllRooms?.map((item) =>
          setRoomList((prev) => [
            ...prev,
            { label: item.Name, value: item.RoomTypeID },
          ])
        );
      }
    } else {
      setRoomList([]);
    }
  }, [hostel?.value, allRoomsData?.getAllRooms]);

  useEffect(() => {
    let hostelList = [];
    if (hostelData?.getHostelList?.length) {
      hostelData?.getHostelList?.map((item) =>
        hostelList.push({ value: item._id, label: item?.name })
      );
      setHostelList(hostelList);
    }
  }, [hostelData?.getHostelList]);

  useEffect(() => {
    if (createTravelPackageData || updateTravelPackageData) {
      refetch();
      toggle();

      setRetchTravelPackages && setRetchTravelPackages(true);
    }
  }, [createTravelPackageData, updateTravelPackageData]);

  useEffect(() => {
    if (open && editData?._id) {
      setHostel({
        value: editData?.hostelId?._id,
        label: editData?.hostelId?.name,
      });
      setMemberShipType({
        value: editData?.memberShipType,
        label: editData?.memberShipType,
      });
      setRoomType({ value: editData?.roomId, label: editData?.roomName });
      setCheckInDate(dateFormatter(new Date(editData?.checkInDate)));
      setCheckOutDate(dateFormatter(new Date(editData?.checkOutDate)));
      setMeals(editData?.meal);
      setStatus(editData?.isActive);
    } else {
      toggle();
    }
  }, [editData]);

  const submitDataHandler = () => {
    if (new Date(checkOutDate).getTime() <= new Date(checkInDate).getTime()) {
      toaster("error", "Checkout date should be greater than checkin date");
    } else if (
      hostel &&
      roomType &&
      checkInDate &&
      checkOutDate &&
      memberShipType &&
      meals
    ) {
      let data = {
        hostelId: hostel?.value,
        memberShipType: memberShipType?.value,
        roomName: roomType?.label,
        roomId: roomType?.value,
        checkInDate,
        checkOutDate,
        meal: meals,
        isActive: status,
      };

      if (editData?._id) {
        updateTravelPackageHandler(editData?._id, data);
        toaster("success", "Travel package updated successfully");
      } else {
        createTravelPackageHandler(data);
        toaster("success", "Travel package added successfully");
      }
    } else {
      toaster("error", "Please fill all required fields");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form onSubmit={(e) => console.log(e)}>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="room">Membership Type *</Label>
              <Select
                isClearable={false}
                id="memberShipType"
                name="memberShipType"
                value={memberShipType}
                isDisabled={editData ? true : false}
                options={membershipList}
                onChange={(value) => setMemberShipType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label for="hostel">Hostel *</Label>
              <Select
                isClearable={false}
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelList}
                isDisabled={editData ? true : false}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label for="room">Room Type *</Label>
              <Select
                isClearable={false}
                id="room"
                name="room"
                value={roomType}
                options={roomList}
                isDisabled={editData ? true : false}
                onChange={(value) => setRoomType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="checkInDate">
                Check In Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={checkInDate}
                onChange={(date) => setCheckInDate(dateFormatter(date))}
                id="checkInDate"
                placeholder="Check In Date"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="checkOutDate">
                Check Out Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={checkOutDate}
                onChange={(date) => setCheckOutDate(dateFormatter(date))}
                id="checkOutDate"
                placeholder="Check Out Date"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="promo code">
                Meals *
              </Label>
              <Input
                type="text"
                value={meals}
                id="meals"
                name="meals"
                placeholder="Add Meals"
                onChange={(e) => setMeals(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(val) => setStatus(val)}
            />
          </Col>

          {editData?.logs?.length > 0 && (
            <Col sm="12">
              <FormGroup className="w-100 mt-2">
                <strong>Logs:</strong>

                {editData?.logs
                  .map((log, index) => (
                    <Label className="form-label" for="name">
                      <li key={index}>{log}</li>
                    </Label>
                  ))
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
