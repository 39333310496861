import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import {
  useGetEmployeeProfileForAttendance,
  useGetHostelsNameandId,
} from "../../../api/dataSource";
import { useGetEmployeeProfileForRoster } from "../../../api/EmployeeRoster/Queries";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import EmployeeAttendanceTable from "./EmployeeAttendanceTable";
import EmployeeRosterTable from "./EmployeeRosterTable";
import "./style.css";

const YearList = [
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
];
const MonthsList = [
  { label: "January", value: "1" },
  { label: "February ", value: "2" },
  { label: "March", value: "3" },
  { label: "April ", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const EmployeeAttendance = () => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [year, setYear] = useState({ label: "2025", value: "2025" });
  const [month, setMonth] = useState({
    label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
      .label,
    value: new Date().getMonth() + 1,
  });
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const { data: allHostelsData } = useGetHostelsNameandId();
  const { data: employeeProfileData, refetch: refetchEmployeeProfile } =
    useGetEmployeeProfileForAttendance(
      selectedHostel,
      selectedMonth,
      selectedYear
    );

  const {
    data: employeeProfileRosterData,
    refetch: refetchEmployeeProfileRoster,
  } = useGetEmployeeProfileForRoster(
    selectedHostel,
    selectedMonth,
    selectedYear
  );

  const [dateArray, setDateArray] = useState([]);

  const getDatesOfMonth = (year, month) => {
    // Create the start date of the month
    const startDate = new Date(year, month - 1, 1);

    // Create the end date of the month
    const endDate = new Date(year, month, 0);

    // Initialize a variable to track the current date
    const currentDateOfMonth = new Date(startDate);

    // Initialize an array to store date objects
    var datesArray = [];

    // Iterate through each day of the month
    while (currentDateOfMonth <= endDate) {
      // Push a date object to the datesArray with label and key properties
      datesArray.push({
        label: currentDateOfMonth.toLocaleDateString("en-CA"),
        key: currentDateOfMonth.toLocaleDateString("en-CA"),
      });

      // Move to the next day
      currentDateOfMonth.setDate(currentDateOfMonth.getDate() + 1);
    }

    // Set the tableHeaderData state with an array containing date labels and keys
    setTableHeaderData([
      { label: "Emp Code", key: "empCode" },
      { label: "Emp Name", key: "empName" },
      { label: "DOJ", key: "doj" },
      { label: "Sub Dpmt", key: "subDepartment" },
      { label: "Designation", key: "designation" },
      { label: "Live Status", key: "empStatus" },
      { label: "PL", key: "pl" },
      { label: "CL", key: "cl" },
      ...datesArray, // Include datesArray in the table header
    ]);

    // Set the dateArray state with the generated datesArray
    setDateArray(datesArray);
  };

  useEffect(() => {
    // Map the hostels data to create an array of objects with label and value properties
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));

    // Check if HostelsList has length
    if (HostelsList?.length) {
      // Retrieve allowed hostels from local storage
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));

      // Filter HostelsList based on allowedHostels
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;

      // Set the state with the filtered hostels
      setHostelsList(filteredHostel);
    }
  }, [allHostelsData]);

  const [shouldRefetch, setShouldRefetch] = useState(false);

  useEffect(() => {
    // Check if shouldRefetch is true
    if (shouldRefetch) {
      // Trigger the refetch of employee profile data
      refetchEmployeeProfile();

      // Trigger the refetch of employee profile roster data
      refetchEmployeeProfileRoster();

      // Reset the shouldRefetch state to false
      setShouldRefetch(false);
    }
  }, [shouldRefetch, refetchEmployeeProfile, refetchEmployeeProfileRoster]);

  const submitHandler = (year, month) => {
    getDatesOfMonth(year, month);
    setSelectedHostel(hostel?.value);
    setSelectedMonth(month);
    setSelectedYear(year);
    setShouldRefetch(true);
  };

  return (
    localStorage.getItem("modules")?.includes("Employee Attendance") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="year">
                Year
              </Label>
              <Select
                isSearchable={true}
                id="year"
                name="year"
                options={YearList}
                required
                value={year}
                onChange={(value) => setYear(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="month">
                Months
              </Label>
              <Select
                isSearchable={true}
                id="month"
                name="month"
                options={MonthsList}
                required
                value={month}
                onChange={(value) => setMonth(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="2 p-2">
            <Button.Ripple
              type="submit"
              color={`${hostel ? `light` : `secondary`}`}
              appearance="success"
              onClick={() => {
                submitHandler(Number(year?.value), Number(month?.value));
              }}
            >
              Search
            </Button.Ripple>
          </Col>
        </Row>
        {year && month && hostel && (
          <EmployeeAttendanceTable
            tableHeaderData={tableHeaderData}
            employeeProfileData={employeeProfileData}
            selectedHostel={selectedHostel}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            dateArray={dateArray}
            allHostelsData={allHostelsData}
            refetchEmployeeProfile={refetchEmployeeProfile}
          />
        )}

        {/* {year && month && hostel && (
          <EmployeeRosterTable
            // tableHeaderData={tableHeaderData}
            employeeProfileRosterData={employeeProfileRosterData}
            selectedHostel={selectedHostel}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            dateArray={dateArray}
            // allHostelsData={allHostelsData}
            // refetchEmployeeProfileRoster={refetchEmployeeProfileRoster}
          />
        )} */}
      </div>
    )
  );
};

export default EmployeeAttendance;
