import React from "react";

import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Table } from "reactstrap";
import { Card, Col, DropdownItem, Row, UncontrolledDropdown } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

import { HostelRoomRatesTable } from "./HostelRoomRatesTable";
import { useGetAllColivingList } from "../../api/Coliving/Queries";

const tableHeaderData = ["Hostel", "Actions"];
const HostelWiseTable = ({ hostelList }) => {
  const [tableData, setTableData] = useState([]);
  const [selectId, setSelectedId] = useState(null);

  const [fetchColivingHandler, { data: colivingList, error, refetch }] =
    useGetAllColivingList();

  // useEffect(() => {
  //   refetch();
  // }, [selectId, refetch]);

  useEffect(() => {
    if (hostelList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return hostelList?.find((hostel) => hostel?._id === hostelID);
            })
          : hostelList;
      setTableData(filteredHostel);
    }
  }, [hostelList]);
  return (
    <Row>
      <Col sm="4">
        <Card>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr
                  key={uuidv4()}
                  onClick={() => {
                    setSelectedId(item?._id);
                    fetchColivingHandler(item?._id);
                  }}
                  className={selectId === item?._id ? "bg-primary" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <b>{item?.name}</b>
                  </td>
                  {/* <td></td> */}
                  <td>
                    <UncontrolledDropdown>
                      <DropdownItem>
                        <ChevronRight className="mr-50" size={17} />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
              {/*  */}
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col sm="8">
        <HostelRoomRatesTable
          colivingList={colivingList}
          hostelId={selectId}
          //refetch={refetch}
        />
      </Col>
    </Row>
  );
};

export default HostelWiseTable;

// export const HostelWiseTable = () => {
//   return (
//     <div>HostelWiseTable</div>
//   )
// }
