import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import Select from "react-select";
import {
  useAddMessageTemplate,
  useUpdateMessageTemplate,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [templateType, setTemplateType] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateMessage, setTemplateMessage] = useState("");
  const [templateCode, setTemplateCode] = useState("");
  const [urlParameter, setUrlParameter] = useState("");
  const [mobileNo, setMobileNo] = useState(null);
  const [templateUrlCode, setTemplateUrlCode] = useState(null);

  const [addMessageTemplateHandler, { data }] = useAddMessageTemplate();

  const [updateMessageTemplateHandler, { data: updatedMessageTemplateData }] =
    useUpdateMessageTemplate();

  const TemplateTypeList = [
    // { label: "WhatsApp", value: "WhatsApp" },
    { label: "Text", value: "Text" },
    { label: "Media", value: "Media" },
  ];

  const templateUrlOptions = [
    { label: "Website", value: "Website" },
    { label: "Glu Home", value: "Gluhome" },
    { label: "Glu Services", value: "Gluservices" },
    { label: "Glu Addon", value: "Gluaddon" },
  ];

  useEffect(() => {
    if (
      data?.createMessageTemplate?.TemplateName ||
      updatedMessageTemplateData
    ) {
      refetch();
      handleToggle();

      toaster("success", "Template added successfully");
    }
  }, [data, updatedMessageTemplateData]);

  const submitDataHandler = () => {
    let data = {
      TemplateType: templateType.value,
      TemplateName: templateName,
      TemplateMessage: templateMessage,
      TemplateCode: templateCode,
      UrlParameter: urlParameter,
      templateUrlCode: templateUrlCode?.value,
      mobileNo: mobileNo ? mobileNo.split(",") : null,
    };

    if (data.TemplateName) {
      if (editData?.length) {
        const { _id } = editData[0];

        updateMessageTemplateHandler(_id, data);
      } else {
        addMessageTemplateHandler(data);
      }
      setTemplateType("");
      setTemplateName("");
      setTemplateMessage("");
      setTemplateCode("");
      setUrlParameter("");
      setMobileNo("");
      setTemplateUrlCode(null);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      const {
        TemplateType,
        TemplateName,
        TemplateMessage,
        TemplateCode,
        UrlParameter,
        templateUrlCode,
      } = editData[0];

      setTemplateType({ label: TemplateType, value: TemplateType });
      setTemplateName(TemplateName);
      setTemplateMessage(TemplateMessage);
      setTemplateCode(TemplateCode);
      setUrlParameter(UrlParameter);
      if (templateUrlCode) {
        const url = templateUrlOptions?.find(
          (el) => el?.value === templateUrlCode
        );
        setTemplateUrlCode(url);
      } else {
        setTemplateUrlCode(null);
      }
    } else {
      setTemplateType("");
      setTemplateName("");
      setTemplateMessage("");
      setTemplateCode("");
      setUrlParameter("");
      setTemplateUrlCode(null);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/*// ! Template Type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="templateType">
                Template type*
              </Label>
              <Select
                isClearable={false}
                id="templateType"
                name="templateType"
                value={templateType}
                options={TemplateTypeList}
                required
                onChange={(value) => setTemplateType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Template Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="templateName">
                Template name
              </Label>
              <Input
                type="text"
                value={templateName}
                id="templateName"
                name="templateName"
                placeholder="Add Template Name"
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* // ! Template Message */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="templateMessage">
                Template message
              </Label>
              <Input
                type="textarea"
                value={templateMessage}
                id="templateMessage"
                name="templateMessage"
                placeholder="Add Template Message"
                onChange={(e) => setTemplateMessage(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Template Code */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="templateCode">
                Template code (<i>Only msg code</i>)
                <br />
                <strong>
                  Note: For Guest status "All", use #HostelName# for Hostels and
                  #Name# for Guest name
                </strong>
              </Label>
              <Input
                type="textarea"
                value={templateCode}
                id="templateCode"
                name="templateCode"
                placeholder="Add Template Code"
                onChange={(e) => setTemplateCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* Template  Url Options */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="urlCode">
                Select Url Options
              </Label>
              <Select
                isClearable={false}
                id="urlCode"
                name="urlCode"
                value={templateUrlCode}
                options={templateUrlOptions}
                onChange={(value) => setTemplateUrlCode(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="templateName">
                Mobile Number (
                <i>
                  Only for instant messaging, enter multiple no. separated by
                  comma.
                </i>
                )
              </Label>
              <Input
                type="textarea"
                value={mobileNo}
                id="mobileNo"
                name="mobileNo"
                placeholder="Mobile No"
                onChange={(e) => setMobileNo(e.target.value.trim())}
              />
            </FormGroup>
          </Col>

          {/* // ! Url Parameter */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="urlParameter">
                Url Parameter
              </Label>
              <Input
                
                type="text"
                value={urlParameter}
                id="urlParameter"
                name="urlParameter"
                placeholder="Add Url Parameter"
                onChange={(e) => setUrlParameter(e.target.value)}
              /> 
            </FormGroup> 
          </Col>*/}

          {/* // ! Logs */}
          {editData?.length && editData[0]?.logs && (
            <Col sm="12">
              {/* <hr/> */}
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>

                <br />
                {editData[0]?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
