import React, { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
import Select from "react-select";
import { useCreateEmployeeRoster } from "../../../api/EmployeeRoster/Mutation";
import { useGetEmployeeRosterByHostelId } from "../../../api/EmployeeRoster/Queries";
import { useGetEmployeeAttendanceByHostelId } from "../../../api/dataSource";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RosterLogsModal from "./RosterLogsModal";

// const ErrorSwal = withReactContent(Swal);
const SuccessSwal = withReactContent(Swal);

const EmployeeRosterTable = ({
  employeeProfileData,
  selectedHostel,
  dateArray,
  selectedMonth,
  selectedYear,
}) => {
  const hostelId = selectedHostel;
  const [employeeRoster, setEmployeeRoster] = useState([
    {
      hostelId: "",
      empId: "",
      empCode: "",
      rosterDate: null,
      rosterSlot: "",
      rosterSelected: "",
      isExistingRecord: false, // Flag to track if the record is from database
    },
  ]);

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedEmployee: null,
  });

  const role = localStorage.getItem("role");

  const isAreaManager = role === "Area Manager";
  // const canModifyExistingRoster = ["Regional Manager", "CEO"].includes(role);

  const [createEmployeeRosterHandler, { data: createdResponse }] =
    useCreateEmployeeRoster();

  const { data: employeeRosterData, refetch: refetchRoster } =
    useGetEmployeeRosterByHostelId(hostelId, selectedMonth, selectedYear);

  const { data: employeeAttendanceData, refetch: refetchAttendance } =
    useGetEmployeeAttendanceByHostelId(null, selectedMonth, selectedYear);

  const timeSlots = [
    "07:00 - 17:00",
    "10:00 - 20:00",
    "15:00 - 01:00",
    "23:00 - 09:00",
  ];

  const workingTimeSlotList = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "WO", value: "WO" },
  ];

  // Function to check if employee was absent on a specific date
  const wasEmployeeAbsent = (empCode, date) => {
    const attendanceEntry =
      employeeAttendanceData?.getEmployeeAttendanceByHostelId?.find(
        (entry) =>
          entry.empCode === empCode &&
          new Date(entry.attendanceDate).toLocaleDateString("en-IN") === date
      );

    return attendanceEntry?.attendance === "A";
  };

  useEffect(() => {
    if (createdResponse?.createEmployeeRoster?.status === true) {
      SuccessSwal.fire({
        title: "Saved Successfully :)",
        icon: "success",
        draggable: true,
      });

      // Update all current records to be existing records after successful save
      setEmployeeRoster((prev) =>
        prev.map((roster) => ({ ...roster, isExistingRecord: true }))
      );
    }
    refetchRoster();
    refetchAttendance();
  }, [createdResponse]);

  useEffect(() => {
    if (employeeRosterData) {
      const formattedRoster =
        employeeRosterData?.getEmployeeRosterByHostelId.map((roster) => ({
          hostelId: roster.hostelId?._id || roster.hostelId,
          empId: roster.empId?._id || roster.empId,
          empCode: roster.empCode,
          rosterDate: new Date(roster.rosterDate),
          rosterSlot: roster.rosterSlot,
          rosterSelected: roster.rosterSelected,
          isExistingRecord: true, // Mark records from database as existing
        }));
      setEmployeeRoster(formattedRoster || []);
    }
  }, [employeeRosterData]);

  const formattedDates = dateArray.map((date) => ({
    day: new Date(date?.label).toLocaleString("en-US", { weekday: "long" }),
    formattedDate: new Date(date?.label).toLocaleDateString("en-IN"),
  }));

  const headerStyle = {
    textAlign: "center",
    fontSize: "12px",
    whiteSpace: "nowrap",
    padding: "8px",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    height: "60px",
  };

  // Modified custom styles for react-select
  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     // minHeight: "30px",
  //     // height: "30px",
  //     backgroundColor:
  //       state.getValue()?.[0]?.value === "Yes"
  //         ? "#dcfce7"
  //         : state.getValue()?.[0]?.value === "Wo"
  //         ? "#ffedd5"
  //         : "white",
  //     opacity: state.isDisabled ? 0.7 : 1,
  //   }),
  //   // menu: (provided) => ({
  //   //   ...provided,
  //   //   position: "absolute",
  //   //   zIndex: 9999,
  //   //   maxHeight: "200px",
  //   //   width: "100%",
  //   //   right: "auto",
  //   //   left: "0",
  //   // }),
  //   // menuPortal: (base) => ({
  //   //   ...base,
  //   //   zIndex: 9999,
  //   //   pointerEvents: "auto",
  //   // }),
  //   // option: (base, state) => ({
  //   //   ...base,
  //   //   backgroundColor:
  //   //     state.value === "Yes"
  //   //       ? "#dcfce7"
  //   //       : state.value === "Wo"
  //   //       ? "#ffedd5"
  //   //       : state.isSelected
  //   //       ? "#e2e8f0"
  //   //       : "white",
  //   //   color: "black",
  //   //   "&:hover": {
  //   //     backgroundColor:
  //   //       state.value === "Yes"
  //   //         ? "#bbf7d0"
  //   //         : state.value === "Wo"
  //   //         ? "#fed7aa"
  //   //         : "#f1f5f9",
  //   //   },
  //   // }),
  //   // singleValue: (base, { data }) => ({
  //   //   ...base,
  //   //   color:
  //   //     data.value === "Yes"
  //   //       ? "#166534"
  //   //       : data.value === "Wo"
  //   //       ? "#9a3412"
  //   //       : "black",
  //   // }),
  // };

  // Modified custom styles for react-select to include attendance-based coloring
  const getCustomStyles = (empCode, date, value) => ({
    control: (base, state) => ({
      ...base,
      backgroundColor:
        wasEmployeeAbsent(empCode, date) &&
        state.getValue()?.[0]?.value === "Yes"
          ? "#ffcccc" // Red background for absent but marked as working
          : state.getValue()?.[0]?.value === "Yes"
          ? "#dcfce7"
          : state.getValue()?.[0]?.value === "WO"
          ? "#ffedd5"
          : "white",
      opacity: state.isDisabled ? 0.7 : 1,
    }),
  });

  const handleWorkingTimeSlotChange = (
    empCode,
    dateKey,
    value,
    employee,
    slot
  ) => {
    const [dateIndex] = dateKey.split("-");
    const formattedDate = formattedDates[dateIndex].formattedDate;
    const date = convertDateFormat(formattedDate);

    setEmployeeRoster((prevRoster) => {
      // Remove existing roster entry for this employee, date and slot if exists
      const filteredRoster = prevRoster.filter(
        (r) =>
          !(
            r.empCode === empCode &&
            r.rosterDate?.toDateString() === new Date(date).toDateString() &&
            r.rosterSlot === slot
          )
      );

      // Add new roster entry if value is selected
      if (value?.value) {
        return [
          ...filteredRoster,
          {
            hostelId: employee?.hostel?._id || employee?.hostel,
            empId: employee?._id || employee?.empId,
            empCode: employee?.empCode,
            rosterDate: new Date(date),
            rosterSlot: slot,
            rosterSelected: value.value,
            isExistingRecord: false, // Mark new selections as non-existing
          },
        ];
      }

      return filteredRoster;
    });
  };

  function convertDateFormat(dateStr) {
    const [day, month, year] = dateStr.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  const handleSubmit = (dateIndex, formattedDate) => {
    const dateToSubmit = new Date(convertDateFormat(formattedDate));

    const rosterToSubmit = employeeRoster
      .filter(
        (roster) =>
          roster.rosterDate?.toDateString() === dateToSubmit.toDateString()
      )
      .map(({ isExistingRecord, ...roster }) => ({
        ...roster,
        hostelId: roster.hostelId?._id || roster.hostelId,
        empId: roster.empId?._id || roster.empId,
      }));

    // const employeeDateMap = {};
    // for (const entry of rosterToSubmit) {
    //   const key = `${entry.empId}-${entry.rosterDate.toISOString()}`;
    //   if (!employeeDateMap[key]) {
    //     employeeDateMap[key] = 0;
    //   }
    //   if (entry.rosterSelected === "Yes") {
    //     employeeDateMap[key]++;
    //     if (employeeDateMap[key] > 1) {
    //       ErrorSwal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: "Select only one working slot per employee or ensure no duplicate entries.",
    //       });
    //       return;
    //     }
    //   }
    // }

    createEmployeeRosterHandler(rosterToSubmit);
  };

  const getRosterSlotForCell = (empCode, date, slot) => {
    const rosterEntry = employeeRoster.find(
      (roster) =>
        roster.empCode === empCode &&
        new Date(roster.rosterDate).toLocaleDateString("en-IN") === date &&
        roster.rosterSlot === slot
    );

    return rosterEntry?.rosterSelected;
  };

  const isSlotDisabled = (empCode, date, slot) => {
    if (!isAreaManager) return false;

    const rosterEntry = employeeRoster.find(
      (roster) =>
        roster.empCode === empCode &&
        new Date(roster.rosterDate).toLocaleDateString("en-IN") === date &&
        roster.rosterSlot === slot
    );

    // Disable only if it's an existing record from database
    return rosterEntry?.isExistingRecord === true;
  };

  // Handler for opening modal with specific employee data
  const handleOpenLogs = (employee) => {
    setModalState({
      isOpen: true,
      selectedEmployee: employee,
    });
  };

  // Handler for closing modal
  const handleCloseLogs = () => {
    setModalState({
      isOpen: false,
      selectedEmployee: null,
    });
  };

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Row>
        {selectedHostel && (
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  Employee Roster (count:{" "}
                  {employeeProfileData?.getEmployeeProfileForRoster?.length ||
                    0}
                  )
                </CardTitle>
              </CardHeader>
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                  position: "relative",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* Fixed Left Columns */}
                  <div
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      //backgroundColor: "white",
                    }}
                  >
                    <Table
                      responsive
                      bordered
                      style={{
                        minWidth: "250px",
                        borderCollapse: "collapse",
                        margin: 0,
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ ...headerStyle, height: "35px" }}>
                            Emp. Code
                          </th>
                          <th style={{ ...headerStyle, height: "35px" }}>
                            Emp. Name
                          </th>
                          <th style={{ ...headerStyle, height: "35px" }}>
                            Sub-Dept
                          </th>
                          <th style={{ ...headerStyle, height: "35px" }}>
                            Designation
                          </th>
                          {/* <th style={{ ...headerStyle, height: "35px" }}>View Logs</th> */}
                        </tr>
                        <tr>
                          <th style={{ ...headerStyle, height: "35px" }}></th>
                          <th style={{ ...headerStyle, height: "35px" }}></th>
                          <th style={{ ...headerStyle, height: "35px" }}></th>
                          <th style={{ ...headerStyle, height: "35px" }}></th>
                          {/* <th style={{ ...headerStyle, height: "35px" }}></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {employeeProfileData?.getEmployeeProfileForRoster?.map(
                          (employee, index) => (
                            <tr key={index}>
                              <td style={headerStyle}>{employee.empCode}</td>
                              <td style={headerStyle}>{employee.fullName}</td>
                              <td style={headerStyle}>
                                {employee.subDepartment}
                              </td>
                              <td style={headerStyle}>
                                {employee.designation}
                              </td>
                              {/* <td>
                                <button
                                  onClick={() => handleOpenLogs(employee)}
                                  style={{
                                    width: "100px",
                                    padding: "5px",
                                    backgroundColor: "#28c76f",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Logs
                                </button>
                              </td> */}
                            </tr>
                          )
                        )}
                        {/* Save button row */}
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              // backgroundColor: "#f8f9fa",
                              ...headerStyle,
                            }}
                          >
                            Save Changes
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  {/* Scrollable Right Columns */}
                  <div
                    style={{
                      overflowX: "auto",
                      width: "calc(100% - 250px)",
                    }}
                  >
                    <Table
                      responsive
                      bordered
                      style={{
                        minWidth: "1200px",
                        borderCollapse: "collapse",
                        margin: 0,
                      }}
                    >
                      <thead>
                        <tr>
                          {formattedDates.map((date, index) => (
                            <th
                              key={index}
                              colSpan={timeSlots.length}
                              style={{ ...headerStyle, height: "35px" }}
                            >
                              {date.day} ({date.formattedDate})
                            </th>
                          ))}
                          <th style={{ ...headerStyle, height: "35px" }}>
                            View Logs
                          </th>
                        </tr>
                        <tr>
                          {formattedDates.map(() =>
                            timeSlots.map((slot, index) => (
                              <th
                                key={index}
                                style={{ ...headerStyle, height: "35px" }}
                              >
                                {slot}
                              </th>
                            ))
                          )}
                          <th style={{ ...headerStyle, height: "35px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeProfileData?.getEmployeeProfileForRoster?.map(
                          (employee, index) => (
                            <tr key={index}>
                              {formattedDates.map((date, dateIndex) =>
                                timeSlots.map((slot, slotIndex) => {
                                  const dateKey = `${dateIndex}-${slotIndex}`;
                                  const value = getRosterSlotForCell(
                                    employee.empCode,
                                    date.formattedDate,
                                    slot
                                  );
                                  const isDisabled = isSlotDisabled(
                                    employee.empCode,
                                    date.formattedDate,
                                    slot
                                  );
                                  return (
                                    <td
                                      key={slotIndex}
                                      style={{
                                        ...headerStyle,
                                        position: "relative",
                                        minWidth: "100px",
                                      }}
                                    >
                                      <Select
                                        isClearable={false}
                                        isDisabled={isDisabled}
                                        value={
                                          value ? { label: value, value } : null
                                        }
                                        onChange={(val) =>
                                          handleWorkingTimeSlotChange(
                                            employee.empCode,
                                            dateKey,
                                            val,
                                            employee,
                                            slot
                                          )
                                        }
                                        options={workingTimeSlotList}
                                        classNamePrefix="select"
                                        styles={getCustomStyles(
                                          employee.empCode,
                                          date.formattedDate,
                                          value
                                        )}
                                        menuPortalTarget={document.body}
                                        menuPlacement="auto"
                                        menuPosition="absolute"
                                        placeholder=""
                                        required
                                      />
                                    </td>
                                  );
                                })
                              )}
                              <td>
                                <button
                                  onClick={() => handleOpenLogs(employee)}
                                  style={{
                                    width: "100px",
                                    padding: "5px",
                                    backgroundColor: "#28c76f",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Logs
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          {formattedDates.map((date, dateIndex) => (
                            <td
                              key={`save-${dateIndex}`}
                              colSpan={timeSlots.length}
                              style={{
                                textAlign: "center",
                                padding: "8px",
                                ...headerStyle,
                              }}
                            >
                              <button
                                onClick={() =>
                                  handleSubmit(dateIndex, date.formattedDate)
                                }
                                style={{
                                  width: "100px",
                                  padding: "5px",
                                  backgroundColor: "#28c76f",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                Save
                              </button>
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        )}
      </Row>

      {/* Modal component remains the same */}
      {modalState.isOpen && (
        <RosterLogsModal
          showModal={modalState.isOpen}
          empData={modalState.selectedEmployee}
          empRosterData={employeeRosterData}
          onClose={handleCloseLogs}
        />
      )}
    </div>
  );
};

export default EmployeeRosterTable;
