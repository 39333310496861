import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Hero from "./Hero";
import Guest from "./Guest";
import Privacy from "./Privacy";
import TermsAndConditions from "./TermsAndConditions";
import Logs from './Logs'
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useGetPolicies } from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import { HOSTELLER } from "../../../../utility/constant";

const tabOptions = [
  {
    id: 0,
    title: "Hero",
    formComponent: <Hero />,
  },
  {
    id: 1,
    title: "Guest",
    formComponent: <Guest />,
  },
  {
    id: 2,
    title: "Privacy",
    formComponent: <Privacy />,
  },
  {
    id: 3,
    title: "Terms And Conditions",
    formComponent: <TermsAndConditions />,
  },
  {
    id: 4,
    title: "Logs",
    formComponent: <Logs />,
  },
];

const Policies = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);

  const { data } = useGetPolicies(HOSTELLER); // "hosteller" is used to get landing page doc

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getPolicyByType) {
      dispatch(tableDataHandler(data?.getPolicyByType));
    }
  }, [data?.getPolicyByType]);

  return (
    <div>
      <div className='mt-1 mb-2'>
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className='border-bottom'>
                <CardTitle>
                  Policies -{" "}
                  <b>
                    {" "}
                    {tabOptions.find((item) => item.id === active).title}{" "}
                    Section{" "}
                  </b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className='border-bottom'>
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default Policies;

// <StatisticsCard />;
