import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation } from "@apollo/client";

export const CREATE_EVENT_CATEGORY = gql`
mutation createEventCategory($categoryInput:categoryInput){
    createEventCategory(categoryInput:$categoryInput){
    name
    }
}
`

export const useCreateEventCategory = () => {
    const [createEventCategory, { loading, error, data, refetch }] =
        useMutation(CREATE_EVENT_CATEGORY);

    useLoadingHandler(loading);

    const createEventCategoryHandler = (categoryInput) => {
        createEventCategory({
            variables: { categoryInput },
        });
    };

    return [createEventCategoryHandler, { loading, error, data, refetch }];
};

export const UPDATE_EVENT_CATEGORY = gql`
mutation updateEventCategory($id:ID, $categoryInput:categoryInput){
    updateEventCategory(id:$id, categoryInput:$categoryInput){
        categoryIcon
    }
}
`

export const useUpdateEventCategory = () => {
    const [updateEventCategory, { loading, error, data, refetch }] =
        useMutation(UPDATE_EVENT_CATEGORY);

    useLoadingHandler(loading);

    const updateEventCategoryHandler = (id, categoryInput) => {
        updateEventCategory({
            variables: { id, categoryInput },
        });
    };

    return [updateEventCategoryHandler, { loading, error, data, refetch }];
};