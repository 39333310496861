import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  "Hostel name",
  "Available bed nights",
  "Sold bed nights",
  "Occupancy",
  "Adr",
  "Revenue",
];

const OccupancyADRTable = ({ data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const calculateTotals = () => {
    const totals = {
      totalActiveRooms: 0,
      totalOccupiedRooms: 0,
      occupancy: 0,
      adr: 0,
      revenue: 0,
    };

    tableData?.forEach((item) => {
      totals.totalActiveRooms += item?.totalActiveRooms || 0;
      totals.totalOccupiedRooms += item?.totalOccupiedRooms || 0;
      totals.occupancy += item?.occupancy || 0;
      totals.adr += item?.adr || 0;
      totals.revenue += item?.revenue || 0;
    });

    const rowCount = tableData?.length || 0;
    if (rowCount > 0) {
      totals.occupancy = (totals.totalOccupiedRooms * 100 / totals.totalActiveRooms).toFixed(2); 
      totals.adr = (totals.revenue / totals.totalOccupiedRooms).toFixed(2); 
    }

    totals.revenue = parseFloat(totals.revenue.toFixed(2));

    return totals;
  };

  const totals = calculateTotals();

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Occupancy ADR Report</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <h6>{item?.name}</h6>
                  </td>

                  <td>
                    <h6>{item?.totalActiveRooms}</h6>
                  </td>

                  <td>
                    <h6>{item?.totalOccupiedRooms}</h6>
                  </td>

                  <td>
                    <h6> {item?.occupancy} </h6>
                  </td>

                  <td>
                    <h6>{item?.adr}</h6>
                  </td>

                  <td>
                    <h6>{item?.revenue}</h6>
                  </td>
                </tr>
              ))}

              <tr style={{ fontWeight: "bold", backgroundColor: "#f8f9fa" }}>
                <td>Total</td>
                <td>{totals.totalActiveRooms}</td>
                <td>{totals.totalOccupiedRooms}</td>
                <td>{totals.occupancy}</td>
                <td>{totals.adr}</td>
                <td>{totals.revenue}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default OccupancyADRTable;
