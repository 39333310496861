import React, { useEffect, useState } from 'react'
import SlideModal from '../../@core/components/common/slide-modal'
import { Badge, Col, Input, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";



const tableHeaderData = [
    "Fare",
    "Seat",
    "Name",
    "Gender",
    "Age"
];

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
    const [bookingPNR, setBookingPNR] = useState("")
    const [inventoryItems, setInventoryItems] = useState([])
    const [paymentID, setPaymentID] = useState("")
    const [journeyDate, setJourneyDate] = useState("")
    const [boardingAddress, setBoardingAddress] = useState("")
    const [boardingLandmark, setBoardingLandmark] = useState("")
    const [boardingTime, setBoardingTime] = useState("")
    const [droppingAddress, setDroppingAddress] = useState("")
    const [droppingLandmark, setDroppingLandmark] = useState("")
    const [droppingTime, setDroppingTime] = useState("")
    const [busName, setBusName] = useState("")
    const [busType, setBusType] = useState("");
    const [bookingStatus, setBookingStatus] = useState(null)
    const [refundId, setRefundId] = useState(null)
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [bookingDate, setBookingDate] = useState(null)
    const [sourceCity, setSourceCity] = useState("")
    const [destinationCity, setDestinationCity] = useState("")
    const [totalAmount, setTotalAmount] = useState(0);


    useEffect(() => {
        if (editData?._id) {
            setBookingPNR(editData?.bookingPNR)
            setInventoryItems(editData?.inventoryItems)
            setPaymentID(editData?.razorpayPaymentId)
            setJourneyDate(editData?.journeyDate)
            setBoardingAddress(editData?.boardingAddress)
            setDroppingAddress(editData?.droppingAddress)
            setBoardingLandmark(editData?.boardingAddress)
            setDroppingLandmark(editData?.droppingLandmark)
            setBoardingTime(editData?.boardingTime)
            setDroppingTime(editData?.droppingTime)
            setBusName(editData?.busName)
            setBusType(editData?.busType)
            setBookingStatus(editData?.bookingStatus)
            setRefundId(editData?.refundId)
            setSourceCity(editData?.sourceCity)
            setDestinationCity(editData?.destinationCity)
            editData?.inventoryItems.forEach((passenger) => {
                if (passenger?.passenger[0]?.primary === 'true') {
                    setPhoneNumber(passenger?.passenger[0]?.mobile);
                    setEmail(passenger?.passenger[0]?.email);
                }
            });
            setBookingDate(formatDate(editData?.createdAt))
            setTotalAmount(editData?.totalAmount)

        } else {
            setBookingPNR("")
            setInventoryItems([])
            setPaymentID("")
            setJourneyDate("")
            setBoardingAddress("")
            setDroppingAddress("")
            setBoardingLandmark("")
            setDroppingLandmark("")
            setBoardingTime("")
            setDroppingTime("")
            setBusName("")
            setBusType("")
            setBookingStatus()
            setRefundId()
            setPhoneNumber("")
            setEmail("")
            setBookingDate(null)
        }
    }, [editData]);

    const journeyTimeCalculator = (time) => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;

        const journeyDay = Math.floor(hours / 24);
        const hourOfDay = hours % 24;

        const formattedTime = `${hourOfDay.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return { journeyDay, formattedTime };
    };

    const formatDate = (date) => {
        console.log(date)
        return new Date(date).toLocaleDateString('en-GB', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    return (
        <SlideModal
            open={open}
            handleToggle={handleToggle}
        >
            <Row>
                <Col sm="12" className="">
                    <span style={{ position: "absolute", right: "20px" }}><Badge color={bookingStatus == "success" ? "light-success" : "light-danger"}>{bookingStatus}</Badge></span>
                </Col>
                <Col>
                    {editData?.inventoryItems.map((passenger, index) => (
                        passenger?.passenger[0]?.primary === 'true' ? (
                            <h4 key={index} style={{ fontWeight: "800" }}>{passenger?.passenger[0]?.name}</h4>
                        ) : null
                    ))}
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>Email :  </label>
                    <span>{" "}{email}</span>
                </Col>
                <Col sm="12">
                    <label style={{ fontWeight: 'bold' }}>Phone number : </label>
                    <span>{" "}{phoneNumber}</span>
                </Col>
                {/* <hr className='py-1' /> */}
                <Col sm="12" className="" style={{ marginTop: "20px" }}>
                    <label style={{ fontWeight: 'bold' }}>Booking date : </label>
                    <span>{" "}{bookingDate}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>Booking PNR : </label>
                    <span>{"- "}{bookingPNR}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>Total amount : </label>
                    <span>{" "}{totalAmount}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>Payment ID : </label>
                    <span>{" "}{paymentID}</span>
                </Col>
                {
                    bookingStatus === "cancelled" && <Col sm="12">
                        <label style={{ fontWeight: 'bold' }}>Refund ID : </label>
                        <span>{" "}{refundId}</span>
                    </Col>
                }
                {/* <hr className='py-1' /> */}
                <Col sm="12" className="" style={{ marginTop: "20px" }}>
                    <label style={{ fontWeight: 'bold' }}>Bus name : {" "}</label>
                    <span>{" "}{busName}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>Bus type : </label>
                    <span>{" "}{busType}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>Journey date : </label>
                    <span>{" "}{formatDate(journeyDate)}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>From : </label>
                    <span>{" "}{sourceCity}</span>
                </Col>
                <Col sm="12" className="">
                    <label style={{ fontWeight: 'bold' }}>To : </label>
                    <span>{" "}{destinationCity}</span>
                </Col>
                {/* <hr className='py-1' /> */}
                <Col className="" style={{ marginTop: "20px" }}>
                    <label style={{ fontWeight: 'bold' }}>Boarding date : </label>
                    <span>{" "}{formatDate(journeyDate)}</span>
                </Col>
                <Col className="" style={{ marginTop: "20px" }}>
                    <label style={{ fontWeight: 'bold' }}>Boarding time : </label>
                    <span>{" "}{journeyTimeCalculator(boardingTime).formattedTime}</span>
                </Col>
                <Col sm="12">
                    <label style={{ fontWeight: 'bold' }}>Boarding address : </label>
                    <span>{" "}{boardingAddress}</span>
                </Col>
                <Col sm="6" style={{ whiteSpace: "nowrap" }} >
                    <label style={{ fontWeight: 'bold' }}>Dropping date : </label>
                    <span >{" "}
                        {(() => {
                            const doj = new Date(journeyDate);
                            const journeyDay = journeyTimeCalculator(boardingTime).journeyDay;
                            doj.setDate(doj.getDate() + journeyDay);
                            return formatDate(doj);
                        })()}</span>
                </Col>
                <Col className="">
                    <label style={{ fontWeight: 'bold' }}>Dropping time : </label>
                    <span>{" "}{journeyTimeCalculator(droppingTime).formattedTime}</span>
                </Col>
                <Col sm="12" >
                    <label style={{ fontWeight: 'bold' }}>Dropping address : </label>
                    <span>{" "}{droppingAddress}</span>
                </Col>

                <Col sm="12">
                    <Table>
                        <thead>
                            <tr>
                                {tableHeaderData.map((item) => {
                                    return <th key={uuidv4()}>{item}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inventoryItems?.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item?.fare}</td>
                                            <td>{item?.seatName}</td>
                                            {
                                                item?.passenger?.map((passenger) => {
                                                    return (
                                                        <>
                                                            <td>
                                                                <span>

                                                                    {passenger?.name}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>

                                                                    {passenger?.gender}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>

                                                                    {passenger?.age}
                                                                </span>
                                                            </td>
                                                        </>

                                                    )
                                                })
                                            }
                                            <hr />
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </SlideModal >
    )
}

export default AddEditModal