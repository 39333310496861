import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));


export default modules?.includes("Coliving") ////localStorage.getItem("role") === "Super Admin" ?
    ? [
        {
            id: "coliving",
            title: "Coliving",
            icon: <Grid size={20} />,
            badge: "light-warning",
            children: [
                {
                    id: "colivingRoomRates",
                    title: "Coliving Room Rates",
                    icon: <Circle size={12} />,
                    navLink: "/coliving/colivingroomrates",
                },
                {
                    id: "colivingBooking",
                    title: "Coliving Bookings",
                    icon: <Circle size={12} />,
                    navLink: "/report/websiteBooking?coLiving=true",
                },
                // {
                //     id: "websiteBooking",
                //     title: "Website Booking",
                //     icon: <Circle size={12} />,
                //     navLink: "/report/websiteBooking",
                // },
            ]
        }
    ] : []