import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Collapse,
} from "reactstrap";
import toaster from "@components/common/Toaster";
import {
  useCreatePLStatement,
  useUpdatePLStatement,
} from "../../api/P&LStatement/Mutation";
import ErrorBoundary from "./ErrorBoundary";
import { PlusCircle, MinusCircle } from "react-feather";
import { fieldConfig } from "./FieldConfig"; // Import the separated field configuration

const PLStatementOverviewModal = ({ isOpen, toggle, editData, refetch }) => {
  const [formData, setFormData] = useState(editData || {});
  const [createPLStatementHandler] = useCreatePLStatement();
  const [updatePLStatementHandler] = useUpdatePLStatement();
  const [isMutationComplete, setIsMutationComplete] = useState(false);

  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [revenueOpen, setRevenueOpen] = useState(false);
  const [operationalExpenseOpen, setOperationalExpenseOpen] = useState(false);
  const [marginAnalysisOpen, setMarginAnalysisOpen] = useState(false);
  const [reviewsFeedbackOpen, setReviewsFeedbackOpen] = useState(false);
  const [remarksActionOpen, setRemarksActionOpen] = useState(false);

  const toggleInventory = () => setInventoryOpen(!inventoryOpen);
  const toggleRevenue = () => setRevenueOpen(!revenueOpen);
  const toggleOperationalExpense = () =>
    setOperationalExpenseOpen(!operationalExpenseOpen);
  const toggleMarginAnalysis = () => setMarginAnalysisOpen(!marginAnalysisOpen);
  const toggleReviewsFeedback = () =>
    setReviewsFeedbackOpen(!reviewsFeedbackOpen);
  const toggleRemarksAction = () => setRemarksActionOpen(!remarksActionOpen);

  useEffect(() => {
    if (!editData) {
      // Initialize all fields for creation
      setFormData({
        hostelName: "",
        hostelCode: "",
        operationalModel: "",
        areaManager: "",
        month: "",
        year: "",
        fields: [
          ...fieldConfig.inventory.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
          })),
          ...fieldConfig.revenue.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
          })),
          ...fieldConfig.operationalExpense.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
          })),
          ...fieldConfig.marginAnalysis.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
          })),
          ...fieldConfig.reviewsFeedback.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
          })),
        ],
      });
    } else {
      // Use provided editData
      setFormData(editData);
      refetch();
    }

    // Reset collapse states when the modal is opened or closed
    return () => {
      setInventoryOpen(false);
      setRevenueOpen(false);
      setOperationalExpenseOpen(false);
      setMarginAnalysisOpen(false);
      setReviewsFeedbackOpen(false);
      setRemarksActionOpen(false);
    };
  }, [editData, isOpen]);

  useEffect(() => {
    if (isMutationComplete) {
      setIsMutationComplete(false);
    }
  }, [isMutationComplete]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { _id, createdAt, updatedAt, ...plInputData } = { ...formData };

    // Ensure all fields are sanitized (empty strings are replaced with null for fields expecting numbers)
    const sanitizedPlInputData = {
      ...plInputData,
      fields: plInputData.fields.map((field) => ({
        ...field,
        fieldValue: field.fieldValue === "" ? null : field.fieldValue,
      })),
    };

    try {
      let response;

      if (_id) {
        response = await updatePLStatementHandler({
          id: _id,
          plInput: sanitizedPlInputData,
        });

        console.log("Update Response: ", response);

        if (response?.data?.updatePLStatement) {
          toaster("success", "P&L statement updated successfully!");
          setFormData(response.data.updatePLStatement);
        } else {
          throw new Error("Update failed: Response data is missing.");
        }
      } else {
        response = await createPLStatementHandler({
          plInput: sanitizedPlInputData,
        });
        console.log("Create Response: ", response);

        if (response?.data?.createPLStatement) {
          toaster("success", "P&L statement created successfully!");
          setFormData(response.data.createPLStatement);
        } else {
          throw new Error("Create failed: Response data is missing.");
        }
      }

      setIsMutationComplete(true);
      toggle();
    } catch (error) {
      console.error("Error during mutation:", error);
      toaster("error", "Something went wrong! Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    let parsedValue = value;

    if (type === "number") {
      parsedValue = value ? parseFloat(value) : null;
    } else if (value === "") {
      parsedValue = null;
    }

    setFormData((prev) => {
      // Check if the field is top-level or part of the `fields` array
      if (prev.hasOwnProperty(name)) {
        return { ...prev, [name]: parsedValue };
      } else {
        const updatedFields = prev.fields.map((field) =>
          field.fieldName === name
            ? { ...field, fieldValue: parsedValue }
            : field
        );
        return { ...prev, fields: updatedFields };
      }
    });
  };

  const renderField = (label, name, type = "number", readOnly = false) => {
    const isFieldInArray = formData?.fields?.find(
      (item) => item.fieldName === name
    );
    const fieldValue = isFieldInArray
      ? isFieldInArray.fieldValue
      : formData?.[name] || "";

    return (
      <Col md={6} key={name}>
        <FormGroup>
          <Label>{label}</Label>
          <Input
            type={type}
            name={name}
            value={fieldValue || ""}
            onChange={handleInputChange}
            readOnly={readOnly}
            onWheel={(e) => e.target.blur()}
          />
        </FormGroup>
      </Col>
    );
  };

  const renderSection = (title, sectionKey, toggle, isOpen) => (
    <Card className="mt-3">
      <CardHeader>
        <strong>{title}</strong>
        <Button
          color="link"
          onClick={toggle}
          className="ml-auto"
          style={{ float: "right" }}
        >
          {isOpen ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
        </Button>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Row>
            {fieldConfig[sectionKey]?.map((field) =>
              renderField(field.label, field.name, field.type, field.readOnly)
            )}
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );

  return (
    <ErrorBoundary>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>Overview</ModalHeader>
        <ModalBody>
          {formData ? (
            <Form>
              {/* Hostel Information Section */}
              {renderSection(
                "Hostel Information",
                "hostelInfo",
                () => {},
                true
              )}

              {/* Inventory Section */}
              {renderSection(
                "Inventory",
                "inventory",
                toggleInventory,
                inventoryOpen
              )}

              {/* Revenue Section */}
              {renderSection("Revenue", "revenue", toggleRevenue, revenueOpen)}

              {/* Operational Expense Section */}
              {renderSection(
                "Operational Expense",
                "operationalExpense",
                toggleOperationalExpense,
                operationalExpenseOpen
              )}

              {/* Margin Analysis Section */}
              {renderSection(
                "Margin Analysis",
                "marginAnalysis",
                toggleMarginAnalysis,
                marginAnalysisOpen
              )}

              {/* Reviews Feedback Section */}
              {renderSection(
                "Reviews Feedback",
                "reviewsFeedback",
                toggleReviewsFeedback,
                reviewsFeedbackOpen
              )}

              {/* Remarks Action Section */}
              {/* {renderSection(
                "Remarks Action",
                "remarksAction",
                toggleRemarksAction,
                remarksActionOpen
              )} */}

              {/* Save Button */}
              <Row>
                <Col>
                  <Button
                    color="primary"
                    className="mt-3"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <p>No data available.</p>
          )}
        </ModalBody>
      </Modal>
    </ErrorBoundary>
  );
};

export default PLStatementOverviewModal;

// import React, { useState, useEffect } from "react";
// import {
//   Col,
//   Row,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Card,
//   CardHeader,
//   CardBody,
//   Collapse,
// } from "reactstrap";
// import toaster from "@components/common/Toaster";
// import {
//   useCreatePLStatement,
//   useUpdatePLStatement,
// } from "../../api/P&LStatement/Mutation";
// import ErrorBoundary from "./ErrorBoundary";
// import { PlusCircle, MinusCircle } from "react-feather";
// import { fieldConfig } from "./FieldConfig"; // Import the separated field configuration

// const PLStatementOverviewModal = ({ isOpen, toggle, editData, refetch }) => {
//   const [formData, setFormData] = useState(editData || {});
//   const [createPLStatementHandler] = useCreatePLStatement();
//   const [updatePLStatementHandler] = useUpdatePLStatement();
//   const [isMutationComplete, setIsMutationComplete] = useState(false);

//   const [inventoryOpen, setInventoryOpen] = useState(false);
//   const [revenueOpen, setRevenueOpen] = useState(false);
//   const [operationalExpenseOpen, setOperationalExpenseOpen] = useState(false);
//   const [marginAnalysisOpen, setMarginAnalysisOpen] = useState(false);
//   const [reviewsFeedbackOpen, setReviewsFeedbackOpen] = useState(false);
//   const [remarksActionOpen, setRemarksActionOpen] = useState(false);

//   const toggleInventory = () => setInventoryOpen(!inventoryOpen);
//   const toggleRevenue = () => setRevenueOpen(!revenueOpen);
//   const toggleOperationalExpense = () =>
//     setOperationalExpenseOpen(!operationalExpenseOpen);
//   const toggleMarginAnalysis = () => setMarginAnalysisOpen(!marginAnalysisOpen);
//   const toggleReviewsFeedback = () =>
//     setReviewsFeedbackOpen(!reviewsFeedbackOpen);
//   const toggleRemarksAction = () => setRemarksActionOpen(!remarksActionOpen);

//   useEffect(() => {
//     setFormData(editData || {});
//   }, [editData]);

//   useEffect(() => {
//     if (isMutationComplete) {
//       setIsMutationComplete(false);
//     }
//   }, [isMutationComplete]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const { _id, createdAt, updatedAt, ...plInputData } = { ...formData };

//     try {
//       if (_id) {
//         await updatePLStatementHandler({
//           id: _id,
//           plInput: plInputData,
//         });
//         toaster("success", "P&L statement updated successfully!");
//       } else {
//         await createPLStatementHandler({
//           plInput: plInputData,
//         });
//         toaster("success", "P&L statement created successfully!");
//       }

//       setIsMutationComplete(true);
//       toggle();
//     } catch (error) {
//       toaster("error", "Something went wrong! Please try again.");
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type } = e.target;
//     let parsedValue = value;
//     if (type === "number") {
//       parsedValue = value ? parseFloat(value) : null;
//     }
//     setFormData((prev) => {
//       const updatedFields = prev.fields.map((field) =>
//         field.fieldName === name ? { ...field, fieldValue: parsedValue } : field
//       );
//       return { ...prev, fields: updatedFields };
//     });
//   };

//   const renderField = (label, name, type = "number", readOnly = false) => {
//     const isFieldInArray = formData?.fields?.find(
//       (item) => item.fieldName === name
//     );
//     const fieldValue = isFieldInArray
//       ? isFieldInArray.fieldValue
//       : formData?.[name] || "";

//     return (
//       <Col md={6} key={name}>
//         <FormGroup>
//           <Label>{label}</Label>
//           <Input
//             type={type}
//             name={name}
//             value={fieldValue || ""}
//             onChange={handleInputChange}
//             readOnly={readOnly}
//             onWheel={(e) => e.target.blur()}
//           />
//         </FormGroup>
//       </Col>
//     );
//   };

//   const renderSection = (title, sectionKey, toggle, isOpen) => (
//     <Card className="mt-3">
//       <CardHeader>
//         <strong>{title}</strong>
//         <Button
//           color="link"
//           onClick={toggle}
//           className="ml-auto"
//           style={{ float: "right" }}
//         >
//           {isOpen ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
//         </Button>
//       </CardHeader>
//       <Collapse isOpen={isOpen}>
//         <CardBody>
//           <Row>
//             {fieldConfig[sectionKey]?.map((field) =>
//               renderField(field.label, field.name, field.type, field.readOnly)
//             )}
//           </Row>
//         </CardBody>
//       </Collapse>
//     </Card>
//   );

//   return (
//     <ErrorBoundary>
//       <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
//         <ModalHeader toggle={toggle}>Overview</ModalHeader>
//         <ModalBody>
//           {formData ? (
//             <Form>
//               {/* Hostel Information Section */}
//               <Card>
//                 <CardHeader>
//                   <strong>Hostel Information</strong>
//                 </CardHeader>
//                 <CardBody>
//                   <Row>
//                     {fieldConfig.hostelInfo?.map((field) =>
//                       renderField(
//                         field.label,
//                         field.name,
//                         field.type,
//                         field.readOnly
//                       )
//                     )}
//                   </Row>
//                 </CardBody>
//               </Card>

//               {/* Inventory Section */}
//               {renderSection(
//                 "Inventory",
//                 "inventory",
//                 toggleInventory,
//                 inventoryOpen
//               )}

//               {/* Revenue Section */}
//               {renderSection("Revenue", "revenue", toggleRevenue, revenueOpen)}

//               {/* Operational Expense Section */}
//               {renderSection(
//                 "Operational Expense",
//                 "operationalExpense",
//                 toggleOperationalExpense,
//                 operationalExpenseOpen
//               )}

//               {/* Margin Analysis Section */}
//               {renderSection(
//                 "Margin Analysis",
//                 "marginAnalysis",
//                 toggleMarginAnalysis,
//                 marginAnalysisOpen
//               )}

//               {/* Reviews Feedback Section */}
//               {renderSection(
//                 "Reviews Feedback",
//                 "reviewsFeedback",
//                 toggleReviewsFeedback,
//                 reviewsFeedbackOpen
//               )}

//               {/* Remarks Action Section */}
//               {/* {renderSection(
//                 "Remarks Action",
//                 "remarksAction",
//                 toggleRemarksAction,
//                 remarksActionOpen
//               )} */}

//               {/* Save Button */}
//               <Row>
//                 <Col>
//                   <Button
//                     color="primary"
//                     className="mt-3"
//                     onClick={handleSubmit}
//                   >
//                     Save Changes
//                   </Button>
//                 </Col>
//               </Row>
//             </Form>
//           ) : (
//             <p>No data available.</p>
//           )}
//         </ModalBody>
//       </Modal>
//     </ErrorBoundary>
//   );
// };

// export default PLStatementOverviewModal;

// import React, { useState, useEffect } from "react";
// import {
//   Col,
//   Row,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Card,
//   CardHeader,
//   CardBody,
//   Collapse,
// } from "reactstrap";
// import toaster from "@components/common/Toaster";
// import {
//   useCreatePLStatement,
//   useUpdatePLStatement,
// } from "../../api/P&LStatement/Mutation";
// import ErrorBoundary from "./ErrorBoundary";

// import { PlusCircle, MinusCircle } from "react-feather";

// const PLStatementOverviewModal = ({ isOpen, toggle, editData, refetch }) => {
//   const [formData, setFormData] = useState(editData || {});
//   const [createPLStatementHandler] = useCreatePLStatement();
//   const [updatePLStatementHandler] = useUpdatePLStatement();
//   const [isMutationComplete, setIsMutationComplete] = useState(false);

//   // States for collapsing sections
//   const [inventoryOpen, setInventoryOpen] = useState(true);
//   const [revenueOpen, setRevenueOpen] = useState(true);
//   const [operationalExpenseOpen, setOperationalExpenseOpen] = useState(true);
//   const [marginAnalysisOpen, setMarginAnalysisOpen] = useState(true);
//   const [reviewsFeedbackOpen, setReviewsFeedbackOpen] = useState(true);
//   const [remarksActionOpen, setRemarksActionOpen] = useState(true);

//   const toggleInventory = () => setInventoryOpen(!inventoryOpen);
//   const toggleRevenue = () => setRevenueOpen(!revenueOpen);
//   const toggleOperationalExpense = () =>
//     setOperationalExpenseOpen(!operationalExpenseOpen);
//   const toggleMarginAnalysis = () => setMarginAnalysisOpen(!marginAnalysisOpen);
//   const toggleReviewsFeedback = () =>
//     setReviewsFeedbackOpen(!reviewsFeedbackOpen);
//   const toggleRemarksAction = () => setRemarksActionOpen(!remarksActionOpen);

//   useEffect(() => {
//     setFormData(editData || {});
//   }, [editData]);

//   useEffect(() => {
//     if (isMutationComplete) {
//       refetch();
//       setIsMutationComplete(false);
//     }
//   }, [isMutationComplete, refetch]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const { _id, createdAt, updatedAt, ...plInputData } = { ...formData };

//     try {
//       if (_id) {
//         await updatePLStatementHandler({
//           id: _id,
//           plInput: plInputData,
//         });
//         toaster("success", "P&L statement updated successfully!");
//       } else {
//         await createPLStatementHandler({
//           plInput: plInputData,
//         });
//         toaster("success", "P&L statement created successfully!");
//       }

//       setIsMutationComplete(true);
//       toggle();
//     } catch (error) {
//       toaster("error", "Something went wrong! Please try again.");
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type } = e.target;
//     let parsedValue = value;
//     if (type === "number") {
//       parsedValue = value ? parseFloat(value) : "";
//     }
//     setFormData((prev) => {
//       const updatedFields = prev.fields.map((field) =>
//         field.fieldName === name ? { ...field, fieldValue: parsedValue } : field
//       );
//       return { ...prev, fields: updatedFields };
//     });
//   };

//   const renderField = (label, name, type = "number", readOnly = false) => {
//     const isFieldInArray = formData?.fields?.find(
//       (item) => item.fieldName === name
//     );
//     const fieldValue = isFieldInArray
//       ? isFieldInArray.fieldValue
//       : formData?.[name] || "";

//     return (
//       <Col md={6} key={name}>
//         <FormGroup>
//           <Label>{label}</Label>
//           <Input
//             type={type}
//             name={name}
//             value={fieldValue || ""}
//             onChange={handleInputChange}
//             readOnly={readOnly}
//             onWheel={(e) => e.target.blur()}
//           />
//         </FormGroup>
//       </Col>
//     );
//   };

//   const renderSection = (title, fields, toggle, isOpen) => (
//     <Card className="mt-3">
//       <CardHeader>
//         <strong>{title}</strong>
//         <Button
//           color="link"
//           onClick={toggle}
//           className="ml-auto"
//           style={{ float: "right" }}
//         >
//           {isOpen ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
//         </Button>
//       </CardHeader>
//       <Collapse isOpen={isOpen}>
//         <CardBody>
//           <Row>
//             {formData?.fields
//               ?.filter((field) => fields.includes(field.fieldName))
//               ?.map((field) => renderField(field.fieldName, field.fieldName))}
//           </Row>
//         </CardBody>
//       </Collapse>
//     </Card>
//   );

//   return (
//     <ErrorBoundary>
//       <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
//         <ModalHeader toggle={toggle}>Overview</ModalHeader>
//         <ModalBody>
//           {formData ? (
//             <Form>
//               {/* Hostel Information Section */}
//               <Card>
//                 <CardHeader>
//                   <strong>Hostel Information</strong>
//                 </CardHeader>
//                 <CardBody>
//                   <Row>
//                     {renderField("Hostel Name", "hostelName", "text", true)}
//                     {renderField(
//                       "Operational Model",
//                       "operationalModel",
//                       "text",
//                       true
//                     )}
//                     {renderField("Area Manager", "areaManager", "text", true)}
//                     {renderField("Month", "month", "text", true)}
//                     {renderField("Year", "year", "text", true)}
//                     {renderField("Hostel Code", "hostelCode", "number", true)}
//                   </Row>
//                 </CardBody>
//               </Card>

//               {/* Inventory Section */}
//               {renderSection(
//                 "Inventory",
//                 [
//                   "No. of rooms",
//                   "No. of beds",
//                   "Total Inventory",
//                   "Blocked Inventory",
//                 ],
//                 toggleInventory,
//                 inventoryOpen
//               )}
//               {/* Revenue Section */}
//               {renderSection(
//                 "Revenue",
//                 [
//                   "Total Revenue",
//                   "T A BN",
//                   "T S BN",
//                   "Occ%",
//                   "ADR",
//                   "Bed Revenue",
//                   "F&B AOV (in INR)",
//                   "F&B Orders",
//                   "F&B Order Per Bed Night Sold",
//                   "F&B Revenue",
//                   "Ancillary Revenue",
//                   "Total OPEX",
//                 ],
//                 toggleRevenue,
//                 revenueOpen
//               )}
//               {/* Operational Expense Section */}
//               {renderSection(
//                 "Operational Expense",
//                 [
//                   "Rent",
//                   "Rental Cost Per Bed",
//                   "U1: Electricity + Fuel + Genset",
//                   "U2: Water + Tanker",
//                   "U3: TV & Internet + Telephone + SW subscriptions",
//                   "U4: Toiletry",
//                   "U5: Laundry",
//                   "R&M",
//                   "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)",
//                   "OTA",
//                   "Total salary",
//                   "Hostel Mandays",
//                   "# of Hostel Staff",
//                   "Hostel salary (Own)",
//                   "Hostel salary (Agency)",
//                   "Hostel Salary Per Manday",
//                   "Hostel welfare (Staff food room rent & other)",
//                   "Cafe Mandays",
//                   "# of Cafe Staff",
//                   "F&B salary + F&B welfare",
//                   "Cafe Salary Per Manday",
//                   "F&B material cost (= OS + P - CS - HSW - CSW)",
//                   "Ancillary cost",
//                   "Other Payouts",
//                   "I&P payout",
//                   "Franchise payout",
//                 ],
//                 toggleOperationalExpense,
//                 operationalExpenseOpen
//               )}

//               {/* Margin Analysis Section */}
//               {renderSection(
//                 "Margin Analysis",
//                 [
//                   "Overall Op. Margin (in INR)",
//                   "Hostel margin (in INR)",
//                   "Hostel margin (in %)",
//                   "F&B margin (in INR)",
//                   "F&B margin (in %)",
//                   "Ancillary margin (in INR)",
//                   "Ancillary margin (in %)",
//                   "Analysis (Operational Margin %)",
//                   "Rev/Rental multiple",
//                   "OTA %",
//                   "Utilities/Total Rev",
//                   "R&M/Total Rev",
//                   "Misc/Total Rev",
//                   "Hostel staff salary/(bed sales)",
//                   "F&B staff salary/(F&B sales)",
//                   "F&B food cost/(F&B sales)",
//                 ],
//                 toggleMarginAnalysis,
//                 marginAnalysisOpen
//               )}

//               {/* Reviews & Feedback Section */}
//               {renderSection(
//                 "Reviews & Feedback",
//                 [
//                   "ORM",
//                   "# of positive Reviews",
//                   "# of neutral Reviews",
//                   "# of negative Reviews",
//                   "Total # of reviews",
//                 ],

//                 toggleReviewsFeedback,
//                 reviewsFeedbackOpen
//               )}

//               {/* Remarks & actions */}
//               {renderSection(
//                 "Remarks & Action",
//                 [
//                   "Overall Remarks	Remarks on Ops",
//                   "Remarks on CX",
//                   "Remarks on POS sales",
//                   "Actions/Follow-ups",
//                 ],

//                 toggleRemarksAction,
//                 remarksActionOpen
//               )}
//               {/* Save Button */}
//               <Row>
//                 <Col>
//                   <Button
//                     color="primary"
//                     className="mt-3"
//                     onClick={handleSubmit}
//                   >
//                     Save Changes
//                   </Button>
//                 </Col>
//               </Row>
//             </Form>
//           ) : (
//             <p>No data available.</p>
//           )}
//         </ModalBody>
//       </Modal>
//     </ErrorBoundary>
//   );
// };

// export default PLStatementOverviewModal;

// //Working code
// import React, { useState, useEffect } from "react";
// import {
//   Col,
//   Row,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Card,
//   CardHeader,
//   CardBody,
// } from "reactstrap";
// import toaster from "@components/common/Toaster";

// import {
//   useCreatePLStatement,
//   useUpdatePLStatement,
// } from "../../api/P&LStatement/Mutation";

// const PLStatementOverviewModal = ({ isOpen, toggle, editData, refetch }) => {
//   const [formData, setFormData] = useState(editData || {});
//   const [createPLStatementHandler] = useCreatePLStatement();
//   const [updatePLStatementHandler] = useUpdatePLStatement();
//   const [isMutationComplete, setIsMutationComplete] = useState(false);

//   useEffect(() => {
//     setFormData(editData || {});
//   }, [editData]);

//   useEffect(() => {
//     if (isMutationComplete) {
//       refetch();
//       setIsMutationComplete(false);
//     }
//   }, [isMutationComplete, refetch]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const { _id, createdAt, updatedAt, ...plInputData } = { ...formData };

//     try {
//       if (_id) {
//         await updatePLStatementHandler({
//           id: _id,
//           plInput: plInputData,
//         });
//         toaster("success", "P&L statement updated successfully!");
//       } else {
//         await createPLStatementHandler({
//           plInput: plInputData,
//         });
//         toaster("success", "P&L statement created successfully!");
//       }

//       setIsMutationComplete(true);

//       toggle();
//     } catch (error) {
//       toaster("error", "Something went wrong! Please try again.");
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type } = e.target;
//     let parsedValue = value;
//     if (type === "number") {
//       parsedValue = value ? parseFloat(value) : "";
//     }
//     setFormData((prev) => ({ ...prev, [name]: parsedValue }));
//   };

//   const renderField = (
//     label,
//     name,
//     type = "text",
//     readOnly = false,
//     options = []
//   ) => {
//     const isMonthOrYear = name === "month" || name === "year";

//     return (
//       <Col md={6} key={name}>
//         <FormGroup>
//           <Label>{label}</Label>
//           {type === "select" ? (
//             <Input
//               type="select"
//               name={name}
//               value={formData?.[name] || ""}
//               onChange={handleInputChange}
//               readOnly={readOnly}
//               onWheel={(e) => e.target.blur()}
//             >
//               <option value="">Select {label}</option>{" "}
//               {/* Add an empty option for selecting nothing */}
//               {options.map((option) => (
//                 <option key={option.value} value={option.value}>
//                   {option.label}
//                 </option>
//               ))}
//             </Input>
//           ) : (
//             <Input
//               type={type}
//               name={name}
//               value={formData?.[name] || ""}
//               onChange={handleInputChange}
//               readOnly={readOnly}
//               onWheel={(e) => e.target.blur()}
//             />
//           )}
//         </FormGroup>
//       </Col>
//     );
//   };

//   return (
//     <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
//       <ModalHeader toggle={toggle}>Overview</ModalHeader>
//       <ModalBody>
//         {formData ? (
//           <Form>
//             {/* Finance Section */}
//             <Card>
//               <CardHeader>
//                 <strong>Hostel Information</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField("Hostel Code", "hostelCode", "text", true)}
//                   {renderField("Hostel Name", "hostelName")}

//                   {renderField("Month", "month", "select", false, [
//                     { value: "01", label: "January" },
//                     { value: "02", label: "February" },
//                     { value: "03", label: "March" },
//                     { value: "04", label: "April" },
//                     { value: "05", label: "May" },
//                     { value: "06", label: "June" },
//                     { value: "07", label: "July" },
//                     { value: "08", label: "August" },
//                     { value: "09", label: "September" },
//                     { value: "10", label: "October" },
//                     { value: "11", label: "November" },
//                     { value: "12", label: "December" },
//                   ])}

//                   {renderField("Year", "year", "select", false, [
//                     { value: "2020", label: "2020" },
//                     { value: "2021", label: "2021" },
//                     { value: "2022", label: "2022" },
//                     { value: "2023", label: "2023" },
//                     { value: "2024", label: "2024" },
//                     { value: "2025", label: "2025" },
//                     { value: "2026", label: "2026" },
//                   ])}

//                   {renderField(
//                     "Hostel Age in Months",
//                     "hostelAgeInMonths",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Operation Model",
//                     "operationModel",
//                     "text",
//                     false
//                   )}
//                   {renderField("Area Manager", "areaManager", "text", false)}
//                 </Row>
//               </CardBody>
//             </Card>

//             {/* Revenue & Inventory Section */}
//             <Card className="mt-3">
//               <CardHeader>
//                 <strong>Revenue & Inventory</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField("Total Revenue", "totalRevenue", "number", true)}
//                   {renderField(
//                     "Number of Rooms",
//                     "numberOfRooms",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Number of Beds",
//                     "numberOfBeds",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Total Inventory",
//                     "totalInventory",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Blocked Inventory",
//                     "blockedInventory",
//                     "number",
//                     false
//                   )}

//                   {renderField(
//                     "Total available bed nights (TABN)",
//                     "totalAvailableBedNight(TASN)",
//                     "number",
//                     false
//                   )}

//                   {renderField(
//                     "Total available sold nights",
//                     "totalSoldBedNight",
//                     "number",
//                     false
//                   )}

//                   {renderField(
//                     "Occupancy Percentage",
//                     "occupancyPercentage",
//                     "number",
//                     true
//                   )}
//                   {renderField("ADR", "adr", "number", false)}
//                   {renderField("Bed Revenue", "bedRevenue", "number", false)}
//                 </Row>
//               </CardBody>
//             </Card>

//             <Card className="mt-3">
//               <CardHeader>
//                 <strong>F&B Information</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField("FB AOV", "fbAOV", "number", true)}
//                   {renderField("FB Orders", "fbOrders", "number", false)}
//                   {renderField(
//                     "FB Order Per Bed Night Sold",
//                     "fbOrderPerBedNightSold",
//                     "number",
//                     true
//                   )}
//                   {renderField("FB Revenue", "fbRevenue", "number", false)}
//                 </Row>
//               </CardBody>
//             </Card>

//             {/* Opex Section */}
//             <Card className="mt-3">
//               <CardHeader>
//                 <strong>Operational Expenses</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField(
//                     "Ancillary Revenue",
//                     "ancillaryRevenue",
//                     "number",
//                     false
//                   )}
//                   {renderField("Total Opex", "totalOpex", "number", true)}
//                   {renderField("Rent", "rent", "number", false)}
//                   {renderField(
//                     "Rental Cost Per Bed",
//                     "rentalCostPerRoom",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Electricity, Fuel, Genset",
//                     "u1ElectricityFuelGenset",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Water Tanker",
//                     "u2WaterTanker",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "U3: TV & Internet + Telephone + SW subscriptions",
//                     "u3Subscriptions",
//                     "number",
//                     false
//                   )}
//                   {renderField("Toiletry", "u4Toiletry", "number", false)}
//                   {renderField("Laundry", "u5Laundry", "number", false)}
//                   {renderField(
//                     "Repair & Maintenance",
//                     "repairMaintenence",
//                     "number",
//                     false
//                   )}
//                   {renderField("Misc Opex", "miscOpex", "number", false)}
//                   {renderField("OTA", "ota", "number", false)}
//                   {renderField("Total Salary", "totalSalary", "number", true)}
//                   {renderField(
//                     "Hostel Mandays",
//                     "hostelMandays",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Number of Hostel Staff",
//                     "numberOfHostelStaff",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Hostel Salary (Own)",
//                     "hostelSalaryOwn",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Hostel salary (Agency)",
//                     "hostelSalaryAgency",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Hostel Salary Per Manday",
//                     "hostelSalaryPerManday",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Hostel welfare (Staff food room rent & other)",
//                     "hostelWelfare",
//                     "number",
//                     false
//                   )}
//                   {renderField("Cafe Mandays", "cafeMandays", "number", false)}
//                   {renderField(
//                     "Number of Cafe staff",
//                     "numberOfCafeStaff",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "F&B salary + F&B welfare",
//                     "fbSalaryAndWelfare",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Cafe Salary Per Manday",
//                     "cafeSalaryPerManday",
//                     "number",
//                     true
//                   )}

//                   {renderField(
//                     "F&B material cost (= OS + P - CS - HSW - CSW)",
//                     "fbMaterialCost",
//                     "number",
//                     false
//                   )}

//                   {renderField(
//                     "Ancillary cos)",
//                     "ancillaryCost",
//                     "number",
//                     true
//                   )}

//                   {renderField("Other Payouts", "otherPayouts", "number", true)}

//                   {renderField("I&P payout", "ipPayout", "number", false)}

//                   {renderField(
//                     "Franchise payout",
//                     "franchisePayout",
//                     "number",
//                     false
//                   )}
//                 </Row>
//               </CardBody>
//             </Card>

//             {/* Margin Analysis Section */}
//             <Card className="mt-3">
//               <CardHeader>
//                 <strong>Margin Analysis</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField(
//                     "Overall Operational Margin",
//                     "overallOpMargin",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Hostel Margin (in INR)",
//                     "hostelMargin",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Hostel Margin Percentage",
//                     "hostelMarginPercentage",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "FB Margin (in INR)",
//                     "fbMarginIn",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "FB Margin Percentage",
//                     "fbMarginPercentage",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Ancillary Margin (in INR)",
//                     "ancillaryMargin",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Ancillary Margin Percentage",
//                     "ancillaryMarginPercentage",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Analysis (Operational Margin %)",
//                     "analysisOperationalMargin",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Rev Rental Multiple",
//                     "revRentalMultiple",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "OTA Percentage",
//                     "otaPercentage",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Utilities to Total Revenue",
//                     "utilitiesToTotalRev",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "RM to Total Revenue",
//                     "rmToTotalRev",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Misc to Total Revenue",
//                     "miscToTotalRev",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "Hostel staff salary/(bed sales)",
//                     "hostelStaffSalaryPerBedSales",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "F&B staff salary/(F&B sales)",
//                     "fbStaffSalaryPerFBSales",
//                     "number",
//                     true
//                   )}
//                   {renderField(
//                     "F&B food cost/(F&B sales)",
//                     "fbFoodCostPerFBSales",
//                     "number",
//                     true
//                   )}
//                 </Row>
//               </CardBody>
//             </Card>

//             {/* Reviews & Feedback Section */}
//             <Card className="mt-3">
//               <CardHeader>
//                 <strong>Reviews & Feedback</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField("NPS", "nps", "number", false)}
//                   {renderField("ORM", "orm", "number", false)}
//                   {renderField(
//                     "Positive Reviews",
//                     "numberOfPositiveReviews",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Neutral Reviews",
//                     "numberOfNeutralReviews",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Negative Reviews",
//                     "numberOfNegativeReviews",
//                     "number",
//                     false
//                   )}
//                   {renderField(
//                     "Total Reviews",
//                     "totalNumberOfReviews",
//                     "number",
//                     true
//                   )}
//                 </Row>
//               </CardBody>
//             </Card>

//             {/* Remarks Section */}
//             <Card className="mt-3">
//               <CardHeader>
//                 <strong>Remarks & Actions</strong>
//               </CardHeader>
//               <CardBody>
//                 <Row>
//                   {renderField(
//                     "Overall Remarks",
//                     "overallRemarks",
//                     "text",
//                     false
//                   )}
//                   {renderField("Remarks on Ops", "remarksOnOps", "text", false)}
//                   {renderField("Remarks on CX", "remarksOnCX", "text", false)}
//                   {renderField(
//                     "Remarks on POSales",
//                     "remarksOnPOSales",
//                     "text",
//                     false
//                   )}
//                   {renderField(
//                     "Actions & Follow-ups",
//                     "actionsFollowUps",
//                     "text",
//                     false
//                   )}
//                 </Row>
//               </CardBody>
//             </Card>

//             {/* Save Button */}
//             <Row>
//               <Col>
//                 <Button color="primary" className="mt-3" onClick={handleSubmit}>
//                   Save Changes
//                 </Button>
//               </Col>
//             </Row>
//           </Form>
//         ) : (
//           <p>No data available.</p>
//         )}
//       </ModalBody>
//     </Modal>
//   );
// };

// export default PLStatementOverviewModal;
