import { Package, Circle } from "react-feather";

const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

const filterModules = (role, modules) => {
  try {
    if (
      modules?.includes("ORM Configuration") &&
      modules?.includes("ORM Reports")
    ) {
      return [
        {
          id: "ormHomepage",
          title: "Home",
          icon: <Circle size={12} />,
          navLink: "/orm/homepage",
        },
        {
          id: "ormReviews",
          title: "Reviews Feed",
          icon: <Circle size={12} />,
          navLink: "/orm/reviews",
        },
        {
          id: "ormReviewsLinks",
          title: "Reviews Links",
          icon: <Circle size={12} />,
          navLink: "/orm/reviewsLinks",
        },
        {
          id: "ticketsReport",
          title: "Tickets Report",
          icon: <Circle size={12} />,
          navLink: "/orm/ticketsReport",
        },
        {
          id: "corporateReport",
          title: "Brand Report",
          icon: <Circle size={12} />,
          navLink: "/orm/corporateReport",
        },
        {
          id: "ormTargetRatings",
          title: "Ratings",
          icon: <Circle size={12} />,
          navLink: "/orm/targetRatings",
        },
        {
          id: "ormSummary",
          title: "Review Rate",
          icon: <Circle size={12} />,
          navLink: "/orm/summary",
        },
        {
          id: "syncReviews",
          title: "Sync Reviews",
          icon: <Circle size={12} />,
          navLink: "/orm/syncReviews",
        },
        {
          id: "ormReplyTemplate",
          title: "Response template",
          icon: <Circle size={12} />,
          navLink: "/orm/replyTemplate",
        },
        {
          id: "feedback",
          title: "Complaints list",
          icon: <Circle size={12} />,
          navLink: "/feedback",
        },
        {
          id: "FeedbackReport",
          title: "Complaints report",
          icon: <Circle size={12} />,
          navLink: "/report/FeedbackReport",
        },

        {
          id: "hostelReviewSummary",
          title: "Hostel Review Summary",
          icon: <Circle size={12} />,
          navLink: "/orm/HostelReviewSummary",
        },
        {
          id: "otaLogin",
          title: "OTA Login",
          icon: <Circle size={12} />,
          navLink: "/orm/otaLogin",
        },
        {
          id: "ORMComparativeRatings",
          title: "Comparative ratings",
          icon: <Circle size={12} />,
          navLink: "/orm/ormComparativeRatings",
        },
      ];
    }
    if (modules?.includes("ORM Configuration")) {
      return [
        {
          id: "ormReviewsLinks",
          title: "Reviews Links",
          icon: <Circle size={12} />,
          navLink: "/orm/reviewsLinks",
        },
        {
          id: "syncReviews",
          title: "Sync Reviews",
          icon: <Circle size={12} />,
          navLink: "/orm/syncReviews",
        },
        {
          id: "ormReplyTemplate",
          title: "Response template",
          icon: <Circle size={12} />,
          navLink: "/orm/replyTemplate",
        },
      ];
    } else if (modules?.includes("ORM Reports")) {
      return [
        {
          id: "ormHomepage",
          title: "Home",
          icon: <Circle size={12} />,
          navLink: "/orm/homepage",
        },
        {
          id: "ormReviews",
          title: "Reviews Feed",
          icon: <Circle size={12} />,
          navLink: "/orm/reviews",
        },
        {
          id: "ticketsReport",
          title: "Tickets Report",
          icon: <Circle size={12} />,
          navLink: "/orm/ticketsReport",
        },
        role !== "Ground Staff" && {
          id: "corporateReport",
          title: "Brand Report",
          icon: <Circle size={12} />,
          navLink: "/orm/corporateReport",
        },
        {
          id: "ormTargetRatings",
          title: "Ratings",
          icon: <Circle size={12} />,
          navLink: "/orm/targetRatings",
        },
        {
          id: "ormSummary",
          title: "Review Rate",
          icon: <Circle size={12} />,
          navLink: "/orm/summary",
        },
        {
          id: "feedback",
          title: "Complaints list",
          icon: <Circle size={12} />,
          navLink: "/feedback",
        },
        {
          id: "FeedbackReport",
          title: "Complaints report",
          icon: <Circle size={12} />,
          navLink: "/report/FeedbackReport",
        },
      ];
    }
  } catch (error) {
    console.error(error);
  }
};

export default modules?.includes("ORM Configuration") ||
modules?.includes("ORM Reports")
  ? [
      {
        id: "orm",
        title: "ORM",
        icon: <Package size={20} />,
        badge: "light-warning",
        children:
          filterModules(role, modules)?.length > 0
            ? filterModules(role, modules)
            : null,
      },
    ]
  : [];
