import React, { useEffect, useState } from "react";
import { Button, Card, Col, Input, Label, Row, Table } from "reactstrap";
import { useGetUpdateColivingRoomRates } from "../../api/Coliving/Mutations";
import toaster from "../../@core/components/common/Toaster";
import { v4 as uuidv4 } from "uuid";

const tableHeaderData = [
  "Room Name",
  "1 Month",
  "2 Month",
  "3 Month",
  "4 Month",
  "5 Month",
  "6 Month",
];
export const HostelRoomRatesTable = ({ colivingList, hostelId, refetch }) => {
  const [tableData, setTableData] = useState(colivingList);
  const [updateColivingHandler, { data, error }] =
    useGetUpdateColivingRoomRates();

  useEffect(() => {
    if (colivingList?.getColivingDetails?.length > 0) {
      setTableData(colivingList?.getColivingDetails);
    } else {
      setTableData([]);
    }
  }, [colivingList]);

  const [roomwise, setRoomWise] = useState([]);

  useEffect(() => {
    let newArr = [];
    tableData?.forEach((i) => {
      let updatedPrices = Array.from({ length: 6 }, (_, idx) => ({
        period: idx + 1,
        amount: i?.prices?.[idx]?.amount || 0,
      }));
      let updateObject = {
        _id: i?._id,
        roomType: i?.roomType,
        // prices: i?.prices,
        prices: updatedPrices,
      };
      newArr.push(updateObject);
    });
    setRoomWise(newArr);
  }, [hostelId, tableData]);

  // const handleUpdatePrice = (id, name, amount) => {
  //   console.log(id, name, amount, "parameters");
  //   const updatedArray = roomwise?.map((element) => {
  //     if (element?._id === id) {
  //       const updatedPrices = element?.prices?.map((el) =>
  //         el?.period === name
  //           ? { ...el, amount: Number(amount) }
  //           : { amount: +amount, period: +name }
  //       );
  //       console.log(updatedPrices);
  //       return { ...element, prices: updatedPrices };
  //     }
  //     return element;
  //   });

  //   console.log(updatedArray);
  //   setRoomWise(updatedArray);
  // };

  const handleUpdatePrice = (id, name, amount) => {
    if (!isNaN(amount) || amount !== "") {
      const updatedAmount = amount === "" ? "" : Number(amount);

      const updatedArray = roomwise.map((element) => {
        if (element?._id === id) {
          const updatedPrices = element?.prices?.map((el) =>
            el?.period === name ? { ...el, amount: updatedAmount } : el
          );
          return { ...element, prices: updatedPrices };
        }
        return element;
      });
      setRoomWise(updatedArray);
    } else {
      toaster("error", "Enter number only");
    }
  };

  useEffect(() => {
    if (data) {
      toaster("success", `Coliving room rates saved successfully`);
    }
  }, [data]);

  const handleCreate = (updateArrayParams) => {
    if (
      updateArrayParams?.some(({ roomType, ...el }) =>
        el?.prices?.some((price) => price?.amount === "")
      )
    ) {
      toaster("error", "Prices cannot be empty");
    } else {
      const updatedParams = updateArrayParams?.map(({ roomType, ...el }) => el);
      updateColivingHandler(updatedParams);
    }
  };
  return (
    <>
      {tableData?.length > 0 && (
        <>
          <Row>
            <Col>
              <Card>
                <Table responsive>
                  <thead>
                    <tr>
                      {tableHeaderData?.map((item, i) => {
                        return <th key={i}>{item}</th>;
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{item?.roomType}</td>
                          {
                            Array?.from({ length: 6 })?.map((_, index) => {
                              const price = {
                                //_id: uuidv4(),
                                amount: item?.prices[index]?.amount || "",
                                period: index + 1,
                              };

                              return (
                                <td key={index + 1}>
                                  <Input
                                    type="number"
                                    id={`${index + 1}`}
                                    name={`${index + 1}`}
                                    value={
                                      roomwise
                                        ?.find((el) => el._id === item._id)
                                        ?.prices?.find(
                                          (r) => r?.period === price?.period
                                        )?.amount
                                    }
                                    onChange={(e) => {
                                      handleUpdatePrice(
                                        item?._id,
                                        //price?._id,
                                        price?.period,
                                        e.target.value
                                      );
                                    }}
                                    onWheelCapture={(e) => e.target.blur()}
                                    style={{ width: "100px" }}
                                  />
                                </td>
                              );
                            })
                            // );
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Button
            color="light"
            className="float-right"
            onClick={() => {
              handleCreate(roomwise);
            }}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
};
