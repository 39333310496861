//Added by Pruthvi
import React from "react";
import Chart from "react-apexcharts";
import { Col } from "reactstrap";

const DaySalesChart = ({ data }) => {
  var options = {
    chart: {
      type: "bar",
      
    },
    dataLabels: {
      enabled:false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: []
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      tickPlacement: 'on'
    },
  };
  const series = [
    {
      name: "sales",
      data: data.map((item) => item?.bookingCount),
    },
  ];

  return (
    <Col>
      <h2 align="center">Website Days Booking Report</h2>
      <div id="chart" className="p-2">
        <Chart
          responsive
          options={options}
          series={series}
          type="bar"
          height="300"
        />
      </div>
    </Col>
  );
};

export default DaySalesChart




