import React from 'react'

const ConnectPopupImage = ({popupImageUrl , toggleImage , settoggleImage}) => {
  return (
<div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // width: "65%",
        width: "auto",
        zIndex: "5",
        display:"flex",
        justifyContent:"right", 
        maxHeight:"70%",
      }}
    >
      <div style={{ width:"90%", display:"flex"}}>
        <img src={popupImageUrl} alt="" style={{ width: "95%",zIndex:"2"}} />
        <span
          style={{
            position: "fixed",
            right: "4.5%",
            zIndex: "5",
            color: "black",
            cursor: "pointer",
            backgroundColor:"yellow",
            height:"30px",
            width:"30px",
            marginTop:"0",
            marginLeft:"0",
            textAlign:"center",
            fontSize:"20px",
            fontWeight:"800",
            borderRadius:"0 5px 5px 0"
          }}
          onClick={() => toggleImage()}
        >
          X
        </span>
      </div>
    </div>
  )
}

export default ConnectPopupImage