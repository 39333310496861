// fieldConfig.js
export const hostelInfoFields = [
  { name: "hostelName", label: "Hostel Name", type: "text", readOnly: false },
  {
    name: "operationalModel",
    label: "Operational Model",
    type: "text",
    readOnly: false,
  },
  { name: "areaManager", label: "Area Manager", type: "text", readOnly: false },
  { name: "month", label: "Month", type: "text", readOnly: false },
  { name: "year", label: "Year", type: "text", readOnly: false },
  { name: "hostelCode", label: "Hostel Code", type: "number", readOnly: false },
];

export const inventoryFields = [
  { name: "No. of rooms", label: "No. of rooms", type: "number" },
  { name: "No. of beds", label: "No. of beds", type: "number" },
  { name: "Total Inventory", label: "Total Inventory", type: "number" },
  { name: "Blocked Inventory", label: "Blocked Inventory", type: "number" },
];

export const revenueFields = [
  { name: "Total Revenue", label: "Total Revenue", type: "number" },
  { name: "T A BN", label: "T A BN", type: "number" },
  { name: "T S BN", label: "T S BN", type: "number" },
  { name: "Occ%", label: "Occ%", type: "number" },
  { name: "ADR", label: "ADR", type: "number" },
  { name: "Bed Revenue", label: "Bed Revenue", type: "number" },
  { name: "F&B AOV (in INR)", label: "F&B AOV (in INR)", type: "number" },
  { name: "F&B Orders", label: "F&B Orders", type: "number" },
  {
    name: "F&B Order Per Bed Night Sold",
    label: "F&B Order Per Bed Night Sold",
    type: "number",
  },
  { name: "F&B Revenue", label: "F&B Revenue", type: "number" },
  { name: "Ancillary Revenue", label: "Ancillary Revenue", type: "number" },
  { name: "Total OPEX", label: "Total OPEX", type: "number" },
];

export const operationalExpenseFields = [
  { name: "Rent", label: "Rent", type: "number" },
  { name: "Rental Cost Per Bed", label: "Rental Cost Per Bed", type: "number" },
  {
    name: "U1: Electricity + Fuel + Genset",
    label: "U1: Electricity + Fuel + Genset",
    type: "number",
  },
  { name: "U2: Water + Tanker", label: "U2: Water + Tanker", type: "number" },
  {
    name: "U3: TV & Internet + Telephone + SW subscriptions",
    label: "U3: TV & Internet + Telephone + SW subscriptions",
    type: "number",
  },
  { name: "U4: Toiletry", label: "U4: Toiletry", type: "number" },
  { name: "U5: Laundry", label: "U5: Laundry", type: "number" },
  { name: "R&M", label: "R&M", type: "number" },
  {
    name: "Misc. (local travel, General liaising, etc.)",
    label: "Misc. (local travel, General liaising, etc.)",
    type: "number",
  },
  { name: "OTA", label: "OTA", type: "number" },
  { name: "Total Salary", label: "Total Salary", type: "number" },
  { name: "Hostel Mandays", label: "Hostel Mandays", type: "number" },
  { name: "# of Hostel Staff", label: "# of Hostel Staff", type: "number" },
  { name: "Hostel salary (Own)", label: "Hostel salary (Own)", type: "number" },
  {
    name: "Hostel salary (Agency)",
    label: "Hostel salary (Agency)",
    type: "number",
  },
  {
    name: "Hostel Salary Per Manday",
    label: "Hostel Salary Per Manday",
    type: "number",
  },
  {
    name: "Hostel welfare (Staff food, room rent & other)",
    label: "Hostel welfare (Staff food, room rent & other)",
    type: "number",
  },
  { name: "Cafe Mandays", label: "Cafe Mandays", type: "number" },
  { name: "# of Cafe Staff", label: "# of Cafe Staff", type: "number" },
  {
    name: "F&B salary + F&B welfare",
    label: "F&B salary + F&B welfare",
    type: "number",
  },
  {
    name: "Cafe Salary Per Manday",
    label: "Cafe Salary Per Manday",
    type: "number",
  },
  {
    name: "F&B material cost (= OS + P - CS - HSW - CSW)",
    label: "F&B material cost (= OS + P - CS - HSW - CSW)",
    type: "number",
  },
  { name: "Ancillary cost", label: "Ancillary cost", type: "number" },
  { name: "Other Payouts", label: "Other Payouts", type: "number" },
  { name: "I&P payout", label: "I&P payout", type: "number" },
  { name: "Franchise payout", label: "Franchise payout", type: "number" },
];

export const marginAnalysisFields = [
  {
    name: "Overall Op. Margin (in INR)",
    label: "Overall Op. Margin (in INR)",
    type: "number",
  },
  {
    name: "Hostel margin (in INR)",
    label: "Hostel margin (in INR)",
    type: "number",
  },
  {
    name: "Hostel margin (in %)",
    label: "Hostel margin (in %)",
    type: "number",
  },
  { name: "F&B margin (in INR)", label: "F&B margin (in INR)", type: "number" },
  { name: "F&B margin (in %)", label: "F&B margin (in %)", type: "number" },
  {
    name: "Ancillary margin (in INR)",
    label: "Ancillary margin (in INR)",
    type: "number",
  },
  {
    name: "Ancillary margin (in %)",
    label: "Ancillary margin (in %)",
    type: "number",
  },
  {
    name: "Analysis (Operational Margin %)",
    label: "Analysis (Operational Margin %)",
    type: "number",
  },
  { name: "Rev/Rental multiple", label: "Rev/Rental multiple", type: "number" },
  { name: "OTA %", label: "OTA %", type: "number" },
  { name: "Utilities/Total Rev", label: "Utilities/Total Rev", type: "number" },
  { name: "R&M/Total Rev", label: "R&M/Total Rev", type: "number" },
  { name: "Misc/Total Rev", label: "Misc/Total Rev", type: "number" },
  {
    name: "Hostel staff salary/(bed sales)",
    label: "Hostel staff salary/(bed sales)",
    type: "number",
  },
  {
    name: "F&B staff salary/(F&B sales)",
    label: "F&B staff salary/(F&B sales)",
    type: "number",
  },
  {
    name: "F&B food cost/(F&B sales)",
    label: "F&B food cost/(F&B sales)",
    type: "number",
  },
];

export const reviewsFeedbackFields = [
  { name: "ORM", label: "ORM", type: "number" },
  {
    name: "# of positive Reviews",
    label: "# of positive Reviews",
    type: "number",
  },
  { name: "# of neutral Reviews", label: "# of neutral Reviews", type: "text" },
  {
    name: "# of negative Reviews",
    label: "# of negative Reviews",
    type: "number",
  },
  { name: "Total # of reviews", label: "Total # of reviews", type: "number" },
];

export const remarksActionFields = [
  { name: "Overall Remarks", label: "Overall Remarks", type: "text" },
  { name: "Remarks on Ops", label: "Remarks on Ops", type: "text" },
  { name: "Remarks on CX", label: "Remarks on CX", type: "text" },
  { name: "Remarks on POS sales", label: "Remarks on POS sales", type: "text" },
  { name: "Actions/Follow-ups", label: "Actions/Follow-ups", type: "text" },
];

export const fieldConfig = {
  hostelInfo: hostelInfoFields,
  inventory: inventoryFields,
  revenue: revenueFields,
  operationalExpense: operationalExpenseFields,
  marginAnalysis: marginAnalysisFields,
  reviewsFeedback: reviewsFeedbackFields,
  remarksAction: remarksActionFields,
};

// const fieldConfig = {
//   hostelInfo: [
//     { label: "Hostel Name", name: "hostelName", type: "text", readOnly: true },
//     {
//       label: "Operational Model",
//       name: "operationalModel",
//       type: "text",
//       readOnly: true,
//     },
//     {
//       label: "Area Manager",
//       name: "areaManager",
//       type: "text",
//       readOnly: true,
//     },
//     { label: "Month", name: "month", type: "text", readOnly: true },
//     { label: "Year", name: "year", type: "text", readOnly: true },
//     {
//       label: "Hostel Code",
//       name: "hostelCode",
//       type: "number",
//       readOnly: true,
//     },
//   ],
//   inventory: [
//     { label: "No. of rooms", name: "noOfRooms", type: "number" },
//     { label: "No. of beds", name: "noOfBeds", type: "number" },
//     { label: "Total Inventory", name: "totalInventory", type: "number" },
//     { label: "Blocked Inventory", name: "blockedInventory", type: "number" },
//   ],
//   revenue: [
//     { label: "Total Revenue", name: "totalRevenue", type: "number" },
//     { label: "T A BN", name: "taBn", type: "number" },
//     { label: "T S BN", name: "tsBn", type: "number" },
//     { label: "Occ%", name: "occPercentage", type: "number" },
//     { label: "ADR", name: "adr", type: "number" },
//     { label: "Bed Revenue", name: "bedRevenue", type: "number" },
//     { label: "F&B AOV (in INR)", name: "fbAov", type: "number" },
//     { label: "F&B Orders", name: "fbOrders", type: "number" },
//     {
//       label: "F&B Order Per Bed Night Sold",
//       name: "fbOrderPerBedNight",
//       type: "number",
//     },
//     { label: "F&B Revenue", name: "fbRevenue", type: "number" },
//     { label: "Ancillary Revenue", name: "ancillaryRevenue", type: "number" },
//     { label: "Total OPEX", name: "totalOpex", type: "number" },
//   ],
//   operationalExpense: [
//     { label: "Rent", name: "rent", type: "number" },
//     { label: "Rental Cost Per Bed", name: "rentalCostPerBed", type: "number" },
//     {
//       label: "U1: Electricity + Fuel + Genset",
//       name: "electricityFuelGenset",
//       type: "number",
//     },
//     { label: "U2: Water + Tanker", name: "waterTanker", type: "number" },
//     {
//       label: "U3: TV & Internet + Telephone + SW subscriptions",
//       name: "tvInternetSubscriptions",
//       type: "number",
//     },
//     { label: "U4: Toiletry", name: "toiletry", type: "number" },
//     { label: "U5: Laundry", name: "laundry", type: "number" },
//     { label: "R&M", name: "rm", type: "number" },
//     {
//       label: "Misc. (local travel General liasing etc.)",
//       name: "miscellaneous",
//       type: "number",
//     },
//     { label: "OTA", name: "ota", type: "number" },
//     { label: "Total salary", name: "totalSalary", type: "number" },
//     { label: "Hostel Mandays", name: "hostelMandays", type: "number" },
//     { label: "# of Hostel Staff", name: "numHostelStaff", type: "number" },
//     { label: "Hostel salary (Own)", name: "hostelSalaryOwn", type: "number" },
//     {
//       label: "Hostel salary (Agency)",
//       name: "hostelSalaryAgency",
//       type: "number",
//     },
//     {
//       label: "Hostel Salary Per Manday",
//       name: "hostelSalaryPerManday",
//       type: "number",
//     },
//     {
//       label: "Hostel welfare (Staff food room rent & other)",
//       name: "hostelWelfare",
//       type: "number",
//     },
//     { label: "Cafe Mandays", name: "cafeMandays", type: "number" },
//     { label: "# of Cafe Staff", name: "numCafeStaff", type: "number" },
//     {
//       label: "F&B salary + F&B welfare",
//       name: "fbSalaryWelfare",
//       type: "number",
//     },
//     {
//       label: "Cafe Salary Per Manday",
//       name: "cafeSalaryPerManday",
//       type: "number",
//     },
//     {
//       label: "F&B material cost (= OS + P - CS - HSW - CSW)",
//       name: "fbMaterialCost",
//       type: "number",
//     },
//     { label: "Ancillary cost", name: "ancillaryCost", type: "number" },
//     { label: "Other Payouts", name: "otherPayouts", type: "number" },
//     { label: "I&P payout", name: "ipPayout", type: "number" },
//     { label: "Franchise payout", name: "franchisePayout", type: "number" },
//   ],
//   marginAnalysis: [
//     {
//       label: "Overall Op. Margin (in INR)",
//       name: "overallOpMargin",
//       type: "number",
//     },
//     { label: "Hostel margin (in INR)", name: "hostelMargin", type: "number" },
//     {
//       label: "Hostel margin (in %)",
//       name: "hostelMarginPercentage",
//       type: "number",
//     },
//     { label: "F&B margin (in INR)", name: "fbMargin", type: "number" },
//     { label: "F&B margin (in %)", name: "fbMarginPercentage", type: "number" },
//     {
//       label: "Ancillary margin (in INR)",
//       name: "ancillaryMargin",
//       type: "number",
//     },
//     {
//       label: "Ancillary margin (in %)",
//       name: "ancillaryMarginPercentage",
//       type: "number",
//     },
//     {
//       label: "Analysis (Operational Margin %)",
//       name: "operationalMarginPercentage",
//       type: "number",
//     },
//     { label: "Rev/Rental multiple", name: "revRentalMultiple", type: "number" },
//     { label: "OTA %", name: "otaPercentage", type: "number" },
//     { label: "Utilities/Total Rev", name: "utilitiesTotalRev", type: "number" },
//     { label: "R&M/Total Rev", name: "rmTotalRev", type: "number" },
//     { label: "Misc/Total Rev", name: "miscTotalRev", type: "number" },
//     {
//       label: "Hostel staff salary/(bed sales)",
//       name: "hostelStaffSalaryPerBedSales",
//       type: "number",
//     },
//     {
//       label: "F&B staff salary/(F&B sales)",
//       name: "fbStaffSalaryPerFBSales",
//       type: "number",
//     },
//     {
//       label: "F&B food cost/(F&B sales)",
//       name: "fbFoodCostPerFBSales",
//       type: "number",
//     },
//   ],
//   reviewsFeedback: [
//     { label: "ORM", name: "orm", type: "number" },
//     {
//       label: "# of positive Reviews",
//       name: "numPositiveReviews",
//       type: "number",
//     },
//     {
//       label: "# of neutral Reviews",
//       name: "numNeutralReviews",
//       type: "number",
//     },
//     {
//       label: "# of negative Reviews",
//       name: "numNegativeReviews",
//       type: "number",
//     },
//     { label: "Total # of reviews", name: "totalReviews", type: "number" },
//   ],
//   remarksAction: [
//     {
//       label: "Overall Remarks Remarks on Ops",
//       name: "overallRemarksOps",
//       type: "text",
//     },
//     { label: "Remarks on CX", name: "remarksCX", type: "text" },
//     { label: "Remarks on POS sales", name: "remarksPOSSales", type: "text" },
//     { label: "Actions/Follow-ups", name: "actionsFollowUps", type: "text" },
//   ],
// };

// export default fieldConfig;
