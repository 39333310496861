import React, { useEffect, useState } from 'react'
import { DragDrop } from "@uppy/react";
import SlideModal from "@components/common/slide-modal";
import { Row, Col, Input } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import {
    useUploadImage,
} from "../../../api/dataSource";
import { useCreateEventCategory, useUpdateEventCategory } from '../../../api/EventCategories/Mutation';
const EventsAddEditModal = ({
    open,
    handleToggle,
    refetch,
    editData,
    eventCategoryList
}) => {
    const [name, setName] = useState("")
    const [status, setStatus] = useState(false);
    const [displayOrder, setDisplayOrder] = useState("");
    const [previewArr, setPreviewArr] = useState([]);

    const [uploadImageHandler, { data: imageData, reset: imageReset }] =
        useUploadImage();
    const [createEventCategoryHandler, { loading, error, data }] = useCreateEventCategory()
    const [updateEventCategoryHandler, { data: updatedEventData }] = useUpdateEventCategory()
    const submitDataHandler = () => {

        const categoryInput = {
            name: name,
            status,
            displayOrder: parseInt(displayOrder),
            categoryIcon: imageData?.uploadImage?.url,
        };
        if (editData?._id) {
            updateEventCategoryHandler(editData?._id, categoryInput)
            toaster("success", "category updated");
            handleToggle();
            refetch();
        } else {
            createEventCategoryHandler(categoryInput);
            toaster("success", "category created");
            handleToggle();
            refetch();
        }
    };


    useEffect(() => {
        if (editData) {
            console.log("hai")
            setName(editData.name);
            setStatus(editData.status);
            setDisplayOrder(editData.displayOrder);
            // setImageUrl(editData.ImageUrl);
            setPreviewArr([editData.categoryIcon]);
        } else {
            setName("");
            setStatus(false);
            setDisplayOrder("");
            // setImageUrl(editData.ImageUrl);
            setPreviewArr([]);
        }
    }, [editData])

    const uppy = new Uppy({
        meta: { type: "avatar" },
        autoProceed: true,
        restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
            maxFileSize: 512000,
        },
    });

    uppy.use(thumbnailGenerator);
    uppy.on("thumbnail:generated", (file, preview) => {
        setPreviewArr([preview]);

        const fileName = file.name;

        const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

        if (specialCharsRegex.test(fileName)) {
            console.error("File name:", fileName);
            toaster(
                "error",
                "File name contains special characters. Please provide a valid file name."
            );
            uppy.removeFile(file.id);
            return;
        }

        uploadImageHandler(file.data, "image/walloflove");
        toaster("success", "Wall of love image uploaded successfully");
    });

    uppy.on("restriction-failed", (file, error) => {
        toaster("error", `Error uploading wall of love image: ${error.message}`);
    });

    const renderPreview = () => {
        if (previewArr.length) {
            console.log(previewArr)
            return previewArr.map((src, index) => (
                <img
                    key={index}
                    className="img-thumbnail rounded mt-2 mr-1"
                    src={src}
                    alt="avatar"
                />
            ));
        } else {
            return null;
        }
    };
    return (
        <SlideModal
            open={open}
            handleToggle={handleToggle}
            submitDataHandler={() => submitDataHandler()}
        >
            <form>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <label>Name*</label>
                                <Input
                                    autoFocus
                                    type="text"
                                    value={name}
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SwitchIcon
                                    checked={status}
                                    id="status"
                                    name="status"
                                    label="Publish on Website ?"
                                    handleChecked={(value) => setStatus(value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Display Order</label>
                                <Input
                                    autoFocus
                                    type="Number"
                                    value={displayOrder}
                                    id="displayorder"
                                    name="displayorder"
                                    placeholder="Display Order"
                                    onChange={(e) => setDisplayOrder(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <p>Upload Image</p>
                        <DragDrop
                            note="Image size should be less than 500 KB"
                            uppy={uppy}
                        />
                        {renderPreview()}
                    </Col>
                </Row>
            </form>
        </SlideModal>
    )
}

export default EventsAddEditModal