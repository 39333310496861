import gql from "graphql-tag";

export const GET_ALL_POSTS = gql`
query getposts{
    getAllPosts{
        _id
        title
        department
        description
        addedBy
        createdAt
        addedById
    }
}
`

export const GET_ALL_POST_REPLY = gql `
query getAllCommentsOfPost($id: ID){
    getAllReplyToPosts(id: $id){
        _id
        comment
        addedBy
        addedDateTime
        addedById
        replies {
            reply
            replyBy
            replyDateTime
            replyAddedById
            _id
        }
    }
}
`

export const GET_POST_DETAIL = gql`
query getdetail($id: ID){
    getDetailsOfPost(id: $id){
        _id
        image
        title
        addedBy
        description
        attachment
        disscussionEndDate
        hostels{
            _id 
            name
        }
        department
        addedBy
        createdAt
        addedById
    }
}
`

// export const GET_REPLY_ON_POST = gql`
// query getreply($id: ID){
//     getAllReplyToPosts(id: $id){
//         comment
//     }
// }
// `

export const GET_ALL_USER_LIST_TO_TAG = gql`
query getUserList($name:String){
    getUserListToTag(name:$name){
        name
        id
    }
}
`
export const GET_POSTS_PACKAGES = gql`
  query getPagginatedPostsList($filter: filter) {
    getPagginatedPostsList(filter: $filter) {
        data {
            _id
            title
            description
            addedBy
            addedById
            createdAt
        }
        count
        page
        limit
    }
  }
`;