import { DollarSign } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Post-stay Refunds")
  ? [
      //   {
      //     id: "plStatement",
      //     title: "P&L Statement Finance",
      //     icon: <DollarSign />,
      //     navLink: "/plStatement",
      //   },
    ]
  : [];
