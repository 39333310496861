// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Image from "./Images";
import Hostel from "./Hostel";
import Destination from "./Destination";
import TravellerGallery from "./TravellerGallery";
import Workation from "./Workation";
import Blog from "./Blog";
import Events from "./Events";
import Seo from "./Seo";
import Logs from "./Logs";
import CmsTabs from "@components/Cms/CmsTabs";
import { useSelector, useDispatch } from "react-redux";
import { useGetHomePageDetails } from "../../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useParams } from "react-router-dom";
import { NewDestinations } from "./NewDestinations";
import { NewImages } from "./NewImages";
import Tagline from "./Tagline";

const tabOptions = [
  {
    id: 0,
    title: "Images",
    navLink: "/cms/homepage",
    formComponent: <Image />,
  },
  {
    id: 1,
    title: "Hostels",
    formComponent: <Hostel />,
  },
  {
    id: 2,
    title: "Destinations",
    formComponent: <Destination />,
  },
  {
    id: 3,
    title: "Workations",
    formComponent: <Workation />,
  },
  {
    id: 4,
    title: "Events",
    formComponent: <Events />,
  },
  {
    id: 5,
    title: "Blogs",
    formComponent: <Blog />,
  },
  {
    id: 6,
    title: "Traveller Gallery",
    formComponent: <TravellerGallery />,
  },
  {
    id: 7,
    title: "SEO",
    formComponent: <Seo />,
  },
  {
    id: 8,
    title: "Destination category",
    navLink: "cms/newdestinations",
    formComponent: <NewDestinations />,
  },
  {
    id: 9,
    title: "Offer Image",
    formComponent: <NewImages />,
  },
  {
    id: 10,
    title: "Booking widget tagline",
    formComponent: <Tagline />,
  },
  {
    id: 11,
    title: "Logs",
    formComponent: <Logs />,
  },
];

const AddHomePageTabs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const state = useSelector((state) => state?.cms);

  const { data, refetch } = useGetHomePageDetails(id); // "hosteller" is used to get landing page doc

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getHomePageDetails) {
      dispatch(tableDataHandler(data?.getHomePageDetails));
    }
    refetch();
  }, [data, dispatch]);
  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  {state?.tableData?.slug} -<span>&nbsp;</span>
                  <b>
                    {tabOptions.find((item) => item.id === active).title}
                    <span>&nbsp;</span>Section
                  </b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddHomePageTabs;
