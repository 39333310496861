// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import AdminUsersTable from "./AdminUsersTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import {
  useGetAllPaginatedAdminUsers,
  useHostelList,
} from "../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";

const AdminUsers = () => {
  // const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  // ! Coded by Ashwin
  // const { data, refetch } = useGetAllPaginatedStaffs(limit, page); // ! Coded by Ashwin

  const [totalPage, setTotalPage] = useState(1);
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [departmentName, setDepartmentName] = useState("");
  const [departmentName, setDepartmentName] = useState(null);
  // const [staffRole, setStaffRole] = useState("");
  const [staffRole, setStaffRole] = useState(null);
  const [modules, setModules] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  const { data: allHostelsData } = useHostelList();
  const { data, refetch } = useGetAllPaginatedAdminUsers(
    limit,
    page,
    filteredData
  );

  const DepartmentList = [
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    { label: "Technology", value: "Technology" },
    { label: "Management", value: "Management" },
    { label: "Customer Experience", value: "Customer Experience" },
    { label: "Operations", value: "Operations" },
    { label: "Finance", value: "Finance" },
    { label: "HR", value: "HR" },
    { label: "Purchase", value: "Purchase" },
    { label: "Reservation", value: "Reservation" },
    { label: "Owner", value: "Owner" },
  ];

  const StaffRoleList = [
    { label: "Super Admin", value: "Super Admin" },
    { label: "Reservation", value: "Reservation" },
    { label: "Listing", value: "Listing" },
    { label: "Sales Admin", value: "Sales Admin" },
    { label: "Marketing", value: "Marketing" },
    { label: "Marketing Admin", value: "Marketing Admin" },
    { label: "Developer", value: "Developer" },
    { label: "Executive", value: "Executive" },
    { label: "Manager", value: "Manager" },
    { label: "Area Manager", value: "Area Manager" },
    { label: "Revenue", value: "Revenue" },
    { label: "Customer Experience", value: "Customer Experience" },
    { label: "Ground Staff", value: "Ground Staff" },
    { label: "HR", value: "HR" },
    { label: "Regional Manager", value: "Regional Manager" },
    { label: "Reservation Executive", value: "Reservation Executive" },
    {
      label: "Reservation Assistant Manager",
      value: "Reservation Assistant Manager",
    },
    { label: "Reservation Manager", value: "Reservation Manager" },
    { label: "Reservation Head", value: "Reservation Head" },
    { label: "CEO", value: "CEO" },
    { label: "Owner", value: "Owner" },
  ];

  const ModulesList = [
    { label: "Packages", value: "Packages" },
    { label: "CMS", value: "CMS" },
    { label: "Blogs", value: "Blogs" },
    { label: "Instant Communication", value: "Instant Communication" },
    // { label: "Complaints list in ORM", value: "In-stay feedback" },
    { label: "ORM Configuration", value: "ORM Configuration" },
    { label: "ORM Reports", value: "ORM Reports" },
    { label: "Coupon Admin", value: "Coupon Admin" },
    { label: "Glu Configuration", value: "Glu Configuration" },
    { label: "Glu Reports", value: "Glu Reports" },
    { label: "Reports", value: "Reports" },
    { label: "Employee Profile", value: "Employee Profile" },
    { label: "Employee Attendance", value: "Employee Attendance" },
    { label: "Loyalty", value: "Loyalty" },
    { label: "Stepout", value: "Stepout" },
    { label: "Admin Users", value: "Admin Users" },
    { label: "Website Users", value: "Website Users" },
    { label: "Wallet", value: "Wallet" },
    { label: "Operations", value: "Operations" },
    { label: "Unbox Configuration", value: "Unbox Configuration" },
    { label: "Unbox Reports", value: "Unbox Reports" },
    { label: "Ezee Meals", value: "Ezee Meals" },
    { label: "Day Book", value: "Day Book" },
    { label: "Payment Request", value: "Payment Request" },
    { label: "Purchase Request", value: "Purchase Request" },
    { label: "Rate Gain", value: "Rate Gain" },
    { label: "Merchandise Store", value: "Merchandise Store" },
    { label: "Booking Dashboard", value: "Booking Dashboard" },
    { label: "Prestay Refund", value: "Prestay Refund" },
    { label: "Post-stay Refunds", value: "Post-stay Refunds" },
    { label: "Owner", value: "Owner" },
    { label: "Bus Booking", value: "Bus Booking" },
    { label: "Coliving", value: "Coliving" },
  ];

  // useEffect(() => {
  //   refetch();
  // }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedAdminUsersList?.data) {
      setTableData(data?.getPaginatedAdminUsersList?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedAdminUsersList?.count / limit));
  }, [data, limit]);

  useEffect(() => {
    if (data?.getPaginatedAdminUsersList?.data.length) {
      let editData = data?.getPaginatedAdminUsersList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedAdminUsersList?.data, id]);

  useEffect(() => {
    const HostelsList = allHostelsData?.getHostelList?.map((el) => {
      return { label: el.name, value: el._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const submitHandler = () => {
    const data = {
      hostels: hostel?.length > 0 ? hostel?.map((item) => item?.value) : null,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      // department: departmentName?.value ? departmentName?.value : null,
      department: departmentName?.map((item) => item?.value),
      // role: staffRole?.value ? staffRole?.value : null,
      role: staffRole?.map((item) => item?.value),
      modules: modules?.map((item) => item?.value),
    };
    setFilteredData(data);
  };

  const handleReset = () => {
    setHostel([]);
    setName("");
    setEmail("");
    // setDepartmentName("");
    setDepartmentName(null);
    // setStaffRole("");
    setModules(null);
    setStaffRole(null);
    setFilteredData(null);
    setPage(1);
  };

  // useEffect(() => {
  //   if(localStorage.getItem("email") !=="dushyant@thehosteller.com")
  //      history.push('/');
  // }, [localStorage]);

  return (
    //localStorage.getItem("email")==="dushyant@thehosteller.com" &&
    localStorage.getItem("modules")?.includes("Admin Users") && (
      <div>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel Name
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Name
              </Label>
              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                Email
              </Label>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="departmentName">
                Department Name
              </Label>
              {/* <Select
                isClearable={false}
                id="departmentName"
                name="departmentName"
                value={departmentName}
                options={DepartmentList}
                onChange={(value) => setDepartmentName(value)}
                classNamePrefix="select"
              /> */}
              <Select
                isClearable={true}
                id="departmentName"
                name="departmentName"
                isMulti
                theme={selectThemeColors}
                options={DepartmentList}
                value={departmentName}
                onChange={(value) => setDepartmentName(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffRole">
                Role
              </Label>
              {/* <Select
                isClearable={false}
                id="staffRole"
                name="staffRole"
                value={staffRole}
                options={StaffRoleList}
                onChange={(value) => setStaffRole(value)}
                classNamePrefix="select"
              /> */}
              <Select
                isClearable={true}
                id="staffRole"
                name="staffRole"
                isMulti
                theme={selectThemeColors}
                options={StaffRoleList}
                value={staffRole}
                onChange={(value) => setStaffRole(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="modules">
                Module
              </Label>

              <Select
                isClearable={true}
                id="modules"
                name="modules"
                isMulti
                theme={selectThemeColors}
                options={ModulesList}
                value={modules}
                onChange={(value) => setModules(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                submitHandler();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        {/* {tableData.length > 0 ? (
          <> */}
        <AdminUsersTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          DepartmentList={DepartmentList}
          StaffRoleList={StaffRoleList}
        />
        {/* </>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )} */}
      </div>
    )
  );
};

export default AdminUsers;
