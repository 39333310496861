import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useAddBlogCategory,
  useUpdateBlogCategory,
  useUploadImage,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [categoryName, setCategoryName] = useState("");
  const [url, setUrl] = useState("");
  const [seo, setSeo] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [status, setStatus] = useState(false);
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();
  const [addBlogCategoryHandler, { data }] = useAddBlogCategory();
  const [updateBlogCategoryHandler, { data: updatedBlogCategoryData }] =
    useUpdateBlogCategory();

  useEffect(() => {
    if (data?.createBlogCategory?.categoryName || updatedBlogCategoryData) {
      refetch();
      handleToggle();

      toaster("success", "Blog Category added successfully");
    }
  }, [data, updatedBlogCategoryData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImagePreview([preview]);
    uploadImageHandler(file.data, "icons/Amenities");
  });

  const renderPreview = () =>
    imagePreview ? (
      <img className="rounded mr-1 mb-2 w-50" src={imagePreview} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImagePreview(imageData?.uploadImage?.url);
  }, [imageData]);
  const submitDataHandler = () => {
    let data = {
      categoryName,
      url,
      seo: { metaTitle, metaDesc, keywords },
      image: imagePreview,
      status,
    };

    if (data.categoryName) {
      if (editData?.length) {
        const { _id } = editData[0];

        updateBlogCategoryHandler(_id, data);
      } else {
        addBlogCategoryHandler(data);
      }
      setCategoryName("");
      setUrl("");
      setSeo("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setImagePreview("");
      setStatus(false);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      setCategoryName(
        editData[0]?.categoryName !== null ? editData[0]?.categoryName : ""
      );
      setMetaTitle(
        editData[0]?.seo?.metaTitle !== null ? editData[0]?.seo?.metaTitle : ""
      );
      setMetaDesc(
        editData[0]?.seo?.metaDesc !== null ? editData[0]?.seo?.metaDesc : ""
      );
      setKeywords(
        editData[0]?.seo?.keywords !== null ? editData[0]?.seo?.keywords : ""
      );
      setUrl(editData[0]?.url);

      setImagePreview(editData[0]?.image);
      setStatus(editData[0]?.status !== null ? editData[0]?.status : false);
    } else {
      setCategoryName("");
      setUrl("");
      setSeo("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setImagePreview("");
      setStatus(false);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Category Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="categoryName">
                Category Name
              </Label>
              <Input
                type="text"
                value={categoryName}
                id="categoryName"
                name="categoryName"
                placeholder="Add Category Name"
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! SEO */}
          <Col sm="11">
            <FormGroup className="w-100">
              <Label className="form-label" for="seo">
                SEO
              </Label>

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Title
                </Label>
                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add Meta Title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Description
                </Label>
                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add Meta Desc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Keywords
                </Label>
                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add Keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </Col>
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL (Format: /blogs/category/category-name)
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add URL"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Image */}
          {/* <Col sm="12">
            <p>Upload Image</p>
            <DragDrop
              note="Image size should be less than 500KB"
              width="100%"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop image here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <Col sm="6">
              <Label className="form-label w-100">Image preview here</Label>
              {imageLoad ? "Uploading image..." : renderPreview()}
            </Col>
          </Col> */}

          {/* // ! Status */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>

          {/* // ! Logs */}
          {editData?.length && editData[0]?.logs && (
            <Col sm="12">
              {/* <hr/> */}
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>

                <br />
                {editData[0]?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
