import InvestorData from "../../views/InvestorData/index";

const FinanceRoutes = [
  {
    path: "/investorData",
    component: InvestorData,
    exact: true,
  },
];
export default FinanceRoutes;
