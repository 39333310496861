import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_EMPLOYEE_ROSTER = gql`
  mutation ($employeeRosterInput: [EmployeeRosterInput]) {
    createEmployeeRoster(employeeRosterInput: $employeeRosterInput) {
      status
    }
  }
`;

export const useCreateEmployeeRoster = () => {
  const [createEmployeeRoster, { loading, error, data, reset }] = useMutation(
    CREATE_EMPLOYEE_ROSTER
  );
  useLoadingHandler(loading);
  const createEmployeeRosterHandler = (employeeRosterInput) => {
    console.log(employeeRosterInput, "Employee Roster Input");
    createEmployeeRoster({
      variables: {
        employeeRosterInput,
      },
    });
  };

  return [createEmployeeRosterHandler, { loading, error, data, reset }];
};
