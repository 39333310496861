import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import Select from "react-select";
import { useCreateEmployeeMonthlyAttendance, useProcessEmployeeMonthlySalary } from '../../../api/EmployeeMonthlyAttendance/Mutation';
import { useGetEmployeeAttendanceExportFile, useGetEmployeeProcessedSalariesExportFile } from '../../../api/dataSource';
import toaster from "@components/common/Toaster";
import { usePublishMonthlyPayslip, useUpdateMonthlyPaysheet } from '../../../api/HR/Mutation';
import SwitchIcon from "@components/common/switch-icon";
import { useGetMonthlyPublishedPayslip } from '../../../api/HR/Queries';




const MonthsList = [
    { label: "January", value: "1" },
    { label: "February ", value: "2" },
    { label: "March", value: "3" },
    { label: "April ", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
];

const YearList = [
    { label: "2025", value: "2025" },
    { label: "2024", value: "2024" },
    { label: "2023", value: "2023" },
];

const ProcessPaySlip = () => {
    const [year, setYear] = useState({ label: "2025", value: "2025" });
    const [publish, setPublish] = useState(false)
    const [month, setMonth] = useState({
        label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
            .label,
        value: new Date().getMonth() + 1,
    });



    const [createEmployeeMonthlyAttendanceHandler, { data: CreatingAttendanceData }] = useCreateEmployeeMonthlyAttendance()
    const [processEmployeeMonthlySalaryHandler, { data: processedSalaryData }] = useProcessEmployeeMonthlySalary()
    // const [getEmployeeMonthlyPayslipHandler, { data: paySlipData }] = useViewEmployeeMonthlypaySlip()
    // console.log(paySlipData)
    const [getEmployeeAttendanceExportFileHandler, { data: attendanceExportFile }] = useGetEmployeeAttendanceExportFile();
    const [getEmployeeProcessedSalariesPaysheetHandler, { data: paySheetExportFile }] = useGetEmployeeProcessedSalariesExportFile();
    const [publishMonthlyPayslipHandler] = usePublishMonthlyPayslip();
    const { loading, error, data, refetch } = useGetMonthlyPublishedPayslip(Number(month.value), Number(year.value));
    const [updatePublishMonthlyPayslipHandler, { data: updatedPaysheetData, refetch: updatePaysheetrefetch, loading: updatePaysheetLoading, }] = useUpdateMonthlyPaysheet()

    const updatePayslipPublish = (month, year, publish) => {
        setPublish(publish);

        publishMonthlyPayslipHandler(month, year, publish)
        refetch();
    }

    useEffect(() => {
        // console.log(CreatingAttendanceData?.createMonthlyAttendance?.message)
        if (CreatingAttendanceData?.createMonthlyAttendance?.message) {
            toaster("success", CreatingAttendanceData?.createMonthlyAttendance?.message)
        }

        if (CreatingAttendanceData?.createMonthlyAttendance?.xlsx?.data) {
            if (CreatingAttendanceData?.createMonthlyAttendance?.xlsx?.data.length > 0) {
                const byteArray = new Uint8Array(
                    CreatingAttendanceData?.createMonthlyAttendance?.xlsx?.data
                );
                const blob = new Blob([byteArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `monthlyAttendance_${month.value}_${year.value}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
    }, [CreatingAttendanceData])

    useEffect(() => {

        if (attendanceExportFile?.getAttendanceXlsxExport?.data) {

            if (attendanceExportFile?.getAttendanceXlsxExport?.data.length > 0) {
                const byteArray = new Uint8Array(
                    attendanceExportFile?.getAttendanceXlsxExport?.data
                );
                const blob = new Blob([byteArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `ProcessedMonthlyAttendance_${month.value}_${year.value}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
    }, [attendanceExportFile])



    useEffect(() => {
        if (paySheetExportFile?.getProcessedPaySheet?.data) {
            if (paySheetExportFile?.getProcessedPaySheet?.data?.length > 0) {
                const byteArray = new Uint8Array(
                    paySheetExportFile?.getProcessedPaySheet?.data
                );
                const blob = new Blob([byteArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `ProcessedSalaries_${month.value}_${year.value}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
    }, [paySheetExportFile])

    useEffect(() => {
        if (data) {
            console.log("changing publish---------")
            setPublish(data?.getCurrentMonthPublishedPayslip)
        }
    }, [data])

    return (
        localStorage.getItem("modules")?.includes("Employee Attendance") && (
            <div>
                <Row>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="year">
                                Year
                            </Label>
                            <Select
                                isSearchable={true}
                                id="year"
                                name="year"
                                options={YearList}
                                required
                                value={year}
                                onChange={(value) => setYear(value)}
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="month">
                                Months
                            </Label>
                            <Select
                                isSearchable={true}
                                id="month"
                                name="month"
                                options={MonthsList}
                                required
                                value={month}
                                onChange={(value) => setMonth(value)}
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>

                </Row>
                <hr />
                <Row className="mt-2">
                    {localStorage.getItem("role") === "HR" && (
                        <Col >
                            <ul>
                                <li>
                                    After marking monthly attendance, press "Process Attendance".
                                </li>
                            </ul>
                            <Button.Ripple
                                type="button"
                                color="success"
                                onClick={() => createEmployeeMonthlyAttendanceHandler(Number(month?.value), Number(year?.value))}
                                className="ml-4"
                            >
                                Process attendance
                            </Button.Ripple>



                        </Col>
                    )}
                </Row>
                <hr />
                <Row className="mt-2">
                    {localStorage.getItem("role") === "HR" && (
                        <Col >
                            <ul>
                                <li>
                                    Once attendance is processed, verify the exported file to ensure the data is correct.
                                </li>
                                <li>
                                    If the data is accurate, proceed to process salaries.
                                </li>
                            </ul>
                            <Button.Ripple
                                type="button"
                                color="success"
                                onClick={() => processEmployeeMonthlySalaryHandler(Number(month?.value), Number(year?.value))}
                                className="ml-4"
                            >
                                Process salary
                            </Button.Ripple>
                        </Col>
                    )}
                </Row>
                <hr />
                <Row className="mt-2">
                    {localStorage.getItem("role") === "HR" && (
                        <Col>
                            <ul>
                                <li>
                                    After processing salaries, import the monthly salary values and press "Export Processed Salary".
                                </li>
                            </ul>


                            <Button.Ripple
                                onClick={() => getEmployeeProcessedSalariesPaysheetHandler(Number(month?.value), Number(year?.value))}
                                type="button"
                                color="success"
                                className="ml-4"
                            >
                                Export Processed Salary
                            </Button.Ripple>
                        </Col>
                    )}
                </Row>
                <hr />
                <Row className="mt-2">
                    {localStorage.getItem("role") === "HR" && (
                        <Col>
                            <SwitchIcon
                                checked={publish}
                                id="status"
                                name="status"
                                label="Publish payslip"
                                handleChecked={(value) => updatePayslipPublish(Number(month?.value), Number(year?.value), value)}
                            />
                        </Col>
                    )}
                </Row>
                <hr />
            </div>
        )
    )
}

export default ProcessPaySlip