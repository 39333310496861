import PLStatementComponent from "../../views/P&LStatement/index";

const PLStatementRoutes = [
  {
    path: "/plStatement",
    component: PLStatementComponent,
    exact: true,
  },
];

export default PLStatementRoutes;
