import React from "react";
import { Card, CardHeader, CardBody, Table } from "reactstrap";

const InvestorDataTable = ({ investorData }) => {
  const fieldLabels = {
    month: "Month",
    year: "Year",
    noOfHostels: "No of Hostels",
    noOfCocoHotels: "No OF COCO Hotels",
    noOfFocoOmcHostels: "No OF FOCO/OMC Hostels",
    noOfFofoHostels: "No OF FOFO Hostels",
    noOfBeds: "No of Beds",
    noOfCocoHotelBeds: "No OF COCO Hostel Beds",
    noOfFocoOmcHostelBeds: "No FOCO/OMC Hostel Beds",
    noOfFofoHostelBeds: "No OF FOFO Hostel Beds",
    totalAvailableBedNights: "T A Bed Nights (Total)",
    cocoBedNights: "T A Bed Nights (COCO)",
    focoOmcBedNights: "T A Bed Nights (Others (FOCO/OMC))",
    fofoBedNights: "T A Bed Nights (Others (FOFO))",
    totalOccupancy: "Total Occupancy (Overall)",
    cocoOccupancy: "Occupancy (Company Owned)",
    focoOmcOccupancy: "Occupancy (Others (FOCO/OMC))",
    fofoOccupancy: "Occupancy (Others (FOFO))",
    totalSoldBedNights: "T S Bed Nights (Total)",
    cocoSoldBedNights: "T S Bed Nights (Company Owned)",
    focoOmcSoldBedNights: "T S Bed Nights (Others (FOCO/OMC))",
    fofoSoldBedNights: "T S Bed Nights (Others (FOFO))",
    adrOverall: "ADR (Overall)",
    adrCoco: "ADR (Company Owned)",
    adrFocoOmc: "ADR (Others (FOCO/OMC))",
    adrFofo: "ADR (Others (FOFO))",
    totalPxOverall: "Total px (overall)",
    noOfPxCoco: "No. of px (Company Owned)",
    noOfPxFocoOmc: "No. of px (Others (FOCO/OMC))",
    noOfPxFofo: "No. of px (Others (FOFO))",
    cocoBedRevenue: "Bed Revenue (COCO)",
    cocoCafeRevenue: "Cafe Revenue (COCO)",
    cocoAncillaryRevenue: "Ancillary & Local Revenue (COCO)",
    cocoTravelRevenue: "Central Travel Revenue (COCO)",
    cocoGrossRevenue: "Gross Company Owned Revenue",
    cocoRentals: "Rentals (COCO)",
    cocoHostelSalary: "Hostel Salary (COCO)",
    cocoUtilities: "Utilities/R&M (COCO)",
    cocoCommissions: "Commissions (COCO)",
    cocoMiscellaneous: "Miscellaneous (COCO)",
    cocoCafe: "Cafe (COCO)",
    cocoAncillary: "Ancillary & Local (COCO)",
    cocoTravel: "Central Travel (COCO)",
    cocoGrossExpense: "Gross Company Owned Expense",
    cocoOperationalMargin: "Operational Margin (COCO)",
    cocoOperationalMarginPercentage: "Operational Margin % (COCO)",
    focoOmcBedRevenue: "Bed Revenue (FOCO & OMC)",
    focoOmcCafeRevenue: "Cafe Revenue (FOCO & OMC)",
    focoOmcAncillaryRevenue: "Ancillary & Local Revenue (FOCO & OMC)",
    focoOmcTravelRevenue: "Central Travel Revenue (FOCO & OMC)",
    focoOmcGrossRevenue: "Gross FOCO & OMC Revenue",
    focoOmcRentals: "Rentals (FOCO & OMC)",
    focoOmcHostelSalary: "Hostel Salary (FOCO & OMC)",
    focoOmcUtilities: "Utilities/R&M (FOCO & OMC)",
    focoOmcCommissions: "Commissions (FOCO & OMC)",
    focoOmcMiscellaneous: "Miscellaneous (FOCO & OMC)",
    focoOmcCafe: "Cafe (FOCO & OMC)",
    focoOmcAncillary: "Ancillary & Local (FOCO & OMC)",
    focoOmcPartnerPayout: "Partner Payout (FOCO & OMC)",
    focoOmcGrossExpense: "Gross FOCO & OMC Expense",
    focoOmcOperationalMargin: "Operational Margin (FOCO & OMC)",
    focoOmcOperationalMarginPercentage: "Operational Margin % (FOCO & OMC)",
    fofoBedRevenue: "Bed Revenue (FOFO)",
    fofoCafeRevenue: "Cafe Revenue (FOFO)",
    fofoAncillaryRevenue: "Ancillary & Local Revenue (FOFO)",
    fofoTravelRevenue: "Central Travel Revenue (FOFO)",
    fofoGrossRevenue: "Gross FOFO Revenue",
    fofoRoyalty: "ROYALTY (FOFO)",
    fofoPlatformFees: "PLATFORM FEES (FOFO)",
    fofoPartnerPayout: "Partner Payout (FOFO)",
    fofoAncillary: "Ancillary & Local (FOFO)",
    fofoGrossExpense: "Gross FOFO Expense",
    fofoOperationalMargin: "Operational Margin (FOFO)",
    fofoOperationalMarginPercentage: "Operational Margin % (FOFO)",
    hoRevenue: "REVENUE (HO Level)",
    hoMembership: "MEMBERSHIP (HO Level)",
    hoOtherIncome: "ANY OTHER INCOME (Platform Fee)",
    hoGrossRevenue: "Gross HO Revenue",
    hoEmployeeBenefit: "Employee Benefit (HO Level)",
    hoMarketing: "Marketing (HO Level)",
    hoTravelling: "Travelling (HO Level)",
    hoTechSubscriptions: "Tech & Subscriptions (HO Level)",
    hoOfficeRentals: "Office Rentals (HO Level)",
    hoUtilities: "Utilities/R&M (HO Level)",
    hoLegal: "Legal/Compliances (HO Level)",
    hoMiscellaneous: "Miscellaneous (HO Level)",
    hoGrossExpense: "Gross HO Expense",
    totalGmv: "GMV (TOTAL TOPLINE)",
    grossRevenue: "Gross Revenue",
    operationalExpenses: "Operational Expenses",
    ebitda: "EBITDA & Asset Rental",
    ebitdaPercentage: "EBITDA & Asset Rental %",
    financeCost: "Finance Cost",
    oneTimeExpenses: "One-Time Expenses",
    depreciation: "Depreciation",
    assetRental: "Asset Partner Rental",
    notableOneTimeExp: "Notable One-Time Exp.",
    pbt: "PBT",
    pbtPercentage: "PBT %",
    cashBankBalance: "Cash & Bank Balance",
    totalArpc: "Total ARPC (ADR+ACR+ATR)",
    nonBedArpc: "Non-Bed ARPC",
    experiencesArpc: "Experiences ARPC",
    otherBedRevRatio: "Others Bed Rev/Company Owned Bed Rev",
    customerAcquisitionCost: "Customer Acquisition Cost (CAC)",
    cacPercentage: "CAC %",
    rentalsToRevenueRatio: "Rentals/Total Revenue Ratio",
    staffWelfareToRevenueRatio: "Staff Welfare/Total Revenue Ratio",
    utilitiesToRevenueRatio: "Utilities/Total Revenue Ratio",
    hoExpenseToRevenueRatio: "HO Expenses/Total Revenue Ratio",
    bedMarginsPercentage: "Bed Margins %",
    cafeMarginsPercentage: "Cafe Margins %",
    centralTravelMarginsPercentage: "Central Travel Margins %",
    commissionableBookingsPercentage: "Commissionable Bookings %",
    nonCommissionableBookingsPercentage: "Non-Commissionable Bookings %",
    brandScore: "Brand Score",
    totalReviews: "Total Reviews",
    bookingSourceBookingCom: "Booking Source (Booking.com)",
    bookingSourceGoMmt: "Booking Source (Go-MMT)",
    bookingSourceHostelworld: "Booking Source (Hostelworld)",
    bookingSourceAgoda: "Booking Source (Agoda)",
    bookingSourceGoogle: "Booking Source (Google)",
    bookingSourceWebsite: "Booking Source (Website)",
    bookingSourceWalkin: "Booking Source (Walk-In)",
    bookingSourceExtension: "Booking Source (Extension)",
    bookingSourceThHo: "Booking Source (TH HO)",
    bookingSourceB2B: "Booking Source (B2B)",
    bookingSourceTravelDesk: "Booking Source (Travel Desk)",
    bookingSourceOthers: "Booking Source (Others)",
  };

  const getMetrics = () => {
    if (investorData && investorData.length > 0) {
      const firstItem = investorData[0];
      return Object.keys(firstItem).filter(
        (key) => key !== "_id" && key !== "month" && key !== "year"
      );
    }
    return [];
  };

  const getMonthYearColumns = () => {
    if (Array.isArray(investorData)) {
      return investorData.map((item) => `${item.month} ${item.year}`);
    }
    return [];
  };

  return (
    <Card>
      <CardHeader>
        <h4>Investor Data</h4>
      </CardHeader>
      <CardBody>
        <Table bordered striped responsive>
          <thead>
            <tr>
              <th
                style={{
                  position: "sticky",
                  left: 0,
                  backgroundColor: "#f4f4f4",
                  zIndex: 1,
                }}
              >
                Metric
              </th>
              {getMonthYearColumns().map((monthYear, index) => (
                <th key={index}>{monthYear}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getMetrics().map((metric) => (
              <tr key={metric}>
                <td
                  style={{
                    position: "sticky",
                    left: 0,
                    backgroundColor: "#f9f9f9",
                    fontWeight: "bold",
                    zIndex: 1,
                  }}
                >
                  {fieldLabels[metric] || metric}{" "}
                </td>
                {investorData.map((item) => (
                  <td key={item._id}>
                    {item[metric] !== null && item[metric] !== undefined
                      ? item[metric]
                      : "N/A"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default InvestorDataTable;
