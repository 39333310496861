// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_PAGINATED_PARTNERNEW = gql`
  query getPaginatedPartnerNewList($filter: filter) {
    getPaginatedPartnerNewList(filter: $filter) {
      count
      page
      limit
      data {
            _id
            hostelLocation
            hostelName
            image
            label1{
                label
                value
            }
            label2{
                label
                value
            }
            label3{
                label
                value
            }
            label4{
                label
                value
            }
            status
            caseStudyLink
            formLink
            logs
      }
    }
  }
`;

