import gql from "graphql-tag";

export const GET_WEBSITE_BOOKING = gql`
  query ($filter: filter) {
    getWebsiteBookingReport(filter: $filter) {
      data {
        _id
        contactDetails {
          name
        }
        payableAmount
        checkoutDate
        checkinDate
        providerRefId
        createdAt
        bookingDate
        hostelId {
          _id
          name
        }
        contactDetails {
          name
          mobile
          email
        }
        rooms {
          roomName
          quantity
        }
        status
        coupon
        refundType
        refundStatus
        providerRefundRefId
        walletDebit
        cancelDate
        walletRefund
        razorpayRefund
        userId {
          fullName
          email
        }
        bookingSource
        utm_source
        utm_medium
        utm_campaign
        isBlackListed
        UTM
        coliving
      }
      limit
      page
      count
    }
  }
`;
