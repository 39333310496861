import React, { useEffect, useState } from 'react'
import HostelWiseTable from './HostelWiseTable';
import { useGetAllHostelListColiving } from '../../api/Coliving/Queries';
import { Col, Input, Row } from 'reactstrap';

export const ColivingRoomRates = () => {

    const [tableData, setTableData] = useState(null);
    const [filter, setFilter] = useState("")

    const { data: allHostelListColiving } = useGetAllHostelListColiving();

    useEffect(() => {
        if (allHostelListColiving?.getHostelListColiving?.length && filter) {
            setTableData(allHostelListColiving?.getHostelListColiving?.filter((hostel, i) => hostel?.name?.toLowerCase()?.includes(filter?.toLowerCase())));
        } else if (!filter) {
            setTableData(allHostelListColiving?.getHostelListColiving)
        }
        else {
            setTableData([]);
        }
    }, [allHostelListColiving?.getHostelListColiving, filter]);
    return (
        <div>
            <Row>
                <Col sm="4">
                    <Input
                        type="text"
                        placeholder="Filter Hostels"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mt-2">
                    <HostelWiseTable hostelList={tableData} />
                </Col>
            </Row>
        </div>
    );
}
